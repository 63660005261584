<template>
    <label
        class="pendo-toggle"
        :class="{
            'is-inline-label': !!inlineLabel,
            'is-checked': value,
            'is-disabled': disabled
        }"
        @click.prevent>
        <div
            v-if="!!topLabel"
            :id="topLabelId"
            class="pendo-toggle__label pendo-toggle__label--top">
            {{ topLabel }}
        </div>
        <input
            :checked="value"
            class="pendo-toggle__input"
            tabindex="-1"
            aria-hidden="true"
            :disabled="disabled"
            type="checkbox"
            @keypress.enter.space.prevent="toggle">
        <div
            class="pendo-toggle__slide"
            role="switch"
            :aria-checked="value"
            :aria-disabled="disabled"
            :aria-labelledby="toggleLabelId"
            :tabindex="disabled ? -1 : 0"
            @click="toggle"
            @keypress.enter.space.prevent="toggle">
            <div class="pendo-toggle__inner">
                <div class="pendo-toggle__handle" />
                <div class="pendo-toggle__icon">
                    <pendo-icon
                        v-if="value"
                        type="check"
                        aria-hidden="true"
                        stroke-width="3"
                        size="14" />
                </div>
            </div>
        </div>
        <div
            v-if="!!bottomLabel"
            :id="bottomLabelId"
            class="pendo-toggle__label pendo-toggle__label--bottom">
            {{ bottomLabel }}
        </div>
    </label>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import labelsMixin from '@/mixins/labels';

export default {
    name: 'PendoToggle',
    components: {
        PendoIcon
    },
    mixins: [labelsMixin],
    props: {
        /**
         * bound value
         */
        value: {
            type: Boolean,
            required: true
        },
        /**
         * disable user interactions
         */
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            topLabelId: `pendo-toggle-${this._uid}__label--top`,
            bottomLabelId: `pendo-toggle-${this._uid}__label--bottom`
        };
    },
    computed: {
        toggleLabelId () {
            if (this.topLabel) {
                return this.topLabelId;
            }

            if (this.bottomLabel) {
                return this.bottomLabelId;
            }

            return null;
        }
    },
    methods: {
        toggle () {
            if (this.disabled) {
                return;
            }
            /**
             * Emitted when the binding value changes
             *
             * @event change
             * @property Any Current model value
             */
            this.$emit('change', !this.value);
            /**
             * @ignore
             * @deprecated Use `@change` event instead.
             * @since 2.8.8
             * @property {Event} $event - DOM Event
             */
            this.$emit('input', !this.value);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-toggle) {
    display: inline-block;
    margin: 0;

    .pendo-toggle__label {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-gray-110;

        &--top {
            font-weight: 600;
            align-items: start;
        }
        &--bottom {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    &.is-inline-label {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        grid-gap: 8px;

        .pendo-toggle__label {
            display: grid;
            align-items: center;
        }
    }

    @include element(input) {
        opacity: 0;
        position: absolute;
    }

    @include element(slide) {
        box-sizing: initial;
        background-clip: content-box;
        background-color: $toggle-off-background;
        display: block;
        height: 20px;
        position: relative;
        width: 40px;
        border-radius: 16px;
        border: none;

        @include focus-ring(
            $style: 'base',
            $transitions: (
                background-color 200ms
            )
        );

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }

        &:hover {
            cursor: pointer;
        }
    }

    @include element(inner) {
        color: $color-white;
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        width: 100%;
        transition: all 0.15s ease 0s;
    }

    @include element(handle) {
        background-color: $color-white;
        bottom: 2px;
        content: '';
        height: 16px;
        left: 2px;
        position: absolute;
        transform: initial;
        width: 16px;
        border-radius: 50%;
        transition: all 0.15s ease 0s;
    }

    @include element(icon) {
        box-sizing: content-box;
        display: grid;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 4px;
        width: 16px;

        i {
            width: 14px;
            height: 20px;
            display: grid;
            align-items: center;
            justify-content: center;
        }
    }

    @include is(checked) {
        @include element(slide) {
            background-color: $toggle-on-background;
        }

        @include element(inner) {
            flex-direction: row;
        }

        @include element(handle) {
            transform: translateX(20px);
        }

        @include element(icon) {
            padding-left: 4px;
            padding-right: 0;
        }
    }

    @include is(disabled) {
        @include element(slide) {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}
</style>
