<template>
    <transition name="pendo-notification-fade">
        <div
            v-show="visible"
            class="pendo-notification"
            :class="[
                customClass,
                {
                    'is-position-right': position.includes('right'),
                    'is-position-left': position.includes('left'),
                    'is-title-only': !message
                }
            ]"
            :style="positionStyle"
            role="alert"
            @mouseenter="clearTimer"
            @mouseleave="startTimer">
            <pendo-icon
                v-if="type"
                class="pendo-notification__icon"
                v-bind="icon"
                :aria-label="type" />
            <div class="pendo-notification__group">
                <div class="pendo-notification__title">
                    {{ title }}
                </div>
                <div
                    v-if="message"
                    class="pendo-notification__content">
                    <slot>
                        <p v-if="!dangerouslyUseHTMLString">
                            {{ message }}
                        </p>
                        <!-- eslint-disable vue/no-v-html -->
                        <p
                            v-if="dangerouslyUseHTMLString"
                            v-html="message" />
                        <!-- eslint-enable -->
                    </slot>
                </div>
                <div
                    v-if="showClose"
                    class="pendo-notification__close-container">
                    <button
                        aria-label="close"
                        class="pendo-notification__closebtn"
                        @click.stop="close">
                        <pendo-icon
                            type="x"
                            role="img"
                            :size="16"
                            aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon.vue';
import { keyCodes } from '@/utils/utils';

const TYPE_ICON_MAP = {
    success: {
        type: 'check-circle-2-solid',
        fill: '#00c583',
        stroke: '#fff',
        size: 32
    },
    info: {
        type: 'info-solid',
        fill: '#128297',
        stroke: '#fff',
        size: 32
    },
    warning: {
        type: 'alert-circle-solid',
        fill: '#dba211',
        stroke: '#fff',
        size: 32
    },
    error: {
        type: 'minus-circle-solid',
        fill: '#db1111',
        stroke: '#fff',
        size: 32
    }
};

export default {
    components: {
        PendoIcon
    },
    data () {
        return {
            // local data
            verticalOffset: 0,
            closed: false,
            visible: false,
            timer: null
        };
    },

    computed: {
        icon () {
            return TYPE_ICON_MAP[this.type];
        },
        positionStyle () {
            const position = this.position.includes('top') ? 'top' : 'bottom';

            return {
                [position]: `${this.verticalOffset}px`
            };
        }
    },

    watch: {
        closed (newVal) {
            if (newVal) {
                this.visible = false;
                this.$el.addEventListener('transitionend', this.destroyElement);
            }
        }
    },
    mounted () {
        this.startTimer();
        document.addEventListener('keydown', this.keydown);
    },
    beforeDestroy () {
        document.removeEventListener('keydown', this.keydown);
    },
    methods: {
        destroyElement () {
            this.$el.removeEventListener('transitionend', this.destroyElement);
            this.$destroy(true);
            this.$el.parentNode.removeChild(this.$el);
        },
        close () {
            this.closed = true;
            if (typeof this.onClose === 'function') {
                this.onClose();
            }
        },
        clearTimer () {
            clearTimeout(this.timer);
        },
        startTimer () {
            if (this.duration > 0) {
                this.timer = setTimeout(() => {
                    if (!this.closed) {
                        this.close();
                    }
                }, this.duration);
            }
        },
        keydown (event) {
            switch (event.keyCode) {
                case keyCodes.backspace:
                case keyCodes.delete:
                    this.clearTimer();
                    break;
                case keyCodes.esc:
                    if (!this.closed) {
                        this.close();
                    }
                    break;
                default:
                    this.startTimer();
                    break;
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-notification) {
    display: grid;
    grid-template-columns: 72px auto;
    width: $notification-width;
    min-height: $notification-min-height;
    border-radius: $notification-radius;
    box-sizing: border-box;
    position: fixed;
    background-color: $color-white;
    box-shadow: $notification-shadow;
    transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s, top 0.4s, bottom 0.3s;
    overflow: hidden;

    @include element(group) {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    @include element(title) {
        font-weight: 400;
        font-size: $notification-title-font-size;
        color: $notification-title-color;
        padding-right: 16px;
        align-self: end;
        margin: 16px 0 0 0;
    }

    @include element(content) {
        grid-row: 2;
        font-size: $notification-font-size;
        color: $notification-color;
        line-height: 18px;
        padding-right: 16px;
        margin: 0 0 16px 0;

        p {
            color: $color-text-secondary;
            margin: 0;
        }
    }

    @include element(icon) {
        justify-content: center;
        align-items: center;
        display: grid;
    }

    @include element(close-container) {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row: span 2;
        border-left: 1px solid $color-gray-40;
        width: 64px;
    }

    @include element(closebtn) {
        @include button-reset;
        @include focus-ring(
            $style: 'base',
            $transitions: (
                color 200ms
            )
        );
        color: $notification-close-color;
        background: transparent;
        border-radius: 3px;

        &:hover {
            cursor: pointer;
            color: $notification-close-hover-color;
        }

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }
    }

    @include is(position-right) {
        right: 16px;
    }

    @include is(position-left) {
        left: 16px;
    }

    @include is(title-only) {
        @include element(group) {
            grid-template-rows: 1fr;
        }

        @include element(title) {
            align-self: center;
            margin: 0;
        }
    }
}

.pendo-notification-fade-enter {
    &.is-position-right {
        right: 0;
        transform: translateX(100%);
    }

    &.is-position-left {
        left: 0;
        transform: translateX(-100%);
    }
}

.pendo-notification-fade-leave-active {
    opacity: 0;
}
</style>
