<template>
    <div class="display-extension-regular-expressions">
        <div class="extension-regular-expressions-list">
            {{ regularExpressionsListString }}
        </div>
        <pendo-button
            v-if="canShowRegExps"
            class="view-extension-reg-exp-button"
            theme="app"
            type="link"
            :label="canEditRegEx ? 'Edit' : 'View'"
            @click="showRegExpsModal" />
        <pendo-add-update-extension-app-modal
            v-if="showEditModal"
            :visible="showEditModal"
            :create-mode="false"
            :regex-flag="true"
            :enable-regex="true"
            :app="app"
            :saving="savingRegExp"
            :submission-error="submissionError"
            :read-only-mode="!canEditRegEx"
            :websites-modal-alert-link="websitesModalAlertLink"
            :selective-url-support-link="selectiveUrlSupportLink"
            @updateRegex="updateRegularExpressions"
            @close="closeEditModal" />
    </div>
</template>
<script>
import PendoButton from '@/components/button/pendo-button.vue';
import PendoAddUpdateExtensionAppModal from '@/composites/add-update-extension-app-modal/pendo-add-update-extension-app-modal.vue';

export default {
    name: 'DisplayEditExtensionRegularExpressions',
    components: {
        PendoButton,
        PendoAddUpdateExtensionAppModal
    },
    props: {
        app: {
            required: true,
            type: Object
        },
        canEditRegEx: {
            required: true,
            type: Boolean
        },
        submissionError: {
            type: Object,
            default: () => {}
        },
        savingRegExp: {
            type: Boolean,
            default: false
        },
        websitesModalAlertLink: {
            type: String,
            required: true
        },
        selectiveUrlSupportLink: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            showEditModal: false,
            previousSaveState: this.savingRegExp
        };
    },
    computed: {
        regularExpressions () {
            return this.app.extensionStructRegExps ? [...this.app.extensionStructRegExps] : [];
        },
        regularExpressionsListString () {
            const regexStrings = [];
            if (this.regularExpressions.length) {
                this.regularExpressions.forEach((regex) => {
                    regexStrings.push(`${regex.subDomainRegExp}${regex.rootDomain}${regex.pathRegExp}`);
                });
            }

            return regexStrings.length ? regexStrings.join(', ') : 'No Selective URLs configured.';
        },
        canShowRegExps () {
            return this.app.extensionStructRegExps
                ? this.app.extensionStructRegExps.length > 0 || this.canEditRegEx
                : this.canEditRegEx;
        }
    },
    watch: {
        savingRegExp () {
            if (this.previousSaveState && !this.savingRegExp) {
                if (this.submissionError === null || this.submissionError === {}) {
                    this.closeEditModal();
                }
            }

            this.previousSaveState = this.savingRegExp;
        }
    },
    methods: {
        showRegExpsModal () {
            this.showEditModal = true;
        },
        closeEditModal () {
            this.showEditModal = false;
        },
        updateRegularExpressions (regularExpressions) {
            this.$emit('update-reg-exps', regularExpressions);
        }
    }
};
</script>
<style lang="scss" scoped>
.extension-regular-expressions-list {
    line-height: 1.5;
}
</style>
