<template>
    <pendo-radio
        v-pendo-tooltip="{
            content: tooltipContent,
            delay: {
                show: 100,
                hide: 0
            }
        }"
        class="pendo-radio-button"
        :class="{
            [`pendo-radio-button--${radioSize}`]: Boolean(radioSize)
        }"
        :label="label"
        :value="value"
        :name="name"
        :disabled="disabled"
        @change="handleChange">
        <template #default>
            <slot>
                <template v-if="!icon">
                    {{ label }}
                </template>
                <pendo-icon
                    v-if="icon"
                    v-bind="iconProps" />
            </slot>
        </template>
    </pendo-radio>
</template>

<script>
import get from 'lodash/get';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoRadio from '@/components/radio/pendo-radio';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    name: 'PendoRadioButton',
    components: {
        PendoRadio,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inject: {
        $formItem: {
            default: ''
        },
        $radioGroup: {
            default: ''
        }
    },
    props: {
        /**
         * bound value
         */
        value: {
            type: null,
            default: null
        },
        /**
         * label text for radio
         */
        label: {
            type: String,
            default: null
        },
        /**
         * icon instead of label
         */
        icon: {
            type: Object,
            default: null
        },
        /**
         * disables user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * disabled tooltip text
         */
        disabledLabel: {
            type: String,
            default: null
        },
        /**
         * same as native `name` attribute
         */
        name: {
            type: String,
            default: null
        },
        /**
         * size of radio button
         * @values medium, small, mini
         */
        size: {
            type: String,
            default: null,
            validator: (size) => ['mini', 'small', 'medium'].includes(size)
        }
    },
    computed: {
        iconProps () {
            return {
                size: 14,
                ...this.icon
            };
        },
        tooltipContent () {
            if (!this.icon) {
                return null;
            }

            if (this.disabled) {
                return this.disabledLabel;
            }

            return this.label;
        },
        radioSize () {
            const groupSize = get(this, '$radioGroup.radioGroupSize');

            return groupSize || this.size;
        }
    },
    methods: {
        handleChange (value) {
            this.$emit('change', value);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-radio-button) {
    &:focus-within {
        &:has(:focus-visible) {
            .pendo-radio__label {
                @include focus-ring($style: 'focused');
                z-index: 1;
            }
        }
        // Firefox :has selector currently unsupported
        @supports (-moz-appearance: none) {
            .pendo-radio__label {
                @include focus-ring($style: 'focused');
                z-index: 1;
            }
        }
    }
    .pendo-radio__container {
        @include font-family;
        background-color: $color-white;
        box-sizing: border-box;
        color: $color-text-primary;
        display: grid;
        font-size: 14px;
        line-height: 36px;
        margin: 0;
        outline: none;
        position: relative;
        text-align: center;
        transition: $all-transition;
        user-select: none;
        white-space: nowrap;
        -webkit-appearance: none;

        &.is-checked {
            .pendo-radio__label {
                background-color: $radio-button-checked-background-color;
                border-color: $radio-button-checked-color;
                color: $radio-button-checked-color;
                font-weight: 600;
                z-index: 1;
            }
        }

        &.is-disabled {
            .pendo-radio__label {
                background-color: $disabled-fill;
                background-image: none;
                box-shadow: none;
                color: $color-text-placeholder;
                cursor: not-allowed;
            }

            &.is-checked {
                .pendo-radio__label {
                    background-color: $disabled-fill;
                    background-image: none;
                    border-color: $disabled-border;
                    box-shadow: none;
                    color: $color-text-placeholder;
                    cursor: not-allowed;
                }
            }
        }
    }

    .pendo-radio__inner {
        display: none;
    }

    .pendo-radio__input {
        opacity: 0;
        outline: none;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
    }

    .pendo-radio__label {
        display: grid;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        cursor: pointer;
        font-weight: 400;
        border: 2px solid $color-gray-40;
        padding-left: 0;

        &:hover {
            background-color: $color-gray-30;
        }

        @include focus-ring(
            $style: 'base',
            $transitions: (
                background-color 150ms
            )
        );
    }

    .pendo-radio__original {
        &:checked {
            & + .pendo-radio__label {
                background-color: $radio-button-checked-background-color;
                border-color: $radio-button-checked-color;
                color: $radio-button-checked-color;
                font-weight: 600;
                z-index: 1;
            }
        }

        &:disabled {
            & + .pendo-radio__label {
                background-color: $disabled-fill;
                background-image: none;
                border-color: $disabled-border;
                box-shadow: none;
                color: $color-text-placeholder;
                cursor: not-allowed;
            }
        }
    }

    &.pendo-radio-button--medium {
        .pendo-radio__label {
            height: 34px;
            line-height: 34px;
            font-size: 14px;
        }
    }

    &.pendo-radio-button--small {
        .pendo-radio__label {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
        }
    }

    &.pendo-radio-button--mini {
        .pendo-radio__label {
            height: 24px;
            line-height: 24px;
            font-size: 12px;
        }
    }

    &:first-child {
        .pendo-radio__label {
            border-radius: $border-radius-3 0 0 $border-radius-3;
        }
    }

    &:not(:first-child) {
        margin-left: -2px;
    }

    &:last-child {
        .pendo-radio__label {
            border-radius: 0 $border-radius-3 $border-radius-3 0;
        }
    }

    &:first-child:last-child {
        .pendo-radio__label {
            border-radius: $border-radius-3;
        }
    }
}
</style>
