<template>
    <div
        class="code-snippets"
        :class="{ 'is-mobile-app': isMobileApp }">
        <pendo-card
            class="code-snippet-card"
            body-min-height="0"
            :title="step1Title">
            <template
                v-if="!isMobileApp"
                #headerRight>
                <pendo-button
                    theme="app"
                    type="link"
                    icon="alert-circle"
                    @click="showInstallTipsModal">
                    Installation Tips
                </pendo-button>
            </template>
            <div class="code-snippets-settings">
                <div
                    v-if="!isMobileApp"
                    class="code-snippet-options">
                    <span class="option-label">
                        App Type
                    </span>
                    <pendo-radio-group
                        class="app-selection"
                        :value="appType"
                        :disabled="loadingSelections.appType"
                        @change="onAppTypeChange">
                        <pendo-radio
                            v-for="option in appTypeOptions"
                            :key="option.value"
                            v-bind="option" />
                    </pendo-radio-group>
                </div>
                <div
                    v-if="hasAllowSignedMetadataFlag"
                    class="code-snippet-options">
                    <span class="option-label">
                        Advanced Security
                    </span>
                    <div class="jwt-snippet-selections">
                        <p>Use signed metadata</p>
                        <!-- div is required as tooltip will not work on hover of a disabled button -->
                        <div
                            v-if="!enableSignedMetadataFlag"
                            v-pendo-tooltip="
                                disableJwtEnableButton &&
                                    getToolTipConfig({
                                        content: 'This action is not allowed while agent version is less than 2.56.0'
                                    })
                            ">
                            <pendo-button
                                :disabled="disableJwtEnableButton"
                                theme="app"
                                size="mini"
                                type="primary"
                                label="Enable"
                                data-cy="enable-use-jwt-keys-button"
                                :loading="loadingSelections.jwtEnable"
                                @click="handleFlagChange({ enabled: true, flag: 'enableSignedMetadata' }, 'jwtEnable')" />
                        </div>
                        <!-- div is required as tooltip will not work on hover of a disabled button -->
                        <div
                            v-if="enableSignedMetadataFlag"
                            v-pendo-tooltip="
                                requireSignedMetadataFlag &&
                                    getToolTipConfig({
                                        content:
                                            'This action is not allowed while “Only allow signed metadata” is enabled.'
                                    })
                            ">
                            <pendo-button
                                theme="app"
                                :disabled="requireSignedMetadataFlag"
                                size="mini"
                                type="danger"
                                label="Disable"
                                data-cy="disable-use-jwt-keys-button"
                                @click="disableJwt" />
                        </div>
                    </div>
                    <div
                        v-if="enableSignedMetadataFlag"
                        class="jwt-snippet-selections">
                        <p>Only allow signed metadata</p>
                        <div
                            v-pendo-tooltip="
                                activeKeys.length === 0 &&
                                    getToolTipConfig({
                                        content: 'Please generate an active JWT key before enabling this setting.'
                                    })
                            ">
                            <pendo-button
                                v-if="!requireSignedMetadataFlag"
                                :disabled="activeKeys.length === 0"
                                theme="app"
                                :loading="loadingSelections.jwtModal"
                                size="mini"
                                type="primary"
                                label="Enable"
                                data-cy="enable-require-jwt-button"
                                @click="enableRequireJwt" />
                        </div>
                        <pendo-button
                            v-if="requireSignedMetadataFlag"
                            theme="app"
                            :loading="loadingSelections.disableRequireJwt"
                            size="mini"
                            type="danger"
                            label="Disable"
                            data-cy="disable-require-jwt-button"
                            @click="
                                handleFlagChange({ enabled: false, flag: 'requireSignedMetadata' }, 'disableRequireJwt')
                            " />
                    </div>
                </div>
            </div>
            <pendo-modal
                :visible="installTipsModalVisible"
                title="Installation Tips"
                height="auto"
                :action-buttons="installTipsActionButtons"
                @close="hideInstallTipsModal">
                <h5 class="tips-title">
                    Asynchronous Data / Client Side Data
                </h5>
                <p>
                    If you load the data for your Visitors and Accounts asynchronously from within the browser using
                    XHRs or Websockets (or something else) then make sure you check out our&nbsp;<a
                        href="https://support.pendo.io/hc/en-us/articles/360032201071-Client-side-data-installation"
                        target="_blank">docs on client-side rendering.</a>
                </p>
                <h5 class="tips-title">
                    Change visitor-id-goes-here
                </h5>
                <p>
                    Replace the visitor-id-goes-here with the unique visitor or user id from your application. We also
                    recommend adding additional information about your visitor such as their Role (admin, user), email
                    address, and other information you may want to slice your data by, or target user groups by.
                </p>
                <template v-if="hasAccounts">
                    <h5 class="tips-title">
                        Change account-id-goes-here
                    </h5>
                    <p>
                        While not required, we highly recommend passing through the account ID or customer ID associated
                        with each visitor. This lets you roll up data by account. In addition, we recommend passing in
                        any additional information about the account, such as the account name (if not obvious from the
                        ID), and other information that may be helpful to slice by, or target to - such as industry,
                        plan price, or any other information. If you do NOT have the notion of an account, just comment
                        out the ID line in account.
                    </p>
                </template>
                <h5 class="tips-title">
                    Look for an Email
                </h5>
                <p>
                    Once we receive the first piece of data from your application, we’ll email you. It may take up to an
                    hour to receive that data. Alternately, you can reload this page to see in real time if we have
                    received data yet.
                </p>
            </pendo-modal>
        </pendo-card>
        <pendo-modal
            :visible="isJwtConfirmModalVisible"
            height="auto"
            width="450px"
            title="Confirm signed metadata settings"
            @close="closeJwtConfirmModal">
            <template #body>
                <pendo-alert
                    type="warning"
                    :title="jwtWarningMessage" />
                <div class="modal-app-name">
                    <h5 class="modal-app-name-label">
                        App Name:
                    </h5>
                    <h5>{{ app.displayName }}</h5>
                </div>
                <pendo-form
                    :call-validate="callValidate"
                    :model="modalForm"
                    :call-reset-fields="callResetFields"
                    @fieldsReset="callResetFields = false">
                    <pendo-form-item prop="checkBox">
                        <pendo-checkbox-group
                            v-model="jwtConfirmationCheckboxes"
                            label="I understand that after confirming this action:"
                            data-cy="jwt-confirmation-checkbox">
                            <pendo-checkbox
                                v-for="option in jwtCheckboxOptions[confirmModalType]"
                                :key="option.value"
                                v-bind="option" />
                        </pendo-checkbox-group>
                    </pendo-form-item>
                    <pendo-form-item
                        :rules="rules.confirmText"
                        label="Type 'I understand' to confirm"
                        prop="confirmText">
                        <pendo-input
                            v-model="modalForm.confirmText"
                            data-cy="jwt-confirmation-input" />
                    </pendo-form-item>
                </pendo-form>
            </template>
            <template #footer>
                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Cancel"
                    @click="closeJwtConfirmModal" />
                <pendo-button
                    :disabled="disableSubmit"
                    theme="app"
                    :type="getModalButtonType"
                    :loading="loadingSelections.jwtModal"
                    :label="modalButtonText"
                    @click="submitModal" />
            </template>
        </pendo-modal>
        <pendo-card
            v-if="enableSignedMetadataFlag && !isMobileApp"
            class="snippet-card"
            body-min-height="0"
            :title="'Step 2: Add this code server side'">
            <div class="code-snippets--body">
                <pendo-copy-code-block
                    language="javascript"
                    :button-options="{ label: 'Copy Code', position: 'top' }"
                    :code-to-copy="signedMetadataCodeSnippet" />
            </div>
        </pendo-card>
        <pendo-card
            v-if="!isMobileApp"
            class="snippet-card"
            body-min-height="0"
            :title="`Step ${enableSignedMetadataFlag ? 3 : 2}: Add the code below to your common HTML template`">
            <div class="code-snippets--body">
                <pendo-copy-code-block
                    v-for="(snippet, index) in snippets"
                    :key="index + appType"
                    language="javascript"
                    :button-options="{ label: 'Copy Code', position: 'top' }"
                    :code-to-copy="snippet.text"
                    :snippet-config="snippet.config" />
            </div>
        </pendo-card>
        <pendo-key-rotation
            v-if="enableSignedMetadataFlag"
            class="code-snippets--key-rotation"
            :app="app"
            :keys="keys"
            :is-loading="sharedTokensLoading"
            :key-id-prefix="keyIdPrefix"
            @create="handleSharedTokenStateChange('createSharedToken', $event)"
            @update="handleSharedTokenStateChange('updateSharedToken', $event)"
            @revoke="handleSharedTokenStateChange('revokeSharedToken', $event)" />
    </div>
</template>

<script>
import PendoCard from '@/components/card/pendo-card.vue';
import PendoRadioGroup from '@/components/radio-group/pendo-radio-group.vue';
import PendoRadio from '@/components/radio/pendo-radio.vue';
import PendoTooltip from '@/directives/tooltip/tooltip.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoFormItem from '@/components/form/pendo-form-item.vue';
import PendoForm from '@/components/form/pendo-form.vue';
import PendoCheckboxGroup from '@/components/checkbox-group/pendo-checkbox-group.vue';
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoAlert from '@/components/alert/pendo-alert.vue';
import PendoCopyCodeBlock from '@/composites/copy-code-block/pendo-copy-code-block.vue';
import PendoKeyRotation from '@/composites/key-rotation/pendo-key-rotation.vue';

import { getSnippets, SNIPPET_APP_TYPES, engageJWTSnippet, adoptJWTSnippet } from './snippet-code';
import get from 'lodash/get';

export default {
    name: 'PendoInstallSettings',
    components: {
        PendoKeyRotation,
        PendoCard,
        PendoRadioGroup,
        PendoRadio,
        PendoButton,
        PendoCopyCodeBlock,
        PendoModal,
        PendoInput,
        PendoFormItem,
        PendoForm,
        PendoCheckboxGroup,
        PendoCheckbox,
        PendoAlert
    },
    directives: {
        PendoTooltip
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        keys: {
            type: Array,
            required: true
        },
        subscription: {
            type: Object,
            required: true
        },
        isMobileApp: {
            type: Boolean,
            required: true
        },
        hasAllowSignedMetadataFlag: {
            type: Boolean,
            required: true
        },
        hasAccounts: {
            type: Boolean,
            default: true
        },
        hasParentAccounts: {
            type: Boolean,
            required: true
        },
        sharedTokensLoading: {
            type: Boolean,
            required: true
        },
        host: {
            type: String,
            required: true
        },
        loadingSelections: {
            type: Object,
            required: true
        },
        disableJwtEnableButton: {
            type: Boolean,
            required: true
        },
        enableSignedMetadataFlag: {
            type: Boolean,
            required: true
        },
        requireSignedMetadataFlag: {
            type: Boolean,
            required: true
        },
        isAdopt: {
            type: Boolean,
            default: false
        }
    },
    data () {
        const appTypeOptions = [
            {
                value: SNIPPET_APP_TYPES.MULTI_PAGE_APP,
                label: 'Multi-Page App'
            },
            {
                value: SNIPPET_APP_TYPES.SINGLE_PAGE_APP,
                label: 'Single-Page App'
            }
        ];
        const jwtConfirmModalVals = {
            jwtProcessingVal: 'confirmJwtProcessing',
            jwtReprocessingVal: 'confirmCannotReprocess',
            jwtKeyRevokedVal: 'confirmRevokeKeys',
            jwtEventsVal: 'confirmJwtNotProcessEvents'
        };

        return {
            appTypeOptions,
            installTipsActionButtons: [
                {
                    label: 'Done',
                    theme: 'app',
                    handler: this.hideInstallTipsModal
                }
            ],
            installTipsModalVisible: false,
            isJwtConfirmModalVisible: false,
            modalForm: {
                confirmText: ''
            },
            callValidate: false,
            rules: {
                confirmText: [
                    {
                        validator: this.validateConfirmText,
                        trigger: 'blur'
                    }
                ]
            },
            confirmModalType: null,
            jwtCheckboxOptions: {
                disableJwt: [
                    {
                        value: jwtConfirmModalVals.jwtKeyRevokedVal,
                        label: 'All active JWT keys will be revoked'
                    },
                    {
                        value: jwtConfirmModalVals.jwtEventsVal,
                        label: 'Events with signed metadata will not be processed'
                    }
                ],
                requireJwt: [
                    {
                        value: jwtConfirmModalVals.jwtProcessingVal,
                        label: 'Pendo will stop processing any events without signed metadata'
                    },
                    {
                        value: jwtConfirmModalVals.jwtReprocessingVal,
                        label: 'Data cannot be reprocessed'
                    }
                ]
            },
            jwtConfirmationCheckboxes: [],
            callResetFields: false
        };
    },
    computed: {
        isFramework () {
            return get(this.app, 'framework', '');
        },
        minSdkVersion () {
            return this.isFramework ? '2.16' : '2.13';
        },
        snippets () {
            return getSnippets({
                apiKey: this.app.apiKey,
                host: this.host,
                isJwtEnabled: this.enableSignedMetadataFlag,
                hasAccounts: this.hasAccounts,
                hasParentAccounts: this.hasParentAccounts,
                appType: this.appType,
                isAdopt: this.isAdopt
            });
        },
        activeKeys () {
            if (!this.keys) {
                return [];
            }

            return this.keys.filter((key) => !key.revoked);
        },
        appType () {
            return get(this.app.applicationFlags, 'isSinglePage', false)
                ? SNIPPET_APP_TYPES.SINGLE_PAGE_APP
                : SNIPPET_APP_TYPES.MULTI_PAGE_APP;
        },
        keyIdPrefix () {
            const subPrefix = this.subscription.displayName
                .replace(/ /g, '_')
                .substring(0, 4)
                .toLowerCase();
            const appPrefix = this.app.displayName
                .replace(/ /g, '_')
                .substring(0, 4)
                .toLowerCase();

            return `${subPrefix}-${appPrefix}`;
        },
        modalButtonText () {
            switch (this.confirmModalType) {
                case 'requireJwt':
                    return 'Enable Signed Metadata';
                case 'disableJwt':
                    return 'Disable Signed Metadata';
                default:
                    return 'Confirm';
            }
        },
        checkJwtConfirmCheckBoxes () {
            const relevantOptionLabels = this.jwtCheckboxOptions[this.confirmModalType].map(({ label }) => label);
            const confirmations = relevantOptionLabels.every((label) => this.jwtConfirmationCheckboxes.includes(label));
            const mobileOnly = this.jwtConfirmationCheckboxes.includes(this.mobileJwtOnlyCheckBox.label);

            if (this.isMobileApp && this.confirmModalType === 'requireJwt') {
                return confirmations && mobileOnly;
            }

            return confirmations;
        },
        disableSubmit () {
            return this.modalForm.confirmText !== 'I understand' || !this.checkJwtConfirmCheckBoxes;
        },
        jwtWarningMessage () {
            return this.confirmModalType === 'requireJwt'
                ? 'Pendo will not be able to ingest data until a valid JWT key has been added'
                : 'All currently active JWT keys will be revoked and events with signed metadata will not be processed until a new key is installed.';
        },
        getModalButtonType () {
            return this.confirmModalType === 'disableJwt' ? 'danger' : 'primary';
        },
        step1Title () {
            return this.isMobileApp ? '(Optional) Customize your SDK' : 'Step 1: Customize your snippet';
        },
        mobileJwtOnlyCheckBox () {
            return {
                value: 'confirmMobileSdk',
                label: `Events coming in form app versions with SDK lower than ${this.minSdkVersion}
                will be ignored by Pendo.`
            };
        },
        signedMetadataCodeSnippet () {
            return this.isAdopt
                ? adoptJWTSnippet({
                    hasAccounts: this.hasAccounts,
                    hasParentAccounts: this.hasParentAccounts
                })
                : engageJWTSnippet({
                    hasAccounts: this.hasAccounts,
                    hasParentAccounts: this.hasParentAccounts
                });
        }
    },
    watch: {
        loadingSelections: {
            handler () {
                if (this.isJwtConfirmModalVisible && !this.loadingSelections.jwtModal) {
                    this.closeJwtConfirmModal();
                }
            },
            deep: true
        }
    },
    async created () {
        if (this.isMobileApp) {
            this.jwtCheckboxOptions.requireJwt.push(this.mobileJwtOnlyCheckBox);
        }
    },
    methods: {
        updateAppFlag (options) {
            this.$emit('updateAppFlag', options);
        },
        async submitModal () {
            const isRequireJwtModal = this.confirmModalType === 'requireJwt';
            this.handleFlagChange(
                {
                    enabled: isRequireJwtModal,
                    flag: isRequireJwtModal ? 'requireSignedMetadata' : 'enableSignedMetadata'
                },
                'jwtModal'
            );
        },
        onAppTypeChange (appType) {
            this.updateAppFlag({
                appOptions: {
                    appId: this.app.id,
                    flag: 'isSinglePage',
                    enabled: appType === SNIPPET_APP_TYPES.SINGLE_PAGE_APP
                },
                loading: { type: 'appType' }
            });
        },
        handleFlagChange ({ enabled, flag }, buttonLoadingState) {
            this.updateAppFlag({
                appOptions: { appId: this.app.id, flag, enabled },
                loading: { type: buttonLoadingState }
            });
        },
        disableJwt () {
            this.confirmModalType = 'disableJwt';
            this.isJwtConfirmModalVisible = true;
            this.callResetFields = true;
        },
        enableRequireJwt () {
            this.confirmModalType = 'requireJwt';
            this.isJwtConfirmModalVisible = true;
            this.callResetFields = true;
        },
        showInstallTipsModal () {
            this.installTipsModalVisible = true;
        },
        hideInstallTipsModal () {
            this.installTipsModalVisible = false;
        },
        closeJwtConfirmModal () {
            this.eventJwtValue = false;
            this.isJwtConfirmModalVisible = false;
            this.jwtConfirmationCheckboxes = [];
            this.modalForm.confirmText = '';
        },
        validateConfirmText (rule, val, cb) {
            if (val !== 'I understand') {
                cb(new Error('Confirmation text must match'));
            } else {
                cb();
            }
        },
        getToolTipConfig ({ content }) {
            return {
                placement: 'top',
                boundariesElement: 'viewport',
                arrow: false,
                trigger: 'hover',
                multiline: true,
                content
            };
        },
        handleSharedTokenStateChange (method, options) {
            this.$emit(method, options);
        }
    }
};
</script>

<style lang="scss" scoped>
.code-snippet-options {
    margin-right: 100px;
}

.code-snippet-card {
    margin-bottom: 20px;
}

.modal-app-name {
    padding: 15px 0px;
    display: flex;
    border-bottom: 1px solid $color-gray-100;
    margin-bottom: 15px;
}

.app-selection {
    margin-top: 20px;
}

.snippet-card {
    margin-bottom: 25px;

    ::v-deep .pendo-card__body {
        padding-top: 0px;
    }

    &--key-rotation {
        margin-top: 25px;
    }
}

.jwt-snippet-selections {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin-bottom: 10px;

    .pendo-button--mini {
        min-width: 70px;
    }
}

.modal-app-name-label {
    margin-right: 4px;
    font-weight: 600;
}

.tips-title {
    font-weight: 600;
}

.option-label {
    font-size: 0.83em;
    font-weight: 600;
}

.code-snippets-body {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 20px;
}

.code-snippets-settings {
    display: flex;
    flex-direction: row;
}
</style>
