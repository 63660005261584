<template>
    <div
        class="pendo-checkbox-group"
        :class="{
            'is-horizontal': direction === 'horizontal',
            'is-vertical': direction === 'vertical'
        }">
        <div
            v-if="!!topLabel"
            :id="`pendo-checkbox-group__label--top-${_uid}`"
            class="pendo-checkbox-group__label pendo-checkbox-group__label--top">
            {{ topLabel }}
        </div>
        <div
            :id="!!$formItem && $formItem.labelFor"
            role="group"
            :aria-labelledby="ariaLabelledBy"
            :aria-describedby="!!$formItem && $formItem.ariaDescribedBy"
            :aria-invalid="!!$formItem && !!$formItem.ariaDescribedBy"
            :aria-required="!!$formItem && $formItem.isRequired">
            <slot />
        </div>
        <div
            v-if="!!bottomLabel"
            :id="`pendo-checkbox-group__label--bottom-${_uid}`"
            class="pendo-checkbox-group__label pendo-checkbox-group__label--bottom">
            {{ bottomLabel }}
        </div>
    </div>
</template>

<script>
import labelsMixin from '@/mixins/labels';

export default {
    name: 'PendoCheckboxGroup',
    mixins: [labelsMixin],
    inject: {
        $formItem: {
            default: ''
        }
    },
    provide () {
        return { $checkboxGroup: this };
    },
    props: {
        /**
         * Bound value
         */
        value: {
            type: null,
            default: null
        },
        /**
         * direction of group
         * @values vertical, horizontal
         */
        direction: {
            type: String,
            default: 'vertical',
            validator: (direction) => ['vertical', 'horizontal'].includes(direction)
        },
        /**
         * whether the Checkbox is disabled
         */
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ariaLabelledBy () {
            if (this.$formItem) {
                return this.$formItem.labelId;
            }
            if (this.topLabel) {
                return `pendo-checkbox-group__label--top-${this._uid}`;
            }
            if (this.bottomLabel) {
                return `pendo-checkbox-group__label--bottom-${this._uid}`;
            }

            return undefined;
        }
    },
    watch: {
        value (value) {
            if (this.$formItem) {
                this.$formItem.onFieldChange(value);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-checkbox-group) {
    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-text-primary;

        @include modifier(top) {
            font-weight: 600;
            align-items: start;
        }
        @include modifier(bottom) {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    @include is(vertical) {
        .pendo-checkbox {
            display: flex;

            & + .pendo-checkbox {
                margin-left: 0;
                margin-top: 4px;
            }
        }
    }
}
</style>
