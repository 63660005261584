<template>
    <div
        v-pendo-draggable="{
            events: {
                'sortable:stop': updateColumnIndex
            }
        }"
        tabindex="-1"
        class="pendo-column-chooser__columns">
        <div
            v-for="(column, index) in value"
            :key="column[valueKey]"
            :tabindex="-1"
            class="pendo-column-chooser__column"
            :class="{
                'is-disabled': isColumnDisabled(column, index)
            }"
            :pendo-draggable-ignore="isColumnDisabled(column, index)"
            pendo-draggable-item>
            <div
                class="pendo-column-chooser__handle"
                pendo-draggable-handle>
                <pendo-icon
                    type="more-vertical"
                    size="22" />
            </div>
            <div class="pendo-column-chooser__column-options">
                <slot
                    name="multiselect"
                    v-bind="{
                        model: value,
                        index,
                        handleColumnSelect,
                        availableColumns,
                        disabled,
                        placeholder,
                        valueKey,
                        labelKey,
                        disableFirst
                    }">
                    <pendo-multiselect
                        :value="value"
                        aria-label="Column Chooser"
                        multiple
                        full-width
                        hide-selected-options
                        :all-selected-text="allSelectedText"
                        :options="availableColumns"
                        :placeholder="value[index][labelKey] || placeholder"
                        :value-key="valueKey"
                        :label-key="labelKey"
                        :max-trigger-width="286"
                        :max-menu-width="286"
                        :show-selected-values="false"
                        :disabled="(disableFirst && index === 0) || disabled"
                        @all-selected-change="handleAllColumnsSelected"
                        @select="handleColumnSelect($event, index)" />
                </slot>
            </div>
            <div
                v-show="value.length > 1 || disableFirst"
                class="pendo-column-chooser__remove"
                @click="removeColumn(column, index)">
                <pendo-icon
                    type="x-circle"
                    size="16" />
            </div>
        </div>
        <slot
            name="add-button"
            v-bind="{
                allColumnsSelected,
                disableAddColumn,
                addColumn
            }">
            <div
                v-pendo-tooltip="{ content: allColumnsSelected ? allSelectedText : null, arrow: true }"
                class="pendo-column-chooser__add-column"
                :class="{ 'is-disabled': disableAddColumn }"
                @click="addColumn">
                <pendo-icon
                    type="plus"
                    size="16" />
                {{ addButtonLabel }}
            </div>
        </slot>
    </div>
</template>
<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoDraggable from '@/directives/draggable/pendo-draggable';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'PendoColumnReorder',
    components: {
        PendoIcon,
        PendoMultiselect
    },
    directives: {
        PendoDraggable,
        PendoTooltip
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        availableColumns: {
            type: Array,
            default: () => []
        },
        disableFirst: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        valueKey: {
            type: String,
            default: 'prop'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
        placeholder: {
            type: String,
            default: 'Select column'
        },
        addButtonLabel: {
            type: String,
            default: 'Add column'
        },
        allSelectedText: {
            type: String,
            default: 'All available columns selected.'
        }
    },
    data () {
        return {
            allColumnsSelected: false
        };
    },
    computed: {
        disableAddColumn () {
            if (this.$slots.multiselect || this.$scopedSlots.multiselect) {
                return false;
            }

            return this.disabled || this.allColumnsSelected || this.value.length === this.availableColumns.length;
        }
    },
    methods: {
        updateColumnIndex ({ newIndex, oldIndex }) {
            if (newIndex !== oldIndex) {
                // make sure we are splicing a copy of the this.model, not the original
                const columns = this.value.slice(0);
                columns.splice(newIndex, 0, ...columns.splice(oldIndex, 1));

                this.handleColumnsChange(columns);
            }
        },
        handleColumnSelect (column, index) {
            const columns = cloneDeep(this.value);
            columns.splice(index, 1, column);

            this.handleColumnsChange(columns);
        },
        handleAllColumnsSelected (value) {
            this.allColumnsSelected = value;
        },
        handleColumnsChange (columns) {
            this.$emit('columns-change', columns);
        },
        addColumn () {
            if (this.disableAddColumn) {
                return;
            }
            const columns = [...this.value, {}];
            this.handleColumnsChange(columns);
        },
        removeColumn (column, index) {
            if (index === 0 && this.disableFirst) {
                return;
            }

            const columns = this.value.slice(0, index).concat(this.value.slice(index + 1));
            this.handleColumnsChange(columns);
        },
        isColumnDisabled (column, index) {
            return (index === 0 && this.disableFirst) || this.disabled || column.disabled;
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-column-chooser) {
    @include element((column, add-column)) {
        min-height: 52px;
        border: 1px solid $color-gray-40;
        border-radius: 3px;
        display: grid;

        @include is(disabled) {
            user-select: none;

            @include element((handle, remove)) {
                opacity: 0.6;
            }
        }
    }

    @include element(column) {
        box-sizing: border-box;
        background-color: $color-gray-20;
        padding: 8px 0;
        align-items: center;
        grid-template-columns: [handle] 32px [dropdown] 1fr [remove] 40px;

        @include is(disabled) {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    @include element(add-column) {
        cursor: pointer;
        align-items: center;
        justify-content: center;
        grid-auto-flow: column;
        color: $color-teal-70;
        transition: all 200ms;

        &:hover:not(.is-disabled) {
            border: 1px solid $color-teal-70;
            color: $color-teal-80;
        }

        @include is(disabled) {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    @include element(handle) {
        display: grid;
        grid-column: handle;
        color: $color-gray-70;
        height: 36px;
        width: 100%;

        &:hover {
            color: $color-gray-90;
        }

        .pendo-icon {
            align-self: center;
            justify-self: center;
        }
    }

    @include element(column-options) {
        grid-column: dropdown;
        width: 100%;
        display: grid;
        grid-gap: 8px;
    }

    @include element(remove) {
        grid-column: remove;
        justify-self: center;
        color: $color-gray-70;
        cursor: pointer;

        &:hover {
            color: $color-red-60;
        }
    }

    @include element(columns) {
        grid-gap: 12px;
        display: grid;

        &.pendo-draggable {
            &.pendo-draggable-container--placed,
            &.pendo-draggable-container--is-dragging {
                .pendo-column-chooser__column.pendo-draggable--over,
                .pendo-column-chooser__column.pendo-draggable-source--is-dragging {
                    border: 1px dashed $color-teal-70;
                    background-color: $color-teal-10;
                    opacity: 1;

                    .pendo-column-chooser__handle,
                    .pendo-column-chooser__column-options,
                    .pendo-column-chooser__remove {
                        visibility: hidden;
                    }
                }
            }

            .pendo-draggable-mirror {
                &.pendo-column-chooser__column {
                    .pendo-column-chooser__handle {
                        color: $color-gray-90;
                    }
                }
            }
        }
    }
}
</style>
