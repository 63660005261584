<template>
    <div
        class="pendo-multiselect__element"
        role="option"
        :data-uid="index"
        :aria-selected="isSelected ? true : undefined">
        <pendo-divider
            v-if="option.divided"
            :width="dividerWidth"
            height="16px" />
        <pendo-divider
            v-if="isGroupOption && index !== 0 && showGroupDividers"
            :width="dividerWidth"
            height="16px" />
        <div
            v-if="isGroupOption"
            class="pendo-multiselect__group"
            :class="{
                'is-highlighted': isHighlighted,
                'is-selected': isSelected,
                'pendo-multiselect__group--select': allowGroupSelect
            }"
            :style="{
                height: optionHeight
            }"
            :title="groupLabel"
            @mousedown.stop.prevent
            @click.left.stop.prevent="onOptionSelect"
            @mousemove="onOptionHover">
            <slot name="optionGroup">
                <span
                    :style="{
                        lineHeight: optionHeight
                    }">
                    {{ groupLabel }}
                </span>
            </slot>
        </div>
        <div
            v-if="!isGroupOption"
            class="pendo-multiselect__option"
            :class="{
                'is-disabled': isDisabled,
                'is-highlighted': isHighlighted,
                'is-selected': isSelected
            }"
            :style="{
                height: optionHeight
            }"
            :title="optionTitle"
            @mousedown.stop.prevent
            @click.left.stop.prevent="onOptionSelect"
            @mousemove="onOptionHover">
            <slot name="option">
                <span
                    :style="{
                        lineHeight: optionHeight
                    }">
                    {{ optionLabel }}
                    <template v-if="option.isTag && tagPlaceholder">
                        {{ tagPlaceholder }}
                    </template>
                </span>
            </slot>
        </div>
    </div>
</template>

<script>
import { hasSlot } from '@/utils/utils';
import PendoDivider from '@/components/divider/pendo-divider';
/* eslint-disable vue/require-default-prop */

export default {
    name: 'PendoMultiselectOption',
    components: {
        PendoDivider
    },
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String, Number],
            required: true
        },
        scrollActive: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number
        }
    },
    computed: {
        optionHeight () {
            if (this.multiselect.optionHeight) {
                return `${this.multiselect.optionHeight}px`;
            }

            return '100%';
        },
        dividerWidth () {
            if (this.multiselect.isFullWidth) {
                return '100%';
            }

            return `${this.multiselect.computedMenuWidth || this.multiselect.minMenuWidth}px`;
        },
        showGroupDividers () {
            return this.multiselect.groupDividers;
        },
        allowGroupSelect () {
            return this.multiselect.groupSelect;
        },
        isGroupOption () {
            return this.option.type === 'group';
        },
        isSelected () {
            if (this.isGroupOption && this.allowGroupSelect) {
                const groupValues = this.multiselect.filteredOptions.find((filteredOption) => {
                    return filteredOption[this.multiselect.groupLabelKey] === this.option.label;
                });

                return this.multiselect.isEntireGroupSelected(groupValues);
            }

            return this.multiselect.isOptionSelected(this.option);
        },
        isDisabled () {
            if (this.option.disabled) {
                return true;
            }

            return this.multiselect.multiple && this.multiselect.max === this.multiselect.model.length;
        },
        isHighlighted () {
            if (this.isGroupOption && !this.allowGroupSelect) {
                return false;
            }

            if (this.isDisabled) {
                return false;
            }

            return this.multiselect.showPointer && this.multiselect.pointer === this.index;
        },
        optionTitle () {
            if (hasSlot(this, 'option')) {
                return;
            }

            return this.optionLabel;
        },
        optionLabel () {
            return this.multiselect.getOptionLabel(this.option);
        },
        groupLabel () {
            return this.option[this.multiselect.groupLabelKey];
        },
        tagPlaceholder () {
            return this.multiselect.tagPlaceholder;
        }
    },
    methods: {
        onOptionSelect () {
            if (this.isGroupOption && !this.allowGroupSelect) {
                return;
            }

            this.multiselect.select(this.option);
        },
        onOptionHover () {
            if (this.scrollActive) {
                return;
            }

            if (this.isGroupOption && !this.allowGroupSelect) {
                return;
            }

            this.multiselect.pointerSet(this.index);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(element) {
        overflow: hidden;
        display: grid;
        align-items: center;
    }

    @include element((option, group)) {
        font-size: inherit;
        padding: 0 24px 0 16px;
        text-decoration: none;
        text-transform: none;
        position: relative;
        cursor: pointer;
        flex: 1;
        min-width: 0;
        @include ellipsis;

        > * {
            height: inherit;
        }

        @include is(highlighted) {
            background-color: $multiselect-option-highlighted;
            outline: none;
        }

        @include is(selected) {
            background-color: $multiselect-option-selected;
        }

        @include is(disabled) {
            color: $disabled-color;
            opacity: 0.7;
            cursor: not-allowed;
            user-select: none;
        }
    }

    @include element(group) {
        font-weight: normal;
        font-size: 12px;
        display: block;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        cursor: default;
        color: $multiselect-option-group-header;

        @include modifier(select) {
            cursor: pointer;
        }
    }
}
</style>
