<template>
    <div
        ref="pane"
        class="pendo-date-picker__pane">
        <div class="pendo-date-picker__header">
            <pendo-icon-button
                v-if="isHeaderLeftButtonVisible"
                class="pendo-date-picker__header-arrow pendo-date-picker__header-arrow--left"
                type="secondary"
                size="small"
                icon="chevron-left"
                :aria-label="prevAriaLabel"
                :disabled="!canMovePrev"
                @click="movePrev" />
            <h2 class="pendo-date-picker__header-title">
                <pendo-icon-button
                    v-if="isTitleInteractive"
                    type="secondary"
                    size="small"
                    :label="title"
                    @click="toggleMode" />
                <template v-if="!isTitleInteractive">
                    {{ title }}
                </template>
            </h2>
            <pendo-icon-button
                v-if="isHeaderRightButtonVisible"
                class="pendo-date-picker__header-arrow pendo-date-picker__header-arrow--right"
                type="secondary"
                size="small"
                icon="chevron-right"
                :aria-label="nextAriaLabel"
                :disabled="!canMoveNext"
                @click="moveNext" />
        </div>
        <div
            v-if="mode === 'day'"
            class="pendo-date-picker__days">
            <div
                v-for="(label, index) in weekdays"
                :key="index"
                aria-hidden="true"
                class="pendo-date-picker__weekday">
                {{ label }}
            </div>
            <calendar-day
                v-for="day in days"
                ref="days"
                :key="day.id"
                :day="day"
                v-on="$listeners" />
        </div>
        <div
            v-if="mode === 'month'"
            class="pendo-date-picker__months">
            <calendar-month
                v-for="mo in months"
                :key="mo.key"
                :month="mo"
                @click="monthClick" />
        </div>
        <div
            v-if="mode === 'year'"
            class="pendo-date-picker__years">
            <calendar-year
                v-for="yr in years"
                :key="yr.key"
                :year="yr"
                @click="yearClick" />
        </div>
    </div>
</template>

<script>
import PendoIconButton from '@/components/icon-button/pendo-icon-button';
import CalendarMonth from '@/components/date-picker/calendar-month';
import CalendarYear from '@/components/date-picker/calendar-year';
import CalendarDay from '@/components/date-picker/calendar-day';

import { pageIsValid, pageIsBeforePage, pageIsAfterPage, pageForDate } from '@/components/date-picker/utils/helpers';

const modeMap = {
    day: 'month',
    month: 'year',
    year: 'decade'
};

export default {
    name: 'CalendarPane',
    components: {
        CalendarDay,
        CalendarMonth,
        CalendarYear,
        PendoIconButton
    },
    inject: ['state'],
    props: {
        page: {
            type: Object,
            required: true
        },
        canMove: {
            type: Function,
            default: () => true
        },
        minPage: {
            type: Object,
            default: null
        },
        maxPage: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            mode: 'day',
            yearIndex: 0,
            yearGroupIndex: 0
        };
    },
    computed: {
        canMovePrev () {
            if (!this.minPage || !pageIsValid(this.minPage)) {
                return true;
            }

            return {
                day: pageIsAfterPage(this.page, this.minPage),
                month: this.yearIndex > this.minPage.year,
                year: this.firstYear - 1 > this.minPage.year
            }[this.mode];
        },
        canMoveNext () {
            if (!this.maxPage || !pageIsValid(this.maxPage)) {
                return true;
            }

            return {
                day: pageIsBeforePage(this.page, this.maxPage),
                month: this.yearIndex < this.maxPage.year,
                year: this.lastYear + 1 < this.maxPage.year
            }[this.mode];
        },
        masks () {
            return this.state.locale.masks;
        },
        count () {
            return this.state.visibleColumnCount;
        },
        isTitleInteractive () {
            return !(this.count > 1);
        },
        isHeaderRightButtonVisible () {
            return this.page.position === this.count - 1;
        },
        isHeaderLeftButtonVisible () {
            return this.page.position === 0;
        },
        weekdays () {
            return this.state.locale
                .getWeekdayDates()
                .map((date) => this.state.locale.format(date, this.masks.weekdays));
        },
        days () {
            return this.page.days;
        },
        month () {
            if (this.page) {
                return this.page.month || 0;
            }

            return 0;
        },
        year () {
            if (this.page) {
                return this.page.year || 0;
            }

            return 0;
        },
        title () {
            const title = {
                day: this.page.title,
                month: this.yearIndex,
                year: `${this.firstYear} - ${this.lastYear}`
            }[this.mode];

            return String(title);
        },
        months () {
            const { month: currentMonth, year: currentYear } = pageForDate(new Date());

            return this.state.locale
                .getMonthDates()
                .map((date) => this.state.locale.format(date, this.masks.months))
                .map((label, i) => {
                    const month = i + 1;

                    return {
                        label,
                        selected: month === this.month && this.yearIndex === this.year,
                        current: month === currentMonth && this.yearIndex === currentYear,
                        disabled: !this.canMove({ month, year: this.yearIndex }),
                        key: i + 1
                    };
                });
        },
        years () {
            const { year: currentYear } = pageForDate(new Date());
            const startYear = this.yearGroupIndex * 12;
            const endYear = startYear + 12;
            const minYear = this.minPage ? this.minPage.year : null;
            const maxYear = this.maxPage ? this.maxPage.year : null;
            const items = [];

            for (let year = startYear; year < endYear; year += 1) {
                items.push({
                    year,
                    label: year,
                    key: year,
                    selected: year === this.year,
                    current: year === currentYear,
                    disabled: (minYear && minYear > year) || (maxYear && maxYear < year)
                });
            }

            return items;
        },
        firstYear () {
            return [].concat(this.years.map((i) => i.year)).shift();
        },
        lastYear () {
            return [].concat(this.years.map((i) => i.year)).pop();
        },
        prevAriaLabel () {
            return `previous ${modeMap[this.mode]}`;
        },
        nextAriaLabel () {
            return `next ${modeMap[this.mode]}`;
        }
    },
    watch: {
        year () {
            this.yearIndex = this.year;
        },
        yearIndex (val) {
            this.yearGroupIndex = Math.floor(val / 12);
        }
    },
    created () {
        this.yearIndex = this.year;
    },
    methods: {
        move (page) {
            this.$emit('update:page', page);
        },
        toggleMode () {
            if (this.count > 1) {
                return;
            }

            const nextMode = {
                day: 'month',
                month: 'year',
                year: 'month'
            }[this.mode];

            this.mode = nextMode;
        },
        monthClick (month) {
            this.move({ month, year: this.yearIndex });
            this.mode = 'day';
        },
        yearClick (year) {
            this.yearIndex = year;
            this.mode = 'month';
        },
        movePrev () {
            if (this.mode === 'day') {
                return this.$emit('move-prev');
            }

            if (this.mode === 'month') {
                this.yearIndex--;
            }
            this.yearGroupIndex--;
        },
        moveNext () {
            if (this.mode === 'day') {
                return this.$emit('move-next');
            }

            if (this.mode === 'month') {
                this.yearIndex++;
            }
            this.yearGroupIndex++;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(pane) {
        display: grid;
        grid-template-rows: [header] 48px [body] 1fr;
        width: $datepicker-calendar-pane-width;
    }

    @include element(header) {
        user-select: none;
        grid-row: header;
        padding: $datepicker-header-padding;
        height: 48px;
        display: grid;
        grid-template-columns: [arrowLeft] 32px [title] auto [arrowRight] 32px;
        justify-content: space-between;
        align-items: center;

        .pendo-button {
            color: $color-text-primary;
        }
    }

    @include element(header-title) {
        @include heading-reset;
        grid-column: title;
        font-size: 16px;
        line-height: 27px;
        font-weight: bold;

        .pendo-button {
            font: inherit;
        }
    }
    @include element(header-arrow) {
        user-select: none;
        @include modifier(left) {
            grid-column: arrowLeft;
        }

        @include modifier(right) {
            grid-column: arrowRight;
        }
    }

    @include element(weekday) {
        display: grid;
        align-items: center;
        justify-content: center;
        color: $datepicker-weekday-color;
        font-weight: 700;
        font-size: 14px;
    }

    @include element((days, months, years)) {
        display: grid;
        grid-row: body;
        padding: $datepicker-body-padding;
        user-select: none;
    }

    @include element(days) {
        grid-auto-rows: 32px;
        grid-template-columns: repeat(7, 1fr);
    }

    @include element((months, years)) {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
