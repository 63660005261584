<template>
    <pendo-multiselect
        ref="menu"
        :append-to-body="appendToBody"
        :class="classNames"
        :close-on-select="false"
        :group-options-key="groupOptionsKey"
        :group-label-key="groupLabelKey"
        :label-key="labelKey"
        :full-width="fullWidth"
        :max-menu-height="400"
        :min-menu-width="menuWidth"
        :max-menu-width="menuWidth || 250"
        :multiple="true"
        :options="filteredOptions"
        :no-results-text="noDataText"
        :option-height="32"
        :placeholder="label"
        :searchable="false"
        :internal-search="useInternalSearch"
        :disabled="disabled"
        :value="value"
        :show-selected-values="showSelectedValues"
        :popper-options="{ class: 'pendo-multiselect-filter__popper' }"
        @open="onOpen"
        @input="setValue">
        <template
            #trigger
            v-if="useDataMeasureTrigger">
            <pendo-data-measure-trigger :prefix-icon="prefixIcon" />
        </template>
        <template #placeholder="{ placeholder }">
            <slot
                name="placeholder"
                :placeholder="placeholder" />
        </template>
        <template #selectedLabel="{ option, selectedLabel }">
            <slot
                name="selectedLabel"
                :selected-label="selectedLabel"
                :option="option" />
        </template>
        <template #header="{ select, deactivate, handleKeydown, updateInputValue, inputValue }">
            <pendo-loading-indicator
                v-if="loading"
                :loading="true" />
            <template v-if="!loading">
                <div
                    v-if="showSearch"
                    class="options-search">
                    <pendo-input
                        :value="inputValue"
                        :autofocus="autofocusSearch"
                        :placeholder="searchPlaceholder || `Search ${entityName}`"
                        @keydown.up="handleKeydown('up')"
                        @keydown.down="handleKeydown('down')"
                        @keydown.delete.self="handleKeydown('delete')"
                        @keyup.enter.native.exact="handleKeydown('enter', $event)"
                        @keydown.tab="handleKeydown('tab')"
                        @keydown.esc="deactivate"
                        @input="handleSearch($event, updateInputValue)">
                        <template #prefix>
                            <pendo-icon
                                type="search"
                                stroke="#2a2c35"
                                size="14" />
                        </template>
                    </pendo-input>
                </div>
                <pendo-button
                    v-if="showClearAction"
                    :disabled="!value.length"
                    type="link"
                    @click="setValue([])">
                    Clear
                </pendo-button>
                <pendo-button
                    v-if="!showClearAction"
                    :disabled="!options.length"
                    type="link"
                    @click="selectAll([...options])">
                    Select All
                </pendo-button>
                <div
                    v-if="emptyOption"
                    class="pendo-multiselect__guide-category-sticky-header pendo-multiselect__option">
                    <pendo-checkbox-option
                        :option="emptyOption"
                        @change="select(emptyOption)" />
                </div>
            </template>
        </template>
        <template
            v-if="!loading"
            #noData>
            <div>
                {{ noDataText }}
            </div>
        </template>
        <template #option="{ option, index }">
            <slot
                name="option"
                :option="option"
                :index="index">
                <pendo-checkbox-option :option="option">
                    <pendo-actions-option
                        v-if="showAction"
                        :option="option"
                        :index="index"
                        :actions="option.actions || false"
                        @edit="onEdit"
                        @delete="onDelete" />
                </pendo-checkbox-option>
            </slot>
        </template>
        <template
            v-if="$slots.footer && !loading"
            #footer>
            <slot name="footer" />
        </template>
    </pendo-multiselect>
</template>

<script>
import PendoButton from '@/components/button/pendo-button.vue';
import PendoCheckboxOption from '@/components/multiselect/option-types/pendo-checkbox-option.vue';
import PendoDataMeasureTrigger from '@/components/multiselect/trigger-types/pendo-data-measure-trigger.vue';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator.vue';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect.vue';
import PendoActionsOption from '@/components/multiselect/option-types/pendo-actions-option.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import get from 'lodash/get';

export default {
    name: 'PendoMultiselectFilter',
    components: {
        PendoButton,
        PendoCheckboxOption,
        PendoDataMeasureTrigger,
        PendoLoadingIndicator,
        PendoMultiselect,
        PendoActionsOption,
        PendoInput,
        PendoIcon
    },
    props: {
        entityName: {
            type: String,
            required: true
        },
        emptyLabel: {
            type: String,
            default: undefined
        },
        groupOptionsKey: {
            type: String,
            default: undefined
        },
        groupLabelKey: {
            type: String,
            default: undefined
        },
        emptyOption: {
            type: Object,
            default: null
        },
        labelKey: {
            type: String,
            default: undefined
        },
        loading: {
            type: Boolean,
            default: false
        },
        menuWidth: {
            type: Number,
            default: 0
        },
        options: {
            type: Array,
            default: () => []
        },
        searchPlaceholder: {
            type: String,
            default: undefined
        },
        showAction: {
            type: Boolean,
            default: false
        },
        showAllLabel: {
            type: Boolean,
            default: true
        },
        showSearch: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: () => []
        },
        autofocusSearch: {
            type: Boolean,
            default: true
        },
        groupSearch: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        appendToBody: {
            type: Boolean,
            default: false
        },
        prefixIcon: {
            type: String,
            default: null
        },
        allLabel: {
            type: String,
            default: null
        },
        alwaysShowLength: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        showSelectedValues: {
            type: Boolean,
            default: false
        },
        useDataMeasureTrigger: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            allOptions: [],
            filteredOptions: [],
            useInternalSearch: true
        };
    },
    computed: {
        classNames () {
            const classNames = [`multiselect-filter-${this.entityName.toLowerCase().replace(/[^A-Za-z]+/, '-')}`];

            if (this.value.length === 0) {
                classNames.push('inactive');
            }

            return classNames;
        },
        label () {
            const len = this.value.length;

            // For grouped categories
            if (this.options[0] && this.options[0].type === 'metaGroup') {
                let optionsLen = 0;
                if (this.emptyOption) {
                    optionsLen++;
                }
                this.options.forEach((option) => {
                    optionsLen += option[this.groupOptionsKey].length;
                });
                if (this.showAllLabel && (len === 0 || len === optionsLen)) {
                    return this.allLabel || `All ${this.entityName}`;
                }
            } else if (this.showAllLabel && (len === 0 || (len === this.options.length && !this.alwaysShowLength))) {
                return this.allLabel || `All ${this.entityName}`;
            }

            if (!this.showAllLabel && len === 0) {
                return this.entityName;
            }

            if (len === 1) {
                return this.getLabel(this.value[0]);
            }

            return `${this.entityName} (${len})`;
        },
        showClearAction () {
            return !!this.value.length || this.showSearch;
        },
        noDataText () {
            return `No ${this.emptyLabel || this.entityName} Found`;
        },
        groupOptionsKeyLocal () {
            return this.groupOptionsKey || 'options';
        }
    },
    watch: {
        options: {
            immediate: true,
            handler (value) {
                this.allOptions = value;
                this.filteredOptions = value;
            }
        }
    },
    methods: {
        /**
         * @public
         */
        close () {
            this.$refs.menu.$refs.core.deactivate();
        },
        handleSearch (search, updateInputValue) {
            let useInternalSearch = true;
            if (this.groupSearch && search.includes(':')) {
                const { category, label } = this.parseInputValue(search);
                // leverage internal multiselect search logic when not filtering by group
                let options;
                const existingCategory = this.findOptionEqualToInputValue(this.allOptions, category);
                if (existingCategory) {
                    useInternalSearch = false;
                    const existingCategoryOptionKey = get(existingCategory, this.groupOptionsKeyLocal);
                    if (existingCategoryOptionKey) {
                        options = [
                            {
                                ...existingCategory,
                                [this.groupOptionsKeyLocal]: existingCategoryOptionKey.filter((option) =>
                                    this.getLabel(option)
                                        .toLowerCase()
                                        .includes(label.toLowerCase())
                                )
                            }
                        ];
                    } else {
                        options = [existingCategory];
                    }
                }
                this.filteredOptions = options;
            } else {
                this.filteredOptions = this.allOptions;
                updateInputValue(search);
            }
            this.useInternalSearch = useInternalSearch;
        },
        getLabel (option) {
            return option[this.labelKey] || option.label || option;
        },
        getGroupLabel (groupOption) {
            return groupOption[this.groupLabelKey] || groupOption.label;
        },
        selectAll (options) {
            // grouped options
            if (options[0] && options[0].type === 'metaGroup') {
                let ungroupedOptions = [this.emptyOption];
                options.forEach((group) => {
                    if (group[this.groupOptionsKey]) {
                        ungroupedOptions = [...ungroupedOptions, ...group[this.groupOptionsKey]];
                    }
                });
                this.$emit('input', ungroupedOptions);
            } else {
                this.$emit('input', options);
            }
        },
        setValue (value) {
            this.$emit('input', value);
            this.filteredOptions = this.allOptions;
        },
        onEdit (item) {
            this.$emit('edit', item);
        },
        onDelete (item) {
            this.$emit('delete', item);
        },
        onOpen () {
            this.$emit('open');
        },
        parseInputValue (inputValue) {
            if (inputValue && inputValue.includes(':')) {
                const [category, label] = inputValue.split(':');

                return {
                    category: category.trim(),
                    label: label.trim()
                };
            }

            return {
                label: inputValue.trim()
            };
        },
        findOptionEqualToInputValue (options, inputValue) {
            if (!options.length) {
                return undefined;
            }

            return options.find((option) => this.getGroupLabel(option).toLowerCase() === inputValue.toLowerCase());
        }
    }
};
</script>

<style lang="scss" scoped>
.inactive .pendo-multiselect__trigger {
    color: $color-gray-primary;
}
</style>

<style lang="scss">
.pendo-multiselect-filter__popper {
    .pendo-multiselect__guide-category-sticky-header {
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $color-gray-30;
    }

    .pendo-multiselect__header .pendo-button,
    .pendo-multiselect__footer .pendo-button {
        margin: 0 16px;
    }

    .pendo-multiselect__footer {
        border-top: 1px solid $color-gray-40;
    }

    .options-search {
        padding: 12px 16px;
        border-bottom: 1px solid $color-gray-40;
    }

    .pendo-multiselect-option--actions {
        position: unset;
    }

    .pendo-multiselect__content-wrapper {
        padding-top: 0;
    }

    .pendo-picker-popper__content {
        display: flex;
        flex-direction: column;
        max-height: none;
    }

    .pendo-loading-spinner {
        margin: 20px;
    }
}
</style>
