<template>
    <div class="pendo-table__guide-progress">
        <div class="pendo-table__guide-progress-container">
            <div
                v-for="(step, index) in progress"
                :key="index"
                v-pendo-tooltip="step.tooltip"
                class="pendo-table__guide-progress-step">
                <pendo-icon v-bind="step.icon" />
            </div>
        </div>
        <pendo-button
            v-if="row.totalSteps > 9"
            class="pendo-table__guide-progress-show-more"
            type="link"
            :label="toggleAllProgressLabel"
            size="mini"
            @click="isShowingAllProgress = !isShowingAllProgress" />
    </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import PendoButton from '@/components/button/pendo-button';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    name: 'PendoGuideProgressCell',
    components: {
        PendoIcon,
        PendoButton
    },
    directives: {
        PendoTooltip
    },
    inject: ['$table'],
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isShowingAllProgress: false
        };
    },
    computed: {
        toggleAllProgressLabel () {
            if (this.isShowingAllProgress) {
                return 'show less';
            }

            return `+ ${Math.abs(this.row.totalSteps - 9)} more`;
        },
        eventsByStepIndex () {
            if (!this.row.s) {
                return {};
            }

            return groupBy(this.row.s, 'stepIndex');
        },
        visibleStepCount () {
            if (this.isShowingAllProgress) {
                return this.row.totalSteps;
            }

            return Math.min(this.row.totalSteps, 9);
        },
        progress () {
            if (!this.row.s) {
                return;
            }

            return Array.from({ length: this.visibleStepCount }, (x, index) => {
                const stepIndex = index + 1;
                let props;
                if (this.eventsByStepIndex[stepIndex]) {
                    const progressEventTypes = [
                        'guideAdvanced',
                        'guideDismissed',
                        'guideSnoozed',
                        'guideSeen',
                        'guideTimeout'
                    ];
                    const mostRecentEventForStep = [...this.eventsByStepIndex[stepIndex]]
                        .filter((event) => progressEventTypes.includes(event.type))
                        .sort((a, b) => b.browserTime - a.browserTime)[0];

                    props = {
                        guideAdvanced: {
                            label: mostRecentEventForStep.isWalkthrough ? 'Advanced' : 'Action Taken',
                            fill: '#128297'
                        },
                        guideDismissed: {
                            label: 'Closed',
                            fill: '#6A6C75'
                        },
                        guideSnoozed: {
                            label: 'Snoozed',
                            fill: '#9A9CA5'
                        },
                        guideSeen: {
                            label: 'Displayed',
                            fill: '#128297'
                        },
                        guideTimeout: {
                            label: 'Display Error',
                            fill: '#DB1111'
                        }
                    }[mostRecentEventForStep.type];
                } else {
                    props = {
                        label: 'Not Seen',
                        stroke: '#BABCC5',
                        fill: '#FFFFFF'
                    };
                }

                let content = `Step ${stepIndex} of ${this.row.totalSteps}`;

                if (this.row.stepAppNames) {
                    content += `<br/>App: ${this.row.stepAppNames[index]}`;
                }

                content += `<br/>Status: ${props.label}`;

                return {
                    ...props,
                    tooltip: {
                        content,
                        html: true,
                        arrow: true
                    },
                    icon: {
                        size: 12,
                        type: 'circle',
                        strokeWidth: 3,
                        fill: props.fill,
                        stroke: props.stroke || props.fill
                    }
                };
            });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(guide-progress) {
        display: flex;
        align-items: center;
    }

    @include element(guide-progress-container) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -2px;
        max-width: 144px;
    }

    @include element(guide-progress-show-more) {
        margin-left: 8px;
        width: 68px;
        justify-self: flex-end;
    }

    @include element(guide-progress-step) {
        margin: 2px;
    }
}
</style>
