<script>
export default {
    name: 'PendoCollapseTransition',
    functional: true,
    props: {
        /**
         * apply transition on initial render
         */
        appear: {
            type: Boolean,
            default: false
        }
    },
    render (h, { props, children }) {
        const data = {
            props: {
                name: 'pendo-transition-collapse',
                appear: props.appear
            },
            on: {
                enter (element) {
                    const { width } = getComputedStyle(element);

                    element.style.width = width;
                    element.style.position = 'absolute';
                    element.style.visibility = 'hidden';
                    element.style.height = 'auto';

                    const { height } = getComputedStyle(element);

                    element.style.width = null;
                    element.style.position = null;
                    element.style.visibility = null;
                    element.style.height = 0;

                    // Force repaint to trigger animation
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;

                    requestAnimationFrame(() => {
                        element.style.height = height;
                    });
                },
                afterEnter (element) {
                    element.style.height = 'auto';
                },
                leave (element) {
                    const { height } = getComputedStyle(element);

                    element.style.height = height;

                    // Force repaint to trigger animation
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;

                    requestAnimationFrame(() => {
                        element.style.height = 0;
                    });
                }
            }
        };

        return h('transition', data, children);
    }
};
</script>

<style lang="scss">
.pendo-transition-collapse-enter-active,
.pendo-transition-collapse-leave-active {
    overflow: hidden;
}

.pendo-transition-collapse-enter-active {
    transition: height 0.25s $collapse-transition-timing-function;
}

.pendo-transition-collapse-leave-active {
    transition: height 0.2s $collapse-transition-timing-function;
}

.pendo-transition-collapse-enter,
.pendo-transition-collapse-leave-to {
    height: 0;
}
</style>
