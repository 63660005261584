<template>
    <pendo-modal
        class="pendo-favicon-upload-modal"
        title="Replace favicon"
        height="auto"
        :visible="visible"
        @close="cancelUpload">
        <template #body>
            <div class="favicon-upload-container">
                <pendo-radio-group
                    v-model="uploadOptionValue"
                    :button="true">
                    <pendo-radio-button
                        v-for="option in uploadOptions"
                        :key="option.value"
                        :class="`${option.value}-option`"
                        :selected="uploadOptionValue === option.value"
                        v-bind="option" />
                </pendo-radio-group>
                <div class="upload-options-contents">
                    <pendo-alert
                        v-if="localFaviconErr !== '' && uploadOptionValue !== 'url'"
                        type="error"
                        :description="localFaviconErr" />
                    <div
                        v-if="uploadOptionValue === 'url'"
                        class="upload-options-container">
                        <div class="upload-url-option">
                            <pendo-icon
                                v-if="!showUrlFavicon && !isLoadingFavicon"
                                type="image"
                                stroke="#6A6C75" />
                            <img
                                v-if="showUrlFavicon"
                                :src="localUrlFavicon[currentUrl]"
                                alt="Favicon">
                            <pendo-loading-indicator
                                v-if="isLoadingFavicon"
                                size="small" />
                            <pendo-input
                                :value="urlInput"
                                :debounce="600"
                                :invalid="urlError"
                                @input="onUrlInput" />
                            <pendo-button
                                v-if="urlInput !== ''"
                                class="reset-icon"
                                type="tertiary"
                                icon="trash-2"
                                @click="resetUrlUpload" />
                        </div>
                        <p class="favicon-copy">
                            {{
                                urlError
                                    ? `invalid url ${urlInput}`
                                    : "Enter the website URL of the app and we'll look for a favicon."
                            }}
                        </p>
                    </div>
                    <div
                        v-else
                        class="upload-favicon-container">
                        <div class="upload-local-option">
                            <pendo-icon
                                v-if="localFavicon === null"
                                type="image" />
                            <img
                                v-if="localFavicon !== null"
                                :src="localFavicon.imgURI"
                                alt="Favicon">
                            <pendo-button
                                label="Browse for image..."
                                type="secondary"
                                @click="chooseFile" />
                            <input
                                ref="fileInput"
                                class="file-input"
                                aria-label="upload file"
                                type="file"
                                hidden="true"
                                accept="image/png, image/jpg, image/x-icon, image/jpeg, image/gif"
                                @change="uploadFavicon">
                            <pendo-button
                                v-if="file !== null"
                                class="reset-icon"
                                type="tertiary"
                                icon="trash-2"
                                @click="resetLocalUpload" />
                        </div>
                        <p class="favicon-copy">
                            Images must be at least 24px by 24px and under 1 MB.
                        </p>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="pendo-favicon-upload-footer">
                <pendo-button
                    type="secondary"
                    label="Cancel"
                    @click="cancelUpload" />
                <pendo-button
                    :disabled="!hasUploadedFavicon"
                    type="primary"
                    label="Submit"
                    @click="confirmUpload" />
            </div>
        </template>
    </pendo-modal>
</template>
<script>
import PendoRadioButton from '@/components/radio-button/pendo-radio-button.vue';
import PendoRadioGroup from '@/components/radio-group/pendo-radio-group.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoAlert from '@/components/alert/pendo-alert.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';

const MIN_FAVICON_DIMENSIONS = 24;
const EXP_FAVICON_DIMENSIONS = 32;

export default {
    name: 'PendoFaviconUpload',
    components: {
        PendoRadioButton,
        PendoRadioGroup,
        PendoIcon,
        PendoInput,
        PendoLoadingIndicator,
        PendoButton,
        PendoAlert,
        PendoModal
    },
    props: {
        urlFavicon: {
            type: Object,
            default: () => {}
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            uploadOptionValue: 'url',
            uploadOptions: [
                {
                    value: 'url',
                    label: 'By URL'
                },
                {
                    value: 'upload',
                    label: 'Upload image'
                }
            ],
            urlInput: '',
            currentUrl: '',
            localFavicon: null,
            localUrlFavicon: {},
            isLoadingFavicon: false,
            file: null,
            localFaviconErr: '',
            urlError: false,
            hideFavicon: true
        };
    },
    computed: {
        showUrlFavicon () {
            return !this.isLoadingFavicon && !this.hideFavicon && this.isValidUrlFavicon && this.urlInput !== '';
        },
        hasUploadedFavicon () {
            if (this.uploadOptionValue === 'url') {
                return !this.urlError && this.isValidUrlFavicon;
            }

            return this.localFavicon !== null;
        },
        isValidUrlFavicon () {
            return (
                this.localUrlFavicon[this.currentUrl] !== undefined &&
                this.localUrlFavicon[this.currentUrl] !== `invalid url: ${this.currentUrl}`
            );
        },
        isInvalidFaviconUri () {
            return this.localUrlFavicon[this.currentUrl] === `invalid url: ${this.currentUrl}` && this.urlInput !== '';
        }
    },
    watch: {
        urlFavicon () {
            this.isLoadingFavicon = false;
            this.localUrlFavicon = this.urlFavicon;
            if (this.isInvalidFaviconUri) {
                this.urlError = true;
            }
        }
    },
    methods: {
        async onUrlInput ($event) {
            this.isLoadingFavicon = false;
            this.urlInput = $event;
            let preparedUrl = this.urlInput;

            if (!this.isValidDomain(this.urlInput)) {
                this.urlError = this.urlInput !== '';

                return;
            }

            if (preparedUrl.match(/^http[s]?:\/\//) === null) {
                preparedUrl = `https://${preparedUrl}`;
            }
            const urlObj = new URL(preparedUrl);
            if (urlObj.hostname !== this.currentUrl) {
                this.isLoadingFavicon = true;
                this.hideFavicon = false;
                this.$emit('fetch-favicon', urlObj.hostname);
                this.urlError = false;
                this.currentUrl = urlObj.hostname;
            }
        },
        resetLocalUpload () {
            this.localFavicon = null;
            this.file = null;
        },
        resetUrlUpload () {
            this.urlInput = '';
            this.currentUrl = '';
            this.isLoadingFavicon = false;
            this.urlError = false;
            this.hideFavicon = true;
        },
        uploadFavicon (event) {
            this.localFaviconErr = '';
            this.resetLocalUpload();
            this.file = event.target.files[0];
            const reader = new FileReader();
            if (this.file.size / 1024 / 1024 > 1) {
                this.localFaviconErr = 'Image Exceeds 1MB in Size';
                this.resetLocalUpload();

                return;
            }

            reader.onloadend = () => {
                const imgObject = new Image();

                imgObject.onload = () => {
                    const tempImg = {
                        imgURI: reader.result,
                        width: imgObject.width,
                        height: imgObject.height
                    };

                    if (tempImg.width < MIN_FAVICON_DIMENSIONS || tempImg.height < MIN_FAVICON_DIMENSIONS) {
                        this.localFaviconErr = `Image ${
                            tempImg.width > MIN_FAVICON_DIMENSIONS ? 'Width' : 'Height'
                        } is smaller than ${MIN_FAVICON_DIMENSIONS}px`;
                        this.resetLocalUpload();

                        return;
                    }
                    if (tempImg.width !== EXP_FAVICON_DIMENSIONS || tempImg.height !== EXP_FAVICON_DIMENSIONS) {
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        canvas.width = 32;
                        canvas.height = 32;
                        context.drawImage(imgObject, 0, 0, canvas.width, canvas.height);

                        tempImg.imgURI = canvas.toDataURL(imgObject.type);
                        tempImg.height = 32;
                        tempImg.width = 32;
                    }
                    this.localFavicon = tempImg;
                };
                imgObject.src = reader.result;
            };

            reader.readAsDataURL(this.file);
        },
        chooseFile () {
            // clear input before uploading to ensure
            // change event is triggered for same file uploads
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.click();
        },
        isValidDomain (domain) {
            if (domain === '') {
                this.resetUrlUpload();

                return false;
            }
            const strippedDomain = domain.replace(/^http[s]?:\/\//, '');
            // Valid domain must contain only letters, numbers, and hyphens, and must have at least 1 period before a tld.
            // Valid domain may have any number of sequential hyphens, but may not start or end with a hyphen.
            // IP addresses and ports are not supported. Wildcards are not supported.
            const validDomainRegex = new RegExp('^([a-z0-9]+(-+[a-z0-9]+)*\\.)+[a-z]{2,}$', 'gm');

            return validDomainRegex.test(strippedDomain);
        },
        cancelUpload () {
            this.resetLocalUpload();
            this.resetUrlUpload();
            this.$emit('cancel-upload');
        },
        confirmUpload () {
            this.$emit(
                'upload-favicon',
                this.uploadOptionValue === 'url'
                    ? this.urlFavicon[Object.keys(this.urlFavicon)[0]]
                    : this.localFavicon.imgURI
            );
            this.resetLocalUpload();
            this.resetUrlUpload();
        }
    }
};
</script>
<style lang="scss" scoped>
.upload-options-contents {
    margin-top: 32px;
    padding-left: 6px;
    padding-right: 6px;
}

.upload-url-option {
    display: flex;
    align-items: center;
    gap: 14px;
}

.upload-local-option {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 16px;
}

.favicon-copy {
    font-weight: 400;
    font-size: 12px;
    color: $color-text-secondary;
}

.file-input {
    display: none !important;
}

.reset-icon {
    cursor: pointer;
}
</style>
