<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        v-observe-visibility="handleVisibilityChange"
        class="pendo-layout-preview"
        :class="{
            'pendo-layout-preview--mobile': isMobileLayout,
            'pendo-layout-preview--web': isWebLayout,
            'is-hidden': isHidden
        }"
        v-html="layout.previewHTML" />
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';
import { nextFrame } from '@/utils/dom';
import { scaleToFit } from '@/utils/scale-to-fit';

export default {
    directives: {
        ObserveVisibility
    },
    props: {
        layout: {
            type: Object,
            required: true
        },
        offset: {
            type: Number,
            default: 32
        }
    },
    data () {
        return {
            isHidden: true
        };
    },
    computed: {
        isMobileLayout () {
            return this.layout.supportedPlatforms && this.layout.supportedPlatforms.mobile;
        },
        isWebLayout () {
            return this.layout.supportedPlatforms && this.layout.supportedPlatforms.web;
        }
    },
    methods: {
        async handleVisibilityChange (isVisible) {
            if (isVisible) {
                //  first rAF ensures layout.previewHTML has been rendered into dom
                //  second rAF ensures all scaling and flexing is done before showing it to user
                await nextFrame();

                if (window.pendo) {
                    window.pendo.flexElement(this.$el);
                }

                await this.scaleLayoutPreview();

                await nextFrame();
                this.isHidden = false;
            } else {
                this.isHidden = true;
            }
        },
        async scaleLayoutPreview () {
            const scaleConfig = {
                element: this.$el,
                offset: this.offset
            };

            if (this.layout.type && this.layout.type.includes('mobilePopUpBlank')) {
                scaleConfig.offset = 64;
            }

            if (this.isMobileLayout) {
                scaleConfig.aspectRatio = 9 / 16;
                scaleConfig.maxScale = 1;
            }

            await scaleToFit(scaleConfig);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-layout-preview) {
    @include is(hidden) {
        visibility: hidden;
    }

    @include modifier(web) {
        #pendo-base {
            position: unset !important;
        }

        #pendo-base > [id^='pendo-g'],
        #pendo-base > div {
            animation: none !important;
            position: static !important;
            margin-top: 0 !important;
            margin-left: 0 !important;

            &[data-vertical-alignment='Relative to Element'] {
                position: relative !important;
            }
        }

        #pendo-backdrop {
            display: none;
        }

        #pendo-guide-container {
            position: relative;

            & > [id^='pendo-close-guide-'] {
                background-color: transparent;
            }
        }

        [data-layout='bannerNpsSurvey'],
        [data-vertical-alignment='Bottom Aligned'] {
            // do not use !important here. we only want to override width if it isn't already set
            width: 800px;
        }

        [id^='pendo-row-'] textarea {
            width: 100% !important;
        }
    }

    @include modifier(mobile) {
        #pendo-base {
            position: relative !important;
        }

        #pendo-base:not(._pendo-guide-tt_) > [data-pendo-mobile-layout] {
            #pendo-guide-container {
                width: 250px !important;
            }
        }

        #pendo-base > [data-pendo-mobile-layout] {
            position: static !important;
            z-index: 0 !important;
            background-color: transparent !important;

            #pendo-guide-container {
                position: relative !important;
                margin: 0 !important;

                [id^='pendo-close-guide-'] {
                    border: 0px !important;
                }

                [id^='pendo-button-'] {
                    position: relative !important;

                    &:only-child {
                        width: 100% !important;
                    }
                }
            }
        }

        [data-layout='mobilePopUpBlank'] {
            #pendo-guide-container {
                width: 250px;
                min-height: 256px;
            }
        }
    }
}
</style>
