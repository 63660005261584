<template>
    <div
        class="pendo-date-picker__calendar-panel"
        :class="[`pendo-date-picker__calendar-panel--${count > 1 ? 'multiple' : 'single'}`]">
        <div
            v-if="$slots.above"
            class="pendo-date-picker__calendar-panel-above">
            <slot name="above" />
        </div>
        <div
            class="pendo-date-picker__calendar-panel-inner"
            :class="{ 'is-transitioning': isTransitioning }">
            <transition
                :name="transitionName"
                @before-enter="isTransitioning = true"
                @after-enter="isTransitioning = false">
                <div
                    :key="pages[0].key"
                    class="pendo-date-picker__calendar-grid">
                    <calendar-pane
                        v-for="(page, index) in pages"
                        ref="pages"
                        :key="page.key"
                        :page="page"
                        :can-move="canMove"
                        :min-page="minPage"
                        :max-page="maxPage"
                        v-on="$listeners"
                        @move-prev="movePrev"
                        @move-next="moveNext"
                        @update:page="refreshPages({ page: $event, position: index + 1 })" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import isNumber from 'lodash/isNumber';
import CalendarPane from '@/components/date-picker/calendar-pane';

import Attribute from '@/components/date-picker/utils/attribute';

import {
    pageForDate,
    pageForThisMonth,
    addPages,
    pageIsValid,
    pageIsBeforePage,
    pageIsAfterPage,
    pageIsBetweenPages
} from '@/components/date-picker/utils/helpers';

export default {
    name: 'Calendar',
    components: {
        CalendarPane
    },
    inject: ['state'],
    props: {
        activeAttribute: Object,
        disabledAttribute: Object
    },
    data () {
        return {
            pages: [],
            transitionName: '',
            isTransitioning: false,
            attribute: null,
            pinAttr: null
        };
    },
    computed: {
        locale () {
            return this.state.locale;
        },
        minPage () {
            return pageForDate(this.locale.toDate(this.state.minDate));
        },
        maxPage () {
            return pageForDate(this.locale.toDate(this.state.maxDate));
        },
        count () {
            return this.state.visibleColumnCount;
        }
    },
    watch: {
        count (count) {
            if (count > 1) {
                this.movePrev();
            } else if (this.state.model) {
                this.move(this.getPageForAttributes());
            } else {
                this.moveNext();
            }
        },
        activeAttribute () {
            this.refreshActiveAttribute();

            this.refreshAttributes();
        },
        pages () {
            this.refreshAttributes();
        },
        disabledAttribute () {
            this.refreshDisabledDays(this.pages);
        }
    },
    created () {
        this.refreshActiveAttribute();
        this.refreshAttributes();
        this.refreshPages();
    },
    methods: {
        refreshActiveAttribute () {
            if (!this.activeAttribute || !this.activeAttribute.dates) {
                this.attribute = null;
                this.pinAttr = null;

                return;
            }

            let pinAttr = null;
            const attribute = new Attribute(this.activeAttribute, this.locale);

            if (attribute && attribute.pinPage) {
                pinAttr = attribute;
            }

            this.attribute = attribute;
            this.pinAttr = pinAttr;
        },
        canMove (page) {
            return pageIsBetweenPages(page, this.minPage, this.maxPage);
        },
        movePrev () {
            this.move(-this.state.step);
        },
        moveNext () {
            this.move(this.state.step);
        },
        move (monthsOrPage) {
            const page = isNumber(monthsOrPage) ? addPages(this.pages[0], monthsOrPage) : monthsOrPage;
            this.refreshPages({
                page,
                position: 1
            });
        },
        refreshPages ({ page, position = 1 } = {}) {
            // Calculate the page to start displaying from
            let fromPage = null;
            if (pageIsValid(page)) {
                const pagesToAdd = position > 0 ? 1 - position : -(this.count + position);
                fromPage = addPages(page, pagesToAdd);
            } else {
                fromPage = this.getPageForAttributes();
            }
            fromPage = pageIsValid(fromPage) ? fromPage : pageForThisMonth();
            // Adjust from page within allowed min/max pages
            const toPage = addPages(fromPage, this.count - 1);
            if (pageIsBeforePage(fromPage, this.minPage)) {
                fromPage = this.minPage;
            } else if (pageIsAfterPage(toPage, this.maxPage)) {
                fromPage = addPages(this.maxPage, 1 - this.count);
            }
            // Create the new pages
            const pages = [...Array(this.count).keys()].map((i) => this.buildPage(addPages(fromPage, i), i));
            this.refreshDisabledDays(pages);
            // Assign the new transition
            this.transitionName = this.getPageTransition(this.pages[0], pages[0]);
            // Assign the new pages
            this.pages = pages;
            // Emit page update events
            this.$emit('update:from-page', fromPage);
            this.$emit('update:to-page', toPage);
        },
        refreshDisabledDays (pages) {
            pages
                .reduce((prev, curr) => prev.concat(curr.days), [])
                .forEach((day) => {
                    day.disabled = !!this.disabledAttribute && this.disabledAttribute.includesDay(day);
                });
        },
        getPageTransition (oldPage, newPage) {
            if (this.count > 1 || !pageIsValid(oldPage) || !pageIsValid(newPage)) {
                return 'fade';
            }
            // Moving to a previous page
            const movePrev = pageIsBeforePage(newPage, oldPage);

            // Horizontal slide
            return movePrev ? 'slide-right' : 'slide-left';
        },
        getPageForAttributes () {
            const attr = this.pinAttr;
            if (attr && attr.hasDates) {
                let [date] = attr.dates;
                date = date.start || date.date;

                return pageForDate(this.locale.toDate(date));
            }

            return null;
        },
        buildPage ({ month, year }, position) {
            const key = `${year.toString()}-${month.toString()}`;
            let page = this.pages.find((pg) => pg.key === key);

            if (!page) {
                const date = new Date(year, month - 1, 15);
                const monthComps = this.locale.getMonthComps(month, year);
                const prevMonthComps = this.locale.getPrevMonthComps(month, year);
                const nextMonthComps = this.locale.getNextMonthComps(month, year);
                page = {
                    key,
                    month,
                    year,
                    title: this.locale.format(date, this.locale.masks.title),
                    shortMonthLabel: this.locale.format(date, 'MMM'),
                    monthLabel: this.locale.format(date, 'MMMM'),
                    shortYearLabel: year.toString().substring(2),
                    yearLabel: year.toString(),
                    monthComps,
                    prevMonthComps,
                    nextMonthComps,
                    canMove: (pg) => this.canMove(pg),
                    move: (pg) => this.move(pg),
                    movePrevMonth: () => this.move(prevMonthComps),
                    moveNextMonth: () => this.move(nextMonthComps)
                };
                page.days = this.locale.getCalendarDays(page);
            }
            // Reassign position if needed
            page.position = position;

            return page;
        },
        refreshAttributes () {
            if (this.pages.length === 0) {
                return;
            }

            this.pages.forEach((page) => {
                page.days.forEach((day) => {
                    if (!this.attribute || !this.attribute.dates) {
                        day.attribute = null;

                        return;
                    }

                    const targetDate = this.attribute.includesDay(day);
                    if (targetDate) {
                        day.attribute = {
                            ...this.attribute,
                            targetDate
                        };
                    } else {
                        day.attribute = null;
                    }
                });
            });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(calendar-panel) {
        @include font-base;
        position: relative;
        border-radius: 3px;
        background: $color-white;

        @include modifier(multiple) {
            @include element(pane) {
                border-left: 1px solid $color-gray-30;
            }
        }
    }

    @include element(calendar-panel-above) {
        border-bottom: 1px solid $color-gray-30;
        border-left: 1px solid $color-gray-30;
    }

    @include element(calendar-panel-inner) {
        width: 100%;
        position: relative;

        &.is-transitioning {
            overflow: hidden;
        }
    }

    @include element(calendar-grid) {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }
}

.none-enter-active,
.none-leave-active {
    transition-duration: 0s;
}

.fade-enter-active,
.fade-leave-active,
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-up-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-down-leave-active {
    transition: transform $datepicker-slide-duration $datepicker-slide-timing,
        opacity $datepicker-slide-duration $datepicker-slide-timing;
    backface-visibility: hidden;
}

.none-leave-active,
.fade-leave-active,
.slide-left-leave-active,
.slide-right-leave-active,
.slide-up-leave-active,
.slide-down-leave-active {
    position: absolute;
    width: 100%;
}

.none-enter,
.none-leave-to,
.fade-enter,
.fade-leave-to,
.slide-left-enter,
.slide-left-leave-to,
.slide-right-enter,
.slide-right-leave-to,
.slide-up-enter,
.slide-up-leave-to,
.slide-down-enter,
.slide-down-leave-to {
    opacity: 0;
}

.slide-left-enter,
.slide-right-leave-to {
    transform: translateX(#{$datepicker-slide-translate});
}

.slide-right-enter,
.slide-left-leave-to {
    transform: translateX(calc(-1 * #{$datepicker-slide-translate}));
}

.slide-up-enter,
.slide-down-leave-to {
    transform: translateY(#{$datepicker-slide-translate});
}

.slide-down-enter,
.slide-up-leave-to {
    transform: translateY(calc(-1 * #{$datepicker-slide-translate}));
}
</style>
