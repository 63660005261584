<template>
    <div
        class="pendo-multiselect__value"
        :class="{
            'pendo-multiselect__value--tags': showSelectedValueTags,
            'is-disabled': disabled
        }">
        <slot
            name="value"
            v-bind="{
                selectedValuePointer,
                selectedLabel,
                getOptionLabel,
                inputValue,
                isOpen,
                isPlaceholderVisible,
                isSelectedLabelVisible,
                limit,
                limitText,
                loading,
                model,
                placeholder,
                removeSelection,
                selectedValue,
                visibleSelectedValues,
                toggleMenu
            }">
            <template v-if="showSelectedValueTags && visibleSelectedValues.length">
                <template v-for="(option, index) of visibleSelectedValues">
                    <slot
                        name="tag"
                        :option="option"
                        :input-value="inputValue"
                        :remove-selection="removeSelection">
                        <div
                            :key="index"
                            class="pendo-multiselect__tag"
                            :class="{ 'is-selected': index === selectedValuePointer }">
                            <slot
                                name="tagLabel"
                                :option="option">
                                <span>{{ getOptionLabel(option) }}</span>
                            </slot>
                            <div
                                class="pendo-multiselect__tag-remove"
                                role="button"
                                :aria-label="`Remove ${getOptionLabel(option)}`">
                                <pendo-icon
                                    aria-hidden="true"
                                    type="x"
                                    size="14"
                                    @click.prevent.stop="removeSelection(option)" />
                            </div>
                        </div>
                    </slot>
                </template>
            </template>
            <div
                v-if="model && model.length > limit"
                class="pendo-multiselect__tag">
                <span>+ {{ model.length - limit }}</span>
            </div>
            <slot
                v-if="isOpen"
                name="control" />
            <transition
                :name="labelTransition"
                @before-leave="beforeLeave">
                <span
                    v-if="isPlaceholderVisible || isSelectedLabelVisible"
                    class="pendo-multiselect__value-inner">
                    <span
                        v-if="isSelectedLabelVisible"
                        ref="selectedLabel"
                        v-pendo-tooltip="{
                            content: selectedLabelOverflowTooltipContent,
                            multiline: true,
                            delay: { show: 300, hide: 0 }
                        }"
                        class="pendo-multiselect__selected">
                        <slot
                            name="selectedLabel"
                            v-bind="{
                                option: selectedValue,
                                selectedLabel
                            }">
                            {{ selectedLabel }}
                        </slot>
                    </span>
                    <span
                        v-if="isPlaceholderVisible"
                        class="pendo-multiselect__placeholder">
                        <slot
                            name="placeholder"
                            :placeholder="placeholder">
                            {{ placeholder }}
                        </slot>
                    </span>
                </span>
            </transition>
        </slot>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import { shouldAttachOverflowTooltip } from '@/components/multiselect/multiselect-helper';
import { hasSlot } from '@/utils/utils';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        disabled: {
            type: Boolean
        },
        getOptionLabel: {
            type: Function
        },
        inputValue: {
            type: String
        },
        isOpen: {
            type: Boolean
        },
        isPlaceholderVisible: {
            type: Boolean
        },
        isSelectedLabelVisible: {
            type: Boolean
        },
        limit: {
            type: Number
        },
        limitText: {
            type: Function
        },
        loading: {
            type: Boolean
        },
        model: {
            type: Array
        },
        placeholder: {
            type: [String, Number]
        },
        removeSelection: {
            type: Function
        },
        selectedLabel: {
            type: [String, Number]
        },
        selectedValue: {
            type: [Object, Number, String, Array]
        },
        selectedValuePointer: {
            type: Number
        },
        shouldAnimateSearchEnterExit: {
            type: Boolean
        },
        showSelectedValueTags: {
            type: Boolean
        },
        toggleMenu: {
            type: Function
        },
        visibleSelectedValues: {
            type: Array
        }
    },
    data () {
        return {
            selectedLabelOverflowTooltipContent: null
        };
    },
    computed: {
        labelTransition () {
            if (this.shouldAnimateSearchEnterExit) {
                return 'pendo-multiselect-label-slide';
            }

            return '';
        }
    },
    watch: {
        async selectedLabel () {
            await this.$nextTick();
            this.checkForSelectedLabelOverflow();
        }
    },
    methods: {
        beforeLeave (el) {
            el.style.display = 'none';
        },
        checkForSelectedLabelOverflow () {
            this.selectedLabelOverflowTooltipContent = null;

            if (this.isSelectedLabelVisible) {
                const hasCustomSelectedLabelSlotContent = hasSlot(this, 'selectedLabel', {
                    option: this.selectedValue,
                    selectedLabel: this.selectedLabel
                });

                if (hasCustomSelectedLabelSlotContent) {
                    return;
                }

                const showOverflowTooltip = shouldAttachOverflowTooltip(this.selectedLabel, this.$refs.selectedLabel);

                if (showOverflowTooltip) {
                    this.selectedLabelOverflowTooltipContent = this.selectedLabel;
                }
            }
        }
    }
};
</script>

<style lang="scss">
.pendo-multiselect-label-slide-enter {
    transform: translateX(22px);
}

.pendo-multiselect-label-slide-enter-active {
    transition: transform 100ms;
}

@include block(pendo-multiselect) {
    @include element(value) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        box-sizing: border-box;
        flex: 1 1 0%;
        line-height: 24px;
        @include ellipsis;

        @include modifier(tags) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 4px;
            margin: 5px 0;

            .pendo-multiselect__control {
                height: 24px;
            }
        }
    }

    @include element(value-inner) {
        @include ellipsis;
    }

    @include element((placeholder, selected)) {
        transform: translateX(0);
        font-family: inherit;
        font-size: 14px;
        line-height: 24px;
        position: relative;
        display: block;
        margin: 0;
        border: none;
        border-radius: 3px;
        width: 100%;
        min-width: 0;
        @include ellipsis;
    }

    @include element(tag) {
        position: relative;
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-auto-flow: column;
        grid-gap: 4px;
        height: 24px;
        color: $color-text-primary;
        background-color: $color-gray-30;
        max-width: 100%;
        padding: 0 8px;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 3px;

        span {
            @include ellipsis;
        }

        ~ .pendo-multiselect__input,
        ~ .pendo-multiselect__selected {
            width: auto;
        }

        @include is(selected) {
            background-color: $color-gray-40;
        }
    }

    @include element(tag-icon) {
        cursor: pointer;
    }

    @include element(selected) {
        &:focus {
            outline: none;
        }
    }
}
</style>
