<template>
    <pendo-button
        v-pendo-tooltip="{ content: 'Edit Description', boundariesElement: 'viewport' }"
        icon="edit"
        theme="app"
        type="tertiary"
        @click="openModal" />
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    name: 'EditAction',
    components: {
        PendoButton
    },
    directives: {
        PendoTooltip
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    methods: {
        openModal () {
            this.$emit('modal-open', { action: 'edit', id: this.id, description: this.description });
        }
    }
};
</script>
