<template>
    <div class="pendo-data-collection-consent-toggle">
        <pendo-checkbox
            v-if="!loading && type === 'checkbox'"
            :value="value"
            :disabled="disabled"
            @change="toggleConsent($event)" />
        <pendo-button
            v-if="!loading && type === 'button'"
            theme="app"
            size="mini"
            :type="value ? 'danger' : 'primary'"
            :label="value ? 'Disable' : 'Enable'"
            :loading="loading"
            :disabled="disabled"
            @click="toggleConsent(!value)" />
        <pendo-modal
            :visible="showConfirmOptOut"
            :confirm-button-config="{
                type: 'danger',
                label: 'Turn Off'
            }"
            append-to-body
            type="confirmation"
            title="Warning"
            @close="changeValue(true)"
            @cancel="changeValue(true)"
            @confirm="changeValue(false)">
            <slot name="disableBody" />
        </pendo-modal>
        <pendo-legal-acknowledgement
            :visible="showLegalAcknowledgement"
            :is-for-customer="isForCustomer"
            :title="legalTitle"
            action-button-label="I Accept"
            @confirm-action="changeValue(true)"
            @close="changeValue(false)">
            <slot name="enableBody" />
        </pendo-legal-acknowledgement>
    </div>
</template>
<script>
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoLegalAcknowledgement from '@/composites/legal-acknowledgement/pendo-legal-acknowledgement.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';

export default {
    name: 'PendoDataCollectionConsentToggle',
    components: {
        PendoButton,
        PendoCheckbox,
        PendoLegalAcknowledgement,
        PendoModal
    },
    props: {
        /**
         * If doing this on behalf of customer
         */
        isForCustomer: {
            type: Boolean,
            default: false
        },
        /**
         * Value
         * `true`, `false`
         */
        value: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            required: true
        },
        legalTitle: {
            type: String,
            default: 'Increased Data Collection'
        },
        requiresOptOut: {
            type: Boolean,
            default: false
        },
        /**
         * Toggle style
         * `checkbox`, `button`
         */
        type: {
            type: String,
            default: 'checkbox',
            validator: (type) => ['checkbox', 'button'].includes(type)
        }
    },
    data () {
        return {
            showConfirmOptOut: false,
            showLegalAcknowledgement: false
        };
    },
    methods: {
        toggleConsent (value) {
            if (value === true) {
                this.showLegalAcknowledgement = true;

                return;
            }

            if (value === false && this.requiresOptOut) {
                this.showConfirmOptOut = true;
            }

            if (value === false && !this.requiresOptOut) {
                this.changeValue(false);
            }
        },
        changeValue (value) {
            this.$emit('change', value);
            this.showConfirmOptOut = false;
            this.showLegalAcknowledgement = false;
        }
    }
};
</script>
