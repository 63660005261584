<template>
    <pendo-card
        class="pendo-layout-grid"
        :title="title"
        :data-cy="dataCy"
        :collapsible="collapsible"
        :disabled="disabled">
        <template #title>
            <div class="pendo-layout-grid__title-wrapper">
                <div
                    ref="title"
                    v-pendo-tooltip="{
                        content: titleTooltipContent,
                        arrow: true,
                        html: false,
                        delay: { show: 300, hide: 0 }
                    }"
                    class="pendo-layout-grid__title">
                    {{ title }}
                </div>
                <div class="pendo-layout-grid__layouts-text">
                    &nbsp;Layouts
                </div>
            </div>
        </template>
        <slot name="beforeLayouts" />
        <pendo-chooser-grid
            :value="selectedLayout"
            :disabled="disabled"
            :items="formattedLayouts"
            :filters="filters"
            @select="handleLayoutSelect">
            <template #media="{ item }">
                <pendo-layout-preview
                    v-if="!item.loading"
                    :layout="item" />
                <pendo-tag
                    v-if="item.recommended"
                    label="Recommended"
                    color="#18044A"
                    class="pendo-layout-grid__recommended-label"
                    round />
            </template>
            <template #overlay="{ item, select }">
                <pendo-button
                    v-if="allowActions.delete && !item.readOnly"
                    v-pendo-tooltip="{ content: 'Delete Layout', offset: 0, delay: { show: 300, hide: 0 } }"
                    data-cy="delete-layout"
                    class="delete-layout"
                    icon="trash-2"
                    icon-size="18"
                    @click="handleLayoutDelete(item)" />
                <pendo-button
                    theme="p2-dark"
                    type="secondary"
                    data-cy="select-layout"
                    class="select-layout"
                    label="Select Layout"
                    :loading="item.selected"
                    @click="select(item)" />
            </template>
            <template #title="{ item }">
                <span>{{ item.title }}</span>
                <span class="layout-labels">
                    <pendo-tag
                        v-if="item.beta"
                        size="mini"
                        type="beta"
                        round
                        subtle />
                    <pendo-popover
                        v-if="item.minSdkVersion"
                        :arrow="true"
                        placement="bottom"
                        boundaries-element="viewport"
                        popover-class="min-sdk-layout--popover"
                        theme="dark">
                        <pendo-tag
                            size="mini"
                            type="sdk"
                            label="v"
                            round
                            subtle />
                        <template #popover>
                            <div class="min-sdk-popper-container">
                                <div class="min-sdk-popper-title">
                                    <strong>SDK Version Requirement</strong>
                                </div>
                                <div class="{ min-sdk-popper-text: item.percentEligibleVisitors }">
                                    Only visitors on applications with SDK {{ item.minSdkVersion }} or later will see
                                    guides with this layout.
                                </div>
                                <div
                                    v-if="item.percentEligibleVisitors"
                                    class="min-sdk-popper-footer">
                                    <strong>
                                        {{ item.percentEligibleVisitors }} of your users are on a compatible version.
                                    </strong>
                                </div>
                            </div>
                        </template>
                    </pendo-popover>
                </span>
            </template>
        </pendo-chooser-grid>
        <template v-if="showDeleteLayoutModal">
            <pendo-modal
                :visible="showDeleteLayoutModal"
                :confirm-button-config="{
                    type: 'danger',
                    label: 'Delete Layout'
                }"
                append-to-body
                type="confirmation"
                title="Delete Layout?"
                message="You won't be able to recover it later. Are you sure you want to delete this layout?"
                @close="cancelDelete"
                @cancel="cancelDelete"
                @confirm="confirmDelete" />
        </template>
    </pendo-card>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import PendoButton from '@/components/button/pendo-button';
import PendoCard from '@/components/card/pendo-card';
import PendoChooserGrid from '@/components/chooser-grid/pendo-chooser-grid';
import PendoLayoutPreview from '@/composites/layout-chooser/pendo-layout-preview';
import PendoModal from '@/components/modal/pendo-modal';
import PendoTag from '@/components/tag/pendo-tag';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoPopover from '@/components/popover/pendo-popover.vue';
import { elementHasOverflowX } from '@/utils/dom';

export default {
    name: 'PendoLayoutGrid',
    components: {
        PendoCard,
        PendoChooserGrid,
        PendoLayoutPreview,
        PendoButton,
        PendoModal,
        PendoTag,
        PendoPopover
    },
    directives: {
        PendoTooltip
    },
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: null
        },
        layouts: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        allowActions: {
            type: Object,
            default: () => ({})
        },
        filters: {
            type: [Object, Array],
            default: () => [
                {
                    prop: ['title', 'subtitle'],
                    value: ''
                }
            ]
        },
        collapsible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showDeleteLayoutModal: false,
            layoutToDelete: null,
            selectedLayout: null,
            dataCy: kebabCase(`${this.title} Layouts`),
            titleTooltipContent: null
        };
    },
    computed: {
        formattedLayouts () {
            const { loading, layouts, disabled, selectedLayout } = this;

            if (loading && !layouts.length) {
                return Array(8).fill({ disabled: true, loading: true });
            }

            return layouts.map((layout) => {
                const id = layout.id || kebabCase(layout.name);

                return {
                    ...layout,
                    id,
                    title: layout.name,
                    subtitle: layout.subtext || 'Pendo Default',
                    disabled: loading || layout.loading || disabled || !layout.active,
                    loading: loading || layout.loading,
                    selected: selectedLayout ? selectedLayout.id === id : false,
                    tooltipOptions: {
                        ...layout.tooltipOptions,
                        content: layout.active ? null : layout.tooltipOptions && layout.tooltipOptions.content
                    }
                };
            });
        }
    },
    mounted () {
        this.checkTitleOverflow();
    },
    methods: {
        async checkTitleOverflow () {
            const elementHasOverflow = elementHasOverflowX(this.$refs.title);
            if (elementHasOverflow) {
                this.titleTooltipContent = this.title;
            }
        },
        handleLayoutSelect (layout) {
            this.selectedLayout = layout;
            /**
             * Emitted when user selects a layout
             *
             * @event delete-layout
             * @property {Event} $event - DOM Event
             */
            this.$emit('select-layout', layout);
            /**
             * @deprecated Use `@select-layout` event instead.
             * @since 2.5.0
             * @property {Event} $event - DOM Event
             */
            this.$emit('onChooseLayout', layout);
        },
        handleLayoutDelete (layout) {
            this.layoutToDelete = layout;
            this.showDeleteLayoutModal = true;
        },
        cancelDelete () {
            this.layoutToDelete = null;
            this.showDeleteLayoutModal = false;
        },
        confirmDelete () {
            /**
             * Emitted when user confirms delete layout action
             *
             * @event delete-layout
             * @property {Event} $event - DOM Event
             */
            this.$emit('delete-layout', this.layoutToDelete);
            /**
             * @deprecated Use `@delete-layout` event instead.
             * @since 2.5.0
             * @property {Event} $event - DOM Event
             */
            this.$emit('onDeleteLayout', this.layoutToDelete);

            this.layoutToDelete = null;
            this.showDeleteLayoutModal = false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-layout-grid) {
    min-width: 310px;

    @include element(title-wrapper) {
        display: grid;
        grid-auto-flow: column;
        line-height: initial;
        margin-right: 16px;
    }

    @include element(title) {
        @include ellipsis;
    }

    .pendo-card__body {
        padding: 0;
    }

    .pendo-chooser-grid__item {
        .pendo-media-card__content {
            width: 100% !important;
            height: 100% !important;
        }

        .pendo-layout-grid__recommended-label {
            position: absolute;
            top: 5px;
            left: 5px;
        }

        .pendo-media-card__overlay-actions {
            display: grid;
            grid-template-columns: 36px 1fr 36px;
            grid-template-rows: 36px 1fr 36px;
            align-content: center;
            justify-items: center;

            .delete-layout {
                grid-column: 3;
                grid-row: 1;
                background-color: transparent;

                svg {
                    transition: stroke 200ms;
                    stroke: $color-gray-50;
                }

                &:hover {
                    background-color: transparent;
                    cursor: pointer;

                    svg {
                        stroke: $color-white;
                    }
                }
            }

            .select-layout {
                grid-column: 2;
                grid-row: 2;
                z-index: 1;
            }
        }
        .pendo-media-card__header {
            background-color: $color-gray-10;
        }

        .layout-labels {
            vertical-align: text-top;

            .v-popover {
                display: inline;
            }
        }
    }
}

.min-sdk-layout--popover {
    width: 400px;

    .min-sdk-popper-text {
        margin-bottom: 1em;
    }
}
</style>
