<template>
    <div v-if="showCard">
        <pendo-card title="User Preferences">
            <div
                v-for="(preferenceValue, preferenceKey) of userPreferencesMap"
                :key="preferenceKey">
                <pendo-toggle
                    :key="preferenceKey"
                    class="user-preferences__toggle"
                    :labels="{ right: labelFormat(preferenceValue, preferenceKey) }"
                    :value="preferenceValue.value"
                    :disabled="preferenceValue.disabled"
                    @change="handleToggleChange(preferenceKey, !preferenceValue.value)" />
                <div
                    v-if="preferenceValue.subSettings"
                    class="user-preferences__sub-setting">
                    <pendo-toggle
                        v-for="(subSettingValue, subSettingKey) of preferenceValue.subSettings"
                        :key="subSettingKey"
                        class="user-preferences__toggle"
                        :labels="{ right: labelFormat(subSettingValue, subSettingKey) }"
                        :value="subSettingValue.value"
                        :disabled="subSettingValue.disabled"
                        @change="handleToggleChange(subSettingKey, !subSettingValue.value, preferenceKey)" />
                </div>
            </div>
        </pendo-card>
    </div>
</template>

<script>
import PendoToggle from '@/components/toggle/pendo-toggle.vue';
import PendoCard from '@/components/card/pendo-card.vue';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export default {
    name: 'PendoSuggestedPagesTable',
    components: {
        PendoCard,
        PendoToggle
    },
    props: {
        userPreferences: {
            required: true,
            type: Object,
            default: () => {}
        }
    },
    computed: {
        showCard () {
            return !isEmpty(this.userPreferences);
        },
        userPreferencesMap () {
            const userPreferencesClone = cloneDeep(this.userPreferences);

            return Object.fromEntries(
                Object.entries(userPreferencesClone).map(([preferenceKey, preferenceValue]) => {
                    if (preferenceValue.subSettings) {
                        Object.fromEntries(
                            Object.entries(preferenceValue.subSettings).map(([subSettingKey, subSettingValue]) => {
                                subSettingValue.value = preferenceValue.value
                                    ? subSettingValue.value
                                    : preferenceValue.value;
                                subSettingValue.disabled = this.subSettingDisabled(preferenceValue, subSettingValue);

                                return [subSettingKey, subSettingValue];
                            })
                        );
                    }

                    return [preferenceKey, preferenceValue];
                })
            );
        }
    },
    methods: {
        subSettingDisabled (preferenceValue, subSettingValue) {
            return preferenceValue.disabled || !preferenceValue.value ? true : subSettingValue.disabled;
        },
        handleToggleChange (preference, value, parentPreferencekey = false) {
            const userPreferencesClone = cloneDeep(this.userPreferences);
            if (parentPreferencekey) {
                userPreferencesClone[parentPreferencekey].subSettings[preference].value = value;
            } else {
                userPreferencesClone[preference].value = value;
                if (!isEmpty(get(userPreferencesClone[preference], 'subSettings', false))) {
                    Object.fromEntries(
                        Object.entries(userPreferencesClone[preference].subSettings).map(
                            ([subSettingKey, subSettingValue]) => {
                                subSettingValue.value = subSettingValue.disabled
                                    ? false
                                    : (subSettingValue.value = value);

                                return [subSettingKey, subSettingValue];
                            }
                        )
                    );
                }
            }
            this.$emit('toggle-change', userPreferencesClone);
        },
        labelFormat (preferenceValue, preferenceKey) {
            return preferenceValue.displayName || startCase(camelCase(preferenceKey));
        }
    }
};
</script>

<style lang="scss" scoped>
.user-preferences__sub-setting {
    margin-top: 8px;
    margin-left: 45px;
}
.user-preferences__toggle {
    margin-top: 8px;
}
</style>
