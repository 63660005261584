<template>
    <div class="pendo-page-content">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PendoPageContent',
    inject: ['$app'],
    props: {
        tabs: {
            type: Boolean,
            default: false
        }
    },
    created () {
        this.$app.updateProperty('tabs', this.tabs);
    }
};
</script>

<style lang="scss">
@include block(pendo-page-content) {
    flex: 1 1 auto;
    z-index: 1;
    min-width: 0;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    border-top: 1px solid $color-gray-40;
    padding: 32px 32px 0;

    @include block(pendo-filter-bar) {
        margin-bottom: 16px;

        &.is-sticky {
            .pendo-affix {
                z-index: 2;
            }
        }
    }
}
</style>
