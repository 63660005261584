<template>
    <div
        class="pendo-date-picker__month"
        :class="{
            'is-hovering': isHovering,
            'is-disabled': month.disabled,
            'is-selected': month.selected,
            'is-current': month.current
        }"
        @click="handleMonthClick"
        @mouseenter="handleMonthMouseEnter"
        @mouseleave="handleMonthMouseLeave">
        <div class="pendo-date-picker__month-highlight-current" />
        <div
            ref="content"
            class="pendo-date-picker__month-content">
            {{ month.label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CalendarMonth',
    props: {
        month: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isHovering: false
        };
    },
    methods: {
        handleMonthClick () {
            if (this.month.disabled) {
                return;
            }

            this.$emit('click', this.month.key);
        },
        handleMonthMouseEnter () {
            if (this.month.disabled) {
                return;
            }

            this.isHovering = true;
        },
        handleMonthMouseLeave () {
            this.isHovering = false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(month) {
        display: grid;
        grid-template-rows: 10px [content] auto 10px;
        grid-template-columns: [left] 1fr [right] 1fr;
        align-items: center;
        justify-items: center;
        z-index: auto;
        cursor: pointer;
        border-radius: $datepicker-option-border-radius;

        &.is-hovering:not(.is-selected):not(.is-current) {
            @include element(month-highlight-current) {
                border-color: $datepicker-cell-hover-border-color;
                border-width: $datepicker-cell-border-width;
                box-shadow: $datepicker-cell-hover-shadow;
            }

            @include element(month-content) {
                color: $datepicker-color;
            }
        }

        @include is(selected) {
            @include element(month-highlight-current) {
                font-weight: 700;
                background-color: $datepicker-cell-active-color;
            }

            @include element(month-content) {
                color: $datepicker-active-color;
                font-weight: 700;
            }
        }

        @include is(current) {
            @include element(month-highlight-current) {
                font-weight: 700;
                border-color: $datepicker-cell-current-border-color;
            }
        }

        @include is(disabled) {
            cursor: not-allowed;

            @include element(month-content) {
                opacity: 0.4;
            }
        }
    }

    @include element((month-content, month-highlight-current, month-highlight-base)) {
        box-sizing: border-box;
    }

    @include element(month-content) {
        grid-row: content;
        grid-column-start: 1;
        grid-column-end: -1;
        text-align: center;
        line-height: 28px;
        z-index: 2;
    }

    @include element(month-highlight-current) {
        grid-column-start: 1;
        grid-column-end: -1;
        grid-row-start: 1;
        grid-row-end: -1;
        border-radius: $datepicker-option-border-radius;
        height: $datepicker-month-size;
        width: $datepicker-month-size;
        z-index: 1;
        border: 2px solid $datepicker-cell-border-color;
    }
}
</style>
