<template>
    <div
        class="active-keys"
        data-cy="active-signing-keys-table">
        <pendo-table
            ref="activetable"
            class="active-keys--table"
            title="Active Keys"
            empty-text="No Keys have been created"
            :loading="loadingState"
            loading-text="Loading Active Keys"
            max-height="500"
            :data="tableData"
            :columns="columns"
            row-key="id"
            :default-sort="defaultSort">
            <template #headerRight>
                <span>
                    <pendo-icon
                        v-if="hasMaximumActiveKeys"
                        v-pendo-tooltip="{
                            arrow: true,
                            content: 'Applications are limited to a maximum of five active JWT keys.',
                            boundariesElement: 'viewport'
                        }"
                        class="inline-tooltip"
                        type="alert-circle"
                        size="14"
                        stroke-width="2.5" />
                    <pendo-button
                        theme="app"
                        prefix-icon="plus"
                        data-cy="generate-keys-button"
                        type="link"
                        label="Generate Key"
                        :disabled="loadingState || hasMaximumActiveKeys"
                        @click="
                            openModal({
                                action: 'create',
                                id: newKeyName,
                                description: ''
                            })
                        " />
                </span>
            </template>
            <template #secret="{ row }">
                <div class="flex-container">
                    <pendo-button
                        theme="app"
                        type="link"
                        :label="row.showSecret ? 'Hide' : 'Show'"
                        @click="handleToggleSecret(row)" />
                    <span v-show="row.showSecret">
                        <pendo-copy-code-block
                            language="plaintext"
                            :button-options="{ label: 'Copy Key', position: 'right' }"
                            :code-to-copy="row.secret" />
                    </span>
                </div>
            </template>
            <template #actions="{ row }">
                <edit-action
                    :id="row.id"
                    :description="row.description"
                    @modal-open="openModal" />
                <revoke-action
                    :id="row.id"
                    :description="row.description"
                    @modal-open="openModal" />
            </template>
        </pendo-table>

        <pendo-modal
            class="active-key-modal"
            height="auto"
            :esc-to-close="true"
            :visible="isModalVisible"
            :title="modalTitle"
            :confirm-button-config="confirmButtonConfig"
            :cancel-button-config="{ type: 'secondary', theme: 'app' }"
            @close="closeModal"
            @confirm="confirmModal"
            @cancel="closeModal">
            <template #body>
                <pendo-alert
                    v-if="modalAction === 'revoke'"
                    class="active-keys--revoke-alert"
                    type="warning"
                    description="Pendo will no longer be able to process data with this key. This action cannot be undone." />
                <div class="active-keys--info">
                    <strong>{{ modalIdLabel }}:</strong> {{ modalId }}
                </div>
                <pendo-input
                    v-model="modalDescription"
                    placeholder="E.g. Production Server 2..."
                    :labels="{ top: 'Description' }"
                    data-cy="generate-key-description-input" />
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import PendoAlert from '@/components/alert/pendo-alert';
import PendoTable from '@/components/table/pendo-table';
import PendoButton from '@/components/button/pendo-button';
import PendoModal from '@/components/modal/pendo-modal';
import PendoInput from '@/components/input/pendo-input';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoCopyCodeBlock from '@/composites/copy-code-block/pendo-copy-code-block';
import EditAction from './actions/edit-action.vue';
import RevokeAction from './actions/revoke-action.vue';
import moment from 'moment';
import get from 'lodash/get';
const MAXIMUM_ACTIVE_KEYS = 5;

export default {
    name: 'ActiveKeys',
    components: {
        PendoAlert,
        PendoTable,
        PendoModal,
        PendoButton,
        PendoInput,
        PendoIcon,
        EditAction,
        RevokeAction,
        PendoCopyCodeBlock
    },
    directives: {
        PendoTooltip
    },
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        appId: {
            type: [String, Number],
            default: '-323232'
        },
        newKeyName: {
            type: String,
            default: null
        },
        loadingState: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isModalVisible: false,
            modalAction: null,
            modalId: null,
            modalDescription: null,
            defaultSort: {
                prop: 'createdAt',
                order: 'descending'
            },
            columns: [
                {
                    prop: 'id',
                    label: 'Signing Key Name',
                    sortable: true
                },
                {
                    prop: 'description',
                    label: 'Description',
                    width: '250px',
                    sortable: true
                },
                {
                    prop: 'secret',
                    label: 'Key',
                    width: '500px',
                    sortable: false
                },
                {
                    label: 'Created Time',
                    prop: 'createdAt',
                    sortable: true,
                    formatter: (row) => moment(row.createdAt).format('MMM D, YYYY h:mm:ss A z')
                },
                {
                    label: 'Created By',
                    prop: 'createdByUser.username',
                    sortable: true
                },
                {
                    type: 'actions',
                    width: '80px'
                }
            ]
        };
    },
    computed: {
        modalTitle () {
            switch (this.modalAction) {
                case 'create':
                    return 'Generate Key';
                case 'edit':
                    return 'Edit Key Description';
                case 'revoke':
                    return 'Confirm Revocation';
            }

            return;
        },
        modalIdLabel () {
            return this.modalAction === 'create' ? 'Signing Key Name Preview' : 'Signing Key Name';
        },
        confirmButtonConfig () {
            const actionDisabled = !this.modalDescription;
            switch (this.modalAction) {
                case 'create':
                    return {
                        type: 'primary',
                        theme: 'app',
                        label: 'Create',
                        disabled: actionDisabled
                    };
                case 'edit':
                    return {
                        type: 'primary',
                        theme: 'app',
                        label: 'Save',
                        disabled: actionDisabled
                    };
                case 'revoke':
                    return {
                        type: 'danger',
                        theme: 'app',
                        label: 'Revoke Key',
                        disabled: actionDisabled
                    };
            }

            return;
        },
        hasMaximumActiveKeys () {
            return get(this, 'tableData.length', 0) >= MAXIMUM_ACTIVE_KEYS;
        }
    },
    methods: {
        handleToggleSecret (row) {
            this.$refs.activetable.updateRow({ ...row, showSecret: !row.showSecret });
        },
        openModal ({ action, id, description }) {
            this.modalAction = action;
            this.modalId = id;
            this.modalDescription = description;
            this.isModalVisible = true;
        },
        closeModal () {
            this.isModalVisible = false;
        },
        confirmModal () {
            switch (this.modalAction) {
                case 'create':
                    this.$emit('create', {
                        kind: 'jwt',
                        description: this.modalDescription,
                        appId: this.appId
                    });
                    break;
                case 'edit':
                    this.$emit('update', {
                        id: this.modalId,
                        description: this.modalDescription
                    });
                    break;
                case 'revoke':
                    this.$emit('revoke', {
                        id: this.modalId,
                        description: this.modalDescription,
                        revoked: true
                    });
                    break;
            }
            this.isModalVisible = false;
        }
    }
};
</script>

<style scoped lang="scss">
.active-keys--revoke-alert,
.active-keys--info {
    margin-bottom: 25px;
}
.inline-tooltip {
    display: inline-flex;
}
.flex-container {
    display: flex;
}
::v-deep .pendo-copy-code-block__pre {
    margin: 0 8px;
}
</style>
