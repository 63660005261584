<template>
    <div
        class="pendo-date-picker__day"
        :aria-label="ariaLabel"
        :tabindex="isDisabled ? -1 : 0"
        :class="[
            day.classes,
            {
                'is-outside-month': isOutsideMonth,
                'is-disabled': isDisabled,
                'is-hovering': isHovering,
                'is-range-start': onStart && isDragging,
                'is-range-end': onEnd && isDragging,
                'is-selected': isSelected,
                'is-drag-start': isDragStart
            }
        ]"
        @click="handleDayClick"
        @mouseenter="handleDayMouseEnter"
        @mouseleave="handleDayMouseLeave"
        @focusin="handleDayFocus"
        @focusout="handleDayBlur">
        <div class="pendo-date-picker__day-highlight-current" />
        <div
            class="pendo-date-picker__day-highlight-base"
            :class="{
                'is-range-start': onStart,
                'is-range-middle': inRange,
                'is-range-end': onEnd
            }" />
        <div
            ref="content"
            class="pendo-date-picker__day-content">
            {{ day.label }}
        </div>
    </div>
</template>

<script>
import { format } from '@/components/date-picker/utils/fecha';

export default {
    name: 'CalendarDay',
    inject: ['state'],
    props: {
        day: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isHovering: false,
            isFocused: false
        };
    },
    computed: {
        ariaLabel () {
            const formattedDate = format(this.day.date, 'WWWW, MMMM D, YYYY', this.state.locale);

            return this.isSelected ? `${formattedDate}. Selected date.` : `${formattedDate}. Select this date.`;
        },
        isDragging () {
            return !!this.state.dragValue;
        },
        isOutsideMonth () {
            return !this.day.inMonth;
        },
        isDisabled () {
            return !!this.day.disabled;
        },
        isSelected () {
            if (!this.day.attribute) {
                return false;
            }

            if (this.day.attribute.key === 'select' && this.day.attribute.targetDate.isDate) {
                return this.day.attribute.targetDate.dateTime === this.day.dateTime;
            }

            if (this.onStart || this.onEnd) {
                return !this.state.dragValue;
            }

            return false;
        },
        onStart () {
            if (!this.day.attribute) {
                return false;
            }

            return this.day.attribute.targetDate.startTime === this.day.dateTime;
        },
        onEnd () {
            if (!this.day.attribute) {
                return false;
            }

            return this.day.attribute.targetDate.endTime === this.day.dateTime;
        },
        onStartAndEnd () {
            return this.onStart && this.onEnd;
        },
        dayEvent () {
            return {
                ...this.day,
                el: this.$refs.content
            };
        },
        inRange () {
            if (!this.day.attribute) {
                return false;
            }

            if (!['select', 'drag'].includes(this.day.attribute.key)) {
                return false;
            }

            return !this.isDisabled && !this.isDateOrComplex && !this.onStartAndEnd;
        },
        isDateOrComplex () {
            if (!this.day.attribute) {
                return false;
            }

            const { isDate, isComplex } = this.day.attribute.targetDate;

            return isDate || isComplex;
        },
        isDragStart () {
            if (this.state.type !== 'range' || !this.state.dragValue || !this.day.attribute) {
                return false;
            }

            return this.state.dragValue.start.getTime() === this.day.dateTime;
        }
    },
    methods: {
        getDayEvent (origEvent) {
            return {
                ...this.dayEvent,
                event: origEvent
            };
        },
        handleDayClick ($event) {
            if (this.isDisabled) {
                return;
            }

            this.$emit('dayclick', this.getDayEvent($event));
        },
        handleDayMouseEnter ($event) {
            if (this.isDisabled) {
                return;
            }
            this.isHovering = true;
            this.$emit('daymouseenter', this.getDayEvent($event));
        },
        handleDayMouseLeave ($event) {
            this.isHovering = false;
            this.$emit('daymouseleave', this.getDayEvent($event));
        },
        handleDayFocus ($event) {
            this.isFocused = true;
            this.$emit('dayfocusin', this.getDayEvent($event));
        },
        handleDayBlur ($event) {
            this.isFocused = false;
            this.$emit('dayfocusout', this.getDayEvent($event));
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(day) {
        display: grid;
        grid-template-rows: 6px [content] 20px 6px;
        grid-template-columns: [left] 1fr [right] 1fr;
        align-items: center;
        z-index: auto;
        border-radius: $datepicker-option-border-radius;
        cursor: pointer;

        @include element(day-highlight-current) {
            @include focus-ring($style: 'base');
        }

        &:focus-visible {
            outline: none;

            @include element(day-highlight-current) {
                @include focus-ring($style: 'focused');
                z-index: 2;
            }
        }

        &.is-hovering:not(.is-selected):not(.is-drag-start),
        &.is-range-start:not(.is-drag-start),
        &.is-range-end:not(.is-drag-start) {
            @include element(day-highlight-current) {
                background: $datepicker-cell-fill-color;
                border-color: $datepicker-cell-hover-border-color;
                box-shadow: $datepicker-cell-hover-shadow;
            }

            @include element(day-content) {
                font-weight: 400;
                color: $datepicker-color;
            }
        }

        @include is(disabled) {
            cursor: not-allowed;
            text-decoration: line-through;

            @include element(day-content) {
                opacity: 0.4;
            }
        }

        @include is(outside-month) {
            @include element(day-content) {
                opacity: 0.1;
            }
        }

        &.is-selected,
        &.is-range-start,
        &.is-range-end {
            @include element(day-highlight-current) {
                background-color: $datepicker-cell-active-color;
            }

            @include element(day-content) {
                color: $datepicker-active-color;
                font-weight: bold;
                opacity: 1;
            }
        }
    }

    @include element((day-content, day-highlight-current, day-highlight-base)) {
        box-sizing: border-box;
    }

    @include element(day-content) {
        grid-row: content;
        grid-column-start: 1;
        grid-column-end: -1;
        text-align: center;
        line-height: 20px;
        z-index: 2;
    }

    @include element(day-highlight-current) {
        grid-column-start: 1;
        grid-column-end: -1;
        grid-row-start: 1;
        grid-row-end: -1;
        height: $datepicker-day-size;
        width: $datepicker-day-size;
        z-index: 1;

        border: $datepicker-cell-border-width solid $datepicker-cell-border-color;
        border-radius: $datepicker-option-border-radius;
    }

    @include element(day-highlight-base) {
        grid-row: content;
        height: 100%;
        width: 100%;
        z-index: 0;
        background-color: $datepicker-range-highlight-color;

        @include is(range-start) {
            grid-column: 2;
        }

        @include is(range-middle) {
            grid-column-start: 1;
            grid-column-end: -1;
        }

        @include is(range-end) {
            grid-column: 1;
        }
    }
}
</style>
