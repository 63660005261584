<template>
    <div class="app-color-picker">
        <pendo-color-picker
            v-model="selectedColor"
            class="color-picker"
            :labels="{ top: 'Assigned Color' }"
            value-key="hex"
            :presets="swatchColors" />
        <pendo-button
            theme="app"
            icon="check"
            type="primary"
            @click="confirmColor" />
        <pendo-button
            theme="app"
            type="secondary"
            icon="x"
            @click="cancelColor" />
    </div>
</template>

<script>
import PendoColorPicker from '@/components/color-picker/pendo-color-picker.vue';
import PendoButton from '@/components/button/pendo-button.vue';

export default {
    name: 'AppColorPicker',
    components: {
        PendoColorPicker,
        PendoButton
    },
    props: {
        initialColor: {
            type: String,
            default: ''
        },
        swatchColors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            selectedColor: '#000'
        };
    },
    watch: {
        initialColor: {
            handler (newColor) {
                this.selectedColor = newColor || '#000';
            },
            immediate: true
        }
    },
    methods: {
        confirmColor () {
            this.$emit('favicon-color-changed', this.selectedColor !== '#000' ? this.selectedColor : null);
        },
        cancelColor () {
            this.$emit('cancel-color-change');
        }
    }
};
</script>
<style lang="scss" scoped>
.app-color-picker {
    display: flex;
    align-items: flex-end;
}
.color-picker {
    margin-right: 10px;
}
</style>
