<template>
    <pendo-table
        title="Polls"
        :data="pollsData"
        :columns="columns"
        :default-sort="sortConfig"
        :scroll-config="{ rowHeight: null }"
        :max-height="maxHeight"
        @link-click="onLinkClick" />
</template>

<script>
import PendoTable from '@/components/table/pendo-table.vue';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

const pollTypeLabels = {
    PickList: 'Multiple Choice',
    PositiveNegative: 'Yes / No',
    FreeForm: 'Open Text Field',
    NumberScale: 'Number Scale',
    NPSRating: 'NPS Survey',
    NPSReason: 'NPS Reason'
};

// Used to cover edge case where a non-active or non-associated poll is passed in the polls array
const pollNotFoundLabel = 'Not Found';

export default {
    name: 'PendoPollMetricsTable',
    components: {
        PendoTable
    },
    props: {
        /**
         * Table sort configuration object (see pendo-table)
         */
        sortConfig: {
            type: Object,
            default: () => ({
                prop: 'question',
                order: 'none'
            })
        },
        /**
         * Guide object
         */
        guide: {
            type: Object,
            required: true
        },
        /**
         * Polls array – can be provided explicitly (e.g. to remove inactive polls), otherwise it's read from `guide` prop
         */
        polls: {
            type: Array,
            default: null
        },
        /**
         * Step view data rows
         */
        views: {
            type: Array,
            default: null
        },
        /**
         * Loading status for views aggregation (loading, rejected, empty)
         */
        viewsAggStatus: {
            type: String,
            required: true
        },
        /**
         * Response count data rows
         */
        responseCount: {
            type: Array,
            default: null
        },
        /**
         * Loading status for response count aggregation (loading, rejected, empty)
         */
        responseCountAggStatus: {
            type: String,
            required: true
        },
        /**
         * Function to build the href value for each poll link,
         */
        hrefFunction: {
            type: Function,
            default: null
        },
        /**
         * Table max-height (see pendo-table)
         */
        maxHeight: {
            type: Number,
            default: 600
        }
    },
    data () {
        return {
            columns: [
                {
                    prop: 'question',
                    label: 'Question',
                    minWidth: 260,
                    sortable: true,
                    type: 'link',
                    href: this.hrefFunction
                },
                {
                    prop: 'type',
                    label: 'Type',
                    width: 180,
                    sortable: true,
                    formatter: ({ type }) => pollTypeLabels[type]
                },
                {
                    prop: 'views',
                    label: 'Views',
                    async: true,
                    sortable: true
                },
                {
                    prop: 'responseCount',
                    label: 'Responses',
                    async: true,
                    sortable: true
                },
                {
                    prop: 'stepNumber',
                    label: 'Poll Step',
                    sortable: true
                }
            ]
        };
    },
    computed: {
        pollsData () {
            const polls = this.polls || this.guide.polls;
            const responseCountByPollId = keyBy(this.responseCount, 'pollId');
            const viewsByStepId = keyBy(this.views, 'guideStepId');

            return polls.map((poll) => {
                const {
                    id,
                    question,
                    attributes: { type }
                } = poll;
                const responseCount =
                    this.responseCountAggStatus === 'resolved' ? get(responseCountByPollId[id], 'count', 0) : null;
                const pollStepId = this.getPollStepId(id);
                let views;
                if (!pollStepId) {
                    views = pollNotFoundLabel;
                } else {
                    views = this.viewsAggStatus === 'resolved' ? get(viewsByStepId[pollStepId], 'count', 0) : null;
                }

                return {
                    id,
                    question,
                    type,
                    views,
                    responseCount,
                    stepNumber: this.getStepNumber(pollStepId)
                };
            });
        }
    },
    methods: {
        onLinkClick ({ row }) {
            this.$emit('poll-link-click', row);
        },
        getStepNumber (stepId) {
            if (!stepId) {
                return pollNotFoundLabel;
            }

            return this.guide.steps.findIndex((step) => step.id === stepId) + 1;
        },
        getPollStepId (pollId) {
            const pollStep = this.guide.steps.find((step) => {
                return step.pollIds && step.pollIds.includes(pollId);
            });

            return get(pollStep, 'id');
        }
    }
};
</script>
