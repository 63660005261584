<template>
    <transition
        appear
        :name="overlayProps.transition"
        @after-leave="hiding = false">
        <div
            v-if="visible"
            :style="overlayStyle"
            :class="[
                overlayProps.class,
                {
                    'pendo-loading--text': text
                }
            ]"
            class="pendo-loading">
            <component
                :is="type"
                :container-width="containerWidth"
                :container-height="containerHeight"
                :skeleton-props="skeletonProps"
                :spinner-props="spinnerProps" />
            <div
                v-if="text"
                :class="textClass">
                {{ text }}
            </div>
        </div>
    </transition>
</template>

<script>
import Skeleton from '@/directives/loading/components/skeleton.vue';
import Feather from '@/directives/loading/components/feather.vue';
import Material from '@/directives/loading/components/material.vue';

export default {
    name: 'Loading',
    components: {
        Skeleton,
        Feather,
        Material
    },
    data () {
        return {
            visible: false,
            hiding: false,
            containerWidth: 0,
            containerHeight: 0
        };
    },
    computed: {
        overlayStyle () {
            const { type, spinnerProps, overlayProps } = this;

            if (type === 'skeleton') {
                return {
                    position: overlayProps.position || 'absolute'
                };
            }

            return {
                'background-color': spinnerProps.background,
                'position': overlayProps.position || 'absolute',
                'justify-content': 'center',
                'grid-gap': '8px'
            };
        }
    },
    methods: {
        /**
         * @public
         */
        setContainerSize ({ width, height }) {
            this.containerHeight = height;
            this.containerWidth = width;
        }
    }
};
</script>

<style lang="scss">
.pendo-loading {
    &-overlay {
        z-index: 0;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        will-change: opacity;
    }

    &-spinner {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-gap: 0.5rem;
    }

    &-text {
        @include font-base;
        font-weight: 400;
        font-size: 24px;
        color: $color-text-primary;
    }

    &-parent {
        @include modifier(relative) {
            position: relative !important;
        }

        @include modifier(hidden) {
            overflow: hidden !important;
        }
    }

    &-fade-enter-active,
    &-fade-leave-active {
        transition: opacity 150ms;
    }

    &-fade-enter {
        opacity: 1;
    }

    &-fade-leave-to {
        opacity: 0;
    }
}
</style>
