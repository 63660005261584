<template>
    <div class="repeating-text-input">
        <div class="repeating-text-input__header">
            <slot name="header" />
        </div>
        <slot name="description" />
        <div
            v-for="(field, index) in fields"
            :key="index"
            class="repeating-text-input__field-item">
            <pendo-input
                :ref="`field${index}`"
                :value="field"
                :invalid="!validator(field)"
                :disabled="readOnly || onlyDelete"
                class="repeating-text-input__field-input"
                @input="onInputChange({ index, value: $event })"
                @blur="onBlur" />
            <div
                v-if="!readOnly || onlyDelete"
                class="repeating-text-input__field-input-remove">
                <pendo-icon-button
                    tooltip="Remove"
                    icon="trash-2"
                    :disabled="disableDelete"
                    @click="removeField(index)" />
            </div>
        </div>
        <pendo-button
            v-if="!readOnly && !onlyDelete"
            theme="app"
            type="link"
            prefix-icon="plus"
            class="field-input-add"
            label="Add Another"
            @click="addField" />
    </div>
</template>

<script>
import Vue from 'vue';
import PendoInput from '@/components/input/pendo-input';
import PendoIconButton from '@/components/icon-button/pendo-icon-button';
import PendoButton from '@/components/button/pendo-button';
import isEqual from 'lodash/isEqual';

export default {
    name: 'PendoRepeatingTextInput',
    components: {
        PendoInput,
        PendoIconButton,
        PendoButton
    },
    props: {
        /**
         * Default items if any
         */
        value: {
            type: Array,
            default: () => ['']
        },
        /**
         * Function to be used to validate each item
         */
        validator: {
            type: Function,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        onlyDelete: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            fields: ['']
        };
    },
    computed: {
        disableDelete () {
            return isEqual(this.fields, ['']);
        }
    },
    watch: {
        fields (value) {
            /**
             * Updates value for parent consumption
             */
            this.$emit('input', value);
        },
        value (value) {
            /**
             * Makes sure local fields stay in sync with passed in prop
             */
            this.fields = value;
        }
    },
    created () {
        this.fields = this.value;
    },
    methods: {
        removeField (i) {
            this.$emit('removeField', this.fields[i]);

            if (this.fields.length <= 1) {
                // Added this emit since a field will be added with the last delete
                this.$emit('addField');
                this.fields = this.onlyDelete ? [] : [''];
            } else {
                this.fields = this.fields.slice(0, i).concat(this.fields.slice(i + 1));
            }
        },
        onInputChange (event) {
            Vue.set(this.fields, event.index, event.value);
        },
        onBlur () {
            if (this.fields[0] !== '') {
                this.$emit('domain-changed', this.fields[0]);
            }
        },
        addField () {
            this.fields.push('');
            this.$nextTick(() => {
                this.$refs[`field${this.fields.length - 1}`][0].focus();
            });
            this.$emit('addField');
        }
    }
};
</script>

<style lang="scss" scoped>
.repeating-text-input {
    &__header {
        font-weight: bold;
    }
    &__field-item {
        display: flex;
        margin-top: 8px;
    }
}
</style>
