<template>
    <div
        :class="{
            'pendo-tabs--card': card,
            'pendo-tabs--vertical': vertical
        }"
        class="pendo-tabs">
        <div class="pendo-tabs-nav">
            <span class="pendo-tabs-nav__bar" />
            <pendo-scroll-indicator
                ref="content"
                :disabled="!scrollable">
                <template #indicators="{ canScroll }">
                    <transition-group
                        name="pendo-transition-fade"
                        class="pendo-tabs-nav__scroll-actions"
                        tag="div">
                        <div
                            v-if="canScroll.left"
                            key="previous"
                            class="pendo-tabs-nav__prev">
                            <pendo-button
                                aria-hidden
                                class="pendo-tabs-nav__prev-btn"
                                size="small"
                                icon="arrow-left"
                                type="tertiary"
                                @click="onPrevButtonClick" />
                        </div>
                        <div
                            v-if="canScroll.right"
                            key="next"
                            class="pendo-tabs-nav__next">
                            <pendo-button
                                aria-hidden
                                class="pendo-tabs-nav__next-btn"
                                type="tertiary"
                                size="small"
                                icon="arrow-right"
                                @click="onNextButtonClick" />
                        </div>
                    </transition-group>
                </template>
                <div
                    ref="tablist"
                    role="tablist"
                    :aria-orientation="vertical ? 'vertical' : 'horizontal'"
                    class="pendo-tabs-nav__list">
                    <button
                        v-for="tab in tabs"
                        :id="`pendo-tab-${tab.prop}`"
                        ref="tab"
                        :key="tab.prop"
                        :aria-controls="`pendo-tab-panel-${tab.prop}`"
                        :aria-selected="String(tab.prop === value)"
                        :aria-disabled="String(!!tab.disabled)"
                        :class="{
                            'is-active': tab.prop === value,
                            'is-disabled': tab.disabled
                        }"
                        :title="tab.label"
                        :tabindex="tab.prop === value ? undefined : -1"
                        class="pendo-tabs-nav__tab"
                        role="tab"
                        @keyup="onTabKeyup($event, tab)"
                        @keydown="onTabKeydown($event, tab)"
                        @click="onTabClick(tab)"
                        @focus="onTabFocus($event, tab)">
                        <div class="pendo-tabs-nav__tab-label">
                            <slot
                                name="label"
                                :tab="tab">
                                {{ tab.label }}
                            </slot>
                        </div>
                        <span class="pendo-tabs-nav__active-bar" />
                    </button>
                </div>
            </pendo-scroll-indicator>
        </div>
        <div class="pendo-tabs-content">
            <slot>
                <pendo-tab-panel
                    v-for="tab in tabs"
                    :key="tab.prop"
                    :prop="tab.prop"
                    :label="tab.label"
                    :disabled="tab.disabled"
                    :lazy="tab.lazy"
                    :active="tab.prop === value">
                    <slot :name="tab.prop || tab.label">
                        {{ tab.content }}
                    </slot>
                </pendo-tab-panel>
            </slot>
        </div>
    </div>
</template>

<script>
import PendoScrollIndicator from '@/components/scroll-indicator/pendo-scroll-indicator';
import PendoButton from '@/components/button/pendo-button';
import PendoTabPanel from '@/components/tabs/pendo-tab-panel';
import { keyCodes } from '@/utils/utils';
import { getWidth } from '@/utils/dom';

const TAB_THEME_COLOR_MAP = {
    designer: '#128297',
    app: '#2A2C35'
};

const PREV_NEXT_CONTAINER_WIDTH = 40;

export default {
    name: 'PendoTabs',
    components: {
        PendoScrollIndicator,
        PendoButton,
        PendoTabPanel
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        /**
         * bound value
         */
        value: {
            type: String,
            default: undefined,
            required: true
        },
        /**
         * array of tab config objects
         */
        tabs: {
            type: Array,
            default: () => []
        },
        /**
         * applies default styles for each type
         * @values app, designer
         */
        theme: {
            type: String,
            default: 'app',
            validator: (theme) => ['app', 'designer'].includes(theme)
        },
        /**
         * type of tabs
         */
        card: {
            type: Boolean,
            default: false
        },
        /**
         * Stacks tabs on top of each other vertically.
         */
        vertical: {
            type: Boolean,
            default: false
        },
        /**
         * adds prev/next arrows when tab content overflows parent container
         * not supported when using `vertical` prop
         */
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            focusOnNextModelUpdate: false
        };
    },
    watch: {
        value: 'onValueChange'
    },
    created () {
        if (!this.value) {
            this.activateTab(this.tabs[0], true);
        }
    },
    mounted () {
        this.setActiveTabColor();
    },
    methods: {
        onValueChange () {
            const newActiveTabIndex = this.tabs.findIndex((tab) => tab.prop === this.value);
            const anyTabIsFocused = this.$refs.tab.some((tab) => tab === document.activeElement);
            const shouldFocusNewTab = anyTabIsFocused && document.activeElement !== this.$refs.tab[newActiveTabIndex];

            if (this.focusOnNextModelUpdate || shouldFocusNewTab) {
                this.$refs.tab[newActiveTabIndex].focus();
                this.focusOnNextModelUpdate = false;
            }
        },
        onTabClick (tab) {
            if (tab.disabled) {
                return;
            }

            this.activateTab(tab, false);
            this.$emit('tab-click', tab);
        },
        onTabKeydown (event, tab) {
            const key = event.keyCode;

            switch (key) {
                case keyCodes.end: {
                    event.preventDefault();
                    const lastTab = this.tabs[this.tabs.length - 1];
                    if (lastTab.lazy) {
                        this.focusLastTab();
                    } else {
                        this.activateTab(lastTab, true);
                    }
                    break;
                }
                case keyCodes.home: {
                    event.preventDefault();
                    const firstTab = this.tabs[0];
                    if (firstTab.lazy) {
                        this.focusFirstTab();
                    } else {
                        this.activateTab(firstTab, true);
                    }
                    break;
                }
                case keyCodes.left:
                case keyCodes.right:
                    if (!this.vertical) {
                        event.preventDefault();
                    }
                    break;
                case keyCodes.up:
                case keyCodes.down:
                    if (this.vertical) {
                        event.preventDefault();
                        this.switchTabOnArrowPress(event, tab);
                    }
                    break;
            }
        },
        onTabKeyup (event, tab) {
            switch (event.keyCode) {
                case keyCodes.left:
                case keyCodes.right:
                    if (!this.vertical) {
                        event.preventDefault();
                        this.switchTabOnArrowPress(event, tab);
                    }
                    break;
                case keyCodes.enter:
                case keyCodes.space:
                    this.activateTab(tab, true);
                    break;
            }
        },
        onTabFocus (event, tab) {
            if (tab.disabled) {
                return;
            }

            if (event.target === document.activeElement) {
                // prevent auto activation from keyboard navigation if tab is lazy loaded
                if (tab.lazy) {
                    return;
                }

                this.activateTab(tab, false);
            }
        },
        switchTabOnArrowPress (event, tab) {
            const navigationKeyCodes = {
                37: -1,
                38: -1,
                39: 1,
                40: 1
            };

            if (!navigationKeyCodes[event.keyCode]) {
                return;
            }

            const { prop } = tab;
            const index = this.tabs.findIndex((tab) => tab.prop === prop);
            let nextIndex = parseInt(index, 10) + navigationKeyCodes[event.keyCode];

            while (this.tabs[nextIndex] && this.tabs[nextIndex].disabled) {
                nextIndex += navigationKeyCodes[event.keyCode];
            }

            if (this.$refs.tab[nextIndex]) {
                this.$refs.tab[nextIndex].focus();

                return;
            }

            switch (event.keyCode) {
                case keyCodes.left:
                case keyCodes.up:
                    this.focusLastTab();
                    break;
                case keyCodes.right:
                case keyCodes.down:
                    this.focusFirstTab();
            }
        },
        focusFirstTab () {
            this.$refs.tab[0].focus();
        },
        focusLastTab () {
            this.$refs.tab[this.tabs.length - 1].focus();
        },
        activateTab (tab, setFocus) {
            if (tab.prop !== this.value) {
                this.$emit('change', tab.prop);
                this.focusOnNextModelUpdate = setFocus;
            }
        },
        setActiveTabColor () {
            this.$el.style.setProperty('--tab--active-color', TAB_THEME_COLOR_MAP[this.theme]);
        },
        onPrevButtonClick () {
            const { content } = this.$refs.content.$refs;
            const buttonWidth = PREV_NEXT_CONTAINER_WIDTH * 2;
            const width = getWidth(content) - buttonWidth;
            const pos = content.scrollLeft - width;

            content.scrollLeft = pos <= PREV_NEXT_CONTAINER_WIDTH ? 0 : pos;
        },
        onNextButtonClick () {
            const { content } = this.$refs.content.$refs;
            const contentWidth = getWidth(content);
            const distanceToEnd = content.scrollWidth - content.scrollLeft - content.offsetWidth;

            let buttonWidth = PREV_NEXT_CONTAINER_WIDTH * 2;

            if (contentWidth - distanceToEnd >= PREV_NEXT_CONTAINER_WIDTH) {
                buttonWidth -= PREV_NEXT_CONTAINER_WIDTH;
            }

            const width = contentWidth - buttonWidth;
            const pos = content.scrollLeft + width;
            const lastPos = content.scrollWidth - width;

            content.scrollLeft = pos >= lastPos ? lastPos : pos;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-tabs) {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    min-height: 0%;

    @include modifier(card) {
        .pendo-tabs-nav {
            background-color: $color-gray-10;
        }

        .pendo-tabs-nav__list {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            text-align: center;
        }

        .pendo-tabs-nav__tab {
            margin-right: 0;
            margin-left: 0;
            overflow: visible;
            line-height: 28px;
            font-size: 14px;
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &.is-active {
                background-color: $color-white;
                position: relative;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top: 0;
                    z-index: 1;
                    pointer-events: none;
                    visibility: visible;
                    background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) -282.89%, rgba(0, 0, 0, 0) 12.5%);
                }

                &:before {
                    transform: rotate(-90deg);
                    right: 100%;
                }

                &:after {
                    transform: rotate(90deg);
                    left: 100%;
                }
            }
        }

        .pendo-tabs-nav__bar {
            height: 2px;
        }

        .pendo-tabs-nav__active-bar {
            bottom: 0;
        }

        .pendo-tabs-nav__prev {
            border-width: 2px;
            background: linear-gradient(to right, rgba($color-gray-10, 1) 70%, rgba(transparent, 0) 100%);
        }

        .pendo-tabs-nav__next {
            border-width: 2px;
            background: linear-gradient(to left, rgba($color-gray-10, 1) 70%, rgba(transparent, 0) 100%);
        }
    }

    @include modifier(vertical) {
        flex-direction: row;

        .pendo-tabs-nav__list {
            flex-direction: column;
        }

        .pendo-tabs-nav__tab {
            padding: 3px 3px 3px 16px;
            line-height: 30px;
            text-align: left;
            margin-left: 0;
            margin-right: 40px;

            &:last-of-type {
                margin-right: 40px;
            }
        }

        .pendo-tabs-nav__bar {
            top: 0;
            width: 1px;
            height: inherit;
        }

        .pendo-tabs-nav__active-bar {
            height: 100%;
            left: 1px;
            width: 2px;
        }
    }
}

@include block(pendo-tabs-nav) {
    @include font-base;
    position: relative;
    background: $color-white;

    .pendo-scroll-indicator__content {
        scroll-behavior: smooth;
        scrollbar-width: none;
        overscroll-behavior: contain auto;
        scroll-padding-left: 56px;
        scroll-padding-right: 56px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @include element((prev, next)) {
        position: absolute;
        top: 0;
        bottom: 0px;
        z-index: 2;
        display: flex;
        align-items: center;
        width: 40px;

        border: 1px solid $color-gray-40;
        border-image-slice: 1;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }

    @include element(prev) {
        justify-content: flex-start;
        left: 0;
        background: linear-gradient(to right, $color-white 70%, rgba(transparent, 0) 100%);
        border-image-source: linear-gradient(to right, $color-gray-40 70%, rgba(transparent, 0) 100%);
    }

    @include element(next) {
        justify-content: flex-end;
        right: 0;
        background: linear-gradient(to left, $color-white 70%, rgba(transparent, 0) 100%);
        border-image-source: linear-gradient(to left, $color-gray-40 70%, rgba(transparent, 0) 100%);
    }

    @include element((prev-btn, next-btn)) {
        &.pendo-button {
            width: 32px;
            color: $color-gray-70;
        }

        @include focus-ring(
            $style: 'base',
            $offset: -2px,
            $transitions: (
                color 0.2s
            )
        );

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }
    }

    @include element(list) {
        display: flex;
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    @include element(tab) {
        cursor: pointer;
        letter-spacing: 0.15px;
        line-height: 24px;
        margin-right: 20px;
        margin-left: 20px;
        padding: 6px 3px;
        position: relative;
        background-color: transparent;
        outline: 0;
        border: 0;
        -webkit-appearance: none;
        text-transform: none;
        font-size: 16px;
        font-family: inherit;
        white-space: nowrap;

        &:first-of-type {
            margin-left: 0px;
        }
        &:last-of-type {
            margin-right: 0px;
        }

        &::after {
            display: block;
            content: attr(title);
            font-weight: 600;
            height: 0;
            overflow: hidden;
            visibility: hidden;
            letter-spacing: 0px;
        }

        &:focus-visible:not(.is-disabled) {
            .pendo-tabs-nav__tab-label {
                @include focus-ring($style: 'focused');
            }
        }

        @include is(active) {
            letter-spacing: 0px;
            font-weight: 600;

            @include element(active-bar) {
                background-color: var(--tab--active-color);
            }
        }

        @include is(disabled) {
            cursor: not-allowed;
            color: $disabled-color;
        }
    }

    @include element(tab-label) {
        @include focus-ring($style: 'base');
        border-radius: 3px;
    }

    @include element((bar, active-bar)) {
        content: '';
        left: 0;
        margin: 0;
        position: absolute;
        right: 0;
        bottom: 0px;
        width: inherit;
    }

    @include element(bar) {
        background-color: $color-gray-40;
        height: 1px;
    }

    @include element(active-bar) {
        background-color: transparent;
        height: 2px;
    }
}

@include block(pendo-tabs-content) {
    min-height: 0%;
}
</style>
