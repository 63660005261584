<template>
    <div
        :style="{
            background: backgroundColor,
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            overflow: 'hidden'
        }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PendoApp',
    provide () {
        return {
            $app: this
        };
    },
    props: {
        topbarHeight: {
            type: [Number, String],
            default: 0
        },
        footerHeight: {
            type: [Number, String],
            default: 0
        },
        sidebarWidth: {
            type: [Number, String],
            default: 0
        },
        backgroundColor: {
            type: String,
            default: '#FFFFFF'
        },
        pageBackgroundColor: {
            type: String,
            default: '#F8F8F9'
        }
    },
    data () {
        return {
            tabs: false
        };
    },
    methods: {
        updateProperty (property, value) {
            this[property] = value;
        }
    }
};
</script>
