<template>
    <svg
        :width="containerWidth"
        :height="containerHeight"
        :style="{ maxWidth: '100%' }">
        <svg
            :class="skeletonProps.class"
            :viewBox="viewBox"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg">
            <title>
                Loading...
            </title>
            <rect
                :style="{ fill: `url(#${gradientId})` }"
                :clip-path="`url(#${clipId})`"
                width="100%"
                height="100%"
                x="0"
                y="0" />
            <defs>
                <clipPath :id="clipId">
                    <rect
                        width="100%"
                        height="100%"
                        :rx="`${skeletonProps.borderRadius}px`"
                        :ry="`${skeletonProps.borderRadius}px`"
                        x="0"
                        y="0" />
                </clipPath>
                <linearGradient
                    :id="gradientId"
                    gradientTransform="translate(-2 0)">
                    <stop
                        :stop-color="skeletonProps.primaryColor"
                        offset="0%" />
                    <stop
                        :stop-color="skeletonProps.secondaryColor"
                        offset="50%" />
                    <stop
                        :stop-color="skeletonProps.primaryColor"
                        offset="100%" />
                    <animateTransform
                        v-if="skeletonProps.animate"
                        attributeName="gradientTransform"
                        type="translate"
                        values="-2 0; 0 0; 2 0"
                        :dur="`${skeletonProps.speed}s`"
                        repeatCount="indefinite" />
                </linearGradient>
            </defs>
        </svg>
    </svg>
</template>

<script>
export default {
    name: 'Skeleton',
    inheritAttrs: false,
    props: {
        skeletonProps: {
            type: Object,
            default: () => ({})
        },
        containerWidth: {
            type: [Number, String],
            default: 0
        },
        containerHeight: {
            type: [Number, String],
            default: 0
        }
    },
    computed: {
        clipId () {
            return this.uid();
        },
        gradientId () {
            return this.uid();
        },
        viewBox () {
            const { containerWidth, containerHeight } = this;

            return `0 0 ${containerWidth} ${containerHeight}`;
        }
    },
    methods: {
        uid () {
            return Math.random()
                .toString(36)
                .substring(2);
        }
    }
};
</script>
