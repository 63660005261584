<template>
    <div class="display-alerts-drawer">
        <p class="drawer-sub-header">
            {{ displayAlert.guideName }}
        </p>
        <div class="display-alerts-drawer__content">
            <pendo-loading-indicator
                v-if="fetchingNotes"
                class="loading-spinner"
                :loading="true"
                size="large" />
            <div
                v-if="!fetchingNotes && !notes.length"
                class="empty-state">
                <pendo-empty-state
                    description="Add a note to provide more context and document what you learned about this alert"
                    :icon="{
                        type: 'message-circle',
                        size: '36',
                        stroke: '#2A2C35'
                    }"
                    background-color="#F8F8F9" />
            </div>
            <template v-if="!fetchingNotes && notes.length">
                <div
                    v-for="day in notesByDay"
                    :key="day.key"
                    class="note-day-wrapper">
                    <div class="day-header">
                        {{ day.key }}
                    </div>
                    <note
                        v-for="note in day.notes"
                        :key="note.rootVersionId"
                        :note="note"
                        :has-admin-access="hasAdminAccess"
                        :active-note-id="activeNoteId"
                        :display-alert="displayAlert"
                        :active-user="activeUser"
                        :note-action="noteAction"
                        :note-error="noteError"
                        @note-edited="setActiveNoteId"
                        @remove-note="removeNote"
                        @update-note="updateNote" />
                </div>
            </template>
        </div>
        <note-input
            :display-alert="displayAlert"
            :note-action="noteAction"
            :note-error="noteError"
            @create-note="createNote" />
    </div>
</template>

<script>
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';
import PendoEmptyState from '@/components/empty-state/pendo-empty-state.vue';
import Note from './note.vue';
import NoteInput from './note-input.vue';
import get from 'lodash/get';
import moment from 'moment-timezone';

export default {
    name: 'NoteDrawerContent',
    components: {
        PendoLoadingIndicator,
        PendoEmptyState,
        Note,
        NoteInput
    },
    props: {
        activeUser: {
            type: Object,
            required: true
        },
        displayAlert: {
            type: Object,
            default: () => {}
        },
        noteAction: {
            type: Object,
            default: () => ({})
        },
        noteError: {
            type: Object,
            default: () => {}
        },
        hasAdminAccess: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            activeNoteId: undefined,
            fetchingNotes: false
        };
    },
    computed: {
        notes () {
            return get(this.displayAlert, 'notes', []).sort((a, b) => a.createdAt - b.createdAt);
        },
        notesByDay () {
            return this.notes.reduce((map, note) => {
                const dayTimestamp = moment(note.createdAt)
                    .startOf('day')
                    .tz(moment.tz.guess())
                    .format('dddd, MMM D, YYYY');

                if (map[dayTimestamp]) {
                    map[dayTimestamp].notes.push(note);
                } else {
                    map[dayTimestamp] = {
                        notes: [note],
                        key: dayTimestamp
                    };
                }

                return map;
            }, {});
        },
        isFetchingNotes () {
            return this.noteAction.fetch;
        }
    },
    watch: {
        isFetchingNotes (newValue, oldValue) {
            if (this.fetchingNotes && oldValue && !newValue) {
                this.$nextTick(() => {
                    const element = document.getElementsByClassName('display-alerts-drawer__content')[0];
                    element.scrollTop = element.scrollHeight;
                });
                this.fetchingNotes = false;
            }
        },
        displayAlert (newAlert, oldAlert) {
            const newAlertId = get(newAlert, 'alertId');
            const oldAlertId = get(oldAlert, 'alertId');
            if (newAlertId !== oldAlertId) {
                this.fetchNotes(newAlert);
            }
        }
    },
    created () {
        if (this.displayAlert && this.displayAlert.alertId) {
            this.fetchNotes(this.displayAlert);
        }
    },
    methods: {
        fetchNotes (alert) {
            this.fetchingNotes = true;
            this.$emit('fetch-notes', { alert });
        },
        setActiveNoteId (noteId) {
            this.activeNoteId = noteId;
        },
        createNote ({ alert, text }) {
            this.$emit('create-note', { alert, text });
        },
        updateNote ({ alert, noteId, text }) {
            this.$emit('update-note', { alert, noteId, text });
        },
        removeNote ({ alert, noteId }) {
            this.$emit('remove-note', { alert, noteId });
        }
    }
};
</script>

<style lang="scss" scoped>
.drawer-sub-header {
    font-size: 14px;
    color: $color-gray-70;
    font-weight: 400;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 18px;
    border-bottom: 1px solid $color-gray-40;
    margin: auto 0;
}

.display-alerts-drawer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.display-alerts-drawer__content {
    background: $color-gray-10;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.empty-state {
    height: 100%;
    color: $color-gray-70;
    ::v-deep .pendo-empty-state {
        position: initial;
    }
}
.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-left: 14%;
    padding-right: 14%;
    height: 100%;
}

.message-bubble {
    margin-bottom: 26px;
}

.note-day-wrapper {
    padding: 8px 0px 0px;
    margin: 0px 24px;
    border-bottom: 1px solid $color-gray-40;

    .day-header {
        color: $color-gray-70;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 16px;
    }
}
</style>
