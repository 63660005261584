<template>
    <div
        class="pendo-multiselect-option--guide-status"
        :class="{
            [`pendo-multiselect-option--${status}`]: status
        }"
        v-bind="$attrs"
        v-on="$listeners">
        <pendo-icon
            v-pendo-tooltip="{
                content: tooltipContent,
                delay: { show: 300, hide: 0 }
            }"
            type="circle"
            size="10" />
        <span
            ref="label"
            v-pendo-tooltip="{
                content: label,
                multiline: true,
                trigger: 'manual',
                offset: 0,
                show: showOverflowTooltip
            }"
            class="pendo-multiselect-option__label"
            @mouseenter="onOptionMouseenter"
            @mouseleave="onOptionMouseleave">
            {{ label }}
        </span>
    </div>
</template>

<script>
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIcon from '@/components/icon/pendo-icon';
import { shouldAttachOverflowTooltip } from '@/components/multiselect/multiselect-helper';
import startCase from 'lodash/startCase';

export default {
    name: 'PendoGuideStatusOption',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String],
            required: true
        },
        tooltip: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showOverflowTooltip: false,
            tooltipTimeout: null
        };
    },
    computed: {
        label () {
            return this.multiselect.getOptionLabel(this.option);
        },
        status () {
            if (this.option.state) {
                return this.option.state;
            }

            return this.option[this.multiselect.valueKey];
        },
        tooltipContent () {
            if (this.option.state && this.tooltip) {
                return startCase(this.option.state);
            }

            return null;
        }
    },
    methods: {
        onOptionMouseenter () {
            const showOverflowTooltip = shouldAttachOverflowTooltip(this.label, this.$refs.label);

            if (showOverflowTooltip) {
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = setTimeout(() => {
                    this.showOverflowTooltip = true;
                }, 300);
            }
        },
        onOptionMouseleave () {
            clearTimeout(this.tooltipTimeout);
            this.showOverflowTooltip = false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(guide-status) {
        @include font-base;
        line-height: 26px;
        user-select: none;
        height: 100%;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 10px 1fr;
        align-items: center;
        overflow: hidden;

        @include element(label) {
            @include ellipsis;
        }

        @each $status in map-keys($guide-status-map) {
            $background: map-fetch($guide-status-map, $status background);
            &.pendo-multiselect-option--#{$status} {
                .pendo-icon circle {
                    fill: $background;
                    stroke: $background;
                }
            }
        }
    }
}
</style>
