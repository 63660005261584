<template>
    <pendo-multiselect
        :append-to-body="false"
        class="resource-selector"
        :class="`${field}-select`"
        :value="value"
        :options="options"
        :extra-label-width="60"
        :max-trigger-width="286"
        :max-menu-width="286"
        :allow-empty="false"
        :value-key="field"
        :loading="loading"
        sort-by="name"
        hide-selected-options
        full-width
        label-key="name"
        :placeholder="placeholder"
        @select="onOptionSelect">
        <template #header="{ toggleMenu, model }">
            <div
                v-if="model[0]"
                class="resource-selector__header">
                <div class="resource-selector__selected-option">
                    <pendo-group-option
                        v-if="model[0].group"
                        :option="model[0]"
                        @click="toggleMenu" />
                    <pendo-guide-status-option
                        v-if="model[0].state"
                        tooltip
                        :option="model[0]"
                        @click="toggleMenu" />
                </div>
            </div>
        </template>
        <template #noData>
            {{ noResourcesFoundText }}
        </template>
        <template #selectedLabel="{ option }">
            <pendo-group-option
                v-if="!option.guideId"
                :option="option" />
            <pendo-guide-status-option
                v-if="option.guideId"
                tooltip
                :option="option" />
        </template>
        <template #option="{ option }">
            <pendo-group-option
                v-if="!option.guideId"
                :option="option" />
            <pendo-guide-status-option
                v-if="option.guideId"
                tooltip
                :option="option" />
        </template>
    </pendo-multiselect>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoGroupOption from '@/components/multiselect/option-types/pendo-group-option';
import PendoGuideStatusOption from '@/components/multiselect/option-types/pendo-guide-status-option';

export default {
    name: 'ResourceSelect',
    components: {
        PendoMultiselect,
        PendoGuideStatusOption,
        PendoGroupOption
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        column: {
            type: Object,
            default: null
        },
        field: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Select Option'
        }
    },
    computed: {
        noResourcesFoundText () {
            const resourcesLabel = {
                page: 'pages',
                feature: 'features',
                trackType: 'track events',
                guide: 'guides',
                poll: 'polls'
            }[this.column.type];

            return `No ${resourcesLabel} found.`;
        }
    },
    methods: {
        onOptionSelect (option) {
            const selectedOption = { [this.field]: option[this.field] };
            if (this.field === 'guideId') {
                selectedOption.guideStepId = option.guideStepId;
            }
            this.$emit('select', { ...this.column, ...selectedOption });
        }
    }
};
</script>

<style lang="scss">
.resource-selector__header {
    height: 40px;
    border-bottom: 1px solid $color-gray-30;
    display: flex;
    align-items: center;

    .resource-selector__selected-option {
        padding: 0 16px;
        flex: 1;
        cursor: pointer;

        &:hover {
            background-color: $color-gray-20;
        }
    }
}
</style>
