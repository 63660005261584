<template>
    <div
        class="pendo-color-picker-swatch"
        :class="{
            'is-selected': isSelected
        }"
        :style="styleObj"
        :aria-label="'color:' + color"
        :aria-selected="isSelected"
        :disabled="disabled"
        role="button"
        v-on="$listeners">
        <pendo-icon
            v-if="isSelected"
            class="pendo-color-picker-swatch__selected-icon"
            v-bind="selectedIconProps" />
        <div
            class="pendo-color-picker-swatch__color"
            :style="{
                backgroundColor: color
            }" />
        <color-picker-checkboard
            v-if="isTransparent"
            class="pendo-color-picker-swatch__checkboard"
            :size="size / 2" />
    </div>
</template>

<script>
import isNumber from 'lodash/isNumber';
import PendoIcon from '@/components/icon/pendo-icon';
import ColorPickerCheckboard from '@/components/color-picker/common/color-picker-checkboard';
import { getContrastingColor, getAlpha, isValidHex } from '@/utils/color';

export default {
    name: 'ColorPickerSwatch',
    components: {
        PendoIcon,
        ColorPickerCheckboard
    },
    props: {
        value: {
            type: String,
            default: null,
            validator: (value) => isValidHex(value)
        },
        color: {
            type: String,
            required: true
        },
        size: {
            type: [String, Number],
            default: 16
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        styleObj () {
            if (isNumber(this.size)) {
                return {
                    width: `${this.size}px`,
                    height: `${this.size}px`
                };
            }

            return {
                width: this.size,
                height: this.size
            };
        },
        selectedIconProps () {
            return {
                type: 'check',
                strokeWidth: 2.5,
                stroke: getContrastingColor(this.value),
                size: this.size * 0.6
            };
        },
        isTransparent () {
            return getAlpha(this.color) !== 1;
        },
        isSelected () {
            if (this.value) {
                return this.color.toLowerCase() === this.value.toLowerCase();
            }

            return false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-color-picker-swatch) {
    border-radius: $border-radius-3;
    border: none;
    overflow: hidden;
    position: relative;
    width: 16px;
    height: 16px;
    display: grid;
    align-items: center;
    justify-content: center;
    @include focus-ring($style: 'base');

    &:focus-visible {
        @include focus-ring($style: 'focused');
    }

    @include element((color, checkboard)) {
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: contain;
        border-radius: $border-radius-3;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    }

    @include element(color) {
        z-index: 2;
    }

    @include element(selected-icon) {
        z-index: 3;
    }

    @include is(selected) {
        @include element(color) {
            box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15);
        }
    }
}
</style>
