<template>
    <pendo-multiselect
        class="pendo-table__column-filters"
        :value="selectedFilters"
        :options="availableFilters"
        full-width
        :multiple="column.filterMultiple"
        value-key="value"
        label-key="label"
        placeholder="Search"
        :aria-label="`${column.label} filters`"
        :min-menu-width="172"
        :hide-selected-options="false"
        :clear-on-select="!column.filterMultiple"
        :close-on-select="!column.filterMultiple"
        :popper-options="{ placement: 'bottom-end', modifiers: { flipVariations: { enabled: false } } }"
        @open="onFilterPanelOpen"
        @closed="onFilterPanelClose"
        @input="onFilterChange">
        <template #trigger="{ isOpen, handleTriggerClick, handleKeydown, deactivate, handleFocus, handleBlur }">
            <button
                class="pendo-table__column-filters-trigger"
                type="button"
                aria-haspopup
                :aria-expanded="`${isOpen}`"
                :aria-label="isOpen ? 'Close filters menu' : 'Open filters menu'"
                @focus="handleFocus"
                @blur="handleBlur"
                @keyup.esc="deactivate"
                @keydown.tab="handleKeydown('tab')"
                @keydown.up.prevent.self="handleKeydown('up')"
                @keydown.enter="handleKeydown('enter', $event)"
                @keydown.down.prevent.self="handleKeydown('down')"
                @keyup.space.prevent.self.stop="handleKeydown('space', $event)"
                @click.stop="handleTriggerClick">
                <pendo-icon
                    aria-hidden="true"
                    role="img"
                    type="filter"
                    :size="14"
                    :fill="isColumnFiltered ? '#2A2C35' : undefined"
                    :stroke="isColumnFiltered || isOpen ? '#2A2C35' : '#9A9CA5'" />
            </button>
        </template>
        <template
            #header="{
                computedPlaceholder,
                deactivate,
                handleKeydown,
                inputValue,
                isSearchable,
                updateInputValue
            }">
            <div class="pendo-table__column-filters-panel-header">
                <div
                    v-if="isSearchable"
                    class="pendo-table__column-filters-panel-input">
                    <pendo-multiselect-input
                        :computed-placeholder="computedPlaceholder"
                        :input-value="inputValue"
                        is-full-width
                        is-search-icon-visible
                        :update-input-value="updateInputValue"
                        @up="handleKeydown('up')"
                        @down="handleKeydown('down')"
                        @delete="handleKeydown('delete')"
                        @right="handleKeydown('right')"
                        @left="handleKeydown('left')"
                        @enter="handleKeydown('enter', $event)"
                        @space="handleKeydown('space', $event)"
                        @tab="handleKeydown('tab', $event)"
                        @esc="deactivate" />
                </div>
                <pendo-button
                    v-if="column.filterMultiple"
                    type="link"
                    label="Clear"
                    size="small"
                    :disabled="!selectedFilters.length"
                    @click="onClearFiltersClick" />
            </div>
        </template>
        <template #option="{ option }">
            <pendo-checkbox-option :option="option" />
        </template>
    </pendo-multiselect>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoMultiselectInput from '@/components/multiselect/pendo-multiselect-input';
import PendoCheckboxOption from '@/components/multiselect/option-types/pendo-checkbox-option';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoButton from '@/components/button/pendo-button';

export default {
    components: {
        PendoButton,
        PendoCheckboxOption,
        PendoIcon,
        PendoMultiselect,
        PendoMultiselectInput
    },
    inject: ['$table'],
    props: {
        column: {
            type: Object,
            required: true
        }
    },
    computed: {
        availableFilters () {
            return this.column.filters;
        },
        selectedFilters () {
            return this.availableFilters.filter((option) => option.checked === true);
        },
        isColumnFiltered () {
            return this.selectedFilters.length;
        }
    },
    methods: {
        onFilterPanelOpen () {
            this.$table.showFilterPanel(this.column);
        },
        onFilterPanelClose () {
            this.$table.hideFilterPanel();
        },
        onClearFiltersClick () {
            this.$table.filterColumn({ column: this.column, appliedFilters: [] });
        },
        onFilterChange (value) {
            const model = Array.isArray(value) ? value : [value];
            const appliedFilters = model.filter(Boolean).map((filter) => filter.value);
            this.$table.filterColumn({ column: this.column, appliedFilters });
        }
    }
};
</script>
