<template>
    <div class="pendo-table__actions">
        <div
            v-for="action in actions"
            :key="action.type"
            v-pendo-tooltip="{
                content: action.tooltip,
                arrow: true,
                multiline: true,
                delay: { show: 500, hide: 0 }
            }">
            <pendo-button
                :class="`pendo-table__action--${action.type}`"
                class="pendo-table__action"
                size="mini"
                type="tertiary"
                :icon="action.icon"
                :disabled="action.disabled"
                @click.stop="$emit(action.type, row)" />
        </div>
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    name: 'PendoActionsCell',
    components: {
        PendoButton
    },
    directives: {
        PendoTooltip
    },
    props: {
        row: {
            type: Object,
            required: true
        },
        actions: {
            type: Array,
            default: () => [
                {
                    type: 'edit',
                    icon: 'edit',
                    tooltip: 'Edit'
                },
                {
                    type: 'duplicate',
                    icon: 'copy',
                    tooltip: 'Duplicate'
                },
                {
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                }
            ]
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(actions) {
        display: flex;
    }

    @include element(action) {
        padding: 4px !important;
    }
}
</style>
