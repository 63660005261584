<template>
    <div
        class="display-guide-alerts"
        data-cy="guide-display-errors-tab-content">
        <div class="display-guide__content">
            <guide-alerts-top-stats
                :top-stats="topStats"
                :top-stats-config="topStatsConfig"
                :guide-alerts-agg-status="guideAlertsAggStatus"
                @update-user-settings="updateUserSettings" />
            <guide-alerts-table
                ref="guideAlertsTable"
                :error-data="errorData"
                :segments-map="segmentsMap"
                :guide-alerts-agg-status="guideAlertsAggStatus"
                :has-admin-access="hasAdminAccess"
                :filters="filters"
                :table-config="tableConfig"
                :product-type="productType"
                @step-link-click="stepLinkClick"
                @update-display-alert="updateDisplayAlert"
                @create-display-alert="createDisplayAlert"
                @update-user-settings="updateUserSettings"
                @table-data-change="tableDataChange"
                @show-panel-notes="showPanelNotes"
                @show-panel-users="showPanelUsers"
                @show-alert-settings="showAlertSettings" />
            <guide-alert-settings-modal
                :guide-alerts-agg-status="guideAlertsAggStatus"
                :visible="guideAlertSettingsVisible"
                :guide-seen-timeout="guideSeenTimeout"
                :is-saving="isSavingGuideSeenTimeout"
                @update-guide-seen-timeout="updateGuideSeenTimeout"
                @close="closeAlertSettings" />
            <guide-alerts-drawer
                :is-type-note="isTypeNote"
                :visible="guideAlertsDrawerVisible"
                :display-alert="alert"
                :active-user="activeUser"
                :note-action="noteAction"
                :note-error="noteError"
                :has-admin-access="hasAdminAccess"
                :impacted-users="impactedUsers"
                :csv-agg-state="csvAggState"
                :guide-visitor-agg-status="guideVisitorAggStatus"
                :product-type="productType"
                @visitor-link-click="visitorLinkClick"
                @create-note="createNote"
                @remove-note="removeNote"
                @update-note="updateNote"
                @fetch-notes="fetchNotes"
                @close="closeDrawer"
                @download-csv="downloadCsv" />
        </div>
    </div>
</template>

<script>
import GuideAlertsTopStats from './top-stats/guide-alerts-top-stats.vue';
import GuideAlertsTable from './table/guide-alerts-table.vue';
import GuideAlertSettingsModal from './table/guide-alert-settings-modal.vue';
import GuideAlertsDrawer from './drawer/guide-alerts-drawer.vue';

export default {
    name: 'GuideAlertsPage',
    components: {
        GuideAlertsTopStats,
        GuideAlertsTable,
        GuideAlertSettingsModal,
        GuideAlertsDrawer
    },
    props: {
        errorData: {
            type: Array,
            required: true
        },
        segmentsMap: {
            type: Object,
            required: true
        },
        guideAlertsAggStatus: {
            type: String,
            required: true
        },
        hasAdminAccess: {
            type: Boolean,
            default: false
        },
        topStatsConfig: {
            type: Object,
            default: () => {}
        },
        tableConfig: {
            type: Object,
            default: () => {}
        },
        filters: {
            type: Array,
            default: () => []
        },
        productType: {
            type: String,
            default: 'engage'
        },
        isSavingGuideSeenTimeout: {
            type: Boolean,
            default: false
        },
        guideSeenTimeout: {
            type: Number,
            default: 10000
        },
        activeUser: {
            type: Object,
            required: true
        },
        noteAction: {
            type: Object,
            default: () => {}
        },
        noteError: {
            type: Object,
            default: () => {}
        },
        guideVisitorAggStatus: {
            type: String,
            required: true
        },
        csvAggState: {
            type: Object,
            default: () => ({})
        },
        impactedUsers: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            guideAlertSettingsVisible: false,
            guideAlertsDrawerVisible: false,
            topStats: [],
            alert: {},
            isTypeNote: false
        };
    },
    created () {
        this.topStats = this.errorData;
    },
    methods: {
        /**
         * @public
         */
        updateTableRow (alert, closeModal) {
            this.$refs.guideAlertsTable.updateTableRow(alert, closeModal);
        },
        /**
         * @public
         */
        informError (closeModal) {
            this.$refs.guideAlertsTable.informError(closeModal);
        },
        stepLinkClick (guideId) {
            this.$emit('step-link-click', guideId);
        },
        visitorLinkClick (visitorId) {
            this.$emit('visitor-link-click', visitorId);
        },
        downloadCsv () {
            this.$emit('download-csv');
        },
        createDisplayAlert ({ alert, activeAlert, isVisible }) {
            this.$emit('create-display-alert', { alert, activeAlert, isVisible });
        },
        updateDisplayAlert ({ alert, activeAlert, isVisible }) {
            this.$emit('update-display-alert', { alert, activeAlert, isVisible });
        },
        updateUserSettings (settings) {
            this.$emit('update-user-settings', settings);
        },
        tableDataChange ({ data }) {
            this.topStats = data;
            this.$emit('table-data-change', { data });
        },
        showPanelUsers ({ guideId, guideStepId, guideShownReason }) {
            this.isTypeNote = false;
            this.$emit('fetch-impacted-users', { guideId, guideStepId, guideShownReason });
            this.showDrawer();
        },
        showPanelNotes (alert) {
            this.isTypeNote = true;
            this.alert = alert;
            this.showDrawer();
        },
        updateGuideSeenTimeout (timeout) {
            this.$emit('update-guide-seen-timeout', timeout);
        },
        showAlertSettings () {
            this.guideAlertSettingsVisible = true;
        },
        closeAlertSettings () {
            this.guideAlertSettingsVisible = false;
        },
        showDrawer () {
            this.guideAlertsDrawerVisible = true;
        },
        closeDrawer () {
            this.guideAlertsDrawerVisible = false;
            this.alert = {};
        },
        createNote ({ alert, text }) {
            this.$emit('create-note', { alert, text });
        },
        removeNote ({ alert, noteId }) {
            this.$emit('remove-note', { alert, noteId });
        },
        updateNote ({ alert, text, noteId }) {
            this.$emit('update-note', { alert, text, noteId });
        },
        fetchNotes ({ alert }) {
            this.$emit('fetch-notes', { alert });
        }
    }
};
</script>
