<template>
    <div class="pendo-guide-elements-types-actions">
        <div
            v-for="({ name, icon }, index) in uiElements"
            :key="name"
            class="pendo-guide-element-types-actions">
            <pendo-icon
                v-if="icon"
                class="pendo-guide-element-type-action-icon"
                :type="icon"
                :size="iconSize" />
            <div class="pendo-guide-element-type-action-name">
                {{ name }}
            </div>
            <pendo-divider
                v-if="showDivider(index)"
                direction="vertical"
                stroke="#DADCE5"
                width="16px"
                height="16px" />
        </div>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoDivider from '@/components/divider/pendo-divider';

export default {
    name: 'PendoGuideElementTypeAction',
    components: {
        PendoIcon,
        PendoDivider
    },
    props: {
        uiElements: {
            type: Array,
            required: true,
            validator: (elems) => elems.every((el) => el.name)
        },
        iconSize: {
            type: Number,
            default: 14
        }
    },
    methods: {
        showDivider (index) {
            const hasMoreThanOneElement = this.uiElements.length > 1;
            const isIndexAtTheLastElement = index === this.uiElements.length - 1;

            return hasMoreThanOneElement && !isIndexAtTheLastElement;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-guide-elements-types-actions) {
    display: flex;
}

@include block(pendo-guide-element-types-actions) {
    display: flex;
    align-items: center;
}

@include block(pendo-guide-element-type-action-icon) {
    margin-right: 8px;
}
</style>
