<template>
    <div class="pendo-multiselect-option--checkbox">
        <pendo-checkbox
            class="pendo-multiselect-option__input"
            v-bind="{ checked: isChecked, disabled: option.disabled, value: isChecked }"
            v-on="$listeners">
            <slot>
                <pendo-icon-option
                    v-if="type === 'icon'"
                    :option="option" />
                <pendo-group-option
                    v-if="type === 'group'"
                    :option="option" />
            </slot>
        </pendo-checkbox>
    </div>
</template>

<script>
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoGroupOption from '@/components/multiselect/option-types/pendo-group-option';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';

export default {
    name: 'PendoCheckboxOption',
    components: {
        PendoCheckbox,
        PendoGroupOption,
        PendoIconOption
    },
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String],
            required: true
        },
        type: {
            type: String,
            default: 'icon',
            validator: (type) => ['icon', 'group'].includes(type)
        }
    },
    computed: {
        isChecked () {
            return this.multiselect.isOptionSelected(this.option);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(checkbox) {
        @include element(input) {
            display: flex;
            height: inherit;
            width: 100%;
            align-items: unset;

            .pendo-checkbox__input {
                flex: 0;
            }

            .pendo-checkbox__label {
                flex: 1;
                overflow: hidden;

                .pendo-multiselect-option--actions {
                    // Default is 16px. This accounts for the 14px checkbox + 8px padding spacing
                    left: 38px;
                }
            }

            .pendo-checkbox__inner {
                transition: unset;
            }
        }
    }
}
</style>
