<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<template>
    <pendo-modal
        class="pendo-add-update-extension-app-modal"
        :title="title"
        height="auto"
        :visible="visible"
        :width="width"
        size="medium"
        @close="emitClose">
        <template #body>
            <pendo-alert
                class="pendo-add-update-extension-app-modal__staging-url-alert"
                type="info">
                To test staged guides for extension apps, be sure to also add your staging urls to the corresponding
                application's Staging settings.
                <a
                    :href="websitesModalAlertLink"
                    target="_blank"> Learn more</a>.
            </pendo-alert>
            <pendo-alert
                v-if="createMode && hasV2Permissions"
                type="info"
                class="pendo-add-update-extension-app-modal__app-permission-alert">
                In order to establish permissions for new apps, this page will refresh and users will be automatically
                logged out.
            </pendo-alert>
            <pendo-alert
                v-if="mutableSubmissionError"
                class="pendo-add-update-extension-app-modal__submission-error-alert"
                type="error"
                :closable="true"
                :title="mutableSubmissionError.errorMessage"
                @close="resetError">
                <div
                    v-for="(error, index) in mutableSubmissionError.duplicatePatternErrors"
                    :key="index"
                    class="pendo-add-update-extension-app-modal__submission-error-duplicate-item">
                    {{ error }}
                </div>
                <div
                    v-for="(error, index) in mutableSubmissionError.invalidPatternErrors"
                    :key="index"
                    class="pendo-add-update-extension-app-modal__submission-error-invalid-item">
                    {{ error }}
                </div>
                <div
                    v-for="(error, index) in mutableSubmissionError.duplicateRegExErrors"
                    :key="index"
                    class="pendo-add-update-extension-app-modal__submission-error-duplicate-item">
                    {{ error }}
                </div>
            </pendo-alert>
            <pendo-alert
                v-if="readOnlyMode"
                type="info"
                class="pendo-add-update-extension-app-modal__app-permission-alert">
                Reach out to your Pendo representative to modify the scope of data collected by your Extension
                Application
            </pendo-alert>
            <div class="pendo-add-update-extension-app-modal__app-create-fields">
                <div
                    v-if="createMode"
                    class="pendo-add-update-extension-app-modal__app-name-input">
                    <pendo-input
                        :labels="{ top: 'App Name' }"
                        autofocus
                        :value="appName"
                        @input="onNameInputChange" />
                </div>
                <div
                    v-if="createMode"
                    class="pendo-add-update-extension-app-modal__app-platform-type">
                    <pendo-multiselect
                        v-model="extensionPlatformType"
                        :loading="isLoadingPlatformExtensionTypes"
                        value-key="type"
                        full-width
                        :labels="{ top: 'Extension Platform Type' }"
                        :append-to-body="false"
                        :allow-empty="false"
                        :options="extensionPlatformTypeOptions">
                        <template #noData>
                            All available extension platform types configured
                        </template>
                    </pendo-multiselect>
                </div>
            </div>
            <template v-if="showDomainPatterns">
                <pendo-repeating-text-input
                    v-model="domainPatterns"
                    class="repeating-text-input-domain-patterns"
                    :read-only="readOnlyMode"
                    :validator="isValidDomain"
                    @domain-changed="onDomainChanged">
                    <template #header>
                        Websites
                    </template>
                    <template
                        v-if="!readOnlyMode"
                        #description>
                        <p>
                            Add websites you'd like to collect data and deploy guides on. Do not include
                            <code>http://</code> or <code>https://</code>
                        </p>
                        <pendo-alert
                            v-if="showDomainValidationError"
                            class="pendo-add-update-extension-app-modal__invalid-domains-alert"
                            type="error">
                            One or more of the provided URLs are invalid. Use a standard format, such as
                            <code>example.com</code>, avoiding any spaces or parameters.
                        </pendo-alert>
                    </template>
                </pendo-repeating-text-input>
            </template>
            <slot />
            <template v-if="showRegularExpressions">
                <repeating-regexp-input
                    v-model="regexPatterns"
                    class="repeating-struct-input-regular-expressions"
                    :read-only="readOnlyMode"
                    :panels="panels"
                    :domain-validator="isValidDomain"
                    :regex-validator="isValidRegExPiece"
                    :active-panel="activePanel"
                    @updatePanel="updatePanel"
                    @updateActivePanel="updateActivePanel">
                    <template #header>
                        Selective URLs
                    </template>
                    <template
                        v-if="!readOnlyMode"
                        #description>
                        <p>
                            Be careful. If the regular expression isn't accurate, you will collect more information than
                            is requested by the customer.
                            <a
                                :href="selectiveUrlSupportLink"
                                target="_blank"> Learn more</a>.
                        </p>
                        <p>
                            If you have questions, please reach out to someone in <b>#adopt-eng-engagement</b> for
                            support.
                        </p>
                    </template>
                </repeating-regexp-input>
            </template>
            <template v-if="showTaggingSettings">
                <div class="pendo-app-tagging-collection-settings">
                    <hr>
                    <div class="pendo-add-update-extension-app-modal__settings-header">
                        Tagging Settings
                    </div>
                    <pendo-modal
                        :visible="showClassModal"
                        :confirm-button-config="{
                            type: 'danger',
                            label: 'Turn Off'
                        }"
                        append-to-body
                        type="confirmation"
                        title="Warning"
                        @cancel="cancelClassExclusions"
                        @confirm="confirmClassExclusions">
                        <template #body>
                            By turning off this setting, you are opting out of configuration that will enable strong
                            feature tagging. Tags in your application may be weak and can impact behavior when using
                            Pendo, such as in guides, workflows, paths, etc.
                            <strong>
                                We encourage you to keep this setting on to enable the best Pendo experience.</strong>
                            <br>
                            <br>
                            For questions, please reach out directly to your Pendo support representative or
                            <a href="mailto:support@pendo.io">
                                contact support.
                            </a>
                        </template>
                    </pendo-modal>
                    <pendo-modal
                        :visible="showIdModal"
                        :confirm-button-config="{
                            type: 'danger',
                            label: 'Turn Off'
                        }"
                        append-to-body
                        type="confirmation"
                        title="Warning"
                        @cancel="cancelIdExclusions"
                        @confirm="confirmIdExclusions">
                        <template #body>
                            By turning off this setting, you are opting out of configuration that will enable strong
                            feature tagging. Tags in your application may be weak and can impact behavior when using
                            Pendo, such as in guides, workflows, paths, etc.
                            <strong>
                                We encourage you to keep this setting on to enable the best Pendo experience.</strong>
                            <br>
                            <br>
                            For questions, please reach out directly to your Pendo support representative or
                            <a href="mailto:support@pendo.io">
                                contact support.
                            </a>
                        </template>
                    </pendo-modal>
                    <div v-if="!!classValues.length">
                        <pendo-button
                            theme="app"
                            size="mini"
                            type="tertiary"
                            :icon="showClasses ? 'chevron-down' : 'chevron-right'"
                            icon-size="16"
                            @click="showClasses = !showClasses" />
                        <pendo-checkbox
                            label="Class Exclusions"
                            :value="!!classValues.length"
                            @change="loadClasses(shouldSetAllClasses)" />
                        <div class="pendo-app-tagging-collection-settings__exclusion-row">
                            <pendo-checkbox-group
                                :value="classValues"
                                @input="onClassValueChange">
                                <div
                                    v-if="showClasses"
                                    class="pendo-app-tagging-collection-settings__exclusions">
                                    <pendo-checkbox
                                        v-for="classExclusion in extensionPlatformType.classExclusions"
                                        :key="classExclusion.label"
                                        v-model="classExclusion.value"
                                        :label="classExclusion.label" />
                                </div>
                            </pendo-checkbox-group>
                        </div>
                    </div>
                    <div v-if="!!idValues.length">
                        <pendo-button
                            theme="app"
                            size="mini"
                            type="tertiary"
                            :icon="showIds ? 'chevron-down' : 'chevron-right'"
                            icon-size="16"
                            @click="showIds = !showIds" />
                        <pendo-checkbox
                            label="ID Exclusions"
                            :value="!!idValues.length"
                            @change="loadIds(shouldSetAllIds)" />
                        <div class="pendo-app-tagging-collection-settings__exclusion-row">
                            <pendo-checkbox-group
                                :value="idValues"
                                @input="onIdValueChange">
                                <div
                                    v-if="showIds"
                                    class="pendo-app-tagging-collection-settings__exclusions">
                                    <pendo-checkbox
                                        v-for="idExclusion in extensionPlatformType.idExclusions"
                                        :key="idExclusion.label"
                                        v-model="idExclusion.value"
                                        :label="idExclusion.label" />
                                </div>
                            </pendo-checkbox-group>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="showDataCollectionSettings">
                <div class="pendo-app-data-collection-settings">
                    <hr>
                    <div class="pendo-add-update-extension-app-modal__settings-header">
                        Data Collection Settings
                    </div>
                    <p>
                        By collecting more data, these settings enable stronger tags to be shared by Pendo to your
                        application and enable stronger custom tags.
                    </p>
                    <div class="pendo-app-data-collection-settings__consent-options">
                        <div
                            v-for="(consentOption, consentOptionKey) in extensionPlatformType.consentOptions"
                            :key="consentOptionKey"
                            class="pendo-app-data-collection-settings__consent-option">
                            <div>
                                <span v-if="consentOption.subConsentOptions && !consentOption.hideSubConsentOptions">
                                    <pendo-button
                                        theme="app"
                                        size="mini"
                                        type="tertiary"
                                        :icon="
                                            consentOption.isSubConsentOptionsExpanded ? 'chevron-down' : 'chevron-right'
                                        "
                                        icon-size="16"
                                        @click="toggleSubConsentOptions(consentOptionKey)" />
                                </span>
                            </div>
                            <div>
                                <pendo-checkbox
                                    v-if="pendingDataConsent !== consentOptionKey"
                                    :label="consentOption.label"
                                    :value="consentOption.enabled"
                                    @change="toggleConsent(consentOptionKey, $event)" />
                                <div
                                    v-if="consentOption.isSubConsentOptionsExpanded"
                                    class="pendo-app-data-collection-settings__sub-consent-options">
                                    <pendo-checkbox
                                        v-for="(subConsentOption,
                                                subConsentOptionKey) in consentOption.subConsentOptions"
                                        :key="subConsentOptionKey"
                                        v-model="subConsentOption.enabled"
                                        :label="subConsentOption.label"
                                        @change="toggleConsent(consentOptionKey, $event, subConsentOptionKey)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <pendo-modal
                        :visible="showConfirmOptOut"
                        :confirm-button-config="{
                            type: 'danger',
                            label: 'Turn Off'
                        }"
                        append-to-body
                        type="confirmation"
                        title="Warning"
                        @close="cancelOptOut"
                        @cancel="cancelOptOut"
                        @confirm="confirmOptOut">
                        <template #body>
                            By turning off this setting, you are opting out of receiving some strong features from
                            Pendo. Tags in your application may be weak and can impact behavior when using Pendo, such
                            as in guides, workflows, paths, etc.
                            <strong>
                                We encourage you to keep this setting on to enable the best Pendo experience.</strong>
                            <br>
                            <br>
                            For questions, please reach out directly to your Pendo support representative or
                            <a href="mailto:support@pendo.io">
                                contact support.
                            </a>
                        </template>
                    </pendo-modal>
                    <pendo-legal-acknowledgement
                        :visible="showLegalAcknowledgement"
                        :is-for-customer="isForCustomer"
                        title="Increased Data Collection"
                        action-button-label="I Accept"
                        @confirm-action="confirmConsent"
                        @close="cancelConsent">
                        By enabling:
                        <ul>
                            <li
                                v-for="consentOptionNeedingLegalAcknowledgement in consentOptionsNeedingLegalAcknowledgement"
                                :key="consentOptionNeedingLegalAcknowledgement.label">
                                {{ consentOptionNeedingLegalAcknowledgement.label }}
                            </li>
                        </ul>
                        I understand that Pendo will collect more data on my application. This setting enables Pendo to
                        share feature tags which use that data to generate a strong tag rule.
                        <br>
                        <br>
                        To change these settings in the future, please reach out directly to your Pendo support
                        representative or
                        <a href="mailto:support@pendo.io">
                            contact support.
                        </a>
                        <br>
                        <br>
                    </pendo-legal-acknowledgement>
                </div>
            </template>
        </template>
        <template #footer>
            <template v-if="readOnlyMode">
                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Close"
                    @click="emitClose" />
            </template>
            <template v-if="!readOnlyMode">
                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Cancel"
                    @click="emitClose" />
                <pendo-button
                    v-if="createMode"
                    theme="app"
                    label="Save"
                    :loading="saving"
                    :disabled="!isModalValid"
                    @click="submitModalCreateApp" />
                <pendo-button
                    v-if="!createMode && showDomainPatterns"
                    theme="app"
                    label="Save"
                    :loading="saving"
                    :disabled="!areDomainsValid"
                    @click="submitModalUpdateDomains" />
                <pendo-button
                    v-if="!createMode && showRegularExpressions"
                    theme="app"
                    label="Save"
                    :loading="saving"
                    :disabled="!areRegExpsValid"
                    @click="submitModalUpdateRegex" />
                <pendo-button
                    v-if="!createMode && editFavicon"
                    theme="app"
                    label="Save"
                    :loading="saving"
                    :disabled="!areRegExpsValid"
                    @click="submitModalUpdateFavicon" />
            </template>
        </template>
    </pendo-modal>
</template>
<script>
import Vue from 'vue';
import PendoAlert from '@/components/alert/pendo-alert.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoCheckboxGroup from '@/components/checkbox-group/pendo-checkbox-group.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoLegalAcknowledgement from '@/composites/legal-acknowledgement/pendo-legal-acknowledgement.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect.vue';
import PendoRepeatingTextInput from '@/composites/repeating-text-input/pendo-repeating-text-input.vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import RepeatingRegexpInput from './repeating-regexp-input.vue';

export default {
    name: 'PendoAddUpdateExtensionAppModal',
    components: {
        PendoButton,
        PendoCheckbox,
        PendoCheckboxGroup,
        PendoModal,
        PendoLegalAcknowledgement,
        PendoMultiselect,
        PendoRepeatingTextInput,
        PendoAlert,
        PendoInput,
        RepeatingRegexpInput
    },
    props: {
        /**
         * Visiblity of Modal
         */
        visible: {
            type: Boolean,
            default: false
        },
        /**
         * Modal in Create or Edit Mode
         */
        createMode: {
            type: Boolean,
            default: false
        },
        /**
         * Flag for updating Regex vs Domains while not in createMode
         */
        regexFlag: {
            type: Boolean,
            default: false
        },
        /**
         * Flag for showing RegEx input fields when creating a new app
         */
        enableRegex: {
            type: Boolean,
            default: false
        },
        app: {
            type: Object,
            default: () => {
                return {};
            }
        },
        /**
         * hasV2Permissions used for error display
         */
        hasV2Permissions: {
            type: Boolean,
            default: true
        },
        isLoadingPlatformExtensionTypes: {
            type: Boolean,
            default: false
        },
        extensionPlatformTypeOptions: {
            type: Array,
            default: () => {
                return [];
            }
        },
        saving: {
            type: Boolean,
            default: false
        },
        submissionError: {
            type: Object,
            default: () => ({})
        },
        isForCustomer: {
            type: Boolean,
            default: true
        },
        width: {
            type: Number,
            default: 775
        },
        readOnlyMode: {
            type: Boolean,
            default: false
        },
        editFavicon: {
            type: Boolean,
            default: false
        },
        websitesModalAlertLink: {
            type: String,
            required: true
        },
        selectiveUrlSupportLink: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            appName: '',
            extensionPlatformType: { classExclusions: {}, idExclusions: {} },
            domainPatterns: this.app.extensionDomainPatterns ? [...this.app.extensionDomainPatterns] : [''],
            regexPatterns: this.app.extensionStructRegExps
                ? cloneDeep(this.app.extensionStructRegExps)
                : [
                    {
                        subDomainRegExp: '',
                        pathRegExp: '',
                        rootDomain: ''
                    }
                ],
            showConfirmOptOut: false,
            showLegalAcknowledgement: false,
            pendingDataConsent: null,
            mutableSubmissionError: null,
            panels: [
                {
                    name: 'regexp1',
                    title: 'Selective URL 1'
                }
            ],
            classValues: [],
            idValues: [],
            activePanel: ['regexp1'],
            showIds: false,
            showIdModal: false,
            shouldSetAllIds: false,
            showClassModal: false,
            showClasses: false,
            shouldSetAllClasses: false,
            pendingIdExclusions: [],
            pendingClassExclusions: [],
            showDomainValidationError: false
        };
    },
    computed: {
        title () {
            if (!this.createMode) {
                if (this.readOnlyMode) {
                    return this.regexFlag ? 'View Selective URLs' : 'View Websites';
                }
                if (this.regexFlag) {
                    return 'Edit Selective URLs';
                }

                return this.editFavicon ? 'Edit Favicon' : 'Edit Websites';
            }

            return 'Add Extension Application';
        },
        showDomainPatterns () {
            return (this.createMode || !this.regexFlag) && !this.editFavicon;
        },
        showRegularExpressions () {
            return (this.createMode || this.regexFlag) && this.enableRegex;
        },
        showTaggingSettings () {
            const hasClassExclusions = Boolean(
                this.extensionPlatformType && !isEmpty(this.extensionPlatformType.classExclusions)
            );
            const hasIdExclusions = Boolean(
                this.extensionPlatformType && !isEmpty(this.extensionPlatformType.idExclusions)
            );
            const hasIdOrClassExclusions = hasClassExclusions || hasIdExclusions;

            return hasIdOrClassExclusions;
        },
        isAppNameValid () {
            return this.appName !== '';
        },
        areDomainsValid () {
            return !this.domainPatterns.some((domain) => {
                return !this.isValidDomain(domain);
            });
        },
        areRegExpsValid () {
            return this.regexPatterns.every((regex) => {
                return (
                    this.isEmptyRegExp(regex) ||
                    (this.isRootDomainPopulated(regex) &&
                        this.isValidDomain(regex.rootDomain) &&
                        this.isValidRegExPiece(regex.subDomainRegExp, regex.rootDomain, true) &&
                        this.isValidRegExPiece(regex.pathRegExp, regex.rootDomain, false))
                );
            });
        },
        isModalValid () {
            return (
                this.isAppNameValid &&
                this.areDomainsValid &&
                this.areRegExpsValid &&
                !!get(this.extensionPlatformType, 'type')
            );
        },
        consentOptionsNeedingLegalAcknowledgement () {
            return Object.values(get(this.extensionPlatformType, 'consentOptions', {}) || {}).filter(
                (consentOption) => consentOption.enabled && !consentOption.legallyAcknowledged
            );
        },
        showDataCollectionSettings () {
            return Boolean(
                this.extensionPlatformType && !isEmpty(this.extensionPlatformType.consentOptions) && this.createMode
            );
        },
        consentOptionsAppPayload () {
            return Object.entries(get(this.extensionPlatformType, 'consentOptions', {})).reduce(
                (appPayload, [consentOptionKey, consentOption]) => {
                    if (!consentOption.enabled || !consentOption.legallyAcknowledged) {
                        return appPayload;
                    }
                    if (consentOption.subConsentOptions) {
                        appPayload[consentOption.subConsentOptionsField] = Object.values(
                            consentOption.subConsentOptions
                        ).reduce((validSubConsentOptions, subConsentOption) => {
                            if (!subConsentOption.enabled) {
                                // legal acknowledgement is handled at the consentOption level
                                return validSubConsentOptions;
                            }

                            switch (consentOption.subConsentOptionsPayloadFormat) {
                                case 'String':
                                    validSubConsentOptions.push(subConsentOption.label);
                                    break;
                                case 'Object':
                                    validSubConsentOptions.push(omit(subConsentOption, ['enabled', 'label']));
                                    break;
                            }

                            return validSubConsentOptions;
                        }, []);

                        if (consentOption.subConsentOptionsField === 'htmlAttributes') {
                            return appPayload;
                        }
                    }

                    appPayload.applicationFlags[consentOptionKey] = !appPayload.applicationFlags[consentOptionKey];

                    return appPayload;
                },
                {
                    applicationFlags: {
                        siblingSelectors: false,
                        pageBasedEventProperties: false,
                        // disableExtensionTextCapture flag default value is true
                        disableExtensionTextCapture: true
                    }
                }
            );
        },
        populatePanels () {
            const returnPanels = [];
            for (let idx = 0; idx < this.regexPatterns.length; idx++) {
                returnPanels.push({
                    name: `regexp${idx + 1}`,
                    title: `Selective URL ${idx + 1}`
                });
            }

            return returnPanels;
        }
    },
    watch: {
        areDomainsValid (value) {
            if (value === true) {
                this.showDomainValidationError = false;
            }
        },
        extensionPlatformType () {
            if (!this.extensionPlatformType) {
                return;
            }
            const classExclusions = get(this.extensionPlatformType, 'taggingClassExclusions', []).map((excl) => ({
                value: true,
                label: excl,
                type: 'class',
                disabled: false
            }));
            const idExclusions = get(this.extensionPlatformType, 'taggingIdExclusions', []).map((excl) => ({
                value: true,
                label: excl,
                type: 'id',
                disabled: false
            }));
            this.$set(this.extensionPlatformType, 'classExclusions', classExclusions);
            this.$set(this.extensionPlatformType, 'idExclusions', idExclusions);
            this.loadClasses(true);
            this.loadIds(true);
        },
        submissionError (value) {
            this.mutableSubmissionError = value;
        },
        visible (value) {
            if (!value) {
                this.resetModal();
            }
        },
        createMode () {
            this.regexPatterns =
                !this.createMode && this.app.extensionStructRegExps
                    ? cloneDeep(this.app.extensionStructRegExps)
                    : [
                        {
                            subDomainRegExp: '',
                            pathRegExp: '',
                            rootDomain: ''
                        }
                    ];
            this.panels = this.populatePanels;
        }
    },
    created () {
        this.panels = this.populatePanels;
    },
    methods: {
        emitClose () {
            this.resetModal();
            this.$emit('close', true);
        },
        onClassValueChange (newVal) {
            if (this.classValues.length > newVal.length) {
                this.showClassModal = true;
                this.pendingClassExclusions = newVal;
            } else {
                this.classValues = newVal;
            }
        },
        onIdValueChange (newVal) {
            if (this.idValues.length > newVal.length) {
                this.showIdModal = true;
                this.pendingIdExclusions = newVal;
            } else {
                this.idValues = newVal;
            }
        },
        onDomainChanged () {
            if (this.areDomainsValid) {
                this.showDomainValidationError = false;
            } else {
                this.showDomainValidationError = true;
            }
            const domain = this.domainPatterns[0];
            this.$emit('domain-changed', domain);
        },
        cancelClassExclusions () {
            this.showClassModal = false;
        },
        cancelIdExclusions () {
            this.showIdModal = false;
        },
        confirmClassExclusions () {
            this.classValues = this.pendingClassExclusions;
            if (!this.classValues.length) {
                this.shouldSetAllClasses = true;
            }
            this.showClassModal = false;
        },
        confirmIdExclusions () {
            this.idValues = this.pendingIdExclusions;
            if (!this.idValues.length) {
                this.shouldSetAllIds = true;
            }
            this.showIdModal = false;
        },
        loadClasses (set) {
            if (set) {
                this.classValues = this.extensionPlatformType.classExclusions.map((cls) => cls.label);
                this.shouldSetAllClasses = false;
                this.showClassModal = false;
            } else {
                this.pendingClassExclusions = [];
                this.showClassModal = true;
            }
        },
        loadIds (set) {
            if (set) {
                this.idValues = this.extensionPlatformType.idExclusions.map((id) => id.label);
                this.shouldSetAllIds = false;
                this.showIdModal = false;
            } else {
                this.pendingIdExclusions = [];
                this.showIdModal = true;
            }
        },
        submitModalCreateApp () {
            if (this.consentOptionsNeedingLegalAcknowledgement.length > 0) {
                this.requestConsent();

                return;
            }
            const app = {
                displayName: this.appName,
                platform: 'extension',
                extensionDomainPatterns: this.domainPatterns,
                extensionStructRegExps: this.regexPatterns,
                extensionPlatformType: this.extensionPlatformType.type,
                taggingClassExclusions: this.classValues,
                taggingIdExclusions: this.idValues,
                parentAppId: this.extensionPlatformType.parentAppId,
                ...this.consentOptionsAppPayload
            };
            this.$emit('create', app);
        },
        submitModalUpdateDomains () {
            this.$emit('update', this.domainPatterns);
        },
        submitModalUpdateRegex () {
            this.$emit('updateRegex', this.regexPatterns);
        },
        submitModalUpdateFavicon () {
            this.$emit('update-favicon');
        },
        resetError () {
            this.mutableSubmissionError = null;
            // eslint-disable-next-line vue/no-mutating-props
            this.saving = false;
        },
        resetModal () {
            this.resetError();
            this.appName = null;
            this.extensionPlatformType = null;
            this.domainPatterns = this.app.extensionDomainPatterns ? [...this.app.extensionDomainPatterns] : [''];
            this.regexPatterns = this.app.extensionStructRegExps
                ? cloneDeep(this.app.extensionStructRegExps)
                : [
                    {
                        subDomainRegExp: '',
                        pathRegExp: '',
                        rootDomain: ''
                    }
                ];
            this.panels = this.regexPatterns
                ? [...this.panels].splice(0, this.regexPatterns.length)
                : [
                    {
                        name: 'regexp1',
                        title: 'Regular Expression 1'
                    }
                ];
            this.activePanel = ['regexp1'];
        },
        onNameInputChange (value) {
            this.appName = value;
        },
        isValidDomain (domain) {
            if (domain === '') {
                return true;
            }

            // Valid domain must contain only letters, numbers, and hyphens, and must have at least 1 period before a tld.
            // Valid domain may have any number of sequential hyphens, but may not start or end with a hyphen.
            // IP addresses and ports are not supported. Wildcards are not supported.
            const validDomainRegex = new RegExp('^([a-z0-9]+(-+[a-z0-9]+)*\\.)+[a-z]{2,}$', 'gm');

            // Some companies set up dumb intranet hostnames without top-level-domains (TLD).
            // For instance, this might look like: http://top-level/some-app
            // This is absolutely not a valid internet hostname, but DNS can be set up to resolve these so...we gotta support 'em
            const intranetHostnameRegex = new RegExp('^([a-z0-9]+(-+[a-z0-9]+)*)$');

            return validDomainRegex.test(domain) || intranetHostnameRegex.test(domain);
        },
        isValidRegExPiece (regex, rootDomain, isSubDomain) {
            if (regex === '') {
                return true;
            }

            try {
                // eslint-disable-next-line no-unused-vars
                const validRegex = new RegExp(isSubDomain ? `${regex}${rootDomain}` : `${rootDomain}${regex}`);

                return true;
            } catch (err) {
                return false;
            }
        },
        isEmptyRegExp (regex) {
            return regex.rootDomain === '' && regex.pathRegExp === '' && regex.subDomainRegExp === '';
        },
        isRootDomainPopulated (regex) {
            return regex.rootDomain !== '' && (regex.pathRegExp !== '' || regex.subDomainRegExp !== '');
        },
        toggleConsent (consentOptionKey, value, subConsentOptionKey) {
            this.pendingDataConsent = {
                consentOptionKey,
                subConsentOptionKey
            };

            if (value === false) {
                this.showConfirmOptOut = true;

                return;
            }

            this.cancelOptOut();
        },
        toggleSubConsentOptions (consentOption) {
            const currentOption = get(
                this.extensionPlatformType.consentOptions[consentOption],
                'isSubConsentOptionsExpanded',
                false
            );
            Vue.set(
                this.extensionPlatformType.consentOptions[consentOption],
                'isSubConsentOptionsExpanded',
                !currentOption
            );
        },
        changeConsentOption (enabled) {
            const { consentOptionKey, subConsentOptionKey } = this.pendingDataConsent;

            if (subConsentOptionKey) {
                Vue.set(
                    this.extensionPlatformType.consentOptions[consentOptionKey].subConsentOptions[subConsentOptionKey],
                    'enabled',
                    enabled
                );
                const someSubConsentOptionsAreEnabled = Object.values(
                    this.extensionPlatformType.consentOptions[consentOptionKey].subConsentOptions
                ).some((subConsentOption) => subConsentOption.enabled);
                Vue.set(
                    this.extensionPlatformType.consentOptions[consentOptionKey],
                    'enabled',
                    someSubConsentOptionsAreEnabled
                );

                return;
            }

            Vue.set(this.extensionPlatformType.consentOptions[consentOptionKey], 'enabled', enabled);

            // eslint-disable-next-line guard-for-in
            for (const subConsentOptionKey in this.extensionPlatformType.consentOptions[consentOptionKey]
            .subConsentOptions) {
                Vue.set(
                    this.extensionPlatformType.consentOptions[consentOptionKey].subConsentOptions[subConsentOptionKey],
                    'enabled',
                    enabled
                );
            }
        },
        confirmOptOut () {
            this.changeConsentOption(false);
            this.resetOptOut();
        },
        cancelOptOut () {
            this.changeConsentOption(true);
            this.resetOptOut();
        },
        resetOptOut () {
            this.pendingDataConsent = null;
            this.showConfirmOptOut = false;
        },
        requestConsent () {
            this.showLegalAcknowledgement = true;
        },
        confirmConsent () {
            // eslint-disable-next-line guard-for-in
            for (const consentOptionKey in this.extensionPlatformType.consentOptions) {
                Vue.set(this.extensionPlatformType.consentOptions[consentOptionKey], 'legallyAcknowledged', true);
            }
            this.showLegalAcknowledgement = false;
            this.submitModalCreateApp();
        },
        cancelConsent () {
            this.showLegalAcknowledgement = false;
        },
        updatePanel (value) {
            this.panels = value;
        },
        updateActivePanel (value) {
            this.activePanel = value;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-add-update-extension-app-modal) {
    @include element(app-create-fields) {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: auto auto;
    }

    @include element(
        (
            app-name-input,
            app-platform-type,
            app-permission-alert,
            submission-error-alert,
            staging-url-alert,
            invalid-domains-alert
        )
    ) {
        margin-bottom: 16px;
    }

    @include element((submission-error-duplicate-item, submission-error-invalid-item)) {
        margin-bottom: 8px;
    }

    @include element(settings-header) {
        margin: 1.33em 0 1.33em 0;
        font-weight: bold;
    }
}

@include block(pendo-app-data-collection-settings) {
    @include element(consent-option) {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 16px auto;
        margin: 5px 0;
    }

    @include element(sub-consent-options) {
        margin: 0 0 0 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    @include block(pendo-checkbox) {
        & + .pendo-checkbox {
            margin-left: 0;
        }
    }
}

@include block(pendo-app-tagging-collection-settings) {
    @include element(exclusion-row) {
        margin: 0 0 0 40px;
    }

    @include element(exclusions) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    @include block(pendo-checkbox) {
        & + .pendo-checkbox {
            margin-left: 0;
        }
    }
}
</style>
