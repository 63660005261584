<template>
    <div>
        <span
            ref="reference"
            :style="referenceStyle"
            :aria-describedby="id">
            <slot />
        </span>
        <div
            :id="id"
            ref="popper"
            class="pendo-picker-popper__content-wrapper"
            :class="[popperOptions.class, popoverClass]"
            :aria-hidden="isOpen ? 'false' : 'true'">
            <transition
                name="pendo-transition-slide-fade"
                appear>
                <div
                    v-if="isOpen"
                    :style="contentStyles"
                    :class="['pendo-picker-popper__content', `pendo-transition-slide-fade--direction-${direction}`]">
                    <slot
                        v-if="isPopperMounted"
                        name="popper" />
                    <ResizeObserver @notify="handleResize" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import Popper from 'popper.js';
import uuid from 'uuid';
import { ResizeObserver } from 'vue-resize';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import { setStyles, setAttributes } from '@/utils/dom';

export default {
    name: 'PickerPopper',
    components: {
        ResizeObserver
    },
    props: {
        fullWidth: {
            type: [Boolean, Number],
            default: false
        },
        isOpen: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        appendToBody: {
            type: Boolean
        },
        popperOptions: {
            type: Object
        },
        popoverClass: {
            type: [String, Object]
        },
        minWidth: {
            type: [String, Number]
        },
        maxWidth: {
            type: [String, Number]
        },
        maxHeight: {
            type: [String, Number]
        }
    },
    data () {
        return {
            isPopperOpening: false,
            isPopperDisposed: false,
            isPopperMounted: false,
            isPopperClosing: false,
            id: uuid()
        };
    },
    computed: {
        contentStyles () {
            const styles = {};
            if (this.maxHeight) {
                if (typeof this.maxHeight === 'string') {
                    styles.maxHeight = this.maxHeight;
                } else {
                    styles.maxHeight = `${this.maxHeight}px`;
                }
            }

            if (!this.fullWidth && this.maxWidth) {
                if (typeof this.maxWidth === 'string') {
                    styles.maxWidth = this.maxWidth;
                } else {
                    styles.maxWidth = `${this.maxWidth}px`;
                }
            }

            return styles;
        },
        popperTarget () {
            if (this.appendToBody) {
                return window.document.body;
            }

            return this.$refs.reference.parentNode;
        },
        direction () {
            const { placement } = this.popperOptions;
            if (placement) {
                return placement.split('-')[0];
            }

            return 'bottom';
        },
        referenceStyle () {
            if (this.fullWidth) {
                return { display: 'block' };
            }

            return { display: 'inline-block' };
        }
    },
    watch: {
        isOpen (val) {
            if (val) {
                this.showPopper();
            } else {
                window.removeEventListener('mousedown', this.handleClickOutside, true);
                this.hidePopper();
                this.isPopperClosing = true;
                requestAnimationFrame(() => {
                    this.isPopperClosing = false;
                });
            }
        },
        disabled () {
            if (this.disabled && this.isOpen) {
                this.hidePopper();
            }
        },
        popperOptions: {
            handler (oldValue, newValue) {
                if (isEqual(oldValue, newValue)) {
                    return;
                }
                this.restartPopper();
            },
            deep: true
        }
    },
    mounted () {
        const { popper } = this.$refs;
        if (popper.parentNode) {
            popper.parentNode.removeChild(popper);
        }

        if (this.isOpen) {
            this.showPopper();
        }
    },
    beforeDestroy () {
        window.removeEventListener('mousedown', this.handleClickOutside, true);
        this.destroyPopper();
    },
    methods: {
        createPopper () {
            const { reference, popper } = this.$refs;

            if (this.popperInstance) {
                this.popperInstance.enableEventListeners();
                this.scheduleUpdate();
            }

            if (!this.isPopperMounted) {
                this.popperTarget.appendChild(popper);
                this.isPopperMounted = true;
            }

            if (!this.popperInstance) {
                const popperOptions = {
                    onUpdate: this.handleUpdate,
                    ...this.popperOptions,
                    placement: this.popperOptions.placement || 'bottom-start',
                    modifiers: {
                        ...this.popperOptions.modifiers,
                        offset: {
                            ...(this.popperOptions.modifiers && this.popperOptions.modifiers.offset),
                            offset: this.popperOptions.offset || '0, 8'
                        },
                        preventOverflow: {
                            escapeWithReference: true,
                            ...(this.popperOptions.modifiers && this.popperOptions.modifiers.preventOverflow)
                        },
                        flip: {
                            enabled: true,
                            flipVariationsByContent: true,
                            behavior: ['bottom', 'top', 'bottom'],
                            ...(this.popperOptions.modifiers &&
                                (this.popperOptions.modifiers.flip || this.popperOptions.modifiers.flipVariations))
                        },
                        applyStyle: {
                            enabled: true,
                            order: 900,
                            fn: this.applyPopperStyle,
                            ...(this.popperOptions.modifiers && this.popperOptions.modifiers.applyStyle)
                        }
                    }
                };

                this.popperInstance = new Popper(reference, popper, popperOptions);

                // Fix position

                requestAnimationFrame(() => {
                    if (!this.isPopperDisposed && this.popperInstance) {
                        this.scheduleUpdate();
                    } else {
                        this.destroyPopper();
                    }
                });
            }
        },
        showPopper () {
            this.createPopper();
            window.addEventListener('mousedown', this.handleClickOutside, true);

            this.isPopperOpening = true;
            requestAnimationFrame(() => {
                this.isPopperOpening = false;
                this.scheduleUpdate();
            });
        },
        hidePopper () {
            if (!this.isOpen) {
                return;
            }

            if (this.popperInstance) {
                this.popperInstance.disableEventListeners();
            }
        },
        destroyPopper () {
            this.isPopperDisposed = true;
            this.hidePopper();

            if (this.popperInstance) {
                this.popperInstance.destroy();

                if (!this.popperInstance.options.removeOnDestroy) {
                    const { popper } = this.$refs;
                    if (popper.parentNode) {
                        popper.parentNode.removeChild(popper);
                    }
                }
            }

            this.isPopperMounted = false;
            this.popperInstance = null;

            this.$emit('dispose');
        },
        restartPopper () {
            if (this.popperInstance) {
                this.destroyPopper();
                this.isPopperDisposed = false;

                if (this.isOpen) {
                    this.showPopper();
                }
            }
        },
        handleClickOutside (event) {
            if (!this.isOpen || this.isPopperOpening) {
                return;
            }

            const { popper, reference } = this.$refs;

            if (!popper || !reference) {
                return;
            }

            if (!popper.contains(event.target) && !reference.contains(event.target)) {
                this.$emit('click-outside', event);
            }
        },
        scheduleUpdate () {
            if (this.popperInstance) {
                this.popperInstance.scheduleUpdate();
            }
        },
        applyPopperStyle (data) {
            data.styles.minWidth = Math.max(this.minWidth, data.offsets.reference.width);

            if (this.fullWidth) {
                const width = clamp(this.minWidth, data.offsets.reference.width, this.maxWidth);
                data.styles.width = width;
            }

            if (!this.isPopperClosing) {
                setStyles(data.instance.popper, data.styles);
            }
            setAttributes(data.instance.popper, data.attributes);

            return data;
        },
        handleResize () {
            this.$emit('resize', this.popperInstance);
            if (this.isOpen) {
                this.scheduleUpdate();
            }
        },
        handleUpdate (data) {
            if (data.hide) {
                this.$emit('hide');
                this.popperInstance.disableEventListeners();
            }
        }
    }
};
</script>

<style>
.resize-observer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    pointer-events: none;
    display: block;
    overflow: hidden;
    opacity: 0;
}
.resize-observer object {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
}
</style>

<style lang="scss">
@include block(pendo-picker-popper) {
    @include element(popper) {
        box-sizing: border-box;
        z-index: 10001;
        border-radius: $border-radius-3;
        box-shadow: $box-shadow-light;
        border: 1px solid $color-gray-40;
        background-color: $color-white;
        overflow: hidden;

        @include font-base;
    }
}

.pendo-picker-popper__content-wrapper {
    position: absolute;
    display: block;
    outline: none;
    z-index: 10001;
    @include font-base;
}

.pendo-picker-popper__content {
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    z-index: 10001;
    border-radius: $border-radius-3;
    box-shadow: $box-shadow-light;
    border: 1px solid $color-gray-30;
    background-color: $color-white;
    overflow: hidden;

    @include font-base;
}

.pendo-transition-slide-fade-enter-active,
.pendo-transition-slide-fade-leave-active {
    transition: 150ms;
    pointer-events: none;
}

.pendo-transition-slide-fade-enter,
.pendo-transition-slide-fade-leave-to {
    opacity: 0;
    will-change: transform;

    &.pendo-transition-slide-fade--direction-bottom {
        transform-origin: top;
        transform: translate3d(0, -8px, 0);
    }

    &.pendo-transition-slide-fade--direction-top {
        transform-origin: top;
        transform: translate3d(0, 8px, 0);
    }

    &.pendo-transition-slide-fade--direction-left {
        transform-origin: right;
        transform: translate3d(8px, 0, 0);
    }

    &.pendo-transition-slide-fade--direction-right {
        transform-origin: left;
        transform: translate3d(-8px, 0, 0);
    }
}
</style>
