<template>
    <div
        class="pendo-tag-group"
        :class="{
            'pendo-tag-group--column': column
        }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PendoTagGroup',
    props: {
        column: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-tag-group) {
    display: flex;

    .pendo-tag {
        margin: $tag-group-margin;

        + .pendo-tag {
            margin-left: 0px;
        }
    }

    @include modifier(column) {
        white-space: normal;
        flex-wrap: wrap;
        max-width: 100%;
    }
}
</style>
