<template>
    <div
        class="pendo-table__title"
        :style="$table.scrollStore.pageMode && { top: `${$table.scrollStore.stickyElementPositions.title}px` }">
        <slot name="header">
            <slot name="headerLeft">
                <slot name="headerPrefix" />
                <div class="pendo-table__title-info">
                    <h3
                        :id="$table.tableTitleId"
                        class="pendo-table__title-text">
                        {{ $table.tableTitle }}
                    </h3>
                    <div
                        v-pendo-loading:material="{
                            loading: $table.loading,
                            spinnerProps: {
                                color: '#3a3c45',
                                background: '#FFFFFF',
                                size: 16
                            }
                        }"
                        class="pendo-table__active-count">
                        <span v-if="$table.data && $table.data.length">
                            {{ activeCountText }}
                        </span>
                    </div>
                    <div
                        v-if="showClearSelectedAction"
                        class="pendo-table__clear-selected pendo-table__link"
                        @click="$table.clearSelected">
                        Clear
                    </div>
                </div>
            </slot>
            <slot name="headerRight">
                <div class="pendo-table__title-actions">
                    <slot name="headerActions" />
                    <pendo-icon-button
                        v-if="$table.csvDownload"
                        tooltip="Download CSV"
                        class="pendo-table__title-action pendo-table__title-action--download"
                        icon="download"
                        icon-size="16"
                        @click="$table.downloadTableData" />
                    <pendo-icon-button
                        v-if="$table.manageColumns"
                        :tooltip="manageColumnsTooltip"
                        class="pendo-table__title-action pendo-table__title-action--columns"
                        icon="columns"
                        icon-size="16"
                        @click="$table.showManageColumnsModal" />
                    <template v-if="$table.collapsible">
                        <pendo-divider
                            direction="vertical"
                            width="16px"
                            height="24px"
                            stroke="#DADCE5" />
                        <pendo-icon-button
                            :tooltip="$table.collapseTooltipContent"
                            class="pendo-table__title-action pendo-table__title-action--collapse"
                            icon="chevron-up"
                            icon-size="16"
                            :aria-expanded="`${!$table.collapsed}`"
                            :aria-label="$table.collapsed ? 'Show table content' : 'Hide table content'"
                            :aria-controls="`pendo-table__content-${$table.id}`"
                            @click="$table.toggleTableCollapse" />
                    </template>
                </div>
            </slot>
        </slot>
    </div>
</template>

<script>
import PendoLoading from '@/directives/loading/pendo-loading';
import PendoDivider from '@/components/divider/pendo-divider';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIconButton from '@/components/icon-button/pendo-icon-button';

export default {
    name: 'TableTitle',
    components: {
        PendoIconButton,
        PendoDivider
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    inject: ['$table'],
    computed: {
        showClearSelectedAction () {
            if (this.$table.checkboxConfig.selectAll === false) {
                return this.$table.isIndeterminate || this.$table.isAllSelected;
            }

            return false;
        },
        activeCountText () {
            const availableCount = this.$table.allRowsDisplayCount;

            if (this.$table.isIndeterminate || this.$table.isAllSelected) {
                const selectedCount = this.$table.getSelectedRows().length;

                return `(${selectedCount} of ${availableCount} selected)`;
            }

            return `(${availableCount})`;
        },
        manageColumnsTooltip () {
            return this.$table.manageColumnsConfig.title || 'Manage columns';
        }
    }
};
</script>
