<template>
    <div
        v-if="!lazy || active"
        v-show="active"
        :id="`pendo-tab-panel-${prop}`"
        tabindex="-1"
        :aria-disabled="String(disabled)"
        :aria-hidden="String(!active)"
        :aria-labelledby="`pendo-tab-${prop}`"
        class="pendo-tab-panel"
        role="tabpanel">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PendoTabPanel',
    props: {
        prop: {
            type: String,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        },
        lazy: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    }
};
</script>
