<template>
    <pendo-modal
        class="pendo-agent-settings-modal"
        :visible="visible"
        :min-width="600"
        height="auto"
        :title="title"
        esc-to-close
        @close="closeModal">
        <template #default>
            <div class="pendo-agent-settings-modal__header">
                Agent Version Settings
            </div>
            <div class="pendo-agent-settings-modal__subheader">
                Select which version of the agent you would like to deploy.
                <a
                    href="https://support.pendo.io/hc/en-us/articles/360031832152-Agent-2-0-FAQ#what-is-the-difference-between-beta-stable-and-manual"
                    target="_blank">Learn more</a>
            </div>
            <pendo-radio-group
                v-model="agentPolicy"
                class="pendo-agent-settings-modal__agent-policy">
                <pendo-radio value="beta">
                    <template #default>
                        <b>Beta:</b> Automatically deploy newest Beta version of the agent
                        <span
                            v-if="description === 'staging'"
                            class="pendo-agent-settings-modal__light-text">(Recommended)</span>
                    </template>
                </pendo-radio>
                <pendo-radio value="stable">
                    <template #default>
                        <b>Stable:</b> Automatically deploy newest stable version of the agent
                        <span
                            v-if="description === 'production'"
                            class="pendo-agent-settings-modal__light-text">(Recommended)</span>
                    </template>
                </pendo-radio>
                <pendo-radio value="manual">
                    <template #default>
                        <b>Manual:</b> Manually select and deploy a specific version of the agent
                    </template>
                </pendo-radio>
            </pendo-radio-group>
            <div
                v-if="agentPolicy === 'manual'"
                class="pendo-agent-settings-modal__agent-selection">
                <pendo-alert
                    v-if="agentVersionIsDeprecated && !selectedAgentVersion"
                    type="error"
                    class="pendo-agent-settings-modal__agent-selection--deprecated">
                    <template #default>
                        Agent {{ agentVersion }} is deprecated. Update to a newer version for the best experience.
                    </template>
                </pendo-alert>
                <pendo-multiselect
                    placeholder="Select an agent version"
                    :value="selectedAgentVersion"
                    :full-width="true"
                    :allow-empty="false"
                    :options="agentVersions"
                    @select="changeAgentVersion" />
            </div>
            <div class="pendo-agent-settings-modal__agent-type">
                <div class="pendo-agent-settings-modal__header">
                    Agent Compatibility Settings
                </div>
                <div class="pendo-agent-settings-modal__subheader">
                    Pendo lets you choose an Agent to best meet your app's requirements.
                    <a
                        href="https://support.pendo.io/hc/en-us/articles/360031832152-Agent-2-0-FAQ#what-is-jsonp-and-xhr-which-should-i-use"
                        target="_blank">Learn more</a>
                </div>
                <pendo-radio-group
                    v-model="agentType"
                    class="pendo-agent-settings-modal__agent-type-selections">
                    <pendo-radio
                        v-pendo-tooltip="{
                            placement: 'auto-start',
                            content: agentSupportsLightning ? null : 'Upgrade to agent v2.11.3 or greater to access'
                        }"
                        :disabled="!isStandardAgentDisabled"
                        value="lightning">
                        <template #default>
                            <b>Standards:</b> Uses modern browser standards and APIs without relying on JavaScript
                            polyfills.
                            <span class="pendo-agent-settings-modal__light-text">(Recommended)</span>
                            <div class="pendo-agent-settings-modal__light-text">
                                <i>Note: If your app is a Salesforce Lightning application then you must download and
                                    host this version of the Pendo agent.</i>
                            </div>
                            <div>
                                <pendo-button
                                    class="pendo-agent-settings-modal__download"
                                    type="link"
                                    size="mini"
                                    label="Download"
                                    :disabled="!agentSupportsLightning"
                                    @click="
                                        downloadAgent({
                                            agentType: 'lightning',
                                            description: agentSettings.description
                                        })
                                    ">
                                    Download
                                </pendo-button>
                                <span class="pendo-agent-settings-modal__download-optional"> (optional)</span>
                            </div>
                        </template>
                    </pendo-radio>
                    <pendo-radio value="xhr">
                        <template #default>
                            <b>XHR:</b> GET request sent via Ajax request. More secure, usable by most browsers
                            including back to IE9+.
                            <div>
                                <pendo-button
                                    class="pendo-agent-settings-modal__download"
                                    type="link"
                                    size="mini"
                                    label="Download"
                                    @click="downloadAgent({ agentType: 'xhr', description: agentSettings.description })" />
                                <span class="pendo-agent-settings-modal__download-optional"> (optional)</span>
                            </div>
                        </template>
                    </pendo-radio>
                    <pendo-radio value="jsonp">
                        <template #default>
                            <b>JSONP:</b> GET request that adds a
                            <span class="pendo-agent-settings-modal__code">&lt;script&gt;</span>
                            tag to the
                            <span class="pendo-agent-settings-modal__code">&lt;body&gt;</span>; recommended for IE8 and
                            below.
                            <div>
                                <pendo-button
                                    class="pendo-agent-settings-modal__download"
                                    type="link"
                                    size="mini"
                                    label="Download"
                                    @click="
                                        downloadAgent({ agentType: 'jsonp', description: agentSettings.description })
                                    " />
                                <span class="pendo-agent-settings-modal__download-optional"> (optional)</span>
                            </div>
                            <pendo-alert
                                v-if="agentType === 'jsonp' && hasGuideValidation"
                                type="warning">
                                <template #default>
                                    <strong>Content validation</strong> is enabled for your subscription.<br>
                                    Guides will not display in IE8 and below.
                                </template>
                            </pendo-alert>
                        </template>
                    </pendo-radio>
                </pendo-radio-group>
            </div>
        </template>
        <template #footer>
            <div class="pendo-agent-settings-modal__footer">
                <div class="pendo-agent-settings-modal__footer-left">
                    <pendo-button
                        theme="app"
                        type="secondary"
                        label="Use default settings"
                        @click="resetToDefaults" />
                </div>
                <div class="pendo-agent-settings-modal__footer-right">
                    <pendo-button
                        theme="app"
                        type="secondary"
                        label="Cancel"
                        @click="closeModal" />
                    <pendo-button
                        theme="app"
                        :disabled="!isSaveEnabled"
                        :loading="isSaveLoading"
                        type="primary"
                        label="Save"
                        @click="saveAgentSettings" />
                </div>
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoRadio from '@/components/radio/pendo-radio.vue';
import PendoRadioGroup from '@/components/radio-group/pendo-radio-group.vue';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoAlert from '@/components/alert/pendo-alert.vue';
import startCase from 'lodash/startCase';

export default {
    name: 'AgentSettingsModal',
    components: {
        PendoModal,
        PendoButton,
        PendoRadio,
        PendoRadioGroup,
        PendoMultiselect,
        PendoAlert
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        agentVersions: {
            type: Array,
            default: () => []
        },
        agentSettings: {
            type: Object,
            default: () => ({})
        },
        isSaveLoading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            description: this.agentSettings.description,
            agentPolicy: this.agentSettings.agentPolicy,
            agentVersion: this.agentSettings.agentVersion,
            agentType: this.agentSettings.agentType,
            hasGuideValidation: this.agentSettings.hasGuideValidation,
            agentSupportsLightning: this.agentSettings.agentSupportsLightning,
            agentVersionIsDeprecated: this.agentSettings.agentVersionIsDeprecated,
            isStandardAgentDisabled: this.agentSettings.agentSupportsLightning
        };
    },
    computed: {
        title () {
            return `${startCase(this.agentSettings.description)} Settings`;
        },
        selectedAgentVersion () {
            return this.agentVersions.find(({ value }) => value === this.agentVersion);
        },
        selectionsHaveChanged () {
            return (
                this.agentPolicy !== this.agentSettings.agentPolicy ||
                this.agentVersion !== this.agentSettings.agentVersion ||
                this.agentType !== this.agentSettings.agentType
            );
        },
        isSaveEnabled () {
            return this.selectionsHaveChanged && (this.agentPolicy === 'manual' ? !!this.selectedAgentVersion : true);
        }
    },
    watch: {
        agentSettings (newValue) {
            this.description = newValue.description;
            this.agentPolicy = newValue.agentPolicy;
            this.agentVersion = newValue.agentVersion;
            this.agentType = newValue.agentType;
            this.hasGuideValidation = newValue.hasGuideValidation;
            this.agentSupportsLightning = newValue.agentSupportsLightning;
            this.agentVersionIsDeprecated = newValue.agentVersionIsDeprecated;
            this.isStandardAgentDisabled = newValue.agentSupportsLightning;
        }
    },
    methods: {
        closeModal () {
            this.resetSelections();
            this.$emit('close');
        },
        resetSelections () {
            this.agentPolicy = this.agentSettings.agentPolicy;
            this.agentVersion = this.agentSettings.agentVersion;
            this.agentType = this.agentSettings.agentType;
        },
        changeAgentVersion ({ value }) {
            this.agentVersion = value;
        },
        resetToDefaults () {
            this.agentType = 'lightning';
            if (!this.agentSupportsLightning) {
                this.isStandardAgentDisabled = true;
            }
            if (this.description === 'staging') {
                this.agentPolicy = 'beta';
            } else {
                this.agentPolicy = 'stable';
            }
        },
        saveAgentSettings () {
            this.$emit('save', {
                agentPolicy: this.agentPolicy,
                agentVersion: this.agentVersion,
                agentType: this.agentType,
                description: this.description
            });
        },
        downloadAgent ({ agentType, description }) {
            this.$emit('downloadAgent', { agentType, description });
        }
    }
};
</script>

<style lang="scss" scoped>
@include block(pendo-agent-settings-modal) {
    @include element(header) {
        font-size: 18px;
        color: $color-gray-100;
    }
    @include element(subheader) {
        color: $color-gray-70;
        margin-bottom: 15px;
    }
    @include element(agent-policy) {
        padding-left: 40px;
    }
    @include element(agent-selection) {
        @include modifier(deprecated) {
            margin-bottom: 15px;
        }
        padding-left: 40px;
        margin-top: 15px;
    }
    @include element(light-text) {
        color: $color-gray-70;
    }
    @include element(code) {
        padding: 2px 4px;
        color: #c7254e;
        font-size: 90%;
        background-color: #f9f2f4;
        border-radius: 4px;
        font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    @include element(download-optional) {
        color: $color-gray-70;
        font-size: 12px;
    }
    @include element(agent-type) {
        margin-top: 30px;
    }
    @include element(agent-type-selections) {
        padding-left: 40px;
    }
    @include element(footer) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
