<template>
    <pendo-card
        ref="pendoCard"
        class="pendo-guide-alerts-top-stats"
        :body-min-height="hasLoadingError ? null : '0'"
        title="Top Stats"
        collapsible
        @collapse-change="saveCollapseState">
        <pendo-status-overlay
            v-if="hasLoadingError"
            state="rejected" />
        <div
            v-else
            class="top-stats__inner">
            <div class="stat-card">
                <div class="stat-card__stat stat-card__stat--not-reviewed-alerts">
                    <pendo-metric
                        data-cy="not-reviewed-errors-count"
                        :value="notReviewedAlerts"
                        label="Not Reviewed"
                        :loading="isLoading" />
                </div>
                <div class="stat-card__stat stat-card__stat--triage-alerts">
                    <pendo-metric
                        data-cy="triage-errors-count"
                        :value="triageAlerts"
                        label="Triage"
                        :loading="isLoading" />
                </div>
                <div class="stat-card__stat stat-card__stat--resolved-alerts">
                    <pendo-metric
                        data-cy="resolved-errors-count"
                        :value="resolvedAlerts"
                        label="Resolved"
                        :loading="isLoading" />
                </div>
            </div>
        </div>
    </pendo-card>
</template>

<script>
import PendoCard from '@/components/card/pendo-card';
import PendoMetric from '@/components/metric/pendo-metric.vue';
import PendoStatusOverlay from '@/composites/status-overlay/pendo-status-overlay.vue';
import forIn from 'lodash/forIn';
import get from 'lodash/get';

export default {
    name: 'TopStats',
    components: {
        PendoCard,
        PendoMetric,
        PendoStatusOverlay
    },
    props: {
        topStats: {
            type: Array,
            required: true
        },
        topStatsConfig: {
            type: Object,
            default: () => {}
        },
        guideAlertsAggStatus: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            statsData: {},
            notReviewed: [],
            triage: [],
            resolved: []
        };
    },
    computed: {
        notReviewedAlerts () {
            if (!this.isLoading) {
                return this.notReviewed.length;
            }

            return;
        },
        triageAlerts () {
            if (!this.isLoading) {
                return this.triage.length;
            }

            return;
        },
        resolvedAlerts () {
            if (!this.isLoading) {
                return this.resolved.length;
            }

            return;
        },
        hasLoadingError () {
            return this.guideAlertsAggStatus === 'rejected';
        },
        isLoading () {
            return this.guideAlertsAggStatus === 'loading';
        }
    },
    watch: {
        topStats: {
            deep: true,
            immediate: true,
            handler (data) {
                this.statsData = data;
                this.notReviewed = [];
                this.triage = [];
                this.resolved = [];

                forIn(this.statsData, (alert) => {
                    switch (alert.alertStatus) {
                        case 'NotReviewed':
                            this.notReviewed.push(alert);
                            break;
                        case 'Triage':
                            this.triage.push(alert);
                            break;
                        case 'Resolved':
                            this.resolved.push(alert);
                            break;
                    }
                });
            }
        }
    },
    mounted () {
        const collapsed = get(this.topStatsConfig, 'value.collapsed', false);
        if (collapsed) {
            this.$refs.pendoCard.collapseCard();
        }
    },
    methods: {
        saveCollapseState (state) {
            const settings = {
                name: 'displayAlertTopStats',
                namespaced: true,
                value: state,
                forced: true
            };
            this.$emit('update-user-settings', settings);
        }
    }
};
</script>

<style lang="scss" scoped>
@include block(pendo-guide-alerts-top-stats) {
    margin-top: 16px;

    .stat-card {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        border: none;
    }

    .stat-card__stat {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 160px;
    }

    .top-stats__inner {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
