<template>
    <div class="pendo-guide-metrics-drawer-content">
        <div class="pendo-guide-metrics-drawer-content-body">
            <pendo-tabs
                v-model="activeTab"
                :tabs="tabs">
                <template #label="{ tab }">
                    <div class="pendo-guide-metrics-drawer-content-body__tab-label">
                        {{ tab.label }} ({{ tab.data.length }})
                    </div>
                </template>
                <pendo-tab-panel
                    v-for="tab in tabs"
                    :key="tab.prop"
                    :prop="tab.prop"
                    :label="tab.label"
                    lazy
                    :active="tab.prop === activeTab">
                    <pendo-table
                        :loading="loading"
                        borderless
                        condensed
                        auto-height
                        csv-download
                        :auto-height-offset="68"
                        :data="tab.data"
                        :columns="tableColumns">
                        <template #status>
                            <pendo-status-overlay :state="tab.aggStatus" />
                        </template>
                    </pendo-table>
                </pendo-tab-panel>
            </pendo-tabs>
        </div>
        <div class="pendo-guide-metrics-drawer-content-footer">
            <pendo-button
                :block="!showCreateSegment"
                theme="app"
                :class="{ 'pendo-guide-metrics-drawer-content-footer__button': showCreateSegment }"
                prefix-icon="download"
                type="secondary"
                label="Download CSV"
                @click="downloadCsv" />
            <pendo-button
                v-if="showCreateSegment"
                theme="app"
                :class="{ 'pendo-guide-metrics-drawer-content-footer__button': showCreateSegment }"
                prefix-icon="plus"
                type="primary"
                label="Create Segment"
                @click="createSegment" />
        </div>
        <pendo-csv-modal
            :visible="showCsvModal"
            :status="csvModalStatus"
            :url="csvUrl"
            @close="showCsvModal = false" />
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoTabs from '@/components/tabs/pendo-tabs.vue';
import PendoTabPanel from '@/components/tabs/pendo-tab-panel.vue';
import PendoCsvModal from '@/composites/csv-modal/pendo-csv-modal.vue';
import PendoStatusOverlay from '@/composites/status-overlay/pendo-status-overlay.vue';
import get from 'lodash/get';

export default {
    name: 'PendoGuideMetricsDrawerContent',
    components: {
        PendoButton,
        PendoTable,
        PendoTabs,
        PendoTabPanel,
        PendoCsvModal,
        PendoStatusOverlay
    },
    props: {
        /**
         * Denotes what agg is used for downloading a csv file and is emitted on 'download-csv' events.
         * Useful for when a single pendo-side-panel-drawer instance is used to download more than 1 csv.
         */
        csvDownloadAggId: {
            type: String,
            default: ''
        },
        /**
         * Data object with visitors and accounts. Format shown in the usage above.
         */
        data: {
            type: Object,
            default: () => ({ accounts: [], visitors: [] })
        },
        /**
         * Shows the loading indicator if `true`
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * Status for the csv modal: `loading`, `resolved`, `rejected`
         */
        csvModalStatus: {
            type: String,
            default: 'loading'
        },
        /**
         * Link to download the csv
         */
        csvUrl: {
            type: String,
            default: ''
        },
        /**
         * Base link to the visitor details page (excluding the id). i.e.: `/visitor`
         */
        visitorUrl: {
            type: String,
            default: ''
        },
        /**
         * Base link to the visitor details page (excluding the id). i.e.: `/account`
         */
        accountUrl: {
            type: String,
            default: ''
        },
        /**
         * Shows the create segment button if `true`
         */
        showCreateSegment: {
            type: Boolean,
            default: false
        },
        /**
         * Shows the create accounts tab if `true`
         */
        showAccountsTab: {
            type: Boolean,
            default: true
        },
        /**
         * Shows the clicks column if `true`
         */
        showClicksColumn: {
            type: Boolean,
            default: false
        },
        /**
         * Shows the pendoOverlayStatus if One of `loading`, `rejected`, `empty`. Otherwise, overlay renders nothing.
         */
        aggStatus: {
            type: Object,
            default: () => ({ accounts: null, visitors: null })
        }
    },
    data () {
        return {
            activeTab: 'visitor',
            showCsvModal: false
        };
    },
    computed: {
        tabs () {
            return [
                {
                    label: 'Visitors',
                    prop: 'visitor',
                    data: get(this.data, 'visitors') || [],
                    aggStatus: get(this.aggStatus, 'visitors')
                },
                this.showAccountsTab && {
                    label: 'Accounts',
                    prop: 'account',
                    data: get(this.data, 'accounts') || [],
                    aggStatus: get(this.aggStatus, 'accounts')
                }
            ].filter(Boolean);
        },
        tableColumns () {
            return [
                this.activeTab === 'visitor' && {
                    href: (row) => {
                        if (!this.visitorUrl) {
                            return false;
                        }

                        if (row.href) {
                            return row.href;
                        }

                        return `${this.visitorUrl}/${encodeURIComponent(row.id)}`;
                    },
                    label: 'Visitor ID',
                    prop: 'id',
                    sortable: true,
                    target: '_blank',
                    type: 'link'
                },
                this.activeTab === 'account' && {
                    href: (row) => {
                        if (row.id === '(none)' || !this.accountUrl) {
                            return false;
                        }

                        if (row.href) {
                            return row.href;
                        }

                        return `${this.accountUrl}/${encodeURIComponent(row.id)}`;
                    },
                    label: 'Account ID',
                    prop: 'id',
                    sortable: true,
                    target: '_blank',
                    type: 'link'
                },
                this.showClicksColumn && {
                    label: 'Clicks',
                    prop: 'clicks',
                    sortable: true,
                    width: 120
                }
            ].filter(Boolean);
        }
    },
    methods: {
        downloadCsv () {
            this.showCsvModal = true;
            this.$emit('download-csv', {
                activeTab: this.activeTab,
                csvDownloadAggId: this.csvDownloadAggId
            });
        },
        createSegment () {
            this.$emit('create-segment', this.activeTab);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-guide-metrics-drawer-content) {
    display: grid;
    grid-template-rows: 1fr 68px;
    height: 100%;

    .pendo-tabs-nav {
        padding: 0 16px;
    }
}

@include block(pendo-guide-metrics-drawer-content-body) {
    height: inherit;
}

@include block(pendo-guide-metrics-drawer-content-footer) {
    height: 68px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    @include element(button) {
        flex: 1;
    }
}
</style>
