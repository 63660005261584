<template>
    <pendo-modal
        class="localization-modal"
        :visible="openModal && !!modalType"
        :title="modalTitle"
        height="auto"
        @close="closeModal"
        @opened="onModalOpen">
        <template #body>
            <pendo-alert
                v-if="serverErrorMessage"
                type="error"
                :title="serverErrorMessage" />
            <div
                v-if="isDeleteMode"
                class="delete-warning">
                <div><strong>Warning:</strong> This action cannot be undone.</div>
                <br>
                <p>&#x2022; Guides and resource centers will no longer display in this language.<br></p>
                <p>&#x2022; Guide metrics will no longer display in this language.</p>
            </div>
            <pendo-form
                v-if="!isDeleteMode"
                v-model="isFormValid"
                :model="form">
                <pendo-form-item
                    v-if="isAddMode"
                    prop="language"
                    :rules="[{ required: true, message: 'Please select a language', trigger: 'blur' }]">
                    <pendo-multiselect
                        :labels="{ top: 'Language' }"
                        :value="form.language"
                        :options="languageOptions"
                        :preselect-first="false"
                        value-key="code"
                        placeholder="Select language"
                        @select="selectLanguage">
                        <template #option="{ option }">
                            <div v-if="option.unsupportedAgent">
                                <slot
                                    name="unsupportedAgentOption"
                                    :option="option" />
                            </div>
                        </template>
                    </pendo-multiselect>
                </pendo-form-item>
                <div
                    v-if="isEditMode"
                    class="edit-language-name">
                    <span>Language</span>
                    <div>
                        {{ selectedLanguage.name }}
                    </div>
                </div>
                <slot
                    v-if="isAddMode && form.language.isRTL"
                    name="message">
                    <pendo-alert
                        class="add-language-alert"
                        :description="addLanguageAlert" />
                </slot>
                <span
                    v-else
                    class="info">
                    Adding a language will make it available for translation on all guides and resource centers.
                </span>
                <pendo-form-item
                    prop="metadataValue"
                    label="Language Metadata Value"
                    :rules="editMetaDataRules">
                    <pendo-input
                        v-model="form.metadataValue"
                        :disabled="isAddMode && !form.language"
                        @change="trimMetadataValue" />
                </pendo-form-item>
                <span class="info">
                    Please enter the metadata that identifies this language in your app.
                </span>
            </pendo-form>
        </template>
        <template #footer>
            <pendo-button
                type="secondary"
                theme="app"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                theme="app"
                :label="saveChangesLabel"
                :loading="isProcessingChange"
                :type="isDeleteMode ? 'danger' : 'primary'"
                :disabled="!isDeleteMode && !isFormValid"
                @click="handleClick" />
        </template>
    </pendo-modal>
</template>

<script>
import PendoAlert from '@/components/alert/pendo-alert.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoForm from '@/components/form/pendo-form.vue';
import PendoFormItem from '@/components/form/pendo-form-item.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect.vue';
import invert from 'lodash/invert';
import get from 'lodash/get';

export const MODAL_TYPES = {
    add: 'add',
    edit: 'edit',
    delete: 'delete'
};

export default {
    components: {
        PendoAlert,
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoModal,
        PendoMultiselect
    },
    props: {
        appId: {
            type: Number,
            required: true
        },
        selectedLanguage: {
            type: Object,
            default: () => {}
        },
        modalType: {
            type: String,
            required: true
        },
        isProcessingChange: {
            type: Boolean,
            default: false
        },
        openModal: {
            type: Boolean,
            required: true
        },
        existingLanguageMappings: {
            type: Object,
            default: () => {}
        },
        languageMapping: {
            type: Object,
            required: true
        },
        serverErrorMessage: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            isFormValid: false,
            editMetaDataRules: [
                {
                    required: true,
                    message: 'This is a required field.',
                    trigger: 'blur'
                },
                {
                    validator: this.validateUniqueMetadataValue,
                    trigger: ['change', 'blur']
                }
            ],
            form: {
                language: '',
                metadataValue: ''
            },
            addLanguageAlert:
                'Localization for Hebrew / Arabic is currently only supported by the Resource Center. Please Note that translating to these languages will mirror the RC behavior (buttons etc.)'
        };
    },
    computed: {
        enabledLanguageMap () {
            return Object.values(this.languageMap).reduce((map, language) => {
                if (language.metadataValue) {
                    map[language.metadataValue] = language.name;
                }

                return map;
            }, {});
        },
        languageMap () {
            const metadataMap = invert(this.existingLanguageMappings);

            return Object.entries(this.languageMapping).reduce(
                (map, [languageCode, { name, isDeprecated, isRTL, unsupportedAgent, minAgent }]) => {
                    if (!isDeprecated) {
                        map[languageCode] = {
                            name,
                            code: languageCode,
                            metadataValue: metadataMap[languageCode] || '',
                            ...(isRTL && { isRTL }),
                            ...(unsupportedAgent && { unsupportedAgent }),
                            ...(minAgent && { minAgent })
                        };
                    }

                    return map;
                },
                {}
            );
        },
        languageOptions () {
            return Object.entries(this.languageMap).map(([languageCode, currentLanguage]) => {
                const { name, metadataValue, isRTL, unsupportedAgent = false, minAgent } = currentLanguage;

                return {
                    code: languageCode,
                    label: `${name} (${languageCode})`,
                    disabled: !!metadataValue || unsupportedAgent,
                    ...(isRTL && { isRTL }),
                    ...(unsupportedAgent && { unsupportedAgent }),
                    ...(minAgent && { minAgent })
                };
            });
        },
        // eslint-disable-next-line vue/return-in-computed-property
        modalTitle () {
            switch (this.modalType) {
                case MODAL_TYPES.add:
                    return 'Add Language';
                case MODAL_TYPES.edit:
                    return 'Edit Language';
                case MODAL_TYPES.delete:
                    return `Delete ${this.selectedLanguage.name}?`;
            }
        },
        // eslint-disable-next-line vue/return-in-computed-property
        saveChangesLabel () {
            switch (this.modalType) {
                case MODAL_TYPES.add:
                    return 'Add';
                case MODAL_TYPES.edit:
                    return 'Save Changes';
                case MODAL_TYPES.delete:
                    return 'Delete Language';
            }
        },
        isEditMode () {
            return this.modalType === MODAL_TYPES.edit;
        },
        isAddMode () {
            return this.modalType === MODAL_TYPES.add;
        },
        isDeleteMode () {
            return this.modalType === MODAL_TYPES.delete;
        }
    },
    methods: {
        onModalOpen () {
            if (!this.isEditMode) {
                return;
            }
            this.form.metadataValue = get(this.selectedLanguage, 'metadataValue', '');
        },
        handleClick () {
            if (this.isDeleteMode) {
                this.deleteLanguage();

                return;
            }

            this.updateLanguage();
        },
        updateLanguage () {
            const { language, metadataValue } = this.form;
            const code = this.isEditMode ? this.selectedLanguage.code : language.code;
            this.closeModal();
            this.$emit('update-language', { appId: this.appId, code, metadataValue });
        },
        deleteLanguage () {
            this.$emit('delete-language', { appId: this.appId, langCode: this.selectedLanguage.code });
        },
        closeModal () {
            if (!this.isDeleteMode) {
                this.form = {
                    language: '',
                    metadataValue: ''
                };
            }
            this.$emit('close-language-modal');
        },
        trimMetadataValue (value) {
            this.form.metadataValue = value.trim();
        },
        validateUniqueMetadataValue (rule, value, callback) {
            if (!value) {
                return;
            }
            const existingLanguage = this.enabledLanguageMap[value.trim()];
            if (existingLanguage && value !== this.selectedLanguage.metadataValue) {
                callback(new Error(`Metadata value already in use for ${existingLanguage}`));
            } else {
                callback();
            }
        },
        selectLanguage (newLang) {
            this.form.language = newLang;
            this.form.metadataValue = newLang.code.replace('-', '_');
        }
    }
};
</script>
<style lang="scss" scoped>
.info {
    font-size: 14px;
    color: $color-text-secondary;
    margin-bottom: 16px;
    display: block;
}

.edit-language-name {
    margin-bottom: 16px;
}

.add-language-alert {
    margin-bottom: 16px;
}
</style>
