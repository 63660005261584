<template>
    <div>
        <div class="pendo-env-settings">
            <pendo-card
                v-for="env in envs"
                :key="env.description"
                :class="`pendo-env-settings__environment-card--${env.description}`"
                width="auto"
                body-min-height="100px"
                footer-min-height="50px"
                :title="displayTitle(env)">
                <template
                    #headerRight
                    v-if="env.description === 'production' || env.hasStagingServers">
                    <pendo-button
                        v-if="enableLaunchDebugger"
                        theme="app"
                        type="secondary"
                        label="Debug"
                        icon="tool"
                        @click="$emit('launchDebugger', env.description)" />
                    <pendo-button
                        theme="app"
                        type="secondary"
                        icon="settings"
                        label="Settings"
                        @click="openAgentSettingsModal(env)" />
                </template>
                <template #default>
                    <aside
                        v-if="env.description === 'staging' && !env.hasStagingServers"
                        class="pendo-env-settings-no-staging">
                        <div class="pendo-env-settings-no-staging__title">
                            No staging servers set up
                        </div>
                        <div class="pendo-env-settings-no-staging__subtitle">
                            You currently don't have your staging environment set up.
                        </div>
                        <pendo-button
                            theme="app"
                            type="primary"
                            label="Set Up Staging"
                            @click="$emit('routeToStaging')" />
                    </aside>
                    <section v-else>
                        <div class="pendo-agent-settings">
                            <div class="pendo-agent-settings__agent-version">
                                <strong>Version {{ env.agentVersion }}</strong> Released {{ env.agentReleaseDate }}
                            </div>
                            <div class="pendo-agent-settings__beta-stable-manual">
                                <template v-if="env.agentVersionIsDeprecated">
                                    <pendo-icon
                                        type="alert-circle-solid"
                                        fill="#DB1111"
                                        stroke="#fff"
                                        stroke-width="3"
                                        size="18" />
                                    {{ deprecatedAgentVersionText(env) }}
                                </template>
                                <template v-else-if="env.agentPolicy === 'manual'">
                                    <pendo-icon
                                        type="alert-circle-solid"
                                        fill="#DBA211"
                                        stroke="#fff"
                                        size="18" />
                                    You may not have the latest version of the agent
                                </template>
                                <template v-else>
                                    <pendo-icon
                                        type="check-circle-2-solid"
                                        fill="#00C583"
                                        stroke="white"
                                        size="18" />
                                    {{ displayAgentPolicy(env) }}
                                </template>
                            </div>
                            <ul class="pendo-agent-settings__help-block">
                                <li>{{ env.agentPolicy === 'manual' ? 'Manual' : 'Automatic' }} Agent Updates<br></li>
                                <li>
                                    {{
                                        env.agentType === 'lightning'
                                            ? 'Standards Build for Evergreen Browsers'
                                            : `${env.agentType.toUpperCase()} Guide Delivery method`
                                    }}
                                </li>
                            </ul>
                            <div class="pendo-agent-settings__help-block">
                                <a
                                    href="https://support.pendo.io/hc/en-us/articles/360031832152-Agent-2-0-FAQ"
                                    target="_blank">Learn more</a>
                                about the options you can select for the Agent.
                            </div>
                        </div>
                    </section>
                </template>
                <template
                    #footer
                    v-if="env.agentSupportsKillSwitch">
                    <pendo-toggle
                        :value="isPendoEnabled"
                        label="Enable Pendo in Production"
                        label-position="left"
                        @change="$emit('togglePendo')" />
                    <div
                        v-if="pendoDisabledText"
                        class="pendo-disabled-text">
                        {{ pendoDisabledText }}
                    </div>
                </template>
            </pendo-card>
        </div>
        <agent-settings-modal
            :visible="isAgentSettingsModalVisible"
            :agent-versions="agentVersions"
            :agent-settings="agentSettings"
            :is-save-loading="isSaveLoading"
            @save="$emit('save', $event)"
            @close="$emit('closeSettings')"
            @downloadAgent="$emit('downloadAgent', $event)" />
        <pendo-legal-acknowledgement
            :visible="isConfirmationModalVisible"
            :is-loading="isConfirmLoading"
            :title="isPendoEnabled ? 'Disable Pendo?' : 'Enable Pendo?'"
            :action-button-type="isPendoEnabled ? 'danger' : 'primary'"
            :action-button-label="isPendoEnabled ? 'Disable' : 'Enable'"
            @close="$emit('cancelChange')"
            @confirm-action="$emit('confirmChange')">
            <div class="enabled-pendo">
                <template v-if="isPendoEnabled">
                    This will disable Pendo in this application's production environment. The Pendo Agent will still be
                    downloaded, but initialization will be blocked entirely.
                    <strong>Analytics will not be collected and will not be available retroactively. Guides will not be
                        requested or displayed.</strong>
                    Are you sure you want to disable Pendo?
                </template>
                <template v-else>
                    This will re-enable Pendo in this application's production environment.
                    <strong>The Pendo Agent will resume data collection and guide delivery, according to this application's
                        settings.</strong>
                    Are you sure you want to enable Pendo?
                </template>
            </div>
        </pendo-legal-acknowledgement>
    </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoToggle from '@/components/toggle/pendo-toggle.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoCard from '@/components/card/pendo-card.vue';
import AgentSettingsModal from './agent-settings-modal.vue';
import PendoLegalAcknowledgement from '@/composites/legal-acknowledgement/pendo-legal-acknowledgement.vue';

export default {
    name: 'PendoEnvironmentSettings',
    components: {
        PendoButton,
        PendoIcon,
        PendoCard,
        PendoToggle,
        PendoLegalAcknowledgement,
        AgentSettingsModal
    },
    props: {
        isPendoEnabled: {
            type: Boolean,
            required: true
        },
        pendoDisabledText: {
            type: String,
            default: ''
        },
        enableLaunchDebugger: {
            type: Boolean,
            default: false
        },
        isAgentSettingsModalVisible: {
            type: Boolean,
            default: false
        },
        isSaveLoading: {
            type: Boolean,
            default: false
        },
        isConfirmationModalVisible: {
            type: Boolean,
            default: false
        },
        isConfirmLoading: {
            type: Boolean,
            default: false
        },
        staging: {
            type: Object,
            default: () => ({})
        },
        production: {
            type: Object,
            default: () => ({})
        },
        agentVersions: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            agentSettings: {}
        };
    },
    computed: {
        envs () {
            const envs = [this.production];
            if (this.staging) {
                envs.unshift(this.staging);
            }

            return envs;
        }
    },
    methods: {
        displayTitle ({ description }) {
            return `${capitalize(description)} Environment`;
        },
        displayAgentPolicy ({ agentPolicy }) {
            return `Latest ${capitalize(agentPolicy)} Agent Version`;
        },
        deprecatedAgentVersionText ({ agentVersion }) {
            return `Agent ${agentVersion} is deprecated. Update to a newer version for the best experience.`;
        },
        openAgentSettingsModal ({ description }) {
            this.agentSettings = description === 'staging' ? this.staging : this.production;
            this.$emit('openAgentSettingsModal');
        }
    }
};
</script>

<style lang="scss" scoped>
@include block(pendo-env-settings) {
    @include element(environment-card) {
        @include modifier(staging) {
            margin-bottom: 20px;
        }
    }
    .pendo-toggle.is-inline-label {
        justify-content: space-between;
    }
    .pendo-disabled-text {
        margin-top: 5px;
    }
}

@include block(pendo-env-settings-no-staging) {
    background-color: $color-gray-10;
    padding: 5em 2em;
    text-align: center;
    color: $color-gray-100;
    @include element(title) {
        font-size: 24px;
    }
    @include element(subtitle) {
        margin: 20px 0;
    }
}

@include block(pendo-agent-settings) {
    @include element(agent-version) {
        margin-bottom: 10px;
        font-size: 18px;
    }
    @include element(beta-stable-manual) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
    @include element(help-block) {
        color: $color-gray-70;
        margin: 15px 0;
    }
}

.enabled-pendo {
    margin-bottom: 20px;
}

ul {
    padding-inline-start: 20px;
}
li {
    margin: 10px 0px;
    list-style-type: '- ';
}
</style>
