<template>
    <div
        class="pendo-app-display"
        :class="{ 'pendo-app-display--cross-app': showCrossAppDisplay }">
        <span
            v-if="showCrossAppDisplay"
            class="pendo-app-display-item">
            <pendo-icon
                v-pendo-tooltip="{ content: 'Cross-App' }"
                class="pendo-app-display-item__icon"
                type="grid"
                :size="14" />
            <span
                v-for="(appItem, index) in appItems"
                :key="appItem.id"
                class="pendo-app-display-item__name">{{ appItem.nameLabel }}<template v-if="index < appItems.length - 1">,</template></span>
        </span>
        <template v-if="!showCrossAppDisplay">
            <span
                v-for="(appItem, index) in appItems"
                :key="appItem.id"
                class="pendo-app-display-item">
                <pendo-app-icon
                    v-if="showAppIcons"
                    class="pendo-app-display-item__icon"
                    :app="appItem" />
                <span class="pendo-app-display-item__name">{{ appItem.nameLabel }}<template v-if="index < appItems.length - 1">,</template></span>
            </span>
        </template>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoAppIcon from '@/composites/app-icon/pendo-app-icon.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import get from 'lodash/get';

export default {
    name: 'PendoAppDisplay',
    components: {
        PendoIcon,
        PendoAppIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        /**
         * Array of app objects (also accepts a single app object)
         * Each object should have the following fields if available: applicationFlags, color, displayName, faviconB64, id, platform, trainingAttributes
         * Recommended to pass the entire app object
         */
        apps: {
            type: [Array, Object],
            required: true
        },
        /**
         * Used to display a list of apps as cross-app (i.e. with icon for a cross-app guide)
         */
        showCrossAppDisplay: {
            type: Boolean,
            default: false
        },
        /**
         * Enables/disables display of app favicons, logos, or fallback icons
         */
        showAppIcons: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        appItems () {
            const apps = Array.isArray(this.apps) ? this.apps : [this.apps];

            return apps.map((app) => {
                let nameLabel = app.displayName || app.name || String(app.id);
                const trainingApplication = get(app, 'applicationFlags.trainingApplication');

                if (trainingApplication) {
                    nameLabel = get(app, 'trainingAttributes.displayName') || nameLabel;
                }

                return {
                    ...app,
                    nameLabel
                };
            });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-app-display) {
    @include ellipsis;

    @include modifier(cross-app) {
        .pendo-app-display-item__name {
            margin-left: 8px;
        }
    }
}

@include block(pendo-app-display-item) {
    @include element(icon) {
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;

        > img {
            display: block;
        }

        + .pendo-app-display-item__name {
            margin-left: 8px;
        }
    }

    &:not(:first-child) {
        margin-left: 8px;
    }
}

.pendo-multiselect .pendo-app-display {
    line-height: 26px;
    user-select: none;
    height: 100%;
    display: flex;
}
</style>
