<template>
    <div
        data-cy="guide-alert-note-input"
        class="note-input-area">
        <div class="note-text-box">
            <pendo-input
                ref="noteTextarea"
                v-model="note"
                :disabled="!canCreateNote || postingNote"
                :invalid="noteTooLong"
                placeholder="Add Note here..."
                aria-describedby="note-input-description"
                type="textarea">
                <template #bottomLabel>
                    <span
                        v-if="noteTooLong"
                        id="note-input-description">Character limit is 500.</span>
                </template>
            </pendo-input>
        </div>
        <div class="note-buttons">
            <pendo-button
                theme="app"
                type="secondary"
                label="Clear"
                :disabled="!canCreateNote || !note"
                @click="clear" />
            <pendo-button
                theme="app"
                type="primary"
                label="Add Note"
                :loading="postingNote"
                :disabled="!canCreateNote || !note || noteTooLong"
                @click="submitNote" />
        </div>
    </div>
</template>

<script>
import PendoInput from '@/components/input/pendo-input';
import PendoNotification from '@/components/notification/pendo-notification';
import PendoButton from '@/components/button/pendo-button';
import get from 'lodash/get';

export default {
    name: 'NoteInput',
    components: {
        PendoInput,
        PendoButton
    },
    props: {
        displayAlert: {
            type: Object,
            required: true
        },
        noteAction: {
            type: Object,
            default: () => ({})
        },
        noteError: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            note: '',
            postingNote: false
        };
    },
    computed: {
        canCreateNote () {
            return get(this.displayAlert, 'canCreateNote');
        },
        noteTooLong () {
            return this.note.length > 500;
        },
        isCreatingNote () {
            return this.noteAction.create;
        }
    },
    watch: {
        isCreatingNote (newValue, oldValue) {
            if (this.postingNote && !newValue && oldValue) {
                if (this.noteError.create) {
                    PendoNotification({
                        type: 'error',
                        title: 'Note was not posted.',
                        message: 'Looks like something went wrong when posting your note. Please try again.',
                        duration: 3000
                    });
                } else {
                    this.clear();
                }

                this.postingNote = false;
            }
        }
    },
    methods: {
        submitNote () {
            this.postingNote = true;
            this.$emit('create-note', { alert: this.displayAlert, text: this.note });
        },
        clear () {
            this.note = '';
            this.$refs.noteTextarea.resizeTextarea();
        }
    }
};
</script>

<style lang="scss" scoped>
.note-text-box {
    background: $color-white;
    padding-top: 20px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 14px;
}
.note-buttons {
    margin-right: 24px;
    float: right;
    padding-bottom: 12px;
}
.note-input-area {
    box-shadow: 0px -2px 2px $color-gray-40;
    height: auto;
    z-index: 1;
}
</style>
