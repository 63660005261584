<template>
    <div
        class="pendo-table__body"
        :class="{ 'pendo-table__body--page-mode': $table.scrollStore.pageMode }"
        :style="$table.bodyStyle"
        @scroll.passive="handleScroll"
        @mouseleave="$table.clearRowHover()">
        <div
            :style="{
                width: '0px',
                float: 'left',
                height: `${$table.scrollStore.scrollHeight}px`
            }" />
        <div
            class="pendo-table__scroll-container"
            :style="{
                transform: `translate3d(0px, ${$table.scrollStore.scrollTop}px, 0px)`
            }">
            <table
                :key="tableComponentKey"
                v-pendo-draggable="
                    $table.draggable
                        ? {
                            events: {
                                'mirror:create': mirrorCreateHandler,
                                'drag:start': dragStartHandler,
                                'drag:move': dragMoveHandler,
                                'drag:over': dragOverHandler,
                                'drag:stop': dragStopHandler
                            }
                        }
                        : false
                "
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
                :style="{
                    width: $table.bodyWidth === null ? $table.bodyWidth : `${$table.bodyWidth}px`
                }">
                <colgroup>
                    <col
                        v-for="(column, columnIndex) in $table.tableColumns"
                        :key="columnIndex"
                        :name="column.id"
                        :width="column.renderWidth">
                </colgroup>
                <tbody
                    :pendo-draggable-container="$table.draggable"
                    role="rowgroup">
                    <table-row
                        v-for="row of renderedRowElements"
                        :key="row.nr.key"
                        :row="row.data"
                        :data-uid="row.nr.index"
                        :index="row.nr.index"
                        :aria-level="row.data.ariaLevel"
                        :aria-setsize="row.data.ariaSetSize"
                        :aria-posinset="row.data.ariaPosInSet">
                        <template #default="{ naturalIndex, rowIndex }">
                            <template v-if="!row.data.isGroup">
                                <table-cell
                                    v-for="(column, columnIndex) in $table.tableColumns"
                                    :key="column.columnKey"
                                    :column="column"
                                    :column-index="columnIndex"
                                    :natural-index="naturalIndex"
                                    :row="row.data"
                                    :row-index="rowIndex"
                                    :row-level="0" />
                            </template>
                            <template v-if="row.data.isGroup">
                                <group-cell
                                    :row="row.data"
                                    :row-index="rowIndex" />
                            </template>
                        </template>
                    </table-row>
                </tbody>
                <slot name="append" />
                <ResizeObserver
                    aria-hidden="true"
                    @notify="handleResize" />
            </table>
        </div>
        <div
            v-if="!$table.loading && !$table.allRowsCount"
            class="pendo-table__empty-block">
            <span class="pendo-table__empty-text">
                <slot name="empty">
                    {{ $table.emptyText }}
                </slot>
            </span>
        </div>
        <pendo-responsive
            v-if="$table.loading && !$table.allRowsCount"
            :max-height="$table.bodyStyle.maxHeight"
            :height="$table.bodyStyle.height"
            :aspect-ratio="16 / 9" />
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { ResizeObserver } from 'vue-resize';
import ScrollParent from 'scrollparent';
import { raf } from '@/utils/dom';
import PendoResponsive from '@/components/responsive/pendo-responsive';
import tableDraggableMixin from '@/mixins/table-draggable';

import TableRow from '@/components/table/table-row';
import TableCell from '@/components/table/table-cell.js';
import GroupCell from '@/components/table/group-cell.js';

let uid = 0;

export default {
    components: {
        TableRow,
        TableCell,
        GroupCell,
        ResizeObserver,
        PendoResponsive
    },
    mixins: [tableDraggableMixin],
    inject: ['$table'],
    data () {
        return {
            ready: false,
            scheduleRowUpdate: null,
            renderedRowElements: []
        };
    },
    async mounted () {
        if (this.$table.scrollStore.pageMode) {
            this.addPageModeListeners();
        }

        this.scheduleRowUpdate = raf(this.updateVisibleRows);
        this.syncScrollPositions = raf(this.syncScrollPositions);

        await this.$nextTick();

        this.updateVisibleRows();
        this.ready = true;
    },
    beforeDestroy () {
        this.removePageModeListeners();
    },
    methods: {
        getScroll () {
            if (this.$table.scrollStore.pageMode) {
                const { top, height } = this.$el.getBoundingClientRect();
                let start = -top;
                let size = window.innerHeight;
                if (start < 0) {
                    size += start;
                    start = 0;
                }
                if (start + size > height) {
                    size = height - start;
                }

                return {
                    start,
                    end: start + size
                };
            }

            return {
                start: this.$el.scrollTop,
                end: this.$el.scrollTop + this.$el.clientHeight
            };
        },
        getPageModeListenerTarget () {
            let target = ScrollParent(this.$el);
            // Fix global scroll target for Chrome and Safari
            if (window.document && (target === window.document.documentElement || target === window.document.body)) {
                target = window;
            }

            return target;
        },
        addPageModeListeners () {
            this.pageModeListenerTarget = this.getPageModeListenerTarget();
            this.pageModeListenerTarget.addEventListener('scroll', this.handleScroll, {
                passive: true
            });
            this.pageModeListenerTarget.addEventListener('resize', this.handleResize);
        },
        removePageModeListeners () {
            if (!this.pageModeListenerTarget) {
                return;
            }

            this.pageModeListenerTarget.removeEventListener('scroll', this.handleScroll);
            this.pageModeListenerTarget.removeEventListener('resize', this.handleResize);
            this.pageModeListenerTarget = null;
        },
        handleResize () {
            if (this.ready) {
                this.scheduleRowUpdate.cancel();
                this.scheduleRowUpdate();
            }
        },
        updateScrollStatus: debounce(
            function () {
                this.$table.scrollStore.scrollActive = false;

                const currentlyHoveredRow = this.$el.querySelector('.pendo-table__row:hover');

                if (!currentlyHoveredRow) {
                    return;
                }

                // Because we're manually manipulating the dom with a virtual scroll,
                // mouseover events don't trigger naturally if you happen to have your cursor
                // already over an element. We'll manually trigger a hover here to feel more natural
                const index = currentlyHoveredRow.getAttribute('data-uid');
                const hoveredRow = this.$table.tableData[index];

                this.$table.setRowHover(hoveredRow, index);
            },
            200,
            { leading: false, trailing: true }
        ),
        handleScroll (event) {
            const isPageMode = this.$table.scrollStore.pageMode;
            const scrollLeft = isPageMode ? this.$el.scrollLeft : event.target.scrollLeft;
            // horizontal scroll
            if (this.$table.overflowX && this.$table.scrollStore.scrollLeft !== scrollLeft) {
                this.syncScrollPositions(isPageMode ? this.$el : event.target);
            }

            // vertical scroll
            if (this.$table.scrollStore.enabled) {
                if (this.$table.scrollStore.scrollTop !== event.target.scrollTop) {
                    this.scheduleRowUpdate.cancel();
                    this.scheduleRowUpdate();
                }

                this.$table.clearRowHover();
                this.$table.scrollStore.scrollActive = true;
                this.updateScrollStatus();
            }
        },
        syncScrollPositions ({ clientWidth, scrollWidth, scrollLeft }) {
            this.$table.scrollLeftToRight = scrollLeft > 0;
            this.$table.scrollRightToLeft = clientWidth < scrollWidth - scrollLeft;
            this.$table.$refs.tableHeader.$el.scrollLeft = scrollLeft;
            if (this.$table.$refs.tableScrollX && this.$table.$refs.tableScrollX.scrollLeft !== scrollLeft) {
                this.$table.$refs.tableScrollX.scrollLeft = scrollLeft;
            }
            this.$table.scrollStore.scrollLeft = scrollLeft;
        },
        computeStartAndEndIndexes (scroll, buffer, tableDataLength, rowHeight, tableDataHeightMap) {
            scroll.start -= buffer;
            scroll.end += buffer;

            if (rowHeight) {
                // If using a constant row height, we can compute the set of rendered rows with just math
                const startIndex = Math.max(Math.floor(scroll.start / rowHeight), 0);
                const endIndex = Math.min(Math.ceil(scroll.end / rowHeight), tableDataLength);

                return [startIndex, endIndex];
            }

            // We pre-compute the cumulative height at each node when the table loads its data
            // With a small buffer, the -1 accounts for the partially visible row
            const startIndex = Math.max(
                0,
                tableDataHeightMap.findIndex((heightAtIndex) => heightAtIndex > scroll.start) - 1
            );
            let stopIndex = tableDataHeightMap.length;

            // No need to do another complete traversal of tableDataHeightMap to find the ending index, so we'll
            // start our search at the starting index for perf
            for (let i = startIndex; i < tableDataHeightMap.length; i++) {
                const heightAtIndex = tableDataHeightMap[i];

                if (heightAtIndex > scroll.end) {
                    stopIndex = i;
                    break;
                }
            }

            return [startIndex, stopIndex];
        },
        updateVisibleRows () {
            let startIndex = 0;
            let endIndex = 0;
            const rowElements = this.renderedRowElements;
            const { tableData, tableDataHeightMap, scrollStore } = this.$table;
            const { rowHeight, buffer, enabled, dynamicRowHeight } = scrollStore;

            const count = tableData.length;
            const scroll = this.getScroll();

            if (count && enabled) {
                [startIndex, endIndex] = this.computeStartAndEndIndexes(
                    scroll,
                    buffer,
                    count,
                    dynamicRowHeight ? null : rowHeight,
                    tableDataHeightMap
                );
            } else {
                // scroll config is disabled, or there are no items
                // When scroll config is disabled, just render all the elements
                startIndex = 0;
                endIndex = count;
            }

            this.$table.scrollStore.startIndex = startIndex;
            this.$table.scrollStore.endIndex = endIndex;

            for (let r = 0, i = startIndex; i < endIndex; r++, i++) {
                let row = rowElements[r];

                // use existing row if available
                if (row) {
                    row.nr.index = i;
                    row.data = tableData[i];

                    // special rendering logic for draggable
                    if (this.draggedTableRow !== null && this.draggedTableRow.index === i) {
                        if (row.nr.id <= this.draggedTableRow.id) {
                            r--;
                        } else {
                            row.nr.index = i - 1;
                            row.data = tableData[i - 1];
                            i++;
                        }
                    } else if (this.draggedTableRow !== null && row.nr.id > this.draggedTableRow.id) {
                        row.nr.index = i - 1;
                        row.data = tableData[i - 1];
                    }
                    if (dynamicRowHeight) {
                        row.nr.key = row.data[this.$table.rowKey];
                    }
                } else {
                    // create a new row
                    row = {
                        data: tableData[i]
                    };

                    const nonReactive = {
                        id: uid++,
                        key: dynamicRowHeight ? row.data[this.$table.rowKey] : uid,
                        index: i
                    };

                    Object.defineProperty(row, 'nr', {
                        configurable: false,
                        value: nonReactive
                    });

                    rowElements.push(row);
                }
            }

            if (rowElements.length + startIndex > count) {
                rowElements.length = Math.max(count - startIndex, 0);
            }

            // If we end up with a bunch of larger rows during scroll, truncate rowElements to ensure
            // we don't render "dead" duplicates from the back half of rowElements
            if (rowElements.length > endIndex - startIndex) {
                rowElements.length = Math.max(endIndex - startIndex, 0);
            }

            if (rowHeight && !dynamicRowHeight) {
                this.$table.scrollStore.scrollTop = Math.floor(startIndex * rowHeight);
            } else {
                this.$table.scrollStore.scrollTop = tableDataHeightMap[startIndex];
            }

            if (dynamicRowHeight) {
                this.$nextTick(() => {
                    const updatedRows = rowElements.reduce((acc, row) => {
                        const { height } = document
                            .querySelector(`[data-uid="${row.nr.index}"]`)
                            .getBoundingClientRect();
                        if (row.data.height !== height && height) {
                            acc.push({ ...row.data, height });
                        }

                        return acc;
                    }, []);

                    if (updatedRows.length) {
                        this.$table.updateRows(updatedRows);
                    }
                });
            }

            this.$table.calculateStickyElementPositions();
        }
    }
};
</script>
