<template>
    <button
        v-pendo-analytics="{ type: 'components:button', value: isIconOnly ? icon : label }"
        :disabled="buttonDisabled"
        :autofocus="autofocus"
        :type="nativeType"
        :class="buttonClass"
        :aria-label="ariaLabel"
        v-on="$listeners">
        <pendo-loading-indicator
            v-if="loading"
            :spinner-props="{
                color: false,
                size: iconSize
            }"
            :loading="loading"
            type="material"
            button />
        <pendo-icon
            v-if="showPrefixIcon"
            :type="icon || prefixIcon"
            :size="iconSize"
            :stroke-width="2.5"
            :class="{ 'icon-prefix': !isIconOnly }"
            aria-hidden="true" />
        <span>
            <!-- @slot alternative to using the `label` prop -->
            <slot>
                {{ label }}
            </slot>
        </span>
        <pendo-icon
            v-if="showSuffixIcon"
            :type="suffixIcon"
            :size="iconSize"
            :stroke-width="2.5"
            class="icon-suffix"
            aria-hidden="true" />
    </button>
</template>

<script>
import get from 'lodash/get';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator.vue';
import { PendoAnalytics } from '@/directives/analytics/pendo-analytics';
import { iconTypeToGeneric } from '@/utils/utils';

const THEME_CLASSES_MAP = {
    'p2-dark': 'p2-dark',
    // p2-light is no longer an accepted value but this mapping
    // should remain for now to prevent breaking anything downstream
    'p2-light': 'app',
    'app': 'app'
};

export default {
    name: 'PendoButton',
    components: {
        PendoIcon,
        PendoLoadingIndicator
    },
    directives: {
        PendoAnalytics
    },
    inject: {
        $form: {
            default: ''
        }
    },
    props: {
        /**
         * Type of button
         * @values primary, secondary, tertiary, link, danger
         */
        type: {
            type: String,
            default: 'primary',
            validator: (value) => ['primary', 'secondary', 'tertiary', 'link', 'danger'].includes(value)
        },
        /**
         * Theme to apply
         * @values app, p2-dark
         */
        theme: {
            type: String,
            default: 'app',
            validator: (theme) => ['app', 'p2-dark'].includes(theme)
        },
        /**
         * Size of button
         * @values medium, small, mini
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['mini', 'small', 'medium'].includes(size)
        },
        /**
         * Text to display inside of the button
         */
        label: {
            type: String,
            default: null
        },
        /**
         * applies button disabled styles and prevents user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * used to display an "icon-only" button
         */
        icon: {
            type: String,
            default: null
        },
        /**
         * places icon to the left of the passed in label prop
         */
        prefixIcon: {
            type: String,
            default: ''
        },
        /**
         * places icon to the right of the passed in label prop
         */
        suffixIcon: {
            type: String,
            default: ''
        },
        /**
         * sets the size of the prefixIcon, suffixIcon, or icon
         */
        iconSize: {
            type: [String, Number],
            default: '14'
        },
        /**
         * full width
         */
        block: {
            type: Boolean,
            default: false
        },
        /**
         * @ignore
         */
        nativeType: {
            type: String,
            default: 'button'
        },
        /**
         * when true, shows a loading indicator inside of the button
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * @ignore
         */
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonClass () {
            return [
                'pendo-button',
                `pendo-button--${this.type}`,
                `pendo-button--${this.size}`,
                `${THEME_CLASSES_MAP[this.theme]}`,
                {
                    'is-disabled': this.buttonDisabled,
                    'is-loading': this.loading,
                    'is-block': this.block,
                    'is-icon-only': this.isIconOnly,
                    'has-prefix-icon': !this.isIconOnly && this.showPrefixIcon,
                    'has-suffix-icon': this.showSuffixIcon
                }
            ];
        },
        buttonDisabled () {
            const isParentFormDisabled = get(this.$form, 'disabled');

            return this.loading || this.disabled || isParentFormDisabled;
        },
        isIconOnly () {
            return !this.$slots.default && !this.label && !!this.icon;
        },
        showSuffixIcon () {
            if (this.loading) {
                return false;
            }

            return Boolean(this.suffixIcon);
        },
        showPrefixIcon () {
            if (this.loading) {
                return false;
            }

            return Boolean(this.icon || this.prefixIcon);
        },
        ariaLabel () {
            if (this.$attrs && this.$attrs['aria-label']) {
                return this.$attrs['aria-label'];
            }

            if (this.icon) {
                return iconTypeToGeneric(this.icon);
            }

            return null;
        }
    }
};
</script>

<style lang="scss">
@import '../../styles/themes/app/buttons';
@import '../../styles/themes/p2-dark/buttons';

$button-namespace: '.pendo-button';

$button-themes: (
    app: $app--button-theme,
    p2-dark: $p2-dark--button-theme
);

// base button styles
#{$button-namespace} {
    color: $button-font-color;
    border-radius: $button-border-radius;
    font-weight: $button-font-weight;
    font-size: $button-font-size;
    font-family: $button-font-family;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    line-height: $button-height;
    height: $button-height;
    padding: 0;
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    font-weight: 600;
    user-select: none;

    @include focus-ring(
        $style: 'base',
        $transitions: (
            color 0.2s,
            background-color 0.2s,
            border-color 0.2s
        )
    );

    & + & {
        margin-left: 8px;
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &:disabled,
    &.is-disabled {
        &,
        &:hover,
        &:focus {
            cursor: not-allowed;
            opacity: $disabled-opacity;
            background-image: none;
        }
    }

    &:focus-visible {
        @include focus-ring($style: 'focused');

        &.p2-dark {
            outline-color: $color-white;
        }
    }

    // full width
    @include is(block) {
        display: flex;
        width: 100%;
    }

    .icon-prefix {
        padding-right: 8px;

        &.pendo-icon__plus,
        &.pendo-icon__chevron-right,
        &.pendo-icon__chevron-left {
            padding-right: 4px;
        }
    }

    .icon-suffix {
        padding-left: 8px;

        &.pendo-icon__plus,
        &.pendo-icon__chevron-right,
        &.pendo-icon__chevron-left {
            padding-left: 4px;
        }
    }

    // loading indicator styles
    .pendo-loading {
        position: inherit;
        padding-right: 8px;

        &-spinner {
            display: grid;
            align-items: center;

            svg {
                overflow: visible;
            }

            circle {
                stroke-width: 7;
            }
        }
    }
}

// button theme + modifier generator
// comments provide clarity as to what classes these mixins are applied to
// careful in here, breaking this will break ALL buttons, for ALL themes...
@each $theme in map-keys($button-themes) {
    .#{$theme} {
        @each $type, $values in map-fetch($button-themes, $theme types) {
            $button-type-namespace: #{$button-namespace}--#{$type};

            // background
            $button-background: map-fetch($button-themes, $theme types $type background);
            $button-background-rest: nth($button-background, 1);
            $button-background-hover: $button-background-rest;

            @if length($button-background) > 1 {
                $button-background-hover: nth($button-background, 2);
            }

            // borders
            $border-props: map-fetch($button-themes, $theme types $type border);
            $button-border-rest: $button-background-rest;
            $button-border-hover: $button-background-hover;

            @if $border-props {
                $button-border-rest: nth($border-props, 1);
                $button-border-hover: nth($border-props, 2);
            }

            // font colors
            $button-color: map-fetch($button-themes, $theme types $type color);
            $button-color-rest: nth($button-color, 1);
            $button-color-hover: $button-color;

            @if length($button-color) > 1 {
                $button-color-hover: nth($button-color, 2);
            }

            // text decorations
            $button-text-decoration-rest: none;
            $button-text-decoration-hover: map-fetch(
                $button-themes,
                $theme types $type text-decoration,
                $button-text-decoration-rest
            );

            $button-padding: map-fetch($button-themes, $theme types $type padding);
            $button-font-weight: map-fetch($button-themes, $theme types $type font-weight);

            // .pendo-button--primary, .pendo-button--secondary, .pendo-button-tertiary etc.
            &#{$button-type-namespace} {
                background-color: $button-background-rest;
                border-color: $button-background-rest;
                color: $button-color-rest;
                text-decoration: $button-text-decoration-rest;
                padding: $button-padding;
                font-weight: $button-font-weight;

                @if $border-props {
                    border-width: 1px;
                    border-style: solid;
                    border-color: $button-border-rest;
                }

                &:hover {
                    background-color: $button-background-hover;
                    border-color: $button-border-hover;
                    color: $button-color-hover;
                    text-decoration: $button-text-decoration-hover;
                }

                .pendo-loading-spinner {
                    color: $button-color-rest;
                }

                // '.pendo-button--primary.is-disabled'
                @include is(disabled) {
                    &,
                    &:hover,
                    &:focus {
                        background-color: $button-background-rest;

                        @if $border-props {
                            border-color: $button-border-rest;
                        } @else {
                            border-color: $button-background-rest;
                        }

                        color: $button-color-rest;
                        text-decoration: none;
                        opacity: $disabled-opacity;
                    }
                }
            }
        }

        @each $size, $values in map-fetch($button-themes, $theme sizes) {
            $button-size-height: map-fetch($button-themes, $theme sizes $size height);
            $button-size-font: map-fetch($button-themes, $theme sizes $size font-size);
            $button-min-width: map-fetch($button-themes, $theme sizes $size min-width);
            $button-size-namespace: #{$button-namespace}--#{$size};

            // .pendo-button--medium, .pendo-button--small, .pendo-button--mini
            &#{$button-size-namespace} {
                height: $button-size-height;
                line-height: $button-size-height;
                font-size: $button-size-font;
                min-width: $button-min-width;

                // '.is-icon-only' -- square buttons with single icon
                @include is(icon-only) {
                    min-width: $button-size-height;
                    &.is-loading {
                        padding: 0;

                        .pendo-loading {
                            padding-right: 0;
                        }
                    }
                }

                &.has-suffix-icon:not(.has-prefix-icon) {
                    padding: 0 8px 0 10px;
                }

                &.is-loading,
                &.has-prefix-icon:not(.has-suffix-icon) {
                    padding: 0 10px 0 8px;
                }

                &.pendo-button--tertiary,
                &.pendo-button--link {
                    min-width: 0;

                    &:not(.p2-dark) {
                        padding: 0;
                    }

                    @include is(icon-only) {
                        padding: 0;
                    }
                }
            }
        }
    }
}
</style>
