<template>
    <pendo-modal
        class="legal-acknowledgement-modal"
        :title="title"
        height="auto"
        max-height="90%"
        :width="width"
        :visible="visible"
        :esc-to-close="false"
        @close="closeModal">
        <slot>
            <p>
                {{ bodyText }}
            </p>
        </slot>
        <pendo-input
            v-model="confirmationInput"
            data-cy="confirm-action-input"
            :label="`Type &quot;${confirmationText}&quot; to confirm`"
            sanitize />
        <pendo-checkbox
            v-if="isForCustomer"
            v-model="isForCustomerChecked"
            class="pendo-super-confirmation"
            label="I have confirmation to do this on behalf of a customer." />
        <template #footer>
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                theme="app"
                :type="actionButtonType"
                :label="actionButtonLabel"
                :disabled="disabledLogic"
                :loading="isLoading"
                @click="confirmAction" />
        </template>
    </pendo-modal>
</template>

<script>
import PendoModal from '@/components/modal/pendo-modal';
import PendoButton from '@/components/button/pendo-button';
import PendoInput from '@/components/input/pendo-input';
import PendoCheckbox from '@/components/checkbox/pendo-checkbox';

export default {
    name: 'PendoLegalAcknowledgement',
    components: {
        PendoButton,
        PendoCheckbox,
        PendoInput,
        PendoModal
    },
    props: {
        bodyText: {
            type: String,
            default: ''
        },
        isForCustomer: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Acknowledgement'
        },
        actionButtonType: {
            type: String,
            default: 'primary'
        },
        actionButtonLabel: {
            type: String,
            default: 'Confirm'
        },
        width: {
            type: Number,
            default: 425
        },
        confirmationText: {
            type: String,
            default: 'I understand'
        }
    },
    data () {
        return {
            confirmationInput: '',
            isForCustomerChecked: false
        };
    },
    computed: {
        disabledLogic () {
            return (
                this.confirmationInput !== this.confirmationText || (this.isForCustomer && !this.isForCustomerChecked)
            );
        }
    },
    methods: {
        confirmAction () {
            this.$emit('confirm-action');
            this.reset();
        },
        closeModal () {
            this.$emit('close', false);
            this.reset();
        },
        reset () {
            this.confirmationInput = '';
            this.isForCustomerChecked = false;
        }
    }
};
</script>
