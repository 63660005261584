<template>
    <div
        class="pendo-chooser-grid"
        :class="{
            'pendo-chooser-grid--multiselect': multiple,
            'is-disabled': disabled
        }"
        role="menu"
        aria-orientation="horizontal">
        <slot name="before" />
        <div
            class="pendo-chooser-grid__items"
            @keydown="bindTravel">
            <slot name="items">
                <pendo-media-card
                    v-for="(item, index) in visibleItems"
                    ref="chooser-grid-item"
                    :key="item[valueKey]"
                    v-pendo-tooltip="item.tooltipOptions"
                    class="pendo-chooser-grid__item"
                    :class="{
                        'is-selected': isItemSelected(item)
                    }"
                    role="menuitem"
                    :aria-disabled="disabled || item.disabled"
                    :disabled="disabled || item.disabled"
                    :tabindex="index === travel_currentIndex ? 0 : -1"
                    :shadow="getCardShadow(item)"
                    v-bind="item"
                    @keyup.space.native="select(item)"
                    @keyup.enter.native="select(item)">
                    <template #media>
                        <pendo-icon
                            v-if="multiple && isItemSelected(item)"
                            class="pendo-chooser-grid__selected-icon"
                            type="check-circle-2-solid"
                            stroke="#FFF"
                            fill="#229CA8"
                            size="20"
                            aria-hidden="true" />
                        <slot
                            name="media"
                            :item="item"
                            :select="select"
                            :remove-selection="removeSelection"
                            :is-item-selected="isItemSelected">
                            <pendo-icon
                                :size="40"
                                v-bind="item.icon"
                                aria-hidden="true" />
                        </slot>
                    </template>
                    <template #overlay>
                        <slot
                            name="overlay"
                            :item="item"
                            :select="select"
                            :remove-selection="removeSelection"
                            :is-item-selected="isItemSelected">
                            <pendo-button
                                v-if="!multiple"
                                tabindex="-1"
                                theme="p2-dark"
                                type="secondary"
                                aria-hidden="true"
                                :label="selectLabel"
                                @click="select(item)" />
                            <pendo-button
                                v-if="multiple"
                                tabindex="-1"
                                theme="p2-dark"
                                type="secondary"
                                aria-hidden="true"
                                :label="isItemSelected(item) ? removeSelectionLabel : selectLabel"
                                @click="select(item)" />
                        </slot>
                    </template>
                    <template #body>
                        <slot
                            name="body"
                            :item="item" />
                    </template>
                    <template #title>
                        <slot
                            name="title"
                            :item="item" />
                    </template>
                    <template #subtitle>
                        <slot
                            name="subtitle"
                            :item="item" />
                    </template>
                </pendo-media-card>
            </slot>
        </div>
        <slot name="after" />
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoMediaCard from '@/components/media-card/pendo-media-card';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import { filterItems } from '@/components/chooser-grid/utils';
import TravelMixin from '@/mixins/travel';

export default {
    name: 'PendoChooserGrid',
    components: {
        PendoMediaCard,
        PendoButton,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    mixins: [TravelMixin],
    $travel: { refName: 'chooser-grid-item' },
    props: {
        /**
         * bound value
         */
        value: {
            type: [Object, Array, String, Number],
            default: () => []
        },
        /**
         * items to place in grid.
         */
        items: {
            type: Array,
            default: () => [{}]
        },
        /**
         * Allow selection of multiple items
         */
        multiple: {
            type: Boolean,
            default: false
        },
        /**
         * Key used to compare objects in multiple mode
         */
        valueKey: {
            type: String,
            default: 'id'
        },
        /**
         * Button text visible when user hovers grid item
         */
        selectLabel: {
            type: String,
            default: 'Select Item'
        },
        /**
         * When multiple is true, this is the button label shown when an item is selected
         */
        removeSelectionLabel: {
            type: String,
            default: 'Deselect Item'
        },
        /**
         * Disables user interaction with grid tiles
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * filters visible grid items
         */
        filters: {
            type: [Object, Array],
            default: null
        }
    },
    data () {
        return {
            filterCache: null
        };
    },
    computed: {
        model () {
            if (this.value || this.value === 0) {
                return Array.isArray(this.value) ? this.value : [this.value];
            }

            return [];
        },
        visibleItems () {
            let visibleItems = this.items;

            if (this.filters && this.filters.length > 0) {
                this.filters.forEach((filter) => {
                    const { value, prop, filterFn } = filter;
                    if (
                        (Array.isArray(value) && value.length === 0) ||
                        ((value === undefined || value === '') && !filterFn)
                    ) {
                        return true;
                    }
                    const filterHandler = filterFn || this.getFilterHandler(prop);
                    visibleItems = visibleItems.filter((item) => filterHandler(item, filter));
                });
            }

            return visibleItems;
        }
    },
    watch: {
        visibleItems (visibleItems) {
            this.$emit('visible-items-change', visibleItems);
        }
    },
    created () {
        this.filterCache = Object.create(null);
    },
    methods: {
        getCardShadow (item) {
            if (this.isItemSelected(item)) {
                return 'never';
            }

            if (this.model.length && !this.multiple) {
                return 'never';
            }

            return 'hover';
        },
        isItemSelected (item) {
            return this.model.some((itm) => itm[this.valueKey] === item[this.valueKey]);
        },
        removeSelection (item) {
            if (this.disabled || item.disabled) {
                return;
            }

            const indexToRemove = this.model.map((itm) => itm[this.valueKey]).indexOf(item[this.valueKey]);

            this.$emit('remove', item);

            if (this.multiple) {
                const newValue = this.model.slice(0, indexToRemove).concat(this.model.slice(indexToRemove + 1));
                this.$emit('input', newValue);
            } else {
                this.$emit('input', null);
            }
        },
        select (item) {
            if (item.disabled) {
                return;
            }

            if (this.isItemSelected(item)) {
                if (this.multiple) {
                    this.removeSelection(item);
                }

                return;
            }

            if (this.model.length && !this.multiple) {
                return;
            }

            this.$emit('select', item);

            if (this.multiple) {
                this.$emit('input', this.model.concat([item]));
            } else {
                this.$emit('input', item);
            }
        },
        getFilterHandler (prop) {
            let key;
            if (Array.isArray(prop)) {
                key = prop.join('');
            } else if (typeof prop === 'string' || prop instanceof String) {
                key = prop;
            } else {
                return () => false;
            }

            if (this.filterCache[key]) {
                return this.filterCache[key];
            }

            this.filterCache[key] = (item, filter) =>
                (Array.isArray(prop)
                    ? prop.some((prp) => filterItems(prp, item, filter))
                    : filterItems(prop, item, filter));

            return this.filterCache[key];
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-chooser-grid) {
    @include element(items) {
        display: grid;
        justify-content: center;
        padding: 24px;
        grid-template-columns: repeat(auto-fit, 246px);
        grid-gap: 32px;
        margin: 0 auto;
    }

    @include element(item) {
        &.has-tooltip.is-disabled:focus-visible {
            outline: $focus-visible-color $focus-visible-size solid;
            outline-offset: $focus-visible-offset;
        }

        .pendo-media-card__overlay-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    @include modifier(multiselect) {
        @include element(item) {
            transition-duration: $card-hover-transition;
            transition-property: opacity, box-shadow, transform, border-color;

            &.is-selected {
                box-shadow: 0 0 0 0 rgba(42, 44, 53, 0.15), 0 0 0 1px $color-teal-70 !important;
                border-color: $color-teal-70;

                .pendo-media-card__header {
                    border-bottom-color: $color-teal-70;
                }
            }
        }

        @include element(selected-icon) {
            position: absolute;
            top: 8px;
            left: 8px;
        }
    }
}
</style>
