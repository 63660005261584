<template>
    <div
        v-pendo-analytics="{ type: 'components:card', value: title }"
        class="pendo-card"
        :class="{
            'pendo-card--collapsible': collapsible,
            'is-collapsed': collapsed,
            [`is-${shadow}-shadow`]: shadow
        }"
        :style="{
            height,
            width
        }">
        <div
            v-if="showHeader"
            :style="{ minHeight: headerMinHeight }"
            class="pendo-card__header">
            <!-- @slot alternative to title prop -->
            <slot name="header">
                <div class="pendo-card__header--left">
                    <slot name="title">
                        <h3 class="pendo-card__title">
                            {{ title }}
                        </h3>
                    </slot>
                </div>
                <div
                    v-if="$slots.headerRight || $slots.headerActions || collapsible"
                    class="pendo-card__header--right">
                    <!-- @slot place content on right half of card header -->
                    <slot name="headerRight" />
                    <div class="pendo-card__header-actions">
                        <slot name="headerActions" />
                        <template v-if="collapsible">
                            <pendo-divider
                                direction="vertical"
                                width="1px"
                                height="24px"
                                stroke="#DADCE5" />
                            <pendo-icon-button
                                :tooltip="collapseTooltipContent"
                                class="pendo-card__header-action pendo-card__header-action--collapse"
                                :aria-expanded="`${!collapsed}`"
                                :aria-label="collapsed ? 'Show card content' : 'Hide card content'"
                                :aria-controls="`pendo-card__content-${_uid}`"
                                icon="chevron-up"
                                icon-size="16"
                                @click="toggleCardCollapse" />
                        </template>
                    </div>
                </div>
            </slot>
        </div>
        <pendo-collapse-transition>
            <div
                v-show="!collapsed"
                :id="`pendo-card__content-${_uid}`"
                class="pendo-card__content">
                <div
                    v-if="$slots.body || $slots.default || $slots.filters"
                    :style="{ minHeight: bodyMinHeight }"
                    class="pendo-card__body">
                    <div
                        v-if="$slots.filters"
                        class="pendo-card__filters">
                        <!-- @slot place pendo-filters inside of this slot on charts -->
                        <slot name="filters" />
                    </div>
                    <!-- @slot used to pass default card body content @ignore body -->
                    <slot :name="$slots.body ? 'body' : 'default'" />
                </div>
                <slot name="sections" />
                <div
                    v-if="$slots.footer"
                    :style="{ minHeight: footerMinHeight }"
                    class="pendo-card__footer">
                    <!-- @slot -- -->
                    <slot name="footer" />
                </div>
            </div>
        </pendo-collapse-transition>
    </div>
</template>

<script>
import PendoIconButton from '@/components/icon-button/pendo-icon-button';
import PendoDivider from '@/components/divider/pendo-divider';
import PendoCollapseTransition from '@/utils/pendo-collapse-transition';
import { PendoAnalytics } from '@/directives/analytics/pendo-analytics';

export default {
    name: 'PendoCard',
    components: {
        PendoIconButton,
        PendoDivider,
        PendoCollapseTransition
    },
    directives: {
        PendoAnalytics
    },
    props: {
        /**
         * Title text of card
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * Manual height override of card
         */
        height: {
            type: [String, Number],
            default: null
        },
        /**
         * Manual width override of card
         */
        width: {
            type: [String, Number],
            default: null
        },
        /**
         * Manual min-height override for card header
         */
        headerMinHeight: {
            type: String,
            default: '48px'
        },
        /**
         * Manual min-height override for card body
         */
        bodyMinHeight: {
            type: String,
            default: '240px'
        },
        /**
         * Manual min-height override for card footer
         */
        footerMinHeight: {
            type: String,
            default: '155px'
        },
        /**
         * When to show card shadows
         * @values always, hover, never
         */
        shadow: {
            type: String,
            default: 'never',
            validator: (shadow) => ['always', 'hover', 'never'].includes(shadow)
        },
        /**
         * Collapse/expand card in header
         */
        collapsible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            collapsed: false,
            collapseTooltipContent: 'Collapse'
        };
    },
    computed: {
        showHeader () {
            return (
                !!this.$slots.header ||
                !!this.$slots.headerRight ||
                !!this.$slots.headerActions ||
                !!this.$slots.title ||
                !!this.title ||
                this.collapsible
            );
        }
    },
    methods: {
        collapseCard () {
            requestAnimationFrame(() => {
                this.collapsed = true;
                this.collapseTooltipContent = 'Expand';
                this.$emit('collapse-change', { collapsed: this.collapsed });
            });
        },
        expandCard () {
            requestAnimationFrame(() => {
                this.collapsed = false;
                this.collapseTooltipContent = 'Collapse';
                this.$emit('collapse-change', { collapsed: this.collapsed });
            });
        },
        toggleCardCollapse () {
            this.collapseTooltipContent = null;

            if (this.collapsed) {
                return this.expandCard();
            }

            this.collapseCard();
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-card) {
    @include font-base;
    border-radius: $card-border-radius;
    border: $card-border;
    background-color: $card-background-color;
    color: $color-text-primary;
    overflow-y: auto;

    @include element((header, body, footer)) {
        padding: $card-padding;
        box-sizing: border-box;
    }

    @include element(header) {
        border-bottom: $card-border;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-shrink: 0;
        justify-content: space-between;
        padding: 0 16px;

        h3,
        .pendo-card__title {
            @include heading-reset;
        }

        @include modifier(left) {
            font-size: $card-header-font-size;
        }

        @include modifier(right) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    @include element(header-action) {
        &.pendo-button.pendo-button--secondary {
            background-color: $color-white;
            color: $color-gray-70;
            margin-left: 0;

            &:hover {
                background-color: $color-gray-30;
                color: $color-gray-100;
            }
        }

        @include modifier(collapse) {
            .pendo-icon {
                transition: transform 0.25s;
                transform: rotate(0);
            }
        }
    }

    @include element(header-actions) {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        grid-gap: 8px;
    }

    @include element(content) {
        display: contents;
    }

    @include element(body) {
        min-height: 240px;
    }

    @include element(footer) {
        border-top: $card-border;
        overflow: auto;
    }

    @include element(filters) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    @include is(always-shadow) {
        border: 0;
        box-shadow: $card-always-shadow;
    }

    @include is(hover-shadow) {
        transition: $card-hover-transition;

        &:hover,
        &:focus {
            box-shadow: $card-hover-shadow;
            transform: $card-hover-transform;
        }
    }

    @include modifier(collapsible) {
        @include element(header) {
            padding: 0 8px 0 16px;
            border-bottom: 0;
        }

        @include element(content) {
            display: block;
            border-top: $card-border;
        }
    }

    @include is(collapsed) {
        @include element(header-action) {
            @include modifier(collapse) {
                .pendo-icon {
                    transform: rotate(-180deg);
                }
            }
        }

        @include element(header) {
            border-bottom: 0;
        }
    }
}
</style>
