<template>
    <pendo-button
        v-pendo-tooltip="tooltipConfig"
        class="pendo-icon-button"
        :icon="icon"
        :icon-size="iconSize"
        v-bind="$attrs"
        v-on="$listeners" />
</template>

<script>
import isPlainObject from 'lodash/isPlainObject';
import PendoButton from '@/components/button/pendo-button';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    name: 'PendoIconButton',
    components: {
        PendoButton
    },
    directives: {
        PendoTooltip
    },
    inheritAttrs: false,
    props: {
        /**
         * icon to display
         */
        icon: {
            type: String,
            default: null
        },
        /**
         * sets the size of the prefixIcon, suffixIcon, or icon
         */
        iconSize: {
            type: [String, Number],
            default: '16'
        },
        /**
         * content/config for helper tooltip
         */
        tooltip: {
            type: [String, Object],
            default: null
        }
    },
    computed: {
        tooltipConfig () {
            const defaults = {
                delay: {
                    show: 300,
                    hide: 0
                }
            };

            if (this.tooltip && isPlainObject(this.tooltip)) {
                return {
                    ...defaults,
                    ...this.tooltip
                };
            }

            return {
                ...defaults,
                content: this.tooltip
            };
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-icon-button) {
    &.pendo-button {
        background-color: $color-transparent;
        border-color: $color-transparent;
        color: $color-gray-70;

        &:hover {
            border-color: $color-transparent;
            background-color: $color-gray-30;
            color: $color-gray-100;
        }

        &.is-disabled,
        &.is-disabled:hover {
            color: $color-gray-70;
            opacity: $button-disabled-opacity;
            background-color: transparent;
            border-color: $color-transparent;
        }
    }
}
</style>
