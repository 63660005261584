<template>
    <div class="list-type-selection-top">
        <div
            v-if="showTable && !isLoading"
            class="list-type-buttons-and-search">
            <span class="list-type-buttons">
                <pendo-button
                    theme="app"
                    type="primary"
                    prefix-icon="plus"
                    :label="`${typeVerb} ${typeLabel}`"
                    @click="openAddModal" />
                <pendo-button
                    v-if="isHostType && type === 'server'"
                    theme="app"
                    type="secondary"
                    :label="`Convert to ${usesExcludeList ? 'Include List' : 'Exclude List'}`"
                    @click="openListConversionModal" />
            </span>
            <pendo-input
                v-model="searchInput"
                class="search-input"
                placeholder="Search">
                <template #prefix>
                    <pendo-icon
                        type="search"
                        stroke="#2a2c35"
                        size="16" />
                </template>
            </pendo-input>
        </div>
        <pendo-alert
            v-if="isHostType && type === 'server' && initComplete"
            type="info">
            <strong>Note:&nbsp; </strong>{{ usesIncludeList ? 'Include List' : 'Exclude List' }} is enabled. Analytics
            will
            {{ usesIncludeList ? 'only' : 'not' }}
            be shown for traffic in this list.
            <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://support.pendo.io/hc/en-us/articles/360032209171">Learn more.</a>
        </pendo-alert>
        <pendo-alert
            v-if="hasMoreEntries && initComplete"
            type="warning"
            style="margin-top: 0px">
            <strong>Warning:</strong> Prefix search is required. Only first {{ resultLimit }} rows are displayed.
        </pendo-alert>
        <pendo-modal
            :visible="showListConversionModal"
            :title="`Convert Server Host/Domains to an ${usesExcludeList ? 'Include List' : 'Exclude List'}?`"
            :click-to-close="true"
            :esc-to-close="true"
            :confirm-button-config="{
                type: 'primary',
                theme: 'app',
                label: 'Convert List'
            }"
            :server-host-domain-label="serverHostDomainLabel"
            height="auto"
            width="450px"
            @cancel="closeListConversionModal"
            @close="closeListConversionModal"
            @confirm="handleListConversion">
            <div class="list-type-selection">
                <p class="medium-text">
                    What do you want to do with your existing list?
                </p>
                <pendo-radio-group v-model="listConversionChoice">
                    <pendo-radio
                        v-for="option in listConversionOptions"
                        :key="option.value"
                        v-bind="option" />
                </pendo-radio-group>
                <p><strong>Note: </strong>This change can take up to 5 minutes to process.</p>
            </div>
        </pendo-modal>
        <pendo-modal
            :visible="showRemoveModal"
            title="You are about to permanently remove this item from your list"
            message="Are you sure you want to delete it?"
            :show-close="false"
            type="confirmation"
            :confirm-button-config="{ type: 'danger', theme: 'app', label: 'Remove' }"
            width="400px"
            height="auto"
            @cancel="closeRemoveModal"
            @confirm="removeItem" />
        <add-exclude-list-staging-item-modal
            :has-more-hosts="modalHasMoreHosts"
            :hosts="filteredModalHosts"
            :hosts-loaded="modalHostsLoaded"
            :is-visible="showAddModal"
            :item-type="listType"
            :type="type"
            :fetch-my-ip-address="fetchMyIpAddress"
            :server-host-domain-label="serverHostDomainLabel"
            :staging-modal-alert-link="stagingModalAlertLink"
            :sub-has-extension-app="subHasExtensionApp"
            @onCancel="closeAddModal"
            @onConfirm="addListItem"
            @onSearchChange="debouncedModalSearch" />
        <div
            v-if="!showTable && !isLoading && initComplete && !isStaging"
            class="empty-table">
            <div class="big-text">
                Your team has not added any {{ typeLabel }} entries to the
                {{ type === 'server' && usesIncludeList ? 'Include List' : 'Exclude List' }}.
            </div>
            <span>
                <p>
                    You may add {{ typeLabel }} entries to {{ typeVerb.toLowerCase() }} certain traffic from your
                    analytics.
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://support.pendo.io/hc/en-us/articles/360032209171">
                        Learn more.
                    </a>
                </p>
            </span>
            <div class="empty-table-buttons">
                <pendo-button
                    theme="app"
                    type="primary"
                    prefix-icon="plus"
                    :label="`${typeVerb} ${typeLabel}`"
                    @click="openAddModal" />
                <pendo-button
                    v-if="isHostType"
                    theme="app"
                    type="secondary"
                    :label="`Convert to ${usesExcludeList ? 'Include List' : 'Exclude List'}`"
                    @click="openListConversionModal" />
            </div>
        </div>
        <div
            v-if="!showTable && !isLoading && initComplete & isStaging"
            class="empty-table">
            <img
                src="https://cdn.pendo.io/img/staging-list-empty-state.svg"
                alt="empty table image">
            <div class="big-text">
                Your team has not added any {{ typeLabel }} entries yet.
            </div>
            <span>
                <p>
                    {{ typeVerb }} {{ typeLabel }} entries to provide a site for testing Guides.
                    <a href="https://support.pendo.io/hc/en-us/articles/360032203991-Stage-your-guide"> Learn more.</a>
                </p>
            </span>
            <span class="empty-table-buttons">
                <pendo-button
                    theme="app"
                    type="primary"
                    prefix-icon="plus"
                    :label="`${typeVerb} ${typeLabel}`"
                    @click="openAddModal" />
            </span>
        </div>
        <div
            v-if="!initComplete || isLoading"
            class="loading">
            <pendo-loading-indicator size="medium" />
            <h3>{{ loadingLabel }}</h3>
        </div>
        <pendo-table
            v-if="showTable && !isLoading"
            row-key="id"
            :data="filteredList"
            :loading="!tableLoaded"
            :columns="columns"
            :default-sort="{
                prop: 'name',
                order: 'ascending'
            }"
            auto-height
            :auto-height-offset="90"
            empty-text="No entries found.">
            <template #actions="{ row }">
                <pendo-button
                    theme="app"
                    size="small"
                    type="link"
                    label="Remove"
                    @click="openRemoveModal(row)" />
            </template>
        </pendo-table>
    </div>
</template>

<script>
import PendoAlert from '@/components/alert/pendo-alert.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoRadioGroup from '@/components/radio-group/pendo-radio-group.vue';
import PendoRadio from '@/components/radio/pendo-radio.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoNotification from '@/components/notification/pendo-notification.js';
import AddExcludeListStagingItemModal from './add-exclude-list-staging-item-modal.vue';
import debounce from 'lodash/debounce';
import escapeRegExp from 'lodash/escapeRegExp';

export default {
    name: 'PendoExcludeIncludeStagingListDetail',
    components: {
        PendoAlert,
        PendoButton,
        PendoLoadingIndicator,
        PendoModal,
        PendoRadioGroup,
        PendoRadio,
        PendoTable,
        PendoInput,
        PendoIcon,
        AddExcludeListStagingItemModal
    },
    props: {
        /**
         * determines type of list page to render;
         * eg. 'Server Host/Domain' vs 'Visitor ID'
         */
        type: {
            type: String,
            required: true
        },
        /**
         * override labels for list page
         */
        serverHostDomainLabel: {
            type: String,
            default: 'Server Host/Domain'
        },
        /**
         * set or check if sub has whitelistServers feature flag
         * @param {Boolean} value to set, if used as a setter
         * @return {Promise} if used as a setter
         * @return {Boolean} if used as a getter
         */
        accountUsesIncludeList: {
            type: Function,
            default: () => false
        },
        /**
         * glob-regex matching function
         */
        matches: {
            type: Function,
            required: true
        },
        /**
         * function to get user's ip address
         */
        fetchMyIpAddress: {
            type: Function,
            required: true
        },
        /**
         * function to get host/domain by 'type'
         */
        fetchHostsForType: {
            type: Function,
            required: true
        },
        /**
         * function to update host/domain
         */
        updateHost: {
            type: Function,
            required: true
        },
        /**
         * function to fetch exclude list by 'type'
         */
        fetchExcludeListForType: {
            type: Function,
            required: true
        },
        /**
         * function to add new item to exclude list
         */
        createExcludeListItem: {
            type: Function,
            required: true
        },
        /**
         * function to remove item from exclude list
         */
        removeExcludeListItem: {
            type: Function,
            required: true
        },
        /**
         * function to localize error toast props
         */
        localizeApp: {
            type: Function,
            default: null
        },
        stagingModalAlertLink: {
            type: String,
            default: ''
        },
        subHasExtensionApp: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hasMoreEntries: false,
            hostEntries: [],
            initComplete: false,
            isLoading: false,
            itemToRemove: null,
            listConversionChoice: 'clear',
            loadingLabel: 'Loading...',
            modalHosts: [],
            modalHostsLoaded: false,
            modalHasMoreHosts: false,
            searchInput: '',
            showAddModal: false,
            showListConversionModal: false,
            showRemoveModal: false,
            tableLoaded: false,
            typeEntries: [],
            typeGlobEntries: [],
            resultLimit: 1000
        };
    },
    computed: {
        listEntries () {
            return [...this.hostEntries, ...this.typeEntries, ...this.typeGlobEntries];
        },
        listType () {
            return this.isStaging ? 'staging' : 'excludelist';
        },
        columnHeader () {
            if (this.type === 'server') {
                return `${this.typeVerbPastParticiple} ${this.typeLabel}`;
            }

            return this.typeLabel;
        },
        columns () {
            return [
                {
                    prop: 'name',
                    label: this.columnHeader,
                    sortable: true
                },
                {
                    type: 'actions',
                    width: 80
                }
            ];
        },
        filteredList () {
            return this.listEntries.filter((entry) => {
                if (entry) {
                    const { name, type } = entry;
                    const matches = type && this.matches ? this.matches(name, this.type, entry) : true;

                    return matches && name.toLowerCase().includes(this.searchInput.toLowerCase());
                }

                return false;
            });
        },
        isHostType () {
            return this.type === 'server' || this.isStaging;
        },
        showTable () {
            return (this.listEntries && this.listEntries.length > 0 && this.initComplete) || this.hasMoreEntries;
        },
        typeLabel () {
            return this.typeMap[this.type].name;
        },
        typeVerb () {
            if (this.type === 'server') {
                return this.usesExcludeList ? 'Exclude' : 'Include';
            }

            return this.isStaging ? 'Add' : 'Exclude';
        },
        typeVerbPastParticiple () {
            return {
                Exclude: 'Excluded',
                Include: 'Included',
                Add: 'Added'
            }[this.typeVerb];
        },
        typeMap () {
            return {
                accountId: {
                    name: 'Account ID'
                },
                ip: {
                    name: 'IP Address'
                },
                server: {
                    name: 'Server Host/Domain'
                },
                staging: {
                    name: this.serverHostDomainLabel
                },
                visitorId: {
                    name: 'Visitor ID'
                }
            };
        },
        usesExcludeList () {
            return !this.usesIncludeList;
        },
        usesIncludeList () {
            return this.accountUsesIncludeList();
        },
        listConversionOptions () {
            return [
                {
                    value: 'clear',
                    label: 'Clear List – Remove server host/domains in this list.'
                },
                {
                    value: 'transfer',
                    label: `Transfer List – Move server host/domains to ${
                        this.usesExcludeList ? 'Include List' : 'Exclude List'
                    }.`
                }
            ];
        },
        isStaging () {
            return this.type === 'staging';
        },
        filteredModalHosts () {
            const isServer = this.type === 'server';

            if (this.typeGlobEntries.length && isServer) {
                return this.modalHosts.filter((host) => {
                    return this.typeGlobEntries.every((entry) => {
                        const wildcard = entry.name;
                        const regex = new RegExp(
                            escapeRegExp(wildcard)
                                .replace(/^\\\*/, '.*')
                                .replace(/\\\*$/, '.*')
                        );

                        return !regex.test(host.name);
                    });
                });
            }

            return this.modalHosts;
        }
    },
    watch: {
        searchInput (newValue) {
            if (!this.hasMoreEntries) {
                return;
            }
            this.tableLoaded = false;

            this.debouncedTableSearch(newValue);
        }
    },
    created () {
        this.fetchListEntries();
        this.debouncedTableSearch = debounce(this.tableSearch, 1000);
        this.debouncedModalSearch = debounce(this.modalSearch, 1000);
    },
    methods: {
        tableSearch (searchTerm) {
            this.fetchEntries(searchTerm);
            this.fetchEntriesForGlob(searchTerm);
        },
        modalSearch (searchTerm) {
            this.modalHostsLoaded = false;
            this.fetchModalHosts(searchTerm);
        },
        addListItem (item) {
            if (this.isHostType) {
                this.loadingLabel = 'Adding entries...';
                this.isLoading = true;

                item.hosts = item.hosts || [];
                item.wildcards = item.wildcards || [];

                const hostPromises = item.hosts.map((host) => {
                    host.flags[this.isStaging ? 'staging' : 'blacklisted'] = true;

                    return this.updateHost(host).then(() => {
                        this.hostEntries.push(host);

                        if (this.modalHostsLoaded) {
                            this.modalHosts = this.modalHosts.filter((entry) => {
                                return entry.id !== host.id;
                            });
                        }
                    });
                });

                const wildcardPromises = item.wildcards.map((wildcard) => {
                    return this.createExcludeListItem(wildcard).then(({ data }) => {
                        if (wildcard.type.includes('Glob')) {
                            return this.typeGlobEntries.push(data);
                        }

                        return this.typeEntries.push(data);
                    });
                });

                this.closeAddModal();

                Promise.all(hostPromises.concat(wildcardPromises))
                    .catch((error) => {
                        this.isLoading = false;
                        this.showErrorNotification(error, true);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                return this.createExcludeListItem(item)
                    .then(({ data }) => {
                        if (item.type.includes('Glob')) {
                            this.typeGlobEntries.push(data);
                        } else {
                            this.typeEntries.push(data);
                        }
                        this.closeAddModal();
                    })
                    .catch((error) => {
                        this.closeAddModal();
                        this.showErrorNotification(error, true);
                    });
            }
        },
        clearHostList () {
            this.loadingLabel = 'Clearing entries...';
            this.isLoading = true;

            return this.transferHostList().then(() => {
                Promise.all(
                    this.listEntries.map((item) => {
                        return this.removeItem(item);
                    })
                ).then(() => {
                    this.isLoading = false;
                });
            });
        },
        closeAddModal () {
            this.showAddModal = false;
        },
        closeListConversionModal () {
            this.showListConversionModal = false;
        },
        closeRemoveModal () {
            this.showRemoveModal = false;
        },
        fetchListEntries () {
            return Promise.all([this.fetchEntries(), this.fetchEntriesForGlob()]).then(() => {
                this.initComplete = true;
                this.tableLoaded = true;
            });
        },
        async fetchHosts (searchTerm = '') {
            if (this.isHostType) {
                const hostType = this.type === 'server' ? 'blacklisted' : 'staging';
                const { data } = await this.fetchHostsForType(hostType, false, searchTerm);
                this.hostEntries = data.results.filter((host) => {
                    return host.name !== '';
                });
                this.tableLoaded = true;
                if (data.hasMore) {
                    this.hasMoreEntries = true;
                }
            }
        },
        async fetchModalHosts (searchTerm = '') {
            const hostType = this.type === 'server' ? 'blacklisted' : 'staging';
            const { data } = await this.fetchHostsForType(hostType, true, searchTerm);
            this.modalHosts = data.results.filter((host) => {
                return host.name !== '';
            });
            this.modalHostsLoaded = true;
            if (data.hasMore) {
                this.modalHasMoreHosts = true;
            }
        },
        async fetchEntries (searchTerm = '') {
            if (this.isHostType) {
                return this.fetchHosts(searchTerm);
            }

            const { data } = await this.fetchExcludeListForType(this.type, searchTerm);
            this.typeEntries = data.results;
            this.tableLoaded = true;
            if (data.hasMore) {
                this.hasMoreEntries = true;
            }
        },
        async fetchEntriesForGlob (searchTerm = '') {
            const { data } = await this.fetchExcludeListForType(`${this.type}Glob`, searchTerm);
            this.typeGlobEntries = data.results;
            this.tableLoaded = true;
            if (data.hasMore) {
                this.hasMoreEntries = true;
            }
        },
        handleListConversion () {
            return this.listConversionChoice === 'clear' ? this.clearHostList() : this.transferHostList();
        },
        openAddModal () {
            this.showAddModal = true;

            if (this.modalHostsLoaded) {
                return;
            }

            return this.fetchModalHosts();
        },
        openListConversionModal () {
            if (!this.showTable) {
                return this.transferHostList();
            }
            this.showListConversionModal = true;
        },
        openRemoveModal (host) {
            this.itemToRemove = host;
            this.showRemoveModal = true;
        },
        async removeItem (itemToRemove) {
            itemToRemove = itemToRemove || this.itemToRemove;
            try {
                if (!itemToRemove.type) {
                    itemToRemove.flags[this.isStaging ? 'staging' : 'blacklisted'] = false;

                    await this.updateHost(itemToRemove);
                    if (this.modalHostsLoaded) {
                        this.modalHosts.push(itemToRemove);
                    }

                    this.hostEntries = this.hostEntries.filter((entry) => {
                        return entry.id !== itemToRemove.id;
                    });
                } else {
                    await this.removeExcludeListItem(itemToRemove);
                    const targetList = itemToRemove.type.includes('Glob') ? 'typeGlobEntries' : 'typeEntries';
                    this[targetList] = this[targetList].filter((entry) => entry.id !== itemToRemove.id);
                }
            } catch (error) {
                this.showErrorNotification(error, false);
            }

            this.closeRemoveModal();
        },
        async transferHostList () {
            await this.accountUsesIncludeList(!this.usesIncludeList);
            this.closeListConversionModal();
        },
        showErrorNotification (error, isAdding) {
            const listType = `${this.typeVerb} List`;
            const title = this.localizeApp
                ? this.localizeApp("{listType} wasn't updated", { listType })
                : `${listType} wasn't updated`;

            PendoNotification({
                dangerouslyUseHTMLString: true,
                type: 'error',
                duration: 4000,
                title,
                message: this.getNotificationMessage(error, isAdding)
            });
        },
        getNotificationMessage (error, isAdding) {
            const { data, status } = error.response || {};
            let message = '';
            const listItemType = this.typeLabel;
            const listType = `${this.typeVerb} List`;

            if (!isAdding) {
                message = this.localizeApp
                    ? this.localizeApp(
                        'Something went wrong when removing the {listItemType} from the {listType}. Please refresh and try again.',
                        { listItemType, listType }
                    )
                    : `Something went wrong when removing the ${listItemType} from the ${listType}. Please refresh and try again.`;

                return message;
            }

            if (status === 400) {
                message = this.localizeApp
                    ? this.localizeApp("We couldn't add the {listItemType} to the {listType}. {errorMessage}.", {
                        listItemType,
                        listType,
                        errorMessage: data
                    })
                    : `We couldn't add the ${listItemType} to the ${listType}. ${data}.`;

                return message;
            }

            message = this.localizeApp
                ? this.localizeApp(
                    'Something went wrong when adding the {listItemType} to the {listType}. Please refresh and try again.',
                    { listItemType, listType }
                )
                : `Something went wrong when adding the ${listItemType} to the ${listType}. Please refresh and try again.`;

            return message;
        }
    }
};
</script>

<style lang="scss" scoped>
.big-text {
    font-size: 24px;
    margin-bottom: 10px;
}

.medium-text {
    font-size: 18px;
}

.empty-table-buttons {
    margin-top: 10px;
    display: inline-flex;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.empty-table {
    line-height: 1.4;
    text-align: center;
}

.list-type-selection {
    display: grid;
    grid-gap: 20px;
}

.list-type-selection-top {
    display: grid;
    grid-gap: 20px;
}

.list-type-buttons-and-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-type-buttons {
    display: flex;
}

.search-input {
    max-width: 208px;
}
</style>
