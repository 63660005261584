<template>
    <div class="app-details">
        <pendo-card
            title="Basic settings"
            body-min-height="0"
            class="settings-group">
            <div class="settings-row app-display-name">
                <div class="settings-label">
                    Display name
                </div>
                <div class="settings-content">
                    <pendo-editable-content
                        :value="app.displayName || 'Not set'"
                        :before-exit="onDisplayNameChange">
                        <template #append="{ confirm, cancel, pending }">
                            <pendo-button
                                :loading="pending"
                                theme="app"
                                icon="check"
                                type="primary"
                                @click="confirm" />
                            <pendo-button
                                theme="app"
                                type="secondary"
                                icon="x"
                                @click="cancel" />
                        </template>
                    </pendo-editable-content>
                </div>
            </div>
            <div
                v-if="!isPartnerAdoptApplication"
                class="settings-row app-platform">
                <div class="settings-label">
                    Platform
                </div>
                <div class="settings-content">
                    <span class="app-platform-contents">
                        {{ platformLabel }}
                    </span>
                </div>
            </div>
            <div
                v-if="showExtensionPlatformType"
                class="settings-row app-extension-platform-type">
                <div class="settings-label">
                    <div>Extension platform type</div>
                    <small class="aside">
                        For information, see our
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/21165049812635-Add-extension-apps"
                            target="_blank">
                            browser extension documentation.
                        </a>
                    </small>
                </div>
                <div class="settings-content">
                    <span>
                        {{ extensionPlatformType }}
                    </span>
                </div>
            </div>
            <div
                v-if="showExtensionDomainPatterns"
                class="settings-row extension-domain-patterns">
                <div class="settings-label">
                    <div>Websites</div>
                    <small class="aside">
                        The domains associated with the application.
                    </small>
                </div>
                <div class="settings-content">
                    <edit-extension-domain-patterns
                        :submission-error="submissionError"
                        :saving-domain-patterns="savingChanges"
                        :app="app"
                        :websites-modal-alert-link="websitesModalAlertLink"
                        v-on="$listeners" />
                </div>
            </div>
            <div
                v-if="showSelectiveUrls"
                class="settings-row extension-regular-expressions">
                <div class="settings-label">
                    <div>Selective URLs</div>
                    <small class="aside">
                        Regular expressions used to create rules for establishing an app connection. To configure
                        selective URLs, contact Pendo Support.
                    </small>
                </div>
                <div class="settings-content">
                    <display-edit-extension-regular-expressions
                        :app="app"
                        :can-edit-reg-ex="canEditExtensionRegularExpressions"
                        :submission-error="submissionError"
                        :saving-reg-exp="savingChanges"
                        :websites-modal-alert-link="websitesModalAlertLink"
                        :selective-url-support-link="selectiveUrlSupportLink"
                        v-on="$listeners" />
                </div>
            </div>
            <div
                v-if="showApiKey"
                class="settings-row">
                <div class="settings-label">
                    <div>API key</div>
                    <small class="aside">
                        The 32-digit code used to connect your application to Pendo.
                    </small>
                </div>
                <div class="settings-content">
                    <div>{{ app.apiKey }}</div>
                </div>
            </div>
            <div
                v-if="showTrackEventSecretSetting"
                class="settings-row track-event-secret">
                <div class="settings-label">
                    <div>Track Event shared secret</div>
                    <small class="aside">
                        The key to connect
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/360032294151"
                            target="_blank">
                            Track Event
                        </a>
                        data to a specific app.
                    </small>
                </div>
                <div class="settings-content">
                    <div class="track-event-contain">
                        <pendo-button
                            theme="app"
                            type="link"
                            :label="isShowingTrackEventSecret ? 'Hide' : 'Show'"
                            @click="() => (isShowingTrackEventSecret = !isShowingTrackEventSecret)" />
                        <span v-if="isShowingTrackEventSecret">
                            {{ app.trackEventSecret }}
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="showCnameSetting"
                class="settings-row">
                <div class="settings-label">
                    <div>CNAME</div>
                    <small class="aside">
                        The content and data domains established during
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/360043539891-CNAME-for-Pendo"
                            target="_blank">
                            CNAME
                        </a>
                        configuration.
                    </small>
                </div>
                <div class="settings-content">
                    <div v-if="!isCnameConfigured">
                        Not configured
                    </div>
                    <div v-if="isCnameConfigured">
                        Content: <strong>{{ app.contentCname }}</strong>, Data: <strong>{{ app.dataCname }}</strong>
                    </div>
                </div>
            </div>
            <div
                v-if="showGuidesToAnonymousVisitorsSetting"
                class="settings-row flag-show-guides-anon-visitors">
                <div>
                    <div class="settings-label">
                        <div>Show guides to anonymous visitors</div>
                        <small class="aside">
                            Whether anonymous visitors are included within guide segmentation. If you change this
                            setting, you must also reset the segment for each pre-existing guide that you'd like this
                            change to impact.
                        </small>
                    </div>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'showGuidesToAnon'"
                        :value="app.applicationFlags.showGuidesToAnon"
                        @change="handleFlagChange($event, 'showGuidesToAnon')" />
                </div>
            </div>
            <div
                v-if="showDisableDesignerLaunchUrlToken"
                class="settings-row flag-designer-launch-url-token">
                <div class="settings-label">
                    Disable Designer launch URL token
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disableDesignerUrlToken'"
                        :value="app.applicationFlags.disableDesignerUrlToken"
                        @change="handleFlagChange($event, 'disableDesignerUrlToken')" />
                </div>
            </div>
            <div
                v-if="showTrackEventTimeOnSiteSetting"
                class="settings-row">
                <div class="settings-label">
                    <div>Count Track Events as time in app</div>
                    <small class="aside">
                        Allow Track Events to count toward a user's time in your application. For more information, see
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/360032203171"
                            target="_blank">Time on page and app calculations</a>.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'trackEventTimeOnSite'"
                        :value="app.applicationFlags.trackEventTimeOnSite"
                        @change="handleFlagChange($event, 'trackEventTimeOnSite')" />
                </div>
            </div>
            <div
                v-if="showDisableGlobalCss"
                class="settings-row">
                <div class="settings-label">
                    <div>Disable global CSS</div>
                    <small class="aside">
                        Prevent the Pendo agent from injecting global CSS in this application. For more information, see
                        our
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/23124276217115"
                            target="_blank">
                            global CSS documentation.</a>
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disableGlobalCSS'"
                        :value="app.applicationFlags.disableGlobalCSS"
                        @change="handleFlagChange($event, 'disableGlobalCSS')" />
                </div>
            </div>
            <div
                v-if="showSuggestedPagesSetting"
                class="settings-row flag-suggest-page-tags">
                <div class="settings-label">
                    <div>Suggested Pages</div>
                    <small class="aside">
                        Generate recommended Page rules based on URLs from your event data.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'suggestPageTags'"
                        :value="app.applicationFlags.suggestPageTags"
                        @change="onSuggestPageTagsChange($event)" />
                </div>
            </div>
            <div
                v-if="!isPartnerAdoptApplication"
                class="settings-row nested flag-enable-page-event-properties">
                <div class="settings-label">
                    <div>Page event properties</div>
                    <small class="aside">
                        Set up event properties to configure the collection of UI data upon page load, such as capturing
                        page titles when URLs load. For more information, see
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/23299649841051/"
                            target="_blank">
                            Page event properties</a>.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-data-collection-consent-toggle
                        :is-for-customer="isImpersonating"
                        theme="app"
                        :value="pageEventPropertiesButtonValue"
                        type="button"
                        :loading="loading"
                        legal-title="Enable Page Event properties"
                        :disabled="updatingApp"
                        requires-opt-out
                        @change="configurePageEventProperties($event)">
                        <template #disableBody>
                            By turning off this setting, you are opting out of collecting page event properties. Tags in
                            your application that use page event properties may break and can impact behavior when using
                            Pendo, such as in guides, workflows, paths, etc.
                            <strong>
                                We encourage you to keep this setting on to enable the best Pendo experience.</strong>
                            <br>
                            <br>
                            For questions, please reach out directly to your Pendo support representative or
                            <a href="mailto:support@pendo.io"> contact support. </a>
                        </template>

                        <template #enableBody>
                            The feature you are enabling may allow you to collect personal data and send it to Pendo.
                            Please be mindful of your company's policies regarding personal data collection and
                            processing, and the setting you select when configuring Page Event properties.
                            <br>
                            <br>
                            For questions, please reach out directly to your Pendo support representative or
                            <a href="mailto:support@pendo.io"> contact support. </a>
                            <br>
                            <br>
                        </template>
                    </pendo-data-collection-consent-toggle>
                </div>
                <template v-if="app.applicationFlags.pageBasedEventProperties">
                    <div class="settings-label indent">
                        <div>Collect page titles</div>
                        <small class="aside">
                            Add the &lt;title&gt; value of each webpage to the URL collected by the agent.
                        </small>
                    </div>
                    <div class="settings-content">
                        <pendo-data-collection-consent-toggle
                            :is-for-customer="isImpersonating"
                            theme="app"
                            :value="collectPageTitlesButtonValue"
                            type="button"
                            :loading="loading"
                            legal-title="Enable collect page titles"
                            :disabled="updatingApp || !pageEventPropertiesButtonValue"
                            requires-opt-out
                            @change="configureCollectPageTitles($event, 'document_title', true, 'document.title')">
                            <template #disableBody>
                                By turning off this setting, you are opting out of collecting page titles. Tags in your
                                application that use page titles may break and can impact behavior when using Pendo,
                                such as in guides, workflows, paths, etc.
                                <strong>
                                    We encourage you to keep this setting on to enable the best Pendo
                                    experience.</strong>
                                <br>
                                <br>
                                For questions, please reach out directly to your Pendo support representative or
                                <a href="mailto:support@pendo.io"> contact support. </a>
                            </template>

                            <template #enableBody>
                                The feature you are enabling may allow you to collect personal data and send it to
                                Pendo. Please be mindful of your company's policies regarding personal data collection
                                and processing, and the setting you select when configuring Collect Page Titles.
                                <br>
                                <br>
                                For questions, please reach out directly to your Pendo support representative or
                                <a href="mailto:support@pendo.io"> contact support. </a>
                                <br>
                                <br>
                            </template>
                        </pendo-data-collection-consent-toggle>
                    </div>
                </template>
            </div>
            <div
                v-if="showTextCaptureFromEventsSetting"
                class="settings-row flag-disable-extension-text-capture">
                <div>
                    <div class="settings-label">
                        <div>Text capture from events</div>
                        <small class="aside">
                            Allow Pendo to collect innerHTML text as part of automatically captured Click Events. This
                            setting supersedes the subscription-level setting for the application.
                        </small>
                    </div>
                </div>
                <div class="settings-content">
                    {{ app.applicationFlags.disableExtensionTextCapture ? 'No' : 'Yes' }}
                </div>
            </div>
            <div
                v-if="showFaviconSettings"
                class="settings-row app-branding">
                <div class="settings-label">
                    <div>Branding</div>
                    <small class="aside">
                        {{ brandingText }}
                    </small>
                </div>
                <div class="settings-content">
                    <div class="favicon-selection">
                        <p class="favicon-label">
                            Favicon
                        </p>
                        <div class="favicon-button-img">
                            <pendo-icon
                                v-if="!faviconB64"
                                class="set-favicon"
                                :type="platformIcon.type"
                                :fill="platformIcon.fill"
                                stroke="#000000" />
                            <img
                                v-else
                                class="set-favicon"
                                :src="faviconB64"
                                alt="favicon">
                            <pendo-button
                                theme="app"
                                type="link"
                                label="Replace"
                                @click="displayFaviconModal" />
                            <pendo-favicon-upload
                                :visible="showFaviconModal"
                                :url-favicon="faviconUrl"
                                @fetch-favicon="getFaviconByUrl"
                                @cancel-upload="closeFaviconModal"
                                @upload-favicon="submitFavicon" />
                        </div>
                    </div>
                    <div
                        v-if="!isEngage"
                        class="color-selection">
                        <div
                            v-if="!isEditingColor"
                            class="color-display-container">
                            <div class="color-display">
                                <pendo-icon
                                    class="color-display-icon"
                                    type="square"
                                    aria-hidden="true"
                                    :fill="color"
                                    :stroke="color ? color : '#000000'" />
                                <span>{{ color ? color.toUpperCase() : 'App color not set' }}</span>
                            </div>
                            <pendo-button
                                type="link"
                                label="Edit"
                                @click="() => (isEditingColor = !isEditingColor)" />
                        </div>
                        <div
                            v-else
                            class="color-editor">
                            <app-color-picker
                                class="color-picker"
                                :initial-color="color"
                                :swatch-colors="swatchColors"
                                @favicon-color-changed="onColorChange"
                                @cancel-color-change="() => (isEditingColor = !isEditingColor)" />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="showDisableDesignerLaunchUrlSetting"
                class="settings-row flag-designer-launch-url-token">
                <div class="settings-label">
                    Disable Designer launch URL token
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disableDesignerUrlToken'"
                        :value="app.applicationFlags.disableDesignerUrlToken"
                        @change="handleFlagChange($event, 'disableDesignerUrlToken')" />
                </div>
            </div>
            <div
                v-if="showVisitorIdentificationFunctionSetting"
                class="settings-row flag-extension-inject-identity-function">
                <div class="settings-label">
                    Inject visitor identification function for browser scripting
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'extensionInjectIdentityFunction'"
                        :value="app.applicationFlags.extensionInjectIdentityFunction"
                        @change="handleFlagChange($event, 'extensionInjectIdentityFunction')" />
                </div>
            </div>
            <div
                v-if="showDisableInheritedPagesFeaturesSetting"
                class="settings-row flag-disable-passdown">
                <div>
                    <div class="settings-label">
                        Disable inherited pages and features
                    </div>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disablePassDown'"
                        :value="app.applicationFlags.disablePassDown"
                        @change="handleFlagChange($event, 'disablePassDown')" />
                </div>
            </div>
            <div
                v-if="showSiblingSelectorsSetting"
                class="settings-row flag-enable-sibling-selectors">
                <div class="settings-label">
                    <div>Sibling selectors</div>
                    <small class="aside">
                        Allows feature tagging based on CSS sibling selectors. This can be useful in applications
                        without strong unique identifiers.
                    </small>
                </div>
                <pendo-data-collection-consent-toggle
                    :is-for-customer="isImpersonating"
                    theme="app"
                    :value="app.applicationFlags.siblingSelectors"
                    type="button"
                    :loading="loading"
                    :disabled="updatingAppFlag === 'siblingSelectors'"
                    requires-opt-out
                    @change="handleFlagChange($event, 'siblingSelectors')">
                    <template #disableBody>
                        By turning off this setting, you are opting out of receiving some strong features from Pendo.
                        Tags in your application may be weak and can impact behavior when using Pendo, such as in
                        guides, workflows, paths, etc.
                        <strong> We encourage you to keep this setting on to enable the best Pendo experience.</strong>
                        <br>
                        <br>
                        For questions, please reach out directly to your Pendo support representative or
                        <a href="mailto:support@pendo.io"> contact support. </a>
                    </template>

                    <template #enableBody>
                        By enabling sibling selectors, I understand that Pendo will collect more data on my application.
                        This setting enables Pendo to share feature tags which use that data to generate a strong tag
                        rule.
                        <br>
                        <br>
                        For questions, please reach out directly to your Pendo support representative or
                        <a href="mailto:support@pendo.io"> contact support. </a>
                        <br>
                        <br>
                    </template>
                </pendo-data-collection-consent-toggle>
            </div>
        </pendo-card>
        <pendo-card
            v-if="showCookiesAndDataPrivacySettings"
            title="Cookies and data privacy"
            body-min-height="0"
            class="settings-group">
            <div
                v-if="showMaxCookieTTLSetting"
                class="settings-row settings-row--max-cookie-ttl">
                <div class="settings-label">
                    <div>Cookie lifespan</div>
                    <small class="aside">
                        The maximum number of days that a web browser cookie set by the Pendo agent remains valid before
                        expiring.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-editable-content
                        :value="maxCookieTTLDays"
                        empty-text="Not set"
                        allow-empty
                        :validation-rules="maxCookieTTLValidationRules"
                        :before-exit="onMaxCookieTTLChange">
                        <template #append="{ confirm, cancel, pending }">
                            <pendo-button
                                :loading="pending"
                                theme="app"
                                icon="check"
                                type="primary"
                                @click="confirm" />
                            <pendo-button
                                theme="app"
                                type="secondary"
                                icon="x"
                                @click="cancel" />
                        </template>
                    </pendo-editable-content>
                </div>
            </div>
            <div
                v-if="showPreventCookieRefresh"
                class="settings-row settings-row--prevent-cookie-refresh">
                <div class="settings-label">
                    <div>Prevent cookie refresh</div>
                    <small class="aside">
                        Prevent cookies from refreshing their expiration timestamps.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :value="!!app.applicationFlags.preventCookieRefresh"
                        @change="handleFlagChange($event, 'preventCookieRefresh')" />
                </div>
            </div>
            <div
                v-if="showCookieDomainSetting"
                class="settings-row settings-row--app-cookie-domain">
                <div class="settings-label">
                    <div>Cookie domain</div>
                    <small class="aside">
                        Enable agent cookies for a specific domain or subdomain of your app. Alternatively, you can
                        define this directly in the install script.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-editable-content
                        :value="app.cookieDomain"
                        empty-text="Not set"
                        allow-empty
                        :validation-rules="cookieDomainValidationRules"
                        :before-exit="onCookieDomainChange">
                        <template #append="{ confirm, cancel, pending }">
                            <pendo-button
                                :loading="pending"
                                theme="app"
                                icon="check"
                                type="primary"
                                @click="confirm" />
                            <pendo-button
                                theme="app"
                                type="secondary"
                                icon="x"
                                @click="cancel" />
                        </template>
                    </pendo-editable-content>
                </div>
            </div>
            <div
                v-if="showCrossAppAgentCookiesSetting"
                class="settings-row settings-row--shared-cookies">
                <div class="settings-label">
                    <div>Enable cross-app agent cookies and local storage</div>
                    <small class="aside">
                        Support shared visitor identification across apps within the same web domain. Agent cookies are
                        necessary to identify visitors across subdomains.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        v-pendo-tooltip="
                            disableSharedCookies &&
                                'This feature is not available because client-side storage is disabled.'
                        "
                        :disabled="updatingApp || disableSharedCookies"
                        :value="!!app.identityStorageSuffix"
                        @change="onSharedCookieChange(app.identityStorageSuffix)" />
                </div>
            </div>
            <div
                v-if="showUsePostOnlySetting"
                class="settings-row settings-row--post-only">
                <div class="settings-label">
                    <div>Use POST requests only to send data to Pendo</div>
                    <small class="aside">
                        If enabled, the agent only sends data back to Pendo using POST requests instead of GET requests.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'sendEventsWithPostOnly'"
                        :value="app.applicationFlags.sendEventsWithPostOnly"
                        @change="handleFlagChange($event, 'sendEventsWithPostOnly')" />
                </div>
            </div>
            <div
                v-if="showNoCookiesNoLocalstorageSetting"
                class="settings-row settings-row--local-storage-only">
                <div class="settings-label">
                    <div>Don't use cookies when local storage is unavailable</div>
                    <small class="aside">
                        Select this setting to prevent the web agent from using cookies when local storage isn't
                        available.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'localStorageOnly'"
                        :value="app.applicationFlags.localStorageOnly"
                        @change="handleFlagChange($event, 'localStorageOnly')" />
                </div>
            </div>
            <div
                v-if="showDisableAgentIdentificationPersistenceSetting"
                class="settings-row settings-row--disable-persistence">
                <div class="settings-label">
                    <div>Disable agent user identification persistence</div>
                    <small class="aside"> Actively remove pre-existing client-side visitor data at agent load. </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disablePersistence'"
                        :value="app.applicationFlags.disablePersistence"
                        @change="handleFlagChange($event, 'disablePersistence')" />
                </div>
            </div>
            <div
                v-if="showDisableClientSideStorageSetting"
                class="settings-row settings-row--disable-cookies">
                <div class="settings-label">
                    <div>Disable client-side storage</div>
                    <small class="aside"> Select this setting to disable agent cookies and local storage. </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        v-pendo-tooltip="
                            !!app.identityStorageSuffix &&
                                'This option is disabled because cross-app tracking is enabled.'
                        "
                        :disabled="!!app.identityStorageSuffix || updatingAppFlag === 'disableCookies'"
                        :value="app.applicationFlags.disableCookies"
                        @change="handleFlagChange($event, 'disableCookies')" />
                </div>
            </div>
        </pendo-card>
        <pendo-card
            v-if="!isPartnerAdoptApplication"
            title="Engineer settings"
            body-min-height="0"
            class="settings-group">
            <div class="settings-row app-extension-platform-type">
                <div class="settings-label">
                    <div>Application ID</div>
                    <small class="aside">
                        The unique identifier for your application. This can be used to identify this application in API
                        integrations and Aggregations. For more information, see our
                        <a
                            href="https://engageapi.pendo.io/"
                            target="_blank">API documentation</a>.
                    </small>
                </div>
                <div class="settings-content">
                    <span>
                        {{ app.id }}
                    </span>
                </div>
            </div>
            <div
                v-if="showDisableFeedbackAutoInit"
                class="settings-row">
                <div class="settings-label">
                    <div>Disable Feedback auto init</div>
                    <small class="aside">
                        Turns off automatic visitor identification for Pendo Feedback within your application.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="!isOpsUser && updatingAppFlag === 'disableFeedbackAutoInit'"
                        :value="app.applicationFlags.disableFeedbackAutoInit"
                        @change="handleFlagChange($event, 'disableFeedbackAutoInit')" />
                </div>
            </div>
            <div
                v-if="showEnableShadowRoot"
                class="settings-row flag-enable-shadow-root">
                <div>
                    <div class="settings-label">
                        <div>Enable shadow root</div>
                        <small class="aside">
                            Prevents the styling of third party applications from affecting guides by isolating
                            extension app guides and Resource Centers into a shadow root DOM item.
                        </small>
                    </div>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'extensionEnableShadowRoot'"
                        :value="app.applicationFlags.extensionEnableShadowRoot"
                        @change="handleFlagChange($event, 'extensionEnableShadowRoot')" />
                </div>
            </div>
            <div
                v-if="showMutationObserverSetting"
                class="settings-row nested">
                <div class="settings-label">
                    <div>Prefer <code>MutationObserver</code> to 500ms timer</div>
                    <small class="aside">
                        Use the browser's <code>MutationObserver</code> to detect changes in the DOM instead of checking
                        for changes every 500ms.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'preferMutationObserver'"
                        :value="app.applicationFlags.preferMutationObserver"
                        @change="handleFlagChange($event, 'preferMutationObserver')" />
                </div>
                <template v-if="showShadowDomMutationObserverSetting">
                    <div class="settings-label indent">
                        Use <code>MutationObserver</code> on Shadow DOM elements
                    </div>
                    <div class="settings-content">
                        <pendo-checkbox
                            :disabled="updatingAppFlag === 'observeShadowRoots'"
                            :value="app.applicationFlags.observeShadowRoots"
                            @change="handleFlagChange($event, 'observeShadowRoots')" />
                    </div>
                </template>
            </div>
            <div
                v-if="showPreferBroadcastChannelSetting"
                class="settings-row">
                <div class="settings-label">
                    <div>Prefer <code>BroadcastChannel</code> for multi-frame support</div>
                    <small class="aside">
                        Use the <code>BroadcastChannel</code> API to communicate between frames in the same window. If
                        your application contains iframes with different origins or domains, this setting will need to
                        be disabled to allow the iframes to communicate with each other.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'preferBroadcastChannel'"
                        :value="app.applicationFlags.preferBroadcastChannel"
                        @change="handleFlagChange($event, 'preferBroadcastChannel')" />
                </div>
            </div>
            <div class="settings-row">
                <div class="settings-label">
                    <div>Use visibility API instead of unload event</div>
                    <small class="aside">
                        When enabled, the agent will use the <code>visibilitychange</code> event instead of
                        <code>unload</code> when a visitor navigates away. For more information, see the
                        <code>preventUnloadListener</code> option in our
                        <a
                            href="https://agent.pendo.io/config/Core/"
                            target="_blank">Agent API documentation</a>.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'preventUnloadListener'"
                        :value="app.applicationFlags.preventUnloadListener"
                        @change="handleFlagChange($event, 'preventUnloadListener')" />
                </div>
            </div>
            <div class="settings-row">
                <div class="settings-label">
                    <div>Attempt click capture on removed elements</div>
                    <small class="aside">
                        Capture Click Events on elements that might've been removed between <code>mousedown</code> and
                        <code>mouseup</code> browser events. For more information, see the
                        <code>syntheticClicks.elementRemoval</code> option in our
                        <a
                            href="https://agent.pendo.io/config/Analytics"
                            target="_blank">Agent API documentation</a>.
                    </small>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'interceptElementRemoval'"
                        :value="app.applicationFlags.interceptElementRemoval"
                        @change="handleFlagChange($event, 'interceptElementRemoval')" />
                </div>
            </div>
        </pendo-card>
    </div>
</template>
<script>
import PendoCard from '@/components/card/pendo-card.vue';
import PendoEditableContent from '@/components/editable-content/pendo-editable-content.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import EditExtensionDomainPatterns from './app-details-subcomponents/edit-extension-domain-patterns.vue';
import DisplayEditExtensionRegularExpressions from './app-details-subcomponents/display-edit-extension-regular-expressions.vue';
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoDataCollectionConsentToggle from '@/composites/data-collection-consent-toggle/pendo-data-collection-consent-toggle.vue';
import AppColorPicker from './app-details-subcomponents/app-color-picker.vue';
import PendoFaviconUpload from '@/composites/favicon-upload/pendo-favicon-upload.vue';
import { validateCookieDomain, validateCookieTTL } from './utils';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import Vibrant from 'node-vibrant';

export default {
    name: 'PendoAppDetails',
    components: {
        PendoCard,
        PendoEditableContent,
        PendoButton,
        PendoCheckbox,
        PendoIcon,
        PendoDataCollectionConsentToggle,
        PendoFaviconUpload,
        AppColorPicker,
        EditExtensionDomainPatterns,
        DisplayEditExtensionRegularExpressions
    },
    directives: {
        PendoTooltip
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        faviconUrl: {
            type: Object,
            default: () => {}
        },
        subscription: {
            type: Object,
            required: true
        },
        updatingAppFlag: {
            type: String,
            required: true
        },
        updatingApp: {
            type: Boolean,
            required: true
        },
        submissionError: {
            type: Object,
            default: () => {}
        },
        savingChanges: {
            type: Boolean,
            default: false
        },
        isSuperUser: {
            type: Boolean,
            default: false
        },
        isOpsUser: {
            type: Boolean,
            default: false
        },
        consumingPlatform: {
            type: String,
            required: true
        },
        engageCoreEnabled: {
            type: Boolean,
            default: false
        },
        engageFeedbackEnabled: {
            type: Boolean,
            default: false
        },
        isEngage: {
            type: Boolean,
            default: false
        },
        isImpersonating: {
            type: Boolean,
            default: false
        },
        websitesModalAlertLink: {
            type: String,
            required: true
        },
        selectiveUrlSupportLink: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            color: '',
            faviconB64: '',
            showFaviconModal: false,
            isEditingColor: false,
            swatchColors: [],
            isShowingTrackEventSecret: false,
            cookieDomainValidationRules: [
                {
                    validator: validateCookieDomain,
                    trigger: ['blur']
                }
            ],
            maxCookieTTLValidationRules: [
                {
                    validator: validateCookieTTL,
                    trigger: ['blur']
                }
            ]
        };
    },
    computed: {
        platformLabel () {
            return upperFirst(this.app.platform);
        },
        isExtensionApp () {
            return this.app.platform === 'extension';
        },
        isWebApp () {
            return this.app.platform === 'web';
        },
        showExtensionPlatformType () {
            return this.isExtensionApp;
        },
        showExtensionDomainPatterns () {
            return this.isExtensionApp;
        },
        showSelectiveUrls () {
            return this.isExtensionApp;
        },
        showEnableShadowRoot () {
            return this.isExtensionApp;
        },
        showVisitorIdentificationFunctionSetting () {
            return this.subscriptionUsesBrowserIngest && this.isExtensionApp;
        },
        showApiKey () {
            if (this.showSetting(['adopt'], ['web'])) {
                return true;
            }

            return (
                this.showSetting(['engage'], ['extension', 'web']) ||
                (this.isSuperUser && !this.isPartnerAdoptApplication)
            );
        },
        showTrackEventSecretSetting () {
            return this.showSetting(['engage'], ['web'], 'pendoCore');
        },
        showCnameSetting () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showFaviconSettings () {
            return (
                this.showSetting(['adopt'], ['extension', 'web']) ||
                this.showSetting(['engage'], ['extension', 'web'], 'pendoFavicons')
            );
        },
        showSuggestedPagesSetting () {
            return this.showSetting(['adopt', 'engage'], ['extension', 'web']);
        },
        showTextCaptureFromEventsSetting () {
            return this.showSetting(['adopt', 'engage'], ['extension']);
        },
        showDisableInheritedPagesFeaturesSetting () {
            return this.extensionPlatformType !== 'Other' && this.showSetting(['adopt', 'engage'], ['extension']);
        },
        canEditExtensionRegularExpressions () {
            return this.isSuperUser && this.showSetting(['engage'], ['extension']);
        },
        showSiblingSelectorsSetting () {
            return this.showSetting(['adopt', 'engage'], ['extension']);
        },
        showCookiesAndDataPrivacySettings () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showMaxCookieTTLSetting () {
            return this.showSetting(['engage'], ['web']);
        },
        showPreventCookieRefresh () {
            return this.showSetting(['engage'], ['web']);
        },
        showCookieDomainSetting () {
            return this.showSetting(['engage'], ['web']);
        },
        showCrossAppAgentCookiesSetting () {
            return this.showSetting(['engage'], ['web']);
        },
        showUsePostOnlySetting () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showNoCookiesNoLocalstorageSetting () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showDisableAgentIdentificationPersistenceSetting () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showDisableClientSideStorageSetting () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showGuidesToAnonymousVisitorsSetting () {
            return this.showSetting(['adopt', 'engage'], ['extension', 'web'], 'pendoCore');
        },
        showTrackEventTimeOnSiteSetting () {
            return this.showSetting(['engage'], ['web']);
        },
        showDisableDesignerLaunchUrlToken () {
            // This setting has moved to another tab - it's only shown in adopt using the extension
            // If you're reading this, you can probably remove this setting if we've migrated everyone
            // off the adopt ui
            return this.showSetting(['adopt'], ['extension']);
        },
        showDisableFeedbackAutoInit () {
            return this.showSetting(['engage'], ['web'], 'pendoFeedback');
        },
        showDisableGlobalCss () {
            return this.showSetting(['engage'], ['web']);
        },
        showMutationObserverSetting () {
            return this.showSetting(['adopt', 'engage'], ['web']);
        },
        showShadowDomMutationObserverSetting () {
            return this.app.applicationFlags.preferMutationObserver && this.showSetting(['adopt', 'engage'], ['web']);
        },
        showPreferBroadcastChannelSetting () {
            return this.isWebApp;
        },
        disableSharedCookies () {
            return this.app.applicationFlags.disableCookies;
        },
        extensionPlatformType () {
            return this.app.extensionPlatformTypeDisplayName || 'Other';
        },
        pageEventPropertiesButtonValue () {
            return this.app.applicationFlags.pageBasedEventProperties;
        },
        collectPageTitlesButtonValue () {
            return (
                this.app.applicationFlags.pageBasedEventProperties &&
                get(this.app, 'eventPropertyConfigurations', []).some((ep) => ep.name === 'document_title')
            );
        },
        subscriptionUsesBrowserIngest () {
            return get(this.subscription, 'featureFlags.enableExtensionBrowserIngest', false);
        },
        isCnameConfigured () {
            return !!(this.app.applicationFlags.cname && this.app.contentCname && this.app.dataCname);
        },
        brandingText () {
            return this.isEngage
                ? 'Make it easy to identify apps by adding a favicon.'
                : 'Make it easy to identify apps by adding a favicon. Assigned colors are used in some charts.';
        },
        platformIcon () {
            return {
                type: this.isEngage && this.app.platform === 'web' ? 'monitor' : 'puzzle-piece',
                fill: this.isEngage ? '#FFFFFF' : this.color
            };
        },
        maxCookieTTLDays () {
            return this.app.maxCookieTTLDays === 0 ? '' : this.app.maxCookieTTLDays;
        },
        isPartnerAdoptApplication () {
            return get(this.app, 'applicationFlags.partnerAdoptApplication', false);
        }
    },
    created () {
        if (this.app) {
            this.faviconB64 = get(this.app, 'faviconB64', '');
            this.color = get(this.app, 'color', '');
        }
    },
    methods: {
        onDisplayNameChange (value) {
            if (value !== this.app.displayName) {
                this.$emit('update-app-name', {
                    app: this.app,
                    updates: { displayName: value }
                });
            }
        },
        onMaxCookieTTLChange (value = 0) {
            if (value === '') {
                value = 0;
            }
            if (value !== this.app.maxCookieTTLDays) {
                this.$emit('update-app', {
                    app: this.app,
                    updates: { maxCookieTTLDays: value }
                });
            }
        },
        onCookieDomainChange (value = '') {
            if (value !== this.app.cookieDomain) {
                this.$emit('update-app', {
                    app: this.app,
                    updates: { cookieDomain: value }
                });
            }
        },
        onSharedCookieChange (sharedCookieKey = '') {
            this.$emit('update-app', {
                app: this.app,
                updates: {
                    identityStorageSuffix: sharedCookieKey.length ? '' : `${this.subscription.hash}`
                }
            });
        },
        handleFlagChange (enabled, flag) {
            this.$emit('update-app-flag', {
                appId: this.app.id,
                flag,
                enabled
            });
        },
        configurePageEventProperties (enabled) {
            if (enabled === this.pageEventPropertiesButtonValue) {
                return;
            }

            this.$emit('update-app', {
                app: this.app,
                updates: {
                    applicationFlags: {
                        pageBasedEventProperties: enabled
                    }
                }
            });
        },
        configureCollectPageTitles (enabled, name, isActive, path) {
            if (enabled === this.collectPageTitlesButtonValue) {
                return;
            }

            const eventPropertyConfigurations = get(this.app, 'eventPropertyConfigurations', []).filter(
                (config) => config.name !== name
            );
            if (enabled) {
                eventPropertyConfigurations.push({ name, isActive, path });
            }
            this.$emit('update-app', {
                app: this.app,
                updates: {
                    eventPropertyConfigurations
                }
            });
        },
        onSuggestPageTagsChange (event) {
            this.$emit('update-suggest-page-tags', {
                appId: this.app.id,
                flag: 'suggestPageTags',
                enabled: event
            });
        },
        async getColors (favicon) {
            if (favicon) {
                await Vibrant.from(favicon).getPalette((err, palette) => {
                    if (palette.Vibrant) {
                        this.color = palette.Vibrant.hex;
                        this.swatchColors = uniq([
                            palette.Vibrant.hex,
                            palette.DarkMuted.hex,
                            palette.DarkVibrant.hex,
                            palette.LightMuted.hex,
                            palette.LightVibrant.hex,
                            palette.Muted.hex
                        ]);
                    }
                });
            } else {
                this.color = '';
                this.swatchColors = [];
            }
        },
        onColorChange (newColor) {
            this.isEditingColor = false;
            this.color = newColor;
            if (this.color !== '' && this.color !== null) {
                this.$emit('update-app', {
                    app: this.app,
                    updates: {
                        color: this.color
                    }
                });
            }
        },
        displayFaviconModal () {
            this.showFaviconModal = true;
        },
        getFaviconByUrl (url) {
            this.$emit('fetch-url', url);
        },
        closeFaviconModal () {
            this.showFaviconModal = false;
        },
        submitFavicon (event) {
            this.faviconB64 = event;
            this.showFaviconModal = false;

            if (!this.isEngage) {
                this.getColors(this.faviconB64);
            }

            this.$emit('update-app', {
                app: this.app,
                updates: {
                    faviconB64: this.faviconB64
                }
            });
        },
        showSetting (intendedUIs, platforms, engageRequirments = '') {
            if (!this.app) {
                return false;
            }

            if (this.isPartnerAdoptApplication) {
                return false;
            }

            if (this.consumingPlatform === 'engage' && engageRequirments !== '') {
                if (engageRequirments === 'pendoCore') {
                    return (
                        platforms.includes(get(this.app, 'platform', '')) &&
                        intendedUIs.includes(this.consumingPlatform) &&
                        this.engageCoreEnabled
                    );
                }

                if (engageRequirments === 'pendoFeedback') {
                    return (
                        platforms.includes(get(this.app, 'platform', '')) &&
                        intendedUIs.includes(this.consumingPlatform) &&
                        this.engageFeedbackEnabled
                    );
                }

                if (engageRequirments === 'pendoFavicons') {
                    return (
                        platforms.includes(get(this.app, 'platform', '')) &&
                        intendedUIs.includes(this.consumingPlatform) &&
                        this.isEngage
                    );
                }
            }

            return platforms.includes(get(this.app, 'platform', '')) && intendedUIs.includes(this.consumingPlatform);
        }
    }
};
</script>
<style lang="scss" scoped>
.app-details {
    display: grid;
    grid-gap: 32px;

    ::v-deep .aside {
        font-weight: normal;
        color: $color-gray-70;
    }

    ::v-deep .pendo-card__body {
        padding-top: 0;
        padding-bottom: 0;
    }

    ::v-deep .settings-row {
        border-bottom: 1px solid $color-gray-40;
        min-height: 40px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        align-items: center;

        .settings-label {
            font-weight: 600;
            padding: 8px 0;
            width: fit-content;

            .keyboard-shortcut-aside {
                padding-top: 5px;
            }
        }

        .settings-content {
            padding: 8px 0;

            .extension-color-list {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }

        .favicon-color-chooser__favicon {
            max-width: 32px;
            max-height: 32px;
        }
    }

    ::v-deep .nested {
        grid-row-gap: 0px;
    }
}

.text-capture-label {
    display: inline-block;
}

.favicon-button-img {
    display: flex;
    gap: 10px;
    align-items: center;
}

.favicon-label {
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 6px;
}

.color-display {
    display: flex;
    align-items: center;
    gap: 5px;
}

.track-event-contain {
    display: flex;
    gap: 5px;
    align-items: center;
}

.indent {
    margin-left: 30px;
}
</style>
