<template>
    <tr
        :pendo-draggable-item="$table.draggable"
        role="row"
        class="pendo-table__row"
        :class="[
            rowClassName,
            {
                'pendo-table__row--child pendo-table__row--expand': isChild,
                'pendo-table__row--group': isGroupRow,
                'pendo-table__row--expandable-group': isExpandableGroupRow,
                'is-active': isActive,
                'is-expanded': isTreeExpanded,
                'is-selected': isSelected
            }
        ]"
        @mouseenter="$table.setRowHover(row, index)">
        <slot
            :natural-index="index + 1"
            :row-index="index" />
    </tr>
</template>

<script>
import isFunction from 'lodash/isFunction';

export default {
    name: 'TableRow',
    inject: ['$table'],
    props: {
        index: {
            type: Number,
            required: true
        },
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        isTreeExpanded () {
            return Boolean(this.$table.treeExpandedMap[this.row[this.$table.rowKey]]);
        },
        isChild () {
            return Boolean(this.$table.treeExpandedChildMap[this.row[this.$table.rowKey]]);
        },
        isActive () {
            return this.row[this.$table.rowKey] === this.$table.activeRow;
        },
        isSelected () {
            return Boolean(this.$table.selectedMap[this.row[this.$table.rowKey]]);
        },
        isGroupRow () {
            return this.row.isGroup;
        },
        isExpandableGroupRow () {
            return this.isGroupRow && this.$table.groupConfig.expandable;
        },
        rowClassName () {
            if (this.$table.rowClassName) {
                return isFunction(this.$table.rowClassName)
                    ? this.$table.rowClassName({
                        row: this.row,
                        rowIndex: this.index
                    })
                    : this.$table.rowClassName;
            }

            return '';
        }
    }
};
</script>
