<template>
    <div class="pendo-date-picker__default-ranges">
        <ul role="listbox">
            <li
                v-for="(option, index) of options"
                :key="index"
                :title="option.label"
                :aria-selected="option.selected"
                role="option">
                <span
                    :class="{
                        'is-disabled': option.disabled,
                        'is-selected': option.selected
                    }"
                    @click="handleOptionClick(option)">
                    <slot
                        name="shortcut"
                        :shortcut="option">
                        {{ option.label }}
                    </slot>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Shortcuts',
    props: {
        options: {
            type: Array,
            default: null
        }
    },
    methods: {
        handleOptionClick (option) {
            if (option.disabled) {
                return;
            }

            this.$emit('select', option);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(default-ranges) {
        box-sizing: border-box;
        background: $datepicker-shortcut-background;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;

        ul {
            list-style: none;
            padding: 8px 0;
            margin: 0;
            min-width: 100%;
            max-width: 100%;
            vertical-align: top;

            &::webkit-scrollbar {
                display: none;
            }

            li {
                overflow: hidden;
                display: grid;
                align-items: center;

                &.is-disabled {
                    opacity: 0.4;
                }
            }

            span {
                font-size: inherit;
                padding: 0 24px 0 16px;
                height: 26px;
                line-height: 26px;
                text-decoration: none;
                text-transform: none;
                vertical-align: middle;
                position: relative;
                cursor: pointer;
                flex: 1;
                min-width: 0;
                @include ellipsis;

                &:hover {
                    background-color: $datepicker-shortcut-hover;
                    outline: none;
                }

                @include is(selected) {
                    background-color: $datepicker-shortcut-selected;
                }

                &.is-selected:hover {
                    background-color: $datepicker-shortcut-hover-selected;
                }

                @include is(disabled) {
                    background: $datepicker-shortcut-background;
                    color: $disabled-color;
                    cursor: not-allowed;
                    opacity: 0.7;
                }
            }
        }
    }
}
</style>
