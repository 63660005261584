<template>
    <div
        class="pendo-color-picker-saturation"
        :style="{
            height: `${height}px`,
            width: `${width}px`
        }">
        <div class="pendo-color-picker-saturation__border" />
        <canvas
            ref="canvas"
            class="pendo-color-picker-saturation__canvas"
            :height="height"
            :width="width"
            @mousedown.prevent="handleMouseDown" />
        <div
            :style="`transform: translate3d(${cursor.x}px, ${cursor.y}px, 0)`"
            class="pendo-color-picker-saturation__pointer" />
    </div>
</template>

<script>
export default {
    name: 'ColorPickerSaturation',
    props: {
        color: {
            type: Object,
            required: true
        },
        height: {
            type: Number,
            default: 138
        },
        width: {
            type: Number,
            default: 184
        }
    },
    data () {
        return {
            cursor: {},
            canvas: {},
            ctx: null
        };
    },
    watch: {
        color () {
            this.setCanvasBackground();
            this.handleValueChange();
        }
    },
    async mounted () {
        await this.$nextTick();
        this.initCanvas();
        this.handleValueChange();
    },
    methods: {
        initCanvas () {
            this.ctx = this.$refs.canvas.getContext('2d');
            this.canvas = this.$refs.canvas.getBoundingClientRect();
            this.setCanvasBackground();
        },
        handleValueChange () {
            const { width, height } = this.canvas;
            const x = (this.color.hsv.s / 100) * width;
            const y = ((100 - this.color.hsv.v) / 100) * height;
            this.cursor = { x, y };
        },
        updateCursorPosition ({ x, y }) {
            const { left, top, width, height } = this.canvas;
            const normalized = {
                x: Math.min(Math.max(x - left, 0), width),
                y: Math.min(Math.max(y - top, 0), height)
            };
            this.cursor = normalized;
            this.updateColor();
        },
        updateColor () {
            const { x, y } = this.cursor;
            const { width, height } = this.canvas;

            const saturation = (x * 100) / width;
            const bright = 100 - (y * 100) / height;

            this.$emit('change', {
                ...this.color.hsv,
                s: Math.round(saturation),
                v: Math.round(bright)
            });
        },
        setCanvasBackground () {
            const { width, height } = this.canvas;

            this.ctx.clearRect(0, 0, width, height);
            this.ctx.fillStyle = `hsl(${this.color.hsv.h}, 100%, 50%)`;
            this.ctx.fillRect(0, 0, width, height);

            const gradientWhite = this.ctx.createLinearGradient(0, 0, width, 0);
            gradientWhite.addColorStop(0, 'hsl(0, 0%, 100%)');
            gradientWhite.addColorStop(1, 'hsla(0, 0%, 100%, 0)');
            this.ctx.fillStyle = gradientWhite;
            this.ctx.fillRect(0, 0, width, height);

            const gradientBlack = this.ctx.createLinearGradient(0, 0, 0, height);
            gradientBlack.addColorStop(0, 'hsla(0, 0%, 0%, 0)');
            gradientBlack.addColorStop(1, 'hsl(0, 0%, 0%)');
            this.ctx.fillStyle = gradientBlack;
            this.ctx.fillRect(0, 0, width, height);
        },
        handleMouseDown ({ clientX, clientY }) {
            this.canvas = this.$refs.canvas.getBoundingClientRect();
            this.updateCursorPosition({ x: clientX, y: clientY });

            const handleMouseMove = (evnt) => {
                window.requestAnimationFrame(() => {
                    this.updateCursorPosition({ x: evnt.clientX, y: evnt.clientY });
                });
            };

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-color-picker-saturation) {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-3;

    @include element(border) {
        z-index: 1;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
        position: absolute;
        pointer-events: none;
        border-radius: $border-radius-3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @include element(canvas) {
        border-radius: $border-radius-3;
        cursor: pointer;
    }

    @include element(pointer) {
        left: 0;
        top: 0;
        width: 4px;
        height: 4px;
        margin: -2px 0 0 -2px;
        box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, 0.3), 0 0 1px 2px rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        cursor: pointer;
        position: absolute;

        will-change: transform;
        pointer-events: none;
    }
}
</style>
