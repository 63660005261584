<template>
    <div class="launch-preview-pane">
        <div class="section-header">
            Launch Guide
        </div>
        <p class="section-description">
            Select where you want to open your guide. To check responsiveness, select launch in new window.
        </p>
        <pendo-radio-group
            :value="selectedWindowOption"
            @change="updateWindowOption">
            <pendo-radio
                v-for="option in windowOptions"
                :key="option.value"
                :data-cy="`launch-preview-pane-${option.value}`"
                v-bind="option" />
        </pendo-radio-group>
        <div v-if="shouldShowScreenSize">
            <pendo-divider />
            <div class="section-header">
                Screen Size
            </div>
            <pendo-radio-group
                :value="selectedScreenSize"
                @change="updateScreenSize">
                <pendo-radio
                    v-for="option in screenSizeOptions"
                    :key="option.value"
                    :data-cy="`launch-preview-pane-${option.value}`"
                    v-bind="option" />
            </pendo-radio-group>
            <div class="section-custom-size">
                <pendo-input-number
                    v-if="isCustomSelected"
                    data-cy="launch-preview-pane-width"
                    :value="customWidth"
                    :labels="{ bottom: 'Width', suffix: 'px' }"
                    :min="350"
                    :max="1920"
                    suffix="px"
                    @change="updateWidth" />
                <pendo-input-number
                    v-if="isCustomSelected"
                    data-cy="launch-preview-pane-height"
                    :value="customHeight"
                    :labels="{ bottom: 'Height', suffix: 'px' }"
                    :min="256"
                    :max="1200"
                    @change="updateHeight" />
            </div>
            <p class="section-note">
                Note: If your browser is in full screen, we can’t open a new window to the given dimensions.
            </p>
        </div>
    </div>
</template>

<script>
import PendoDivider from '@/components/divider/pendo-divider.vue';
import PendoInputNumber from '@/components/input-number/pendo-input-number.vue';
import PendoRadio from '@/components/radio/pendo-radio.vue';
import PendoRadioGroup from '@/components/radio-group/pendo-radio-group.vue';

export const WINDOW_SIZES = {
    mobile: {
        width: 350,
        height: 800
    },
    tablet: {
        width: 800,
        height: 1000
    }
};

export default {
    name: 'PendoLaunchPreviewPane',
    components: {
        PendoDivider,
        PendoInputNumber,
        PendoRadio,
        PendoRadioGroup
    },
    props: {
        selectedWindowOption: {
            type: String,
            default: 'newTab'
        },
        selectedScreenSize: {
            type: String,
            default: 'mobile'
        },
        customHeight: {
            type: Number,
            default: 1200
        },
        customWidth: {
            type: Number,
            default: 800
        },
        hideSameTab: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            screenSizeOptions: [
                {
                    value: 'mobile',
                    label: `Mobile (${WINDOW_SIZES.mobile.width}x${WINDOW_SIZES.mobile.height})`
                },
                {
                    value: 'tablet',
                    label: `Tablet (${WINDOW_SIZES.tablet.width}x${WINDOW_SIZES.tablet.height})`
                },
                {
                    value: 'custom',
                    label: 'Custom'
                }
            ]
        };
    },
    computed: {
        shouldShowScreenSize () {
            return this.selectedWindowOption === 'newWindow';
        },
        isCustomSelected () {
            return this.selectedScreenSize === 'custom';
        },
        windowOptions () {
            return [
                {
                    value: 'newTab',
                    label: 'New tab'
                },
                !this.hideSameTab && {
                    value: 'sameTab',
                    label: 'Same tab'
                },
                {
                    value: 'newWindow',
                    label: 'New window'
                }
            ].filter(Boolean);
        }
    },
    methods: {
        getDesiredWindowSize (dimension, { selectedWindowOption, selectedScreenSize, customHeight, customWidth }) {
            if (selectedWindowOption !== 'newWindow') {
                return;
            }

            if (selectedScreenSize === 'mobile') {
                return WINDOW_SIZES.mobile[dimension];
            }

            if (selectedScreenSize === 'tablet') {
                return WINDOW_SIZES.tablet[dimension];
            }

            return dimension === 'height' ? customHeight : customWidth;
        },
        emitUpdate (updates) {
            const updateEvent = {
                selectedWindowOption: this.selectedWindowOption,
                selectedScreenSize: this.selectedScreenSize,
                customWidth: this.customWidth,
                customHeight: this.customHeight,
                ...updates
            };
            updateEvent.height = this.getDesiredWindowSize('height', updateEvent);
            updateEvent.width = this.getDesiredWindowSize('width', updateEvent);
            this.$emit('update', updateEvent);
        },
        updateWindowOption (selectedWindowOption) {
            this.emitUpdate({ selectedWindowOption });
        },
        updateScreenSize (selectedScreenSize) {
            this.emitUpdate({ selectedScreenSize });
        },
        updateWidth (customWidth) {
            this.emitUpdate({ customWidth });
        },
        updateHeight (customHeight) {
            this.emitUpdate({ customHeight });
        }
    }
};
</script>

<style lang="scss" scoped>
.launch-preview-pane {
    .section-header {
        color: $color-gray-70;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .section-description {
        margin-top: 0;
        margin-bottom: 8px;
    }

    .section-custom-size {
        display: grid;
        grid-template-columns: repeat(2, 75px);
        grid-gap: 10px;
        margin-top: 10px;
    }

    .section-note {
        color: $color-gray-70;
    }
}
</style>
