<template>
    <portal
        :disabled="!isVisible"
        :append-to="appendTo">
        <div
            :id="id"
            :class="[
                className,
                'pendo-drawer',
                `pendo-drawer--${size}`,
                `pendo-drawer--${placement}`,
                {
                    'pendo-drawer--push': push,
                    'is-open': isVisible
                }
            ]"
            :style="{ zIndex }"
            role="dialog"
            aria-modal="true"
            :aria-labelledby="titleId">
            <transition
                appear
                name="fade">
                <div
                    v-if="showMask && isMaskVisible"
                    class="pendo-drawer__mask"
                    aria-hidden="true"
                    @click="onMaskClick" />
            </transition>
            <div
                v-if="isVisible"
                class="pendo-drawer__wrapper">
                <transition
                    appear
                    :name="`pendo-transition-slide-${oppositePlacement}`"
                    @before-enter="beforeEnter"
                    @after-enter="afterEnter"
                    @before-leave="beforeLeave"
                    @after-leave="afterLeave">
                    <focus-trap
                        v-show="isPanelVisible"
                        v-model="isFocusTrapActive"
                        v-bind="focusTrapConfig">
                        <div
                            ref="panel"
                            tabindex="-1"
                            class="pendo-drawer__panel"
                            :class="{
                                'pendo-drawer__panel--push': push
                            }"
                            :style="panelStyles"
                            @keydown.esc="onEscapeKeydown">
                            <div
                                class="pendo-drawer__content"
                                :style="gridStyles">
                                <div
                                    v-if="hasLabel"
                                    class="pendo-drawer__before-header">
                                    <div class="pendo-drawer__label">
                                        <slot name="label">
                                            {{ label }}
                                        </slot>
                                    </div>
                                    <div class="pendo-drawer__core-actions">
                                        <div v-if="$slots.coreActions">
                                            <slot name="coreActions" />
                                        </div>
                                        <pendo-divider
                                            v-if="$slots.coreActions && showClose"
                                            direction="vertical"
                                            width="1px"
                                            height="24px"
                                            stroke="#DADCE5" />
                                        <pendo-drawer-close v-if="showClose" />
                                    </div>
                                </div>
                                <div
                                    class="pendo-drawer__header"
                                    :class="{
                                        'pendo-drawer__header--fullscreen':
                                            size === 'fullscreen' && ($slots.header || $scopedSlots.header || title)
                                    }">
                                    <slot
                                        :id="titleId"
                                        name="header"
                                        :close="close">
                                        <h2
                                            v-if="title"
                                            :id="titleId"
                                            class="pendo-drawer__title">
                                            {{ title }}
                                        </h2>
                                        <pendo-drawer-close v-if="showClose && !hasLabel" />
                                    </slot>
                                </div>
                                <div
                                    v-if="$slots.aside"
                                    class="pendo-drawer__aside">
                                    <slot name="aside" />
                                </div>
                                <slot>
                                    <div class="pendo-drawer__body">
                                        <slot name="body" />
                                    </div>
                                </slot>
                                <div
                                    v-if="$slots.footer || $scopedSlots.footer"
                                    class="pendo-drawer__footer">
                                    <slot
                                        :close="close"
                                        name="footer" />
                                </div>
                            </div>
                        </div>
                    </focus-trap>
                </transition>
            </div>
        </div>
    </portal>
</template>

<script>
import Portal from '@/components/portal/portal';

import PendoDivider from '@/components/divider/pendo-divider';
import PendoDrawerClose from '@/components/drawer/pendo-drawer-close';
import FocusTrap from '@/utils/focus-trap';

import { getMargin, setStyles, getTarget } from '@/utils/dom';
import { capitalize } from '@/utils/utils';
import { disableBodyScroll, enableBodyScroll } from '@/utils/body-scroll-lock';

export default {
    name: 'PendoDrawer',
    components: {
        PendoDivider,
        PendoDrawerClose,
        FocusTrap,
        Portal
    },
    provide () {
        return {
            $drawer: this
        };
    },
    props: {
        /**
         * Toggles drawer visibility. supports the `sync` modifier for two way binding
         */
        visible: {
            type: Boolean,
            default: false
        },
        /**
         * Helper for passing a classname to the drawer
         */
        className: {
            type: [String, Array],
            default: null
        },
        /**
         * Supports one of the predefined strings or passing a custom string such as `500px` or `60%`
         * @values small, medium, large, fullscreen
         */
        size: {
            type: String,
            default: 'medium'
        },
        /**
         * Title for the drawer. Can be optionally overridden with `header` slot
         */
        title: {
            type: String,
            default: null
        },
        /**
         * Label for the drawer's before-header banner. Can be optionally overridden with `label` slot
         */
        label: {
            type: String,
            default: null
        },
        /**
         * Toggle whether to show the close button in the header or before-header banner
         */
        showClose: {
            type: Boolean,
            default: true
        },
        /**
         * Automatically close drawer on click of background mask
         */
        closeOnMaskClick: {
            type: Boolean,
            default: true
        },
        /**
         * Background mask when size is not `fullscreen`
         */
        mask: {
            type: Boolean,
            default: true
        },
        /**
         * Placement determines where the drawer will open from/be anchored to
         * @values top, bottom, left, right
         */
        placement: {
            type: String,
            default () {
                if (this.size === 'fullscreen') {
                    return 'bottom';
                }

                return 'right';
            },
            validator: (placement) => ['top', 'bottom', 'right', 'left'].includes(placement)
        },
        zIndex: {
            type: Number,
            default: 502
        },
        /**
         * Rather than overlay the mask over existing page content, pass a valid selector and the drawer will push that element to create a sidebar/blade type of component
         */
        push: {
            type: [String, Element, Boolean],
            default: null
        },
        /**
         * Duration of the slide animation
         */
        duration: {
            type: Number,
            default: 220
        },
        /**
         * Valid selector to append drawer portal to. `false` leaves drawer in tree
         */
        appendTo: {
            type: [String, Element, Boolean],
            default: 'body'
        },
        /**
         * Focus trapping config options for [focus-trap](https://github.com/focus-trap/focus-trap#usage)
         */
        focusTrapConfig: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            id: `pendo-drawer-${this._uid}`,
            isFocusTrapActive: false,
            isPanelVisible: false,
            isMaskVisible: false,
            isVisible: this.visible,
            oldPushElMargin: 0,
            oldPushElPlacement: this.placement
        };
    },
    computed: {
        titleId () {
            return `${this.id}-title`;
        },
        oppositePlacement () {
            return {
                top: 'bottom',
                bottom: 'top',
                left: 'right',
                right: 'left'
            }[this.placement];
        },
        drawerSize () {
            if (['small', 'medium', 'large', 'fullscreen'].includes(this.size)) {
                return {
                    small: '400px',
                    medium: '500px',
                    large: '550px',
                    fullscreen: '100'
                }[this.size];
            }

            return this.size;
        },
        panelStyles () {
            const styles = {
                transitionDuration: `${this.duration}ms`
            };

            if (this.push) {
                styles[`border-${this.oppositePlacement}`] = '1px solid #DADCE5';
                styles.boxShadow = 'none';
            }

            if (['top', 'bottom'].includes(this.placement)) {
                return {
                    ...styles,
                    height: this.size === 'fullscreen' ? '100vh' : this.drawerSize
                };
            }

            return {
                ...styles,
                width: this.size === 'fullscreen' ? '100vw' : this.drawerSize
            };
        },
        showMask () {
            if (this.size === 'fullscreen') {
                return false;
            }

            return this.push ? this.$options.propsData.mask : this.mask;
        },
        pushEl () {
            if (this.push) {
                return getTarget(this.push);
            }

            return false;
        },
        hasLabel () {
            return this.label || this.$slots.label || this.$scopedSlots.label;
        },
        gridTemplateRows () {
            const footer = this.$slots.footer || this.$scopedSlots.footer;

            if (this.hasLabel && footer) {
                return '[before-header] minmax(58px, max-content) [header] 68px [body] auto [footer] minmax(68px, max-content)';
            }

            if (this.hasLabel) {
                return '[before-header] minmax(58px, max-content) [header] 68px [body] 1fr';
            }

            if (footer) {
                return '[header] 68px [body] auto [footer] minmax(68px, max-content)';
            }

            return '[header] 68px [body] 1fr';
        },
        gridTemplateAreas () {
            const aside = this.$slots.aside || this.$scopedSlots.aside;
            const beforeHeader = this.hasLabel ? "'before-header before-header' " : '';
            const body = aside ? 'aside body' : 'body body';

            return `${beforeHeader}'header header' '${body}' 'footer footer'`;
        },
        gridStyles () {
            const aside = this.$slots.aside || this.$scopedSlots.aside;
            let gridTemplateColumns;

            if (aside) {
                gridTemplateColumns = '25% 1fr';
            }

            return {
                gridTemplateAreas: this.gridTemplateAreas,
                gridTemplateColumns,
                gridTemplateRows: this.gridTemplateRows,
                gridColumnGap: aside ? '32px' : 0
            };
        }
    },
    watch: {
        push (newValue, oldValue) {
            if (!newValue && oldValue) {
                setStyles(getTarget(oldValue), {
                    transitionDelay: '0ms',
                    [`margin-${this.oldPushElPlacement}`]: this.oldPushElMargin
                });

                this.oldPushElPlacement = this.placement;
            }
        },
        visible: {
            handler () {
                if (this.visible) {
                    requestAnimationFrame(() => {
                        this.isVisible = true;
                        this.isPanelVisible = true;
                        this.isMaskVisible = true;
                    });
                } else if (this.isVisible) {
                    this.close();
                }
            },
            immediate: true
        }
    },
    beforeDestroy () {
        this.beforeLeave();
    },
    methods: {
        onEscapeKeydown () {
            this.close();
        },
        close () {
            let stopClose = false;
            const stop = () => {
                stopClose = true;
            };

            /**
             * Emitted before drawer is going to be closed.
             * Can be stopped from the event listener calling event.stop()
             * @event before-close
             */
            this.$emit('before-close', { stop });

            if (!stopClose) {
                this.isPanelVisible = false;
                this.isMaskVisible = false;
                /**
                 * Emitted when drawer begins to close
                 * @event close
                 */
                this.$emit('close');

                setTimeout(() => {
                    /**
                     * @ignore
                     * @deprecated the .sync modifier is not suppored in vue 3
                     * @since 10.0.0
                     */
                    this.$emit('update:visible', false);

                    /**
                     * Emitted when drawer has finished closing
                     * and leave transition has completed
                     * @event closed
                     */
                    this.$emit('closed');
                    this.isVisible = false;
                }, this.duration + 150);
            }
        },
        onMaskClick () {
            if (this.mask && this.closeOnMaskClick) {
                this.close();
            }
        },
        beforeEnter () {
            if (this.pushEl) {
                // get the existing margin setting if there is one
                const styles = {
                    willChange: `margin-${this.placement}`,
                    transitionDuration: `${this.duration}ms`,
                    transitionTimingFunction: 'cubic-bezier(0.2, 0, 0, 1)',
                    transitionDelay: '70ms'
                };

                let movement = this.drawerSize;
                const margins = getMargin(this.pushEl);
                const existingMargin = margins[`margin${capitalize(this.placement)}`];
                if (existingMargin) {
                    // save it for later so we can put it back
                    this.oldPushElMargin = existingMargin;
                    // use calc in case puslEl is using non-px based margin (em/rem);
                    movement = `calc(${existingMargin} + ${this.drawerSize})`;
                }

                styles[`margin-${this.placement}`] = movement;
                setStyles(this.pushEl, styles);
            }

            if (this.size === 'fullscreen') {
                disableBodyScroll(this.id);
            }

            /**
             * Emitted when drawer is added to DOM and begins enter transition
             * @event open
             */
            this.$emit('open');
        },
        afterEnter () {
            this.isFocusTrapActive = true;
            /**
             * Emits after drawer enter transition has completed
             * @event opened
             */
            this.$emit('opened');
        },
        beforeLeave () {
            if (this.pushEl) {
                const styles = {
                    transitionDelay: '0ms'
                };
                if (this.oldPushElMargin) {
                    styles[`margin-${this.placement}`] = this.oldPushElMargin;
                } else {
                    this.pushEl.style.removeProperty(`margin-${this.placement}`);
                }

                setStyles(this.pushEl, styles);
            }

            if (this.size === 'fullscreen') {
                enableBodyScroll(this.id);
            }
        },
        afterLeave () {
            requestAnimationFrame(() => {
                enableBodyScroll(this.id);

                this.isFocusTrapActive = false;
            });
            if (this.pushEl) {
                this.pushEl.style.removeProperty(`border-${this.placement}`);
                this.pushEl.style.removeProperty('transition-duration');
                this.pushEl.style.removeProperty('transition-delay');
                this.pushEl.style.removeProperty('transition-timing-function');
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-drawer) {
    width: auto;
    height: 100%;
    position: fixed;
    top: 0;

    @include element(wrapper) {
        overflow: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        outline: 0;
        position: static;
    }

    @include element(panel) {
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $color-white;
        will-change: transform;
        transition-property: transform, width;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
        z-index: 1;
        box-shadow: $box-shadow-light;
        overscroll-behavior-y: contain;
    }

    @include element(mask) {
        background-color: rgba($color-gray-100, 0.75);
        will-change: opacity;
        transition: opacity 220ms ease 0s;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        z-index: 1;
    }

    @include element(content) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: $color-white;
        border: 0;
        display: grid;
    }

    @include element(before-header) {
        grid-area: before-header;
        display: grid;
        grid-template-columns: [label] 1fr [actions] auto;
        grid-template-rows: 100%;
        align-items: center;
        padding: 8px 24px;
        border-bottom: 1px solid $color-gray-40;
        overflow: hidden;
    }

    @include element(label) {
        font-size: 14px;
        font-weight: 600;
    }

    @include element(core-actions) {
        display: flex;
        gap: 8px;
        align-items: center;

        > .pendo-drawer__close {
            padding: 0 4px;
        }
    }

    @include element(header) {
        display: grid;
        grid-area: header;
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: [title] 1fr [close] auto;
        z-index: 2; // fix stacking context with body content
        box-shadow: none;
    }

    @include element(title) {
        font-size: 24px;
        font-weight: 400;
        line-height: 27px;
        margin: 0;
        grid-column: title;
    }

    @include element(body) {
        grid-area: body;
        overflow-y: auto;
    }

    @include element(footer) {
        grid-area: footer;
        z-index: 1; // fix stacking context with body content
        display: grid;
        align-items: center;
    }

    @include element((header, footer)) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @include modifier(left) {
        @include element(panel) {
            right: auto;
        }

        @include element(header) {
            grid-template-columns: [close] auto [title] 1fr;
        }

        @include element(title) {
            justify-self: end;
        }
    }

    @include modifier(top) {
        @include element(panel) {
            bottom: auto;
        }
    }

    @include modifier(bottom) {
        @include element(panel) {
            top: auto;
        }
    }

    @include modifier(right) {
        @include element(panel) {
            left: auto;
        }
    }

    @include modifier(fullscreen) {
        @include element(content) {
            display: grid;
            background-color: $color-gray-10;
            height: 100%;
        }

        @include element(header) {
            display: flex;
            justify-content: space-between;
            background-color: $color-white;

            @include modifier(fullscreen) {
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.17);
            }
        }

        @include element(title) {
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            justify-self: start;
        }

        @include element(aside) {
            display: grid;
            grid-area: aside;
        }

        @include element(footer) {
            background-color: $color-white;
            box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.17);
        }

        @include element((header, footer)) {
            padding-right: 32px;
            padding-left: 32px;
        }

        @include element((aside, body)) {
            padding-top: 72px;
            padding-bottom: 72px;
        }
    }
}
</style>
