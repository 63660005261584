<template>
    <div
        class="pendo-loading--material"
        :class="spinnerProps.class"
        :style="{ color: spinnerProps.color }">
        <svg
            class="pendo-loading__svg"
            stroke="currentColor"
            :width="`${spinnerProps.size}px`"
            :height="`${spinnerProps.size}px`"
            viewBox="0 0 66 66"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                class="pendo-loading__path"
                fill="none"
                stroke-width="6"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Material',
    inheritAttrs: false,
    props: {
        spinnerProps: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-loading) {
    @include modifier(material) {
        @include element(svg) {
            will-change: transform;
            animation: pendo-loading-material-rotate 1.4s linear infinite;
        }

        @include element(path) {
            will-change: transform, stroke-dashoffset;
            stroke-dasharray: 187;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: pendo-loading-material-dashoffset 1.4s ease-in-out infinite;
        }
    }
}

@keyframes pendo-loading-material-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pendo-loading-material-dashoffset {
    0% {
        stroke-dashoffset: 187;
        transform: rotate(0deg);
    }
    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(360deg);
    }
}
</style>
