<template>
    <div class="note">
        <pendo-card
            class="note-card"
            :class="{ 'edit-header-wrapper': isEditing }"
            data-cy="note"
            :header-min-height="'32px'"
            :body-min-height="'36px'"
            @mouseover.native="hover = true"
            @focus="hover = true"
            @mouseleave.native="hover = false"
            @blur="hover = false">
            <template #header>
                <template v-if="!isEditing">
                    <div class="header-left">
                        <div class="timestamp">
                            {{ postedTimestamp }}
                        </div>
                        <div class="note-author">
                            {{ note.createdByUser.username }}
                        </div>
                    </div>
                    <div
                        v-if="hover"
                        class="header-right">
                        <pendo-button
                            v-if="showEditIcon"
                            theme="app"
                            :disabled="!canEditNote"
                            type="link"
                            size="mini"
                            prefix-icon="edit"
                            @click="toggleEditMode" />
                        <pendo-button
                            v-if="showDeleteIcon"
                            theme="app"
                            :disabled="!canRemoveNote"
                            type="link"
                            size="mini"
                            prefix-icon="trash-2"
                            @click="toggleDeleteModal" />
                    </div>
                </template>
                <template v-if="isEditing">
                    <strong>Edit Mode</strong>
                </template>
            </template>
            <template #body>
                <template v-if="!isEditing">
                    {{ note.text }}
                    <span
                        v-if="hasEdits"
                        class="edit-tag">
                        <em>(edited)</em>
                    </span>
                </template>
                <template v-if="isEditing">
                    <pendo-input
                        v-model="localText"
                        :disabled="updatingNote"
                        :invalid="noteTooLong"
                        :labels="{ bottom: noteTooLong ? 'Character limit is 500.' : '' }"
                        placeholder="Add note here..."
                        type="textarea" />
                </template>
            </template>
        </pendo-card>
        <div
            v-if="isEditing"
            class="edit-actions">
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="toggleEditMode" />
            <pendo-button
                theme="app"
                type="primary"
                label="Update Note"
                :loading="updatingNote"
                :disabled="noteTooLong"
                @click="updateNote" />
        </div>
        <pendo-modal
            type="confirmation"
            :confirm-button-config="{ loading: removingNote }"
            title="Delete this Note?"
            :visible="showDeleteModal"
            message="Are you sure you want to delete this note?"
            size="medium"
            @cancel="toggleDeleteModal"
            @confirm="removeNote" />
    </div>
</template>

<script>
import PendoCard from '@/components/card/pendo-card.vue';
import PendoButton from '@/components/button/pendo-button';
import PendoInput from '@/components/input/pendo-input';
import PendoNotification from '@/components/notification/pendo-notification.js';
import PendoModal from '@/components/modal/pendo-modal.vue';
import moment from 'moment-timezone';
import get from 'lodash/get';

export default {
    name: 'Note',
    components: {
        PendoCard,
        PendoButton,
        PendoInput,
        PendoModal
    },
    props: {
        note: {
            type: Object,
            required: true
        },
        activeNoteId: {
            type: String,
            default: undefined
        },
        displayAlert: {
            type: Object,
            required: true
        },
        activeUser: {
            type: Object,
            required: true
        },
        hasAdminAccess: {
            type: Boolean,
            default: false
        },
        noteAction: {
            type: Object,
            default: () => ({})
        },
        noteError: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            hover: false,
            isEditing: false,
            updatingNote: false,
            removingNote: false,
            showDeleteModal: false,
            localText: ''
        };
    },
    computed: {
        canEditNote () {
            return get(this.displayAlert, 'canEditNote', false);
        },
        canRemoveNote () {
            return get(this.displayAlert, 'canRemoveNote', false);
        },
        showEditIcon () {
            // we only want users to toggle edit for a single note at a time
            if (this.activeNoteId && this.activeNoteId !== this.note.rootVersionId) {
                return false;
            }

            return this.noteUserId === this.activeUser.id;
        },
        showDeleteIcon () {
            return this.hasAdminAccess || this.noteUserId === this.activeUser.id;
        },
        postedTimestamp () {
            return moment(this.note.createdAt)
                .tz(moment.tz.guess())
                .format('hh:mm A');
        },
        noteTooLong () {
            return this.localText.length > 500;
        },
        hasEdits () {
            return this.note.createdAt !== this.note.lastUpdatedAt;
        },
        noteUserId () {
            return get(this.note, 'createdByUser.id', undefined);
        },
        isUpdatingNote () {
            return get(this.noteAction, 'update', false);
        },
        isRemovingNote () {
            return get(this.noteAction, 'remove', false);
        }
    },
    watch: {
        isUpdatingNote (newValue, oldValue) {
            if (this.updatingNote && !newValue && oldValue) {
                if (this.noteError.update) {
                    PendoNotification({
                        type: 'error',
                        title: 'Note was not edited.',
                        message: 'Looks like something went wrong when editing your note. Please try again.',
                        duration: 3000
                    });
                } else {
                    this.toggleEditMode();
                }
                this.updatingNote = false;
            }
        },
        isRemovingNote (newValue, oldValue) {
            if (this.removingNote && !newValue && oldValue) {
                if (this.noteError.remove) {
                    PendoNotification({
                        type: 'error',
                        title: 'Note was not deleted.',
                        message: 'Looks like something went wrong when deleting your note. Please try again.',
                        duration: 3000
                    });
                } else {
                    this.toggleDeleteModal();
                }
                this.removingNote = false;
            }
        }
    },
    methods: {
        toggleEditMode () {
            this.isEditing = !this.isEditing;

            if (this.isEditing) {
                this.localText = this.note.text;
                this.$emit('note-edited', this.note.rootVersionId);
            } else {
                this.$emit('note-edited', undefined);
            }
        },
        toggleDeleteModal () {
            this.showDeleteModal = !this.showDeleteModal;
        },
        updateNote () {
            this.updatingNote = true;
            this.$emit('update-note', {
                noteId: this.note.rootVersionId,
                text: this.localText,
                alert: this.displayAlert
            });
        },
        removeNote () {
            this.removingNote = true;
            this.$emit('remove-note', { alert: this.displayAlert, noteId: this.note.rootVersionId });
        }
    }
};
</script>

<style lang="scss" scoped>
.note {
    margin-bottom: 20px;
}

::v-deep .pendo-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $color-gray-70;
    padding-right: 8px;

    .header-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .timestamp {
            margin-right: 8px;
        }

        .note-author {
            font-weight: bold;
            font-size: 14px;
            line-height: 120%;
        }
    }

    .header-right {
        button {
            margin: 0px;
            i {
                padding-right: 4px;
            }
        }
    }
}

.edit-header-wrapper {
    ::v-deep .pendo-card__header {
        background: $color-gray-40;
        color: $color-black;
    }

    ::v-deep .pendo-input__field {
        border-style: unset;
    }
}

.edit-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

::v-deep .pendo-card {
    line-height: unset;
}

.edit-tag {
    color: $color-gray-70;
}
</style>
