<template>
    <div class="display-alerts-drawer">
        <p class="drawer-sub-header">
            {{ productType === 'adopt' ? adoptHeaderText : engageHeaderText }}
        </p>
        <div class="display-alerts-drawer__body">
            <pendo-table
                :data="impactedUsers"
                :columns="cols"
                :loading="guideVisitorAggStatus === 'loading'"
                :loading-text="'Loading Impacted Users'"
                condensed
                auto-height
                row-key="visitorId"
                @link-click="onLinkClick" />
        </div>
        <pendo-button
            class="display-alerts-drawer__button"
            theme="app"
            prefix-icon="download"
            type="secondary"
            label="Download CSV"
            data-cy="download-impacted-users"
            @click="downloadCsv" />
        <pendo-csv-modal
            :status="csvAggState.status"
            :url="csvAggState.url"
            :visible="showCsvDownloadModal"
            @close="showCsvDownloadModal = false" />
    </div>
</template>

<script>
import PendoTable from '@/components/table/pendo-table';
import PendoCsvModal from '@/composites/csv-modal/pendo-csv-modal.vue';
import PendoButton from '@/components/button/pendo-button.vue';

export default {
    name: 'UserImpactDrawerContent',
    components: {
        PendoTable,
        PendoButton,
        PendoCsvModal
    },
    props: {
        impactedUsers: {
            type: Array,
            default: () => []
        },
        guideVisitorAggStatus: {
            type: String,
            required: true
        },
        csvAggState: {
            type: Object,
            default: () => ({})
        },
        productType: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            cols: [
                {
                    type: 'link',
                    prop: 'visitorId',
                    label: 'Visitor ID',
                    sortable: true
                }
            ],
            showCsvDownloadModal: false,
            engageHeaderText:
                'Click on the individual user to view their timeline. Viewing their timeline may help you better understand what triggered the alert.',
            adoptHeaderText: 'Click on the individual user to view their Visitor Details.'
        };
    },
    watch: {
        'csvAggState.status': function (newValue) {
            if (newValue === 'loading') {
                this.showCsvDownloadModal = true;
            }
        }
    },
    methods: {
        onLinkClick ({ row }) {
            this.$emit('visitor-link-click', row.visitorId);
        },
        downloadCsv () {
            this.$emit('download-csv');
        }
    }
};
</script>

<style lang="scss" scoped>
.drawer-sub-header {
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 10px;
}

.display-alerts-drawer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.display-alerts-drawer__body {
    flex-grow: 1;
}

.display-alerts-drawer__button {
    margin: 0 24px 16px 24px;
}
</style>
