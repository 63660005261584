<template>
    <pendo-modal
        :visible="visible"
        :action-buttons="actionButtons"
        height="auto"
        title="Download CSV"
        class="pendo-csv-modal"
        esc-to-close
        @close="closeModal">
        <div class="pendo-csv-modal__body">
            <div
                v-if="status === 'loading'"
                class="pendo-csv-modal__state">
                <pendo-loading-indicator size="medium" />
                <div class="pendo-csv-modal__text">
                    Please wait. Generating CSV...
                </div>
            </div>
            <div
                v-if="status === 'rejected'"
                class="pendo-csv-modal__state">
                <pendo-icon
                    type="minus-circle"
                    size="24"
                    stroke="#DB1111" />
                <div class="pendo-csv-modal__text pendo-csv-modal__error-message">
                    Looks like something went wrong. Try downloading the CSV again or contacting support if this issue
                    persists.
                </div>
            </div>
            <div
                v-if="status === 'resolved'"
                class="pendo-csv-modal__state">
                <a
                    :href="url"
                    class="pendo-csv-modal__download-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    download>
                    Click to Download CSV
                </a>
            </div>
            <div
                v-if="note"
                class="pendo-csv-modal__note">
                {{ note }}
            </div>
        </div>
    </pendo-modal>
</template>
<script>
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';

const validLoadingStates = ['loading', 'rejected', 'resolved'];

export default {
    name: 'CsvModal',
    components: {
        PendoModal,
        PendoLoadingIndicator,
        PendoIcon
    },
    props: {
        status: {
            type: String,
            required: true,
            validator (status) {
                const isValid = validLoadingStates.includes(status);
                if (!isValid) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `Status "${status}" is not valid status. Valid status: ${validLoadingStates.join(', ')}`
                    );

                    return false;
                }

                return true;
            }
        },
        note: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            actionButtons: [{ theme: 'app', type: 'secondary', label: 'Close', handler: this.closeModal }]
        };
    },
    methods: {
        closeModal () {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-csv-modal) {
    @include element(body) {
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @include element(note) {
        color: $color-gray-60;
        font-size: $font-size-small;
        text-align: center;
    }

    @include element(state) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @include element(text) {
        text-align: center;
        margin-top: 20px;
    }

    @include element(download-link) {
        font-size: $font-size-large;
        padding: 20px;
    }
}
</style>
