<template>
    <div
        class="pendo-editable-content"
        :class="{ 'is-editing': inEditMode }">
        <div
            v-if="!!topLabel"
            class="pendo-editable-content__label pendo-editable-content__label--top">
            {{ topLabel }}
        </div>
        <template v-if="inEditMode">
            <pendo-form
                ref="contentEditableForm"
                class="pendo-editable-content__form"
                :model="{ value: internalValue }"
                @submit.native.prevent="confirm">
                <pendo-form-item
                    class="pendo-editable-content__form-item"
                    :rules="rules"
                    prop="value">
                    <pendo-input-number
                        v-if="type === 'number'"
                        ref="input"
                        :value="internalValue"
                        :disabled="isPendingExit"
                        v-bind="inputAttrs"
                        class="pendo-editable-content__input"
                        v-on="listeners">
                        <template
                            v-for="(slot, name) in $slots"
                            #[name]>
                            <slot
                                v-if="name !== 'content'"
                                :name="name" />
                        </template>
                        <template
                            v-for="(slot, name) in $scopedSlots"
                            #[name]>
                            <slot
                                v-if="name !== 'content'"
                                :name="name"
                                :pending="isPendingExit"
                                :confirm="confirm"
                                :cancel="cancel" />
                        </template>
                    </pendo-input-number>
                    <pendo-input
                        v-if="type === 'text'"
                        ref="input"
                        v-pendo-input-autowidth="autoWidth && { maxWidth: maxWidth, extraWidth: 0 }"
                        :value="internalValue"
                        :disabled="isPendingExit"
                        v-bind="inputAttrs"
                        class="pendo-editable-content__input"
                        :class="{
                            'pendo-editable-content__slot-input': $slots.content || $scopedSlots.content,
                            'pendo-editable-content__slot-input--with-icon':
                                ($slots.content || $scopedSlots.content) && !!contentSlotIcon
                        }"
                        v-on="listeners">
                        <template
                            v-for="(slot, name) in $slots"
                            #[name]>
                            <slot
                                v-if="name !== 'content'"
                                :name="name" />
                        </template>
                        <template
                            v-for="(slot, name) in $scopedSlots"
                            #[name]>
                            <slot
                                v-if="name !== 'content'"
                                :name="name"
                                :pending="isPendingExit"
                                :confirm="confirm"
                                :cancel="cancel" />
                        </template>
                    </pendo-input>
                    <div
                        v-if="beforeExitError"
                        class="pendo-editable-content__error pendo-form-item__error">
                        {{ beforeExitError }}
                    </div>
                </pendo-form-item>
            </pendo-form>
        </template>
        <template v-if="!inEditMode">
            <div
                v-if="$slots.content || $scopedSlots.content"
                class="pendo-editable-content__slot"
                tabindex="0"
                @keydown.enter.prevent="enterEditMode"
                @mousedown.prevent.stop="enterEditMode">
                <div class="pendo-editable-content__slot-content">
                    <slot
                        :enter-edit-mode="enterEditMode"
                        :pending="isPendingExit"
                        :confirm="confirm"
                        :cancel="cancel"
                        name="content" />
                </div>
                <pendo-icon
                    v-if="contentSlotIcon"
                    :type="contentSlotIcon"
                    class="pendo-editable-content__slot-icon"
                    size="14"
                    display="grid" />
            </div>
            <div
                v-if="!$slots.content && !$scopedSlots.content"
                class="pendo-editable-content__text"
                tabindex="0"
                @keydown.enter.prevent="enterEditMode"
                @mousedown.prevent.stop="enterEditMode">
                <span>
                    <slot
                        name="value"
                        :value="internalValue">
                        {{ formattedValue }}
                    </slot>
                </span>
            </div>
        </template>
        <div
            v-if="!!bottomLabel"
            class="pendo-editable-content__label pendo-editable-content__label--bottom">
            {{ bottomLabel }}
        </div>
    </div>
</template>

<script>
import omit from 'lodash/omit';
import PendoForm from '@/components/form/pendo-form.vue';
import PendoFormItem from '@/components/form/pendo-form-item.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoInputNumber from '@/components/input-number/pendo-input-number.vue';
import PendoInput from '@/components/input/pendo-input';
import labelsMixin from '@/mixins/labels';
import { PendoInputAutowidth } from '@/directives/input-autowidth/pendo-input-autowidth';
import { warn } from '@/utils/console';
import { keyCodes } from '@/utils/utils';

export default {
    name: 'PendoEditableContent',
    components: {
        PendoInputNumber,
        PendoForm,
        PendoFormItem,
        PendoIcon,
        PendoInput
    },
    directives: {
        PendoInputAutowidth
    },
    mixins: [labelsMixin],
    inheritAttrs: false,
    props: {
        /**
         * bound value
         */
        value: {
            type: [String, Number],
            default: ''
        },
        /**
         * type of input
         * @values text, number
         */
        type: {
            type: String,
            default: 'text',
            validator: (type) => ['text', 'number'].includes(type)
        },
        /**
         * props for the underlying input
         */
        inputProps: {
            type: Object,
            default: () => ({})
        },
        /**
         * desigates the event that causes the user to exit edit mode
         */
        exitEvent: {
            type: String,
            default: undefined
        },
        /**
         * text to display when null is passed
         */
        emptyText: {
            type: String,
            default: 'Not Set'
        },
        /**
         * @ignore
         */
        contentSlotClass: {
            type: String,
            default: null
        },
        /**
         * @ignore
         */
        contentSlotIcon: {
            type: [Boolean, String],
            default: 'edit-2'
        },
        /**
         * @ignore
         */
        maxWidth: {
            type: [String, Number],
            default: null
        },
        /**
         * whether to have the input dynamically change width as the content inside it changes
         */
        autoWidth: {
            type: Boolean,
            default: false
        },
        /**
         * Hook function to call before exiting
         */
        beforeExit: {
            type: Function,
            default: null
        },
        /**
         * allow saving of empty string. by default, empty strings trigger a `cancel` event instead of a `confirm` event.
         */
        allowEmpty: {
            type: Boolean,
            default: false
        },
        /**
         * any validation rules to apply to input value before exiting. uses [async-validator](https://github.com/yiminghe/async-validator)
         */
        validationRules: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            initialValue: null,
            internalValue: null,
            inEditMode: false,
            isPendingExit: false,
            beforeExitError: null,
            rules: this.validationRules
        };
    },
    computed: {
        formattedValue () {
            if (this.type === 'number') {
                if (this.internalValue !== null && this.internalValue !== undefined && this.internalValue !== '') {
                    return this.internalValue;
                }

                return this.emptyText;
            }

            return this.internalValue || this.emptyText;
        },
        inputAttrs () {
            if (this.type === 'number') {
                return {
                    alwaysShowArrows: true,
                    ...this.inputProps
                };
            }

            return this.inputProps;
        },
        listeners () {
            const internalEvents = ['enterEditMode', 'exitEditMode', 'cancel', 'confirm'];
            const shouldUseMountedHook = this.$slots.content || this.$scopedSlots.content;
            const updateInternalValueEvent = this.type === 'number' ? 'change' : 'input';
            const listeners = {
                ...omit(this.$listeners, internalEvents),
                keydown: this.onKeydown,
                [updateInternalValueEvent]: this.onInput
            };

            if (shouldUseMountedHook) {
                listeners['hook:mounted'] = this.bindContentClass;
            }

            if (this.type === 'number' && this.exitEvent) {
                // if using `change` for exit event, the number input would call
                // `confirm` and then exit edit mode
                warn("[pendo-editable-content]: exit events are not currently supported with type 'number'", this);

                return listeners;
            }

            if (this.exitEvent) {
                listeners[this.exitEvent] = this.confirm;
            }

            return listeners;
        }
    },
    watch: {
        value (val) {
            this.internalValue = val;
        },
        internalValue () {
            if (this.beforeExitError) {
                this.beforeExitError = null;
            }
        }
    },
    created () {
        this.internalValue = this.value;
    },
    mounted () {
        if (this.$slots.content || this.$scopedSlots.content) {
            this.inputClass = this.getBindingContentClass();
        }
        if (this.inputProps && this.inputProps.autofocus) {
            this.enterEditMode();
        }
    },
    methods: {
        getBindingContentClass () {
            if (!this.contentSlotClass) {
                const contentSlotWrapper = '.pendo-editable-content__slot-content';

                return this.$el.querySelector(contentSlotWrapper).lastElementChild.className;
            }

            return this.contentSlotClass;
        },
        bindContentClass () {
            this.$el.querySelector('.pendo-input__input').classList.add(this.inputClass);
        },
        onKeydown (event) {
            if (event.keyCode === keyCodes.esc) {
                event.preventDefault();
                this.cancel();
            }
        },
        onInput (value) {
            if (this.inEditMode) {
                this.internalValue = value;
                this.$emit('input', value);
            }
        },
        async confirm () {
            // if this.exitEvent is 'blur', an event listener will be triggred
            // when @keydown.esc.native is fired. If this.inEditMode, it means the esc listener
            // hasn't already run and we should handle confirm event
            if (!this.inEditMode) {
                return;
            }

            try {
                await this.$refs.contentEditableForm.validate();
            } catch (err) {
                return;
            }

            if (this.type === 'text') {
                this.internalValue = this.internalValue.trim();
            }

            if (this.type === 'text' && !this.allowEmpty && this.internalValue.trim().length === 0) {
                return this.cancel();
            }

            this.$emit('confirm', this.internalValue);
            this.startExitProcess();
        },
        cancel () {
            this.internalValue = this.initialValue;
            this.$emit('cancel', this.internalValue);
            this.exitEditMode();
        },
        /**
         * @public
         * puts the component into edit mode and focuses the input
         */
        enterEditMode () {
            // store reference to the current model for cancel behavior
            this.initialValue = this.internalValue;
            this.inEditMode = true;
            this.$emit('enterEditMode', this.internalValue);

            this.$nextTick(() => {
                let inputRef = this.$refs.input;
                if (this.type === 'number') {
                    inputRef = this.$refs.input.$refs.input;
                }

                if (inputRef) {
                    inputRef.focus();
                }
            });
        },
        startExitProcess () {
            // support a before exit hook so that consuming app can call
            // a save function and leave the editing mode open until resolve/reject
            // supports both Promise.resolve/reject and return true/false
            if (this.beforeExit && typeof this.beforeExit === 'function') {
                this.isPendingExit = true;
                const before = this.beforeExit(this.internalValue);
                if (before && before.then) {
                    return before.then(
                        () => {
                            this.exitEditMode();
                        },
                        (error) => {
                            this.isPendingExit = false;
                            this.beforeExitError = error;
                        }
                    );
                }

                if (before !== false) {
                    this.exitEditMode();
                } else {
                    this.isPendingExit = false;
                }
            } else {
                this.exitEditMode();
            }
        },
        exitEditMode () {
            this.isPendingExit = false;
            this.inEditMode = false;
            this.$emit('exitEditMode', this.internalValue);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-editable-content) {
    min-height: $input-height;
    max-width: 100%;
    width: fit-content;

    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 20px;
        color: $color-gray-110;

        @include modifier(top) {
            font-weight: 600;
            align-items: start;
        }
        @include modifier(bottom) {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    // default slot styling, user is NOT in edit mode
    @include element((slot, text)) {
        width: fit-content;
    }

    @include element(slot) {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
        align-items: end;
        @include focus-ring($style: 'base');

        .pendo-editable-content__slot-content {
            border-radius: 3px;
            outline-offset: -2px;
            position: relative;
        }

        &:hover {
            cursor: text;

            .pendo-editable-content__slot-content {
                position: relative;
                color: $color-gray-60;
                border-bottom-color: $color-gray-60;

                * {
                    color: inherit;
                }
            }

            .pendo-editable-content__slot-icon {
                visibility: visible;
            }
        }

        &:focus-visible {
            .pendo-editable-content__slot-content {
                @include focus-ring($style: 'focused');
            }
            .pendo-editable-content__slot-icon {
                visibility: visible;
            }
        }
    }

    // not using default slot, user is NOT in edit mode
    @include element(text) {
        max-width: 100%;
        color: $color-link;
        border-radius: 3px;
        @include ellipsis;
        @include focus-ring($style: 'base');

        span {
            white-space: nowrap;
            padding-bottom: 0px;
            border-top: 1px dashed $color-transparent;
            border-bottom: 1px dashed $color-link;
            box-sizing: border-box;
            max-width: max-content;
            font-size: $input-font-size;
            font-weight: 600;
            color: $color-link;
            line-height: $input-height;
        }

        &:hover {
            color: $color-link-hover;

            span {
                cursor: text;
                color: $color-link-hover;
                border-bottom-color: $color-link-hover;
            }
        }

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }
    }

    // default input styling, user IS in edit mode
    @include element(input) {
        &:not(.pendo-editable-content__slot-input) {
            .pendo-input__control {
                width: fit-content;

                .pendo-input__append {
                    border: 0;
                    padding: 0;
                    background-color: $color-transparent;
                    width: auto;
                    margin-left: 8px;
                }

                .pendo-button + .pendo-button {
                    margin-left: 4px;
                }

                .pendo-input__field {
                    border-top-right-radius: $border-radius-3;
                    border-bottom-right-radius: $border-radius-3;
                }
            }
        }
    }

    // slotted input styling, user IS in edit mode
    @include element(slot-input) {
        position: relative;
        margin: auto;
        box-sizing: border-box;
        color: inherit;
        border-bottom: 2px dashed $color-gray-60;

        .pendo-input__field {
            border-radius: 0;
            border: 0;
            padding: 0;
        }

        .pendo-input__input {
            max-height: unset;
            min-height: unset;
            box-sizing: border-box;
            padding: 0;
            max-width: $editable-content-max-width;
            height: 100%;
            line-height: 1;
        }

        @include modifier(with-icon) {
            margin-right: 20px;
        }
    }

    @include element((slot-content, slot-input)) {
        max-width: $editable-content-max-width;
    }

    @include element(slot-content) {
        border-bottom: 2px dashed $color-transparent;

        * {
            @include ellipsis;
        }
    }

    @include element(slot-icon) {
        visibility: hidden;
    }

    @include element(form-item) {
        &.pendo-form-item {
            margin-bottom: 0;
        }
    }

    @include is(editing) {
        width: fit-content;
    }
}
</style>
