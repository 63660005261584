<template>
    <div
        class="pendo-color-picker-slider"
        :class="{
            'pendo-color-picker-slider--alpha': isAlpha,
            'pendo-color-picker-slider--hue': isHue
        }">
        <div
            :style="{ background: gradientColor }"
            class="pendo-color-picker-slider__gradient" />
        <color-picker-checkboard
            v-if="isAlpha"
            class="pendo-color-picker-slider__checkboard" />
        <div class="pendo-color-picker-slider__control">
            <input
                type="range"
                :aria-label="ariaLabel"
                :min="0"
                :max="isHue ? 360 : 100"
                :value="value"
                @input="onInput">
        </div>
    </div>
</template>

<script>
import ColorPickerCheckboard from '@/components/color-picker/common/color-picker-checkboard.vue';

export default {
    name: 'ColorPickerSlider',
    components: {
        ColorPickerCheckboard
    },
    props: {
        valueKey: {
            type: String,
            required: true
        },
        color: {
            type: Object,
            required: true
        },
        ariaLabel: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            oldValue: 0
        };
    },
    computed: {
        isAlpha () {
            return this.valueKey === 'alpha';
        },
        isHue () {
            return this.valueKey === 'h';
        },
        value () {
            if (this.isHue) {
                if (this.color.hsl.h === 0 && this.oldValue > 359) {
                    return 360;
                }

                return this.color.hsl.h;
            }

            return this.color.alpha * 100;
        },
        gradientColor () {
            if (this.isHue) {
                return 'linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%)';
            }

            const rgb = `${this.color.r}, ${this.color.g}, ${this.color.b}`;

            return `linear-gradient(to right, rgba(${rgb}, 0) 0%, rgba(${rgb}, 1) 100%)`;
        }
    },
    methods: {
        onInput (event) {
            let value = event.target.valueAsNumber;

            if (this.isAlpha) {
                value /= 100;
            }

            this.oldValue = value;
            this.$emit('change', {
                ...this.color.hsl,
                [this.valueKey]: value
            });
        }
    }
};
</script>

<style lang="scss">
@use "sass:math";

$thumb-height: 8px;
$thumb-width: 4px;
$thumb-bg-color: #ececec;

$track-bg-color: transparent;
$track-width: 100%;
$track-height: 10px;
$track-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
$track-box-shadow-focus: inset 0 0 0 1px $color-gray-100;
$track-radius: 3px;

@mixin track {
    cursor: pointer;
    height: $track-height;
    width: $track-width;
    transition: all 0.2s ease;
}

@mixin thumb {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    background: $thumb-bg-color;
    border: none;
    border-radius: 1px;
    box-sizing: border-box;
    cursor: ew-resize;
    height: $thumb-height;
    width: $thumb-width;
}

@include block(pendo-color-picker-slider) {
    position: relative;
    height: 10px;
    border-radius: $border-radius-3;

    @include element((gradient, checkboard)) {
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: contain;
        border-radius: $border-radius-3;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    }

    @include element(gradient) {
        z-index: 2;
    }

    @include element(control) {
        input[type='range'] {
            z-index: 2;
            position: absolute;
            -webkit-appearance: none;
            background: transparent;
            margin: 0;
            width: $track-width;

            &::-moz-focus-outer {
                border: 0;
            }

            &:focus {
                outline: 0;

                &::-webkit-slider-runnable-track {
                    box-shadow: $track-box-shadow-focus;
                }

                &::-ms-fill-lower {
                    box-shadow: $track-box-shadow-focus;
                }

                &::-ms-fill-upper {
                    box-shadow: $track-box-shadow-focus;
                }
            }

            &::-webkit-slider-runnable-track {
                @include track;
                box-shadow: $track-box-shadow;
                background: $track-bg-color;
                border: none;
                border-radius: $track-radius;
            }

            &::-webkit-slider-thumb {
                @include thumb;
                -webkit-appearance: none;
                margin-top: (math.div($track-height, 2) - math.div($thumb-height, 2));
            }

            &::-moz-range-track {
                box-shadow: $track-box-shadow;
                @include track;
                background: $track-bg-color;
                border: none;
                border-radius: $track-radius;
                height: math.div($track-height, 2);
            }

            &::-moz-range-thumb {
                @include thumb;
            }

            &::-ms-track {
                @include track;
                background: transparent;
                border-color: transparent;
                border-width: math.div($thumb-height, 2) 0;
                color: transparent;
            }

            &::-ms-fill-lower {
                box-shadow: $track-box-shadow;
                background: $track-bg-color;
                border: none;
                border-radius: ($track-radius * 2);
            }

            &::-ms-fill-upper {
                box-shadow: $track-box-shadow;
                background: $track-bg-color;
                border: none;
                border-radius: ($track-radius * 2);
            }

            &::-ms-thumb {
                @include thumb;
                margin-top: math.div($track-height, 4);
            }

            &:disabled {
                &::-webkit-slider-thumb,
                &::-moz-range-thumb,
                &::-ms-thumb,
                &::-webkit-slider-runnable-track,
                &::-ms-fill-lower,
                &::-ms-fill-upper {
                    cursor: not-allowed;
                }
            }
        }
    }
}
</style>
