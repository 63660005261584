<template>
    <main class="localization-settings">
        <div class="localization-settings__grid">
            <slot name="pageAlerts" />
            <pendo-card
                class="localization-settings__settings"
                title="Settings"
                body-min-height="0">
                <div class="localization-settings__settings-section">
                    <div class="localization-settings__settings-section--left">
                        <label>
                            Enable Localization
                        </label>
                        <p
                            id="l10n-toggle-description"
                            class="description">
                            Localization allows your guides and resource centers to support multiple languages and
                            display in the language of your users' preference.
                        </p>
                    </div>
                    <div class="localization-settings__settings-section--right">
                        <pendo-toggle
                            :value="isLocalizationEnabled"
                            aria-describedby="l10n-toggle-description"
                            @input="toggleLocalization" />
                    </div>
                </div>
                <template v-if="isLocalizationEnabled">
                    <pendo-divider />
                    <div class="localization-settings__settings-section">
                        <div class="localization-settings__settings-section--left">
                            <label>
                                Language Preference Metadata
                            </label>
                            <p class="description">
                                Please specify the metadata that identifies your users' language preference so that
                                Pendo can accurately localize your guides and resource centers.
                            </p>
                        </div>
                        <div class="localization-settings__settings-section--right">
                            <div class="lang-pref-selection-dropdown">
                                <pendo-multiselect
                                    :loading="loading"
                                    :disabled="loading"
                                    :options="options"
                                    :value="selectedOption"
                                    @select="updateLanguagePreference">
                                    <template #selectedLabel="{ option }">
                                        <pendo-icon-option :option="option" />
                                    </template>
                                    <template #option="{ option }">
                                        <pendo-icon-option :option="option" />
                                    </template>
                                </pendo-multiselect>
                                <slot name="dropdownAlerts" />
                            </div>
                        </div>
                    </div>
                </template>
            </pendo-card>
            <template v-if="isLocalizationEnabled">
                <pendo-table
                    title="Supported Languages"
                    :data="languageTableData"
                    row-key="code"
                    :columns="columns"
                    :max-height="500">
                    <template #headerActions>
                        <pendo-button
                            type="link"
                            prefix-icon="plus"
                            label="Add Language"
                            theme="app"
                            @click="openLangModal({}, MODAL_TYPES.add)" />
                    </template>
                    <template #name="{ row }">
                        <div
                            :data-cy="`language-name-${row.name.toLowerCase()}`"
                            :class="!isRowValid(row) ? 'row-has-error' : ''">
                            <pendo-icon
                                v-if="!isRowValid(row)"
                                v-pendo-tooltip="{
                                    arrow: true,
                                    content: `${row.code} does not match any of the supported languages`
                                }"
                                :center="true"
                                :inline="true"
                                fill="#cc3340"
                                size="18"
                                stroke="#fff"
                                type="alert-circle" />
                            {{ row.name }}&nbsp;({{ row.code }})&nbsp;<em
                                v-if="row.isDefaultLanguage"
                                class="default-language-label">
                                Default</em>&nbsp;
                        </div>
                    </template>
                    <template #actions="{ row }">
                        <pendo-button
                            v-if="isRowValid(row)"
                            v-pendo-tooltip="{ content: 'Edit', delay: { show: 300, hide: 0 } }"
                            theme="app"
                            type="tertiary"
                            icon="edit-2"
                            @click="openLangModal(row, MODAL_TYPES.edit)" />
                        <div v-pendo-tooltip="!row.isDefaultLanguage && deleteTooltipConfig">
                            <pendo-button
                                class="delete-language"
                                theme="app"
                                type="tertiary"
                                icon="trash-2"
                                :disabled="row.isDefaultLanguage || disableDeleteSupportedLanguages"
                                @click="openLangModal(row, MODAL_TYPES.delete)" />
                        </div>
                    </template>
                </pendo-table>
                <pendo-card
                    class="localization-settings__fallback"
                    title="Missing Translation Language Behavior"
                    body-min-height="0">
                    <div class="localization-settings__fallback-section">
                        <div class="localization-settings__fallback-section--left">
                            <label>
                                Show Default Language if Translation is Missing
                            </label>
                            <p class="description fallback-description">
                                If a translation is missing for a public guide or resource center, enabling this setting
                                will show the default language instead. Disabling this setting will not show the guide
                                or resource center.
                            </p>
                            <small class="small-description">
                                <strong>Note:</strong>
                                <slot name="disableFallbackStrategyWarning">
                                    <template v-if="!disableChangeFallbackStrategy">
                                        Disabling this setting will affect the behavior of all guides and resource
                                        centers.
                                    </template>
                                    <template v-if="disableChangeFallbackStrategy">
                                        This setting cannot be modified.
                                    </template>
                                </slot>
                            </small>
                        </div>
                        <div class="localization-settings__fallback-section--right">
                            <pendo-toggle
                                :value="localizationConfig.fallbackStrategy === fallbackStrategy.useDefaultLanguage"
                                :disabled="configLoading || disableChangeFallbackStrategy"
                                @input="onToggleFallbackStrategy" />
                        </div>
                    </div>
                </pendo-card>
            </template>
        </div>
        <template v-if="isLocalizationEnabled">
            <pendo-modal
                type="confirmation"
                :visible="!!confirmationModalSetting"
                :title="confirmationModal.title"
                :message="confirmationModal.message"
                :show-close="true"
                :confirm-button-config="{
                    label: 'Disable Setting',
                    loading: configLoading,
                    type: 'danger'
                }"
                @close="confirmationModalSetting = null"
                @cancel="confirmationModalSetting = null"
                @confirm="onConfirmationModalConfirm" />
            <localization-modal
                :app-id="appId"
                :selected-language="selectedLanguage"
                :open-modal="showLangModal"
                :existing-language-mappings="localizationConfig.languageMappings"
                :language-mapping="languageMapping"
                :modal-type="modalType"
                :server-error-message="serverErrorMessage"
                :is-processing-change="configLoading"
                @close-language-modal="handleLanguageModalClose"
                @update-language="updateLanguage"
                @delete-language="deleteLanguageFromApp">
                <template #message>
                    <slot name="message" />
                </template>
                <template #unsupportedAgentOption="{ option }">
                    <slot
                        name="unsupportedAgentOption"
                        :option="option" />
                </template>
            </localization-modal>
        </template>
    </main>
</template>
<script>
import PendoToggle from '@/components/toggle/pendo-toggle.vue';
import PendoDivider from '@/components/divider/pendo-divider.vue';
import PendoCard from '@/components/card/pendo-card.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip.js';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect.vue';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';
import LocalizationModal, { MODAL_TYPES } from './localization-modal.vue';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

const CONFIRMATION_MODAL_SETTINGS = {
    disableFallback: 'disableFallback',
    disableLocalization: 'disableLocalization'
};

export default {
    name: 'PendoLocalizationSettingsPage',
    components: {
        PendoButton,
        PendoIcon,
        PendoModal,
        PendoDivider,
        PendoTable,
        PendoCard,
        PendoToggle,
        PendoMultiselect,
        PendoIconOption,
        LocalizationModal
    },
    directives: {
        PendoTooltip
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        disableChangeFallbackStrategy: {
            type: Boolean,
            default: false
        },
        disableDeleteSupportedLanguages: {
            type: Boolean,
            default: false
        },
        disableLocalizationConfirmationMessage: {
            type: [String, Boolean],
            default: null
        },
        languageMapping: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Array,
            default: () => []
        },
        configLoading: {
            type: Boolean,
            default: true
        },
        languageTableData: {
            type: Array,
            default: () => []
        },
        localizationConfig: {
            type: Object,
            default: () => {}
        },
        serverErrorMessage: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            columns: [
                {
                    prop: 'name',
                    label: 'Language'
                },
                {
                    prop: 'metadataValue',
                    label: 'Metadata Value'
                },
                {
                    type: 'actions',
                    width: 80
                }
            ],
            showLangModal: false,
            selectedLanguage: {},
            modalType: '',
            confirmationModalSetting: null,
            fallbackStrategy: {
                useDefaultLanguage: 'useDefaultLanguage',
                doNotDisplay: 'doNotDisplay'
            },
            MODAL_TYPES
        };
    },
    computed: {
        appId () {
            return get(this.app, 'id', null);
        },
        isLocalizationEnabled () {
            return get(this.app, 'applicationFlags.guideLocalization', false);
        },
        confirmationModalConfig () {
            return {
                [CONFIRMATION_MODAL_SETTINGS.disableLocalization]: {
                    title: 'Disable Localization?',
                    message: this.disableLocalizationConfirmationMessage,
                    action: () => {
                        this.toggleLocalization(false);
                    }
                },
                [CONFIRMATION_MODAL_SETTINGS.disableFallback]: {
                    title: 'Disable Setting?',
                    message:
                        'Any public guides or resource centers with missing translations will not appear for users.',
                    action: () => {
                        this.$emit('update-app-localization-property', {
                            prop: 'fallbackStrategy',
                            value: this.fallbackStrategy.doNotDisplay
                        });
                    }
                }
            };
        },
        confirmationModal () {
            return this.confirmationModalConfig[this.confirmationModalSetting] || {};
        },
        deleteTooltipConfig () {
            return {
                arrow: true,
                multiline: true,
                trigger: 'hover',
                content: this.disableDeleteSupportedLanguages
                    ? 'Cannot delete this Supported Language as it will impact the delivery of your translated guides'
                    : 'Delete',
                delay: { show: 300, hide: 0 }
            };
        },
        loading () {
            return this.configLoading || isEmpty(this.options);
        },
        selectedOption () {
            const { languageMetadataFieldName } = this.localizationConfig;
            if (!languageMetadataFieldName) {
                return;
            }

            return this.options.find((option) => {
                return option.id === languageMetadataFieldName;
            });
        }
    },
    methods: {
        toggleLocalization (guideLocalization) {
            if (!guideLocalization && this.disableLocalizationConfirmationMessage && !this.confirmationModalSetting) {
                this.confirmationModalSetting = CONFIRMATION_MODAL_SETTINGS.disableLocalization;

                return;
            }

            const applicationFlags = {
                ...this.app.applicationFlags,
                guideLocalization
            };

            this.$emit('update-app-localization', {
                app: this.app,
                updates: {
                    applicationFlags
                }
            });
        },
        openLangModal (row, type) {
            this.selectedLanguage = row;
            this.modalType = type;
            this.showLangModal = true;
        },
        updateLanguagePreference (languageMetadataFieldName) {
            this.$emit('update-app-localization-property', {
                prop: 'languageMetadataFieldName',
                value: languageMetadataFieldName.id
            });
            this.handleLanguageModalClose();
        },
        updateLanguage ({ appId, code, metadataValue }) {
            this.$emit('update-language', { appId, code, metadataValue });
        },
        handleLanguageModalClose () {
            this.showLangModal = false;

            setTimeout(() => {
                this.modalType = '';
                this.selectedLanguage = {};
            }, 200);
        },
        onConfirmationModalConfirm () {
            this.confirmationModal.action();
            this.confirmationModalSetting = null;
        },
        onToggleFallbackStrategy () {
            if (this.localizationConfig.fallbackStrategy === this.fallbackStrategy.useDefaultLanguage) {
                this.confirmationModalSetting = CONFIRMATION_MODAL_SETTINGS.disableFallback;
            } else if (this.localizationConfig.fallbackStrategy === this.fallbackStrategy.doNotDisplay) {
                this.$emit('update-app-localization-property', {
                    prop: 'fallbackStrategy',
                    value: this.fallbackStrategy.useDefaultLanguage
                });
            }
        },
        isRowValid (row) {
            return !isUndefined(row.name) && !isUndefined(row.nativeName) && !row.isDeprecated;
        },
        deleteLanguageFromApp ({ appId, langCode }) {
            this.$emit('delete-language', { appId, langCode });
            this.handleLanguageModalClose();
        }
    }
};
</script>
<style lang="scss" scoped>
.localization-settings__grid {
    display: grid;
    grid-gap: 32px;
    min-width: 0;
}

.localization-settings__settings-section,
.localization-settings__fallback-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    align-items: center;
}

.lang-pref-selection-dropdown {
    display: flex;
    justify-content: space-between;

    .pendo-multiselect {
        margin: 15px;
    }
}

.delete-language {
    margin-right: 8px;
}

.description {
    padding: 8px 0;
    line-height: 1.4;
    margin: 0;
}

.small-description {
    padding: 8px 0;
    color: $color-text-secondary;
}

.row-has-error {
    align-items: center;
    display: flex;
}

.default-language-label {
    color: $color-text-secondary;
    font-size: 12px;
}
</style>
