<template>
    <div
        class="pendo-notification-count"
        :style="backgroundStyles">
        <div
            v-if="showCount"
            :style="countStyles">
            {{ count > 99 ? '99+' : count }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PendoNotificationCount',
    props: {
        /**
         * Notification number
         */
        count: {
            type: Number,
            required: true
        },
        /**
         * Size of the notification count circle
         */
        size: {
            type: Number,
            default: 20
        },
        /**
         * Boolean value for display notification count
         */
        showCount: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        backgroundStyles () {
            return {
                height: `${this.size}px`,
                width: `${this.width}px`,
                fontSize: `${this.size}px`,
                backgroundColor: '#ff0000',
                color: '#ffffff',
                borderRadius: `${this.size}px`
            };
        },
        countStyles () {
            return {
                fontSize: '.6em'
            };
        },
        width () {
            if (this.count < 10 || !this.showCount) {
                return this.size;
            }

            if (this.count < 100) {
                return this.size * 1.5;
            }

            return this.size * 2;
        }
    }
};
</script>

<style scoped lang="scss">
.pendo-notification-count {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
