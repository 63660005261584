<template>
    <div
        class="pendo-date-picker"
        :class="{
            'is-active': isPopoverVisible,
            'is-open': isPopoverVisible,
            'has-inline-label': !!labels.left || !!labels.right,
            'is-error': invalid
        }">
        <div
            v-if="labels.top || labels.left || !!$slots.topLabel"
            class="pendo-date-picker__label pendo-date-picker__label--top">
            <slot name="topLabel">
                {{ labels.top || labels.left }}
            </slot>
        </div>
        <picker-popover
            :is-open="isPopoverVisible"
            :append-to-body="appendToBody"
            :popper-options="popoverOptions"
            :min-width="minMenuWidth"
            :full-width="fullWidth"
            @click-outside="hidePopover"
            @hide="hidePopover">
            <slot name="trigger">
                <button
                    ref="trigger"
                    type="button"
                    class="pendo-date-picker__trigger"
                    :aria-label="isPopoverVisible ? 'close date picker' : 'open date picker'"
                    :aria-expanded="isPopoverVisible ? 'true' : 'false'"
                    aria-haspopup
                    :class="[
                        `pendo-date-picker__trigger--${triggerSize}`,
                        {
                            'is-active': focused || isPopoverVisible,
                            'is-open': isPopoverVisible,
                            'is-disabled': isDisabled,
                            'is-error': invalid,
                            'is-input-focused': inputFocused
                        }
                    ]"
                    :disabled="isDisabled"
                    @click="togglePopover"
                    @keyup.esc="hidePopover"
                    @focus="focused = true"
                    @blur="focused = false">
                    <div class="pendo-date-picker__prefix">
                        <slot name="prefix">
                            <pendo-icon
                                type="calendar"
                                size="14" />
                        </slot>
                    </div>
                    <div class="pendo-date-picker__value">
                        <input
                            v-if="editable"
                            ref="input"
                            :readonly="!editable"
                            name="input"
                            class="pendo-date-picker__input"
                            :value="inputValue"
                            autocapitalize="none"
                            autocorrect="off"
                            autocomplete="off"
                            spellcheck="false"
                            aria-autocomplete="list"
                            :placeholder="placeholder"
                            type="text"
                            :disabled="isDisabled"
                            @input="updateInputValue"
                            @change="handleInputChange"
                            @focus.stop="handleInputFocus"
                            @blur="handleInputBlur"
                            @keyup.esc="handleInputEsc">
                        <slot
                            name="value"
                            :value="inputValue">
                            <span
                                v-if="!editable"
                                class="pendo-date-picker__input-value"
                                v-text="inputValue || placeholder" />
                        </slot>
                    </div>
                    <div
                        class="pendo-date-picker__suffix"
                        @click.stop="togglePopover">
                        <slot name="suffix">
                            <pendo-icon
                                v-if="!loading"
                                type="chevron-down"
                                size="14"
                                class="pendo-date-picker__caret"
                                :class="{
                                    'is-active': isPopoverVisible
                                }" />
                            <pendo-loading-indicator
                                v-if="loading"
                                type="material"
                                size="small" />
                        </slot>
                    </div>
                </button>
            </slot>
            <template #popper>
                <div
                    class="pendo-date-picker__panel"
                    :class="[
                        `pendo-date-picker--${type}`,
                        {
                            'has-shortcuts': showShortcuts
                        }
                    ]">
                    <shortcuts
                        v-if="showShortcuts"
                        ref="shortcuts"
                        :options="shortcutOptions"
                        @select="handleShortcutSelect">
                        <template #shortcut="props">
                            <slot
                                name="shortcut"
                                v-bind="props" />
                        </template>
                    </shortcuts>
                    <calendar
                        v-if="isCalendarVisible || !showShortcuts"
                        ref="calendar"
                        :active-attribute="activeAttribute"
                        :disabled-attribute="disabledAttribute"
                        v-on="$listeners"
                        @dayclick="handleDayClick"
                        @dayfocusin="handleDayFocusIn"
                        @daymouseenter="handleDayMouseEnter">
                        <template #above>
                            <slot name="above" />
                        </template>
                    </calendar>
                </div>
            </template>
        </picker-popover>
        <div
            v-if="labels.bottom || labels.right || !!$slots.bottomLabel"
            class="pendo-date-picker__label pendo-date-picker__label--bottom">
            <slot name="bottomLabel">
                {{ labels.bottom || labels.right }}
            </slot>
        </div>
    </div>
</template>

<script>
import isString from 'lodash/isString';
import isArrayLikeObject from 'lodash/isArrayLikeObject';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';

import PickerPopover from '@/utils/picker-popover';
import Shortcuts from '@/components/date-picker/shortcuts';
import Calendar from '@/components/date-picker/calendar';

// pickers
import SinglePicker from '@/components/date-picker/utils/pickers/single';
import MultiplePicker from '@/components/date-picker/utils/pickers/multiple';
import RangePicker from '@/components/date-picker/utils/pickers/range';

import Attribute from '@/components/date-picker/utils/attribute';

import Locale from '@/components/date-picker/utils/locale';
import locales from '@/components/date-picker/utils/locales';

import { addDays } from '@/components/date-picker/utils/date-info';
import { setStyles, setAttributes } from '@/utils/dom';

const DEFAULT_MASKS = {
    title: 'MMMM YYYY',
    weekdays: 'W',
    months: 'MMM',
    input: ['MMM D, YYYY', 'L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
};

export default {
    name: 'PendoDatePicker',
    components: {
        Calendar,
        PendoIcon,
        PendoLoadingIndicator,
        PickerPopover,
        Shortcuts
    },
    provide () {
        return {
            state: this
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: null,
            required: true
        },
        /**
         * type of date picker
         * @values single, multiple, range
         */
        type: {
            type: String,
            default: 'single',
            validator: (type) => ['single', 'multiple', 'range'].includes(type)
        },
        /**
         * Set true to trigger the loading spinner.
         */
        loading: {
            type: Boolean,
            default: false
        },
        isRequired: Boolean,
        disabledDates: {
            type: null,
            default: null
        },
        availableDates: {
            type: null,
            default: null
        },
        updateOnInput: {
            type: Boolean,
            default: false
        },
        updateOnDrag: {
            type: Boolean,
            default: false
        },
        popover: {
            type: Object,
            default: () => ({})
        },
        popperOptions: {
            type: Object,
            default: () => ({})
        },
        labels: {
            type: Object,
            default: () => ({})
        },
        appendToBody: {
            type: Boolean,
            default: true
        },
        /**
         * Disables the date-picker if true.
         */
        disabled: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        minTriggerWidth: {
            type: [String, Number],
            default: 160
        },
        /**
         * max width of trigger button/input
         */
        maxTriggerWidth: {
            type: [String, Number],
            default: 400
        },
        /**
         * defines the max width of the dropdown menu
         */
        maxMenuWidth: {
            type: [String, Number],
            default: 400
        },
        /**
         * defines the min width of the dropdown menu
         */
        minMenuWidth: {
            type: [String, Number],
            default: 160
        },
        /**
         * defines the max height of the dropdown menu. scrolls when content is taller than available max height.
         */
        maxMenuHeight: {
            type: [String, Number],
            default: 300
        },
        /**
         * size of trigger
         * @values medium, small, mini
         */
        triggerSize: {
            type: String,
            default: 'medium',
            validator: (triggerSize) => ['mini', 'small', 'medium'].includes(triggerSize)
        },
        firstDayOfWeek: {
            type: Number,
            default: 1
        },
        masks: {
            type: Object,
            default: null
        },
        localeCode: {
            type: String,
            default: 'en-US'
        },
        columns: {
            type: Number,
            default: 1
        },
        step: {
            type: Number,
            default: 1
        },
        fromDate: Date,
        toDate: Date,
        minDate: {
            type: null,
            default: null
        },
        maxDate: {
            type: null,
            default: null
        },
        shortcuts: {
            type: [Boolean, Array],
            default: () => [
                {
                    label: 'Today',
                    value: {
                        start: 0,
                        end: 0
                    }
                },
                {
                    label: 'Yesterday',
                    value: {
                        start: -1,
                        end: -1
                    }
                },
                {
                    label: 'Last 7 Days',
                    value: {
                        start: -1,
                        end: -7
                    }
                },
                {
                    label: 'Last 30 Days',
                    value: {
                        start: -1,
                        end: -30
                    }
                },
                {
                    label: 'Last 90 Days',
                    value: {
                        start: -1,
                        end: -90
                    }
                }
            ]
        },
        placeholder: {
            type: String,
            default: 'Select date'
        },
        editable: {
            type: Boolean,
            default: false
        },
        /**
         * applies invalid styles to date-picker
         */
        invalid: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            dragValue: null,
            inputValue: null,
            inputFocused: false,
            focused: false,
            isPopoverVisible: false,
            disableFormatInput: false,
            disablePopoverHide: false,
            isCalendarVisible: false,
            visibleColumnCount: this.columns,
            twoColumnBreakpoint: null
        };
    },
    computed: {
        popoverOptions () {
            let behavior = [];
            if (this.popperOptions && this.popperOptions.placement) {
                if (this.popperOptions.placement.includes('-end')) {
                    behavior = ['bottom-end', 'top-end'];
                } else {
                    behavior = ['bottom-start', 'top-start'];
                }
            }

            return {
                ...this.popperOptions,
                modifiers: {
                    hide: { enabled: false },
                    preventOverflow: { enabled: false },
                    flip: {
                        enabled: true,
                        behavior,
                        fn: this.flipPopperVariations
                    },
                    arrow: { enabled: false },
                    applyStyle: {
                        enabled: true,
                        order: 900,
                        fn: this.applyPopperStyle
                    }
                }
            };
        },
        model: {
            get () {
                return this.picker.normalize(this.value);
            },
            set (val) {
                this.$emit('change', val);
                if (!this.disableFormatInput) {
                    this.formatInput();
                }
                if (this.type !== 'multiple' && this.isPopoverVisible && !this.disablePopoverHide) {
                    this.hidePopover();
                }
                this.disableFormatInput = false;
                this.disablePopoverHide = false;
            }
        },
        isDisabled () {
            return this.disabled || this.loading;
        },
        showShortcuts () {
            if (this.type !== 'range') {
                return !!this.$options.propsData.shortcuts;
            }

            return !!this.shortcuts;
        },
        shortcutOptions () {
            if (!this.showShortcuts) {
                return null;
            }

            const shortcuts = this.shortcuts.map((shortcut) => {
                const newValue = this.picker.parseShortcutValue(shortcut.value);
                const selected = this.picker.valuesAreEqual(this.model, newValue);

                return {
                    ...shortcut,
                    value: newValue,
                    disabled: !this.dateIsValid(newValue),
                    selected: !this.isCalendarVisible && selected
                };
            });

            const isCustomRangeSelected = shortcuts.every((option) => !option.selected);

            shortcuts.push({
                label: 'Custom Range',
                value: 'custom',
                disabled: false,
                selected: isCustomRangeSelected || this.isCalendarVisible
            });

            return shortcuts;
        },
        locale () {
            const detLocale = new Intl.DateTimeFormat().resolvedOptions().locale;
            let id = this.localeCode || detLocale;

            id = [id, id.substring(0, 2)].find((i) => locales[i]) || detLocale;

            const config = {
                id,
                firstDayOfWeek: this.firstDayOfWeek || locales[id].firstDayOfWeek,
                masks: {
                    ...locales[id].masks,
                    ...DEFAULT_MASKS,
                    ...this.masks
                }
            };

            return new Locale(config);
        },
        inputMasks () {
            const inputFormat = this.locale.masks.input;

            return (isArrayLikeObject(inputFormat) && inputFormat) || [inputFormat];
        },
        selectAttribute () {
            if (!this.picker.hasValue(this.model)) {
                return null;
            }

            return {
                key: 'select',
                dates: this.model,
                pinPage: true
            };
        },
        dragAttribute () {
            if (this.type !== 'range' || !this.picker.hasValue(this.dragValue)) {
                return null;
            }

            return {
                key: 'drag',
                dates: this.dragValue
            };
        },
        disabledAttribute () {
            const dates = [];
            // Add the manually applied disabled dates
            if (this.disabledDates) {
                if (isArrayLikeObject(this.disabledDates)) {
                    dates.push(...this.disabledDates);
                } else {
                    dates.push(this.disabledDates);
                }
            }
            // Add disabled dates for minDate and maxDate props
            const minDate = this.locale.toDate(this.minDate);
            const maxDate = this.locale.toDate(this.maxDate);

            if (minDate) {
                dates.push({ start: null, end: addDays(minDate, -1) });
            }
            if (maxDate) {
                dates.push({ start: addDays(maxDate, 1), end: null });
            }

            return new Attribute(
                {
                    key: 'disabled',
                    dates,
                    excludeDates: this.availableDates,
                    excludeMode: 'includes'
                },
                this.locale
            );
        },
        activeAttribute () {
            if (this.dragAttribute) {
                return this.dragAttribute;
            }

            if (this.selectAttribute) {
                return this.selectAttribute;
            }

            return null;
        },
        picker () {
            const opts = {
                locale: this.locale,
                format: (date) => this.locale.format(date, this.inputMasks[0]),
                parse: (str) => this.locale.parse(str, this.inputMasks)
            };

            switch (this.type) {
                case 'multiple':
                    return new MultiplePicker(opts);
                case 'range':
                    return new RangePicker(opts);
                case 'date':
                case 'day':
                default:
                    return new SinglePicker(opts);
            }
        }
    },
    watch: {
        model () {
            this.formatInput();
        },
        type () {
            this.inputValue = null;
        },
        dragValue (val) {
            if (this.updateOnDrag) {
                this.formatInput();
            }
            this.$emit('drag', this.picker.normalize(val));
        },
        minTriggerWidth () {
            setStyles(this.$refs.trigger, {
                minWidth: this.fullWidth ? '100%' : this.minTriggerWidth
            });
        },
        maxTriggerWidth () {
            setStyles(this.$refs.trigger, { maxWidth: this.maxTriggerWidth });
        }
    },
    created () {
        this.formatInput();
    },
    mounted () {
        setStyles(this.$refs.trigger, {
            minWidth: this.fullWidth ? '100%' : this.minTriggerWidth,
            maxWidth: this.maxTriggerWidth
        });
    },
    methods: {
        handleShortcutSelect (option) {
            if (option.value === 'custom') {
                this.isCalendarVisible = true;

                return;
            }

            this.$emit('select-shortcut', option);
            this.disableFormatInput = true;
            this.picker.handleShortcutSelect(option, this);
            this.inputValue = option.label;
            this.isPopoverVisible = false;
            this.isCalendarVisible = false;
        },
        dateIsValid (date) {
            return !!this.disabledAttribute && !this.disabledAttribute.intersectsDate(date);
        },
        handleDayClick (day) {
            this.picker.handleDayClick(day, this);
            this.$emit('dayclick', day);
        },
        handleDayFocusIn (day) {
            if (day.el) {
                day.el.blur();
            }
            this.$emit('dayfocusin', day);
        },
        handleDayMouseEnter (day) {
            this.picker.handleDayMouseEnter(day, this);
            this.$emit('daymouseenter', day);
        },
        updateInputValue ({ target }) {
            this.inputValue = target.value;
            if (this.updateOnInput) {
                this.updateValue(this.inputValue, {
                    formatInput: false,
                    hidePopover: false
                });
            }
        },
        handleInputBlur () {
            this.inputFocused = false;
        },
        handleInputFocus () {
            this.inputFocused = true;
            this.showPopover();
        },
        handleInputChange () {
            this.updateValue(this.inputValue, {
                formatInput: true,
                hidePopover: false
            });
        },
        handleInputEsc () {
            this.updateValue(this.model, {
                formatInput: true,
                hidePopover: true
            });
        },
        updateValue (value, { formatInput, hidePopover } = {}) {
            this.inputValue = isString(value) ? value : this.inputValue;
            const userValue = isString(value) ? this.picker.parseValue(value) : value;
            const validatedValue = this.picker.filterDisabled({
                value: this.picker.normalize(userValue),
                isRequired: this.isRequired,
                disabled: this.disabledAttribute,
                fallbackValue: this.model
            });

            if (this.picker.valuesAreEqual(this.model, validatedValue)) {
                if (formatInput) {
                    this.formatInput();
                }
                if (hidePopover) {
                    this.hidePopover();
                }
            } else {
                this.disableFormatInput = !formatInput;
                this.disablePopoverHide = !hidePopover;
                this.model = validatedValue;
            }
        },
        formatInput () {
            this.$nextTick(() => {
                if (this.picker.hasValue(this.dragValue)) {
                    this.inputValue = this.picker.formatValue(this.dragValue);

                    return;
                }

                const matchingShortcut =
                    this.showShortcuts &&
                    this.shortcutOptions
                        .filter((shortcut) => shortcut.value !== 'custom')
                        .find((shortcut) => this.picker.valuesAreEqual(shortcut.value, this.model));

                if (matchingShortcut) {
                    this.inputValue = matchingShortcut.label;

                    return;
                }

                this.inputValue = this.picker.formatValue(this.model);
            });
        },
        togglePopover () {
            if (this.isDisabled) {
                return;
            }

            if (this.isPopoverVisible) {
                if (this.editable && this.inputFocused) {
                    return;
                }

                this.hidePopover();
            } else {
                this.showPopover();
            }
        },
        hidePopover () {
            this.dragValue = null;
            this.isPopoverVisible = false;
            this.isCalendarVisible = false;
        },
        showPopover () {
            if (this.showShortcuts) {
                this.isCalendarVisible = !!this.shortcutOptions.find((shortcut) => shortcut.value === 'custom')
                    .selected;
            }

            this.isPopoverVisible = true;
        },
        applyPopperStyle (data) {
            if (!this.fullWidth) {
                data.styles.minWidth = Math.max(this.minMenuWidth, data.offsets.reference.width);
            }

            setStyles(data.instance.popper, data.styles);
            setAttributes(data.instance.popper, data.attributes);

            return data;
        },
        flipPopperVariations (data) {
            const { offsets } = data;
            const { popper, reference } = offsets;
            if (
                this.type === 'range' &&
                this.columns > 1 &&
                this.visibleColumnCount === 1 &&
                this.twoColumnBreakpoint < window.innerWidth
            ) {
                this.twoColumnBreakpoint = null;
                this.visibleColumnCount++;

                return data;
            }

            // Does the popper go out of bounds when it is on the bottom of the screen?
            // If so, top align the popper
            const elementLocation = this.$el.getBoundingClientRect();
            const elementLocationBottom = elementLocation.bottom;
            let placement = 'bottom';
            if (elementLocationBottom + this.maxMenuHeight > window.innerHeight) {
                placement = 'top';
            }

            // Can it fit if left-aligned?
            if (reference.left + popper.width < window.innerWidth) {
                placement += '-start';
                data.instance.options.placement = placement;

                return data;
            }

            // Otherwise, right-align it
            placement += '-end';
            data.instance.options.placement = placement;

            if (this.type === 'range' && this.visibleColumnCount > 1) {
                this.twoColumnBreakpoint = reference.left + popper.width;
                this.visibleColumnCount--;

                return data;
            }

            data.instance.scheduleUpdate();

            return data;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(trigger) {
        padding: 0;
        position: relative;
        width: 100%;
        text-align: left;
        -webkit-appearance: none;
        border-radius: 3px;
        border: 1px solid $color-gray-40;
        background: $color-white;
        padding-left: 8px;
        display: flex;
        align-items: center;

        @include font-base;

        &:hover {
            cursor: pointer;
        }

        &:hover,
        &.is-active,
        &.is-open {
            border: 1px solid $color-gray-100;
        }

        @include focus-ring(
            $style: 'base',
            $transitions: (
                border-color 200ms
            )
        );

        &:focus-visible,
        &.is-input-focused {
            // input elements that require text input show focus-visible in same instances as focus
            @include focus-ring($style: 'focused');
        }

        @include is(disabled) {
            cursor: not-allowed;
            background: $disabled-fill;
            border-color: $disabled-border;
            color: $disabled-color;

            @include element((input, prefix, suffix)) {
                cursor: inherit;
            }
        }

        @include is(error) {
            border: 1px solid $border-color-invalid;
        }

        @include modifier(medium) {
            min-height: 36px;
            line-height: 34px;

            .pendo-date-picker__suffix {
                height: 34px;
                width: 34px;

                &:before {
                    height: 34px;
                    width: 17px;
                }
            }
        }

        @include modifier(small) {
            min-height: 32px;
            line-height: 30px;

            .pendo-date-picker__suffix {
                height: 30px;
                width: 30px;

                &:before {
                    height: 30px;
                    width: 15px;
                }
            }
        }

        @include modifier(mini) {
            min-height: 26px;
            line-height: 24px;

            .pendo-date-picker__suffix {
                height: 24px;
                width: 24px;

                &:before {
                    height: 24px;
                    width: 12px;
                }
            }
        }
    }

    @include element(prefix) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        margin-right: 8px;
    }

    @include element(suffix) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        text-decoration: none;
        text-align: center;
        cursor: pointer;

        &:before {
            position: absolute;
            width: 17px;
            height: 34px;
            right: 100%;
            top: 0;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(270deg, $color-white 0%, rgba(248, 248, 249, 0) 100%);
        }
    }

    @include element(caret) {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s ease;

        @include is(active) {
            transform: rotateZ(180deg);
        }
    }

    @include element(panel) {
        display: grid;
        grid-auto-flow: column;

        &.has-shortcuts {
            .pendo-date-picker__pane {
                border-left: 1px solid $color-gray-30;
            }
        }
    }

    @include element(value) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        box-sizing: border-box;
        flex: 1 1 0%;
        overflow: hidden;
        white-space: nowrap;
    }

    @include element(input) {
        position: relative;
        display: inline-block;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
        touch-action: manipulation;
        width: 100%;

        @include font-base;

        &:focus {
            outline: none;
        }
    }

    @include element(input-value) {
        line-height: 20px;
    }

    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-gray-110;

        @include modifier(top) {
            font-weight: 600;
            align-items: start;
        }
        @include modifier(bottom) {
            color: $color-gray-70;
            align-items: end;
        }
    }

    @include is(inline-label) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        grid-gap: 8px;

        @include element(label) {
            display: grid;
            align-items: center;
        }
    }
}
</style>
