<template>
    <div
        class="pendo-table__column-sort"
        :class="{
            'is-ascending': isAscending,
            'is-descending': isDescending
        }">
        <pendo-icon
            v-if="!isAscending && !isDescending"
            class="column-sort--ascending"
            type="chevron-up"
            stroke="#9A9CA5"
            size="12"
            stroke-width="2" />
        <pendo-icon
            v-if="!isAscending && !isDescending"
            class="column-sort--descending"
            type="chevron-down"
            stroke="#9A9CA5"
            size="12"
            stroke-width="2" />
        <pendo-icon
            v-if="isAscending"
            class="column-sort--ascending"
            type="arrow-up"
            stroke="#2A2C35"
            size="16"
            stroke-width="2" />
        <pendo-icon
            v-if="isDescending"
            class="column-sort--descending"
            type="arrow-down"
            stroke="#2A2C35"
            size="16"
            stroke-width="2" />
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    components: {
        PendoIcon
    },
    inject: ['$table'],
    props: {
        column: {
            type: Object,
            required: true
        }
    },
    computed: {
        isAscending () {
            if (this.$table.sortStore.column) {
                return (
                    this.$table.sortStore.column.property === this.column.property &&
                    this.$table.sortStore.activeSort === 'ascending'
                );
            }

            return false;
        },
        isDescending () {
            if (this.$table.sortStore.column) {
                return (
                    this.$table.sortStore.column.property === this.column.property &&
                    this.$table.sortStore.activeSort === 'descending'
                );
            }

            return false;
        }
    }
};
</script>
