<template>
    <div
        class="pendo-multiselect__control"
        @click.stop>
        <transition
            :name="searchIconTransition"
            appear
            @before-leave="beforeLeave">
            <div
                v-if="isSearchIconVisible"
                class="pendo-multiselect__search-icon-wrapper">
                <pendo-icon
                    class="pendo-multiselect__search-icon"
                    type="search"
                    size="14" />
            </div>
        </transition>
        <transition
            :name="searchInputTransition"
            appear
            @before-leave="beforeLeave">
            <div class="pendo-multiselect__input-wrapper">
                <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
                <input
                    :id="id"
                    ref="input"
                    autocapitalize="none"
                    autocorrect="off"
                    autocomplete="off"
                    spellcheck="false"
                    aria-autocomplete="list"
                    type="text"
                    class="pendo-multiselect__input"
                    :style="inputStyles"
                    :placeholder="computedPlaceholder"
                    :value="inputValue"
                    :disabled="disabled"
                    @click.stop
                    @keydown.up.self.prevent="$emit('up')"
                    @keydown.down.self.prevent="$emit('down')"
                    @keydown.right="$emit('right')"
                    @keydown.left="$emit('left')"
                    @keydown.delete.self="$emit('delete')"
                    @keydown.enter.stop.self="$emit('enter', $event)"
                    @keyup.tab="$emit('tab')"
                    @keyup.esc="$emit('esc')"
                    @input="updateInputValue($event.target.value)">
                <span
                    ref="mirror"
                    class="pendo-multiselect__input-mirror"
                    v-text="computedPlaceholder" />
            </div>
        </transition>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import PendoIcon from '@/components/icon/pendo-icon';
import { TRIGGER_SIZE_MAP } from '@/components/multiselect/multiselect-helper';

export default {
    components: {
        PendoIcon
    },
    props: {
        computedPlaceholder: {
            type: [String, Object, Number]
        },
        disabled: {
            type: Boolean
        },
        id: {
            type: String
        },
        inputValue: {
            type: String
        },
        isFullWidth: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        },
        isSearchIconVisible: {
            type: Boolean
        },
        minTriggerWidth: {
            type: [String, Number]
        },
        showSelectedValueTags: {
            type: Boolean
        },
        shouldAnimateSearchEnterExit: {
            type: Boolean
        },
        triggerSize: {
            type: String
        },
        triggerWidth: {
            type: Number
        },
        updateInputValue: {
            type: Function
        }
    },
    data () {
        const initialMinWidth = parseInt(this.minTriggerWidth) - TRIGGER_SIZE_MAP[this.triggerSize] - 30;

        return {
            inputMinWidth: initialMinWidth,
            inputWidth: initialMinWidth
        };
    },
    computed: {
        inputStyles () {
            if (this.showSelectedValueTags) {
                return null;
            }

            if (this.isFullWidth) {
                return {
                    width: `${this.inputWidth}px`
                };
            }

            return {
                minWidth: `${this.inputMinWidth}px`,
                width: `${this.inputWidth}px`
            };
        },
        searchIconTransition () {
            if (this.shouldAnimateSearchEnterExit) {
                return 'pendo-multiselect-search-icon-fade-scale';
            }

            return '';
        },
        searchInputTransition () {
            if (this.shouldAnimateSearchEnterExit) {
                return 'pendo-multiselect-search-input-slide';
            }

            return '';
        }
    },
    mounted () {
        if (this.$refs.input) {
            requestAnimationFrame(() => {
                this.$refs.input.focus();
            });
        }

        this.setInputWidth();
    },
    methods: {
        setInputWidth () {
            if (this.showSelectedValueTags || this.isFullWidth) {
                return;
            }

            const { input, mirror } = this.$refs;

            if (input && mirror && this.isOpen) {
                const {
                    fontSize,
                    fontFamily,
                    fontWeight,
                    fontStyle,
                    letterSpacing,
                    textTransform,
                    minWidth,
                    maxWidth
                } = window.getComputedStyle(input);

                Object.assign(mirror.style, {
                    fontSize,
                    fontFamily,
                    fontWeight,
                    fontStyle,
                    letterSpacing,
                    textTransform,
                    minWidth,
                    maxWidth
                });

                const suffixSize = TRIGGER_SIZE_MAP[this.triggerSize] - 2;
                // when visible, 30px = 8px left of icon + 14px icon + 8px spacing right before input element
                const searchIconSize = this.isSearchIconVisible ? 30 : 0;

                const pixelsNeeded = mirror.getBoundingClientRect().width;
                const availablePixels = this.triggerWidth - suffixSize - searchIconSize;

                // either fill total available space or allow input to grow to 100%
                if (pixelsNeeded > availablePixels) {
                    this.inputWidth = availablePixels;
                } else {
                    this.inputWidth = pixelsNeeded;
                }
            }
        },
        beforeLeave (el) {
            el.style.display = 'none';
        }
    }
};
</script>

<style lang="scss">
.pendo-multiselect-search-input-slide-enter-active,
.pendo-multiselect-search-input-slide-leave-active {
    transition: transform 100ms;
}

.pendo-multiselect-search-input-slide-enter,
.pendo-multiselect-search-input-slide-leave-to {
    transform: translateX(-22px);
}

.pendo-multiselect-search-icon-fade-scale-enter-active,
.pendo-multiselect-search-icon-fade-scale-leave-active {
    transition: opacity 100ms 0ms, transform 100ms 50ms;
}

.pendo-multiselect-search-icon-fade-scale-enter,
.pendo-multiselect-search-icon-fade-scale-leave-to {
    opacity: 0;
    transform: scale(0.95);
}

@include block(pendo-multiselect) {
    @include element(control) {
        display: flex;
        gap: 8px;
        align-items: center;
        min-width: 0;
        padding: 0;
        margin: 0;
        cursor: default;
        flex-grow: 1;
    }

    @include element(input-wrapper) {
        display: flex;
        flex: 1 0 auto;

        input {
            flex: 1 0 auto;
            min-width: 100px;
            border: none;
            padding: 0px;
            margin: 0px;
            line-height: 24px;
        }
    }

    @include element(input) {
        opacity: 1;
        transform: translateX(0);
        position: relative;
        display: inline-block;
        border: none;
        border-radius: 0;
        background: $color-white;
        padding: 0;
        margin: 0;
        touch-action: manipulation;
        width: 100%;

        @include font-base;
        line-height: normal;

        &:focus {
            outline: none;
        }
    }

    @include element(input-mirror) {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        height: 0;
        white-space: pre;
    }

    @include element(search-icon) {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
