<template>
    <div v-pendo-loading="loaderConfig" />
</template>

<script>
import { PendoLoading } from '@/directives/loading/pendo-loading';

function getDefault (key) {
    return PendoLoading.options[key];
}

const SIZE_MAP = {
    small: 16,
    medium: 32,
    large: 48
};

export default {
    name: 'PendoLoadingIndicator',
    directives: {
        PendoLoading
    },
    props: {
        /**
         * size of the loading indicator
         * @values small, medium, large
         */
        // eslint-disable-next-line vue/no-unused-properties
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['small', 'medium', 'large'].includes(size)
        },
        /**
         * if using on a dark background, this inverts the color of the loading indicator
         */
        light: {
            type: Boolean,
            default: false
        },
        /**
         * toggles visibility of the loader
         */
        loading: {
            type: Boolean,
            default: true
        },
        /**
         * @ignore
         */
        // eslint-disable-next-line vue/no-unused-properties
        button: {
            type: Boolean,
            default: false
        },
        /**
         * type of spinner. `material` or `feather`.
         */
        type: {
            type: String,
            default: 'material'
        },
        /**
         * associated loading text to show alongside loader
         * @default --
         */
        text: {
            type: String,
            default: () => getDefault('text')
        },
        /**
         * applies a class to the loader text for easier style changes
         * @default pendo-loading-text
         */
        textClass: {
            type: String,
            default: () => getDefault('textClass')
        },
        /**
         * optional overlay [props](https://github.com/pendo-io/components/blob/master/src/directives/loading/pendo-loading.js#L9-L12)
         */
        overlayProps: {
            type: Object,
            default: () => ({})
        },
        /**
         * optional spinner [props](https://github.com/pendo-io/components/blob/master/src/directives/loading/pendo-loading.js#L13-L18)
         */
        spinnerProps: {
            type: Object,
            default: () => ({})
        },
        /**
         * optional skeleton [props](https://github.com/pendo-io/components/blob/master/src/directives/loading/pendo-loading.js#L19-L26)
         */
        skeletonProps: {
            type: Object,
            default: () => ({})
        },
        preventRelativeParent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        loaderConfig () {
            const {
                loading,
                light,
                type,
                text,
                textClass,
                preventRelativeParent,
                spinnerProps,
                skeletonProps,
                overlayProps
            } = this;

            // pre 1.x backwards compatibility. will be removed in next major release
            // use spinnerProps.size instead
            const { size } = this.$options.propsData;

            const conditionalSpinnerProps = {};
            if (light) {
                conditionalSpinnerProps.color = '#F8F8F9';
            }
            if (size && isNaN(size)) {
                conditionalSpinnerProps.size = SIZE_MAP[size];
            }

            return {
                loading,
                type,
                text,
                textClass,
                preventRelativeParent,
                overlayProps: {
                    ...getDefault('overlayProps'),
                    ...overlayProps,
                    position: 'inherit'
                },
                spinnerProps: {
                    ...getDefault('spinnerProps'),
                    background: 'transparent',
                    ...spinnerProps,
                    ...conditionalSpinnerProps
                },
                skeletonProps: {
                    ...getDefault('skeletonProps'),
                    ...skeletonProps
                }
            };
        }
    }
};
</script>
