<template>
    <div
        class="pendo-metric"
        :class="[`pendo-metric--${size}`, `pendo-metric--${alignment}`, { 'is-loading': loading }]">
        <div
            v-if="topLabel || $slots.topLabel || icon"
            class="pendo-metric__top-label">
            <div
                class="pendo-metric__icon-container"
                :class="{ 'is-circle': isCircleIcon }"
                :style="iconContainerStyles">
                <pendo-icon v-bind="iconConfig" />
            </div>
            <slot name="topLabel">
                {{ topLabel }}
            </slot>
        </div>
        <div class="pendo-metric__container">
            <div
                v-pendo-loading="{
                    loading,
                    skeletonProps: { height: loadingOverlayHeight }
                }"
                :style="{ color }"
                class="pendo-metric__value">
                <slot
                    name="value"
                    :formatted-value="formattedValue">
                    {{ formattedValue }}
                </slot>
                <div
                    v-if="trending"
                    class="pendo-metric__trending">
                    <slot
                        name="trending"
                        :trending="trending">
                        <pendo-icon
                            :type="`arrow-${trending}`"
                            :stroke="usageTrendingColor"
                            size="18" />
                        <div
                            class="pendo-metric__trending-label"
                            :style="{ color: usageTrendingColor }">
                            {{ trendingLabel }}
                        </div>
                    </slot>
                </div>
            </div>
        </div>
        <div
            v-if="label || $slots.label"
            class="pendo-metric__label">
            <slot name="label">
                {{ label }}
            </slot>
        </div>
        <div
            v-if="sublabel || $slots.sublabel"
            class="pendo-metric__sublabel">
            <slot name="sublabel">
                {{ sublabel }}
            </slot>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral';
import PendoLoading from '@/directives/loading/pendo-loading';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import { isValidHex, getContrastingColor } from '@/utils/color';

export default {
    name: 'PendoMetric',
    components: {
        PendoIcon
    },
    directives: {
        PendoLoading
    },
    props: {
        /**
         * Determines metric size
         * @values large, medium, small, mini
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['large', 'medium', 'small', 'mini'].includes(size)
        },
        /**
         * value to display
         */
        value: {
            type: [Number, String],
            default: null
        },
        /**
         * optionally leverage [numeral](http://numeraljs.com) to format passed in value
         */
        format: {
            type: String,
            default: null
        },
        /**
         * label text that appears underneath metric
         */
        label: {
            type: String,
            default: null
        },
        /**
         * Secondary label to display below value or primary label
         */
        sublabel: {
            type: String,
            default: null
        },
        /**
         * Top label to display above value
         */
        topLabel: {
            type: String,
            default: null
        },
        /**
         * toggles visibility of up or down arrow based on trending direction
         * @values up, down
         */
        trending: {
            type: String,
            default: null,
            validator: (trending) => ['up', 'down'].includes(trending)
        },
        /**
         * Label (optional) to display next to trending icon
         */
        trendingLabel: {
            type: String,
            default: null
        },
        /**
         * color of trending arrow
         * @values green, red
         */
        trendingColor: {
            type: String,
            default: null,
            validator: (trendingColor) => ['green', 'red'].includes(trendingColor) || isValidHex(trendingColor)
        },
        /**
         * color of primary text
         */
        color: {
            type: String,
            default: '#2a2c35'
        },
        /**
         * toggles skeleton loading state
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * Determines metric alignment.
         * @values center, left, inline
         */
        alignment: {
            type: String,
            default: 'center',
            validator: (alignment) => ['center', 'left', 'inline'].includes(alignment)
        },
        /**
         * icon type to display next to top label
         */
        icon: {
            type: String,
            default: null
        },
        /**
         * sets color of icon background. When icon is set to 'circle', this will be the fill color
         */
        iconBackgroundColor: {
            type: String,
            default: null,
            validator: (color) => isValidHex(color)
        }
    },
    computed: {
        formattedValue () {
            return this.format ? numeral(this.value).format(this.format) : this.value;
        },
        isCircleIcon () {
            return this.icon === 'circle';
        },
        iconConfig () {
            if (this.isCircleIcon) {
                return {
                    type: this.icon,
                    fill: this.iconBackgroundColor,
                    stroke: this.iconBackgroundColor,
                    size: 10
                };
            }

            return {
                type: this.icon,
                stroke: this.iconBackgroundColor ? getContrastingColor(this.iconBackgroundColor) : '#2A2C35',
                strokeWidth: 2.5,
                size: 12
            };
        },
        iconContainerStyles () {
            if (!this.isCircleIcon && this.iconBackgroundColor) {
                return { backgroundColor: this.iconBackgroundColor };
            }

            return {};
        },
        usageTrendingColor () {
            const arrowColorMap = {
                red: '#CC3340',
                green: '#00C583'
            };

            if (this.trendingColor) {
                if (isValidHex(this.trendingColor)) {
                    return this.trendingColor;
                }

                return arrowColorMap[this.trendingColor];
            }

            if (this.trending === 'up') {
                return arrowColorMap.green;
            }

            return arrowColorMap.red;
        },
        loadingOverlayHeight () {
            switch (this.size) {
                case 'large':
                    return 32;
                case 'medium':
                    return 28;
                case 'small':
                    return 24;
                default:
                    return 20;
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-metric) {
    display: grid;
    align-items: center;
    justify-items: center;
    width: fit-content;
    @include font-family;

    @include element(container) {
        display: flex;
        align-items: center;
    }

    @include element(icon-container) {
        margin-right: 8px;
    }

    &__icon-container:not(.is-circle) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin-right: 4px;
    }

    @include element(value) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 26px;
        min-height: 36px;
        gap: 4px;
    }

    @include is(loading) {
        @include element(value) {
            min-width: 60px;
        }
    }

    @include element((label, sublabel, trending-label, top-label)) {
        font-size: 14px;
        line-height: 22px;
    }

    @include element((value, label, top-label)) {
        color: $color-text-primary;
    }

    @include element(sublabel) {
        color: $color-text-secondary;
    }

    @include element(trending-label) {
        font-weight: 400;
        margin-left: 4px;
    }

    @include element(top-label) {
        display: flex;
        align-items: center;
        font-weight: 600;
    }

    @include element(trending) {
        display: flex;
        align-items: center;
    }

    @include modifier(large) {
        @include element(value) {
            font-size: 32px;
            min-height: 40px;
        }

        @include is(loading) {
            @include element(value) {
                min-width: 68px;
            }
        }
    }

    @include modifier(small) {
        @include element(value) {
            font-size: 22px;
            min-height: 30px;
        }

        @include element((label, sublabel, trending-label, top-label)) {
            line-height: 20px;
        }

        @include is(loading) {
            @include element(value) {
                min-width: 48px;
            }
        }
    }

    @include modifier(mini) {
        @include element(value) {
            font-size: 16px;
            min-height: 24px;
        }

        @include element((label, sublabel, trending-label, top-label)) {
            font-size: 12px;
            line-height: 18px;
        }

        @include is(loading) {
            @include element(value) {
                min-width: 36px;
            }
        }
    }

    @include modifier(inline) {
        display: inline-flex;

        @include element((label, sublabel, value)) {
            margin-left: 4px;
        }
    }

    @include modifier(left) {
        justify-items: start;

        @include element(value) {
            justify-content: start;
        }
    }

    .pendo-loading {
        align-items: center;
    }
}
</style>
