<template>
    <div
        class="pendo-radio-group"
        :class="{ 'pendo-radio-group--inline-label': !!inlineLabel }">
        <div
            v-if="!!topLabel"
            :id="`pendo-radio-group__label--top-${_uid}`"
            class="pendo-radio-group__label pendo-radio-group__label--top">
            {{ topLabel }}
        </div>
        <div
            :id="!!$formItem && $formItem.labelFor"
            role="radiogroup"
            :aria-labelledby="ariaLabelledBy"
            :aria-describedby="!!$formItem && $formItem.ariaDescribedBy"
            :aria-invalid="!!$formItem && !!$formItem.ariaDescribedBy"
            :aria-required="!!$formItem && $formItem.isRequired"
            :class="radioGroupClass">
            <slot />
        </div>
        <div
            v-if="!!bottomLabel"
            :id="`pendo-radio-group__label--bottom-${_uid}`"
            class="pendo-radio-group__label pendo-radio-group__label--bottom">
            {{ bottomLabel }}
        </div>
    </div>
</template>

<script>
import labelsMixin from '@/mixins/labels';

export default {
    name: 'PendoRadioGroup',
    mixins: [labelsMixin],
    inject: {
        $formItem: {
            default: ''
        }
    },
    provide () {
        return { $radioGroup: this };
    },
    props: {
        /**
         * bound value
         */
        value: {
            type: [String, Number, Boolean],
            default: null
        },
        /**
         * when true, leverages `pendo-radio-button` instead of `pendo-radio`
         */
        button: {
            type: Boolean,
            default: false
        },
        /**
         * direction of group
         * @values vertical, horizontal
         */
        direction: {
            type: String,
            default: 'vertical',
            validator: (direction) => ['vertical', 'horizontal'].includes(direction)
        },
        /**
         * size of radio, only works when using radio buttons
         * @values medium, small, mini
         */
        size: {
            type: String,
            default: null,
            validator: (size) => ['mini', 'small', 'medium'].includes(size)
        },
        /**
         * disables user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * same as native `name` attribute, used to contain keyboard accessibility of child radio buttons
         */
        name: {
            type: String,
            default: undefined
        }
    },
    computed: {
        ariaLabelledBy () {
            if (this.$formItem) {
                return this.$formItem.labelId;
            }
            if (this.topLabel) {
                return `pendo-radio-group__label--top-${this._uid}`;
            }
            if (this.bottomLabel) {
                return `pendo-radio-group__label--bottom-${this._uid}`;
            }

            return undefined;
        },
        groupName () {
            return this.name || `pendo-radio-group-${this._uid}`;
        },
        radioGroupClass () {
            return [
                'pendo-radio-group',
                {
                    'is-button-group': this.button,
                    'is-vertical': this.direction === 'vertical' && !this.button,
                    'is-horizontal': this.direction === 'horizontal' || this.button
                }
            ];
        }
    },
    watch: {
        value (value) {
            if (this.$formItem) {
                this.$formItem.onFieldChange(value);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-radio-group) {
    display: grid;

    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-text-primary;

        &--top {
            font-weight: 600;
            align-items: start;
        }
        &--bottom {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    @include modifier(inline-label) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        grid-gap: 8px;

        .pendo-radio-group__label {
            display: grid;
            align-items: center;
        }
    }

    @include is(horizontal) {
        grid-auto-flow: column;
        justify-content: start;

        .pendo-radio:not(.pendo-radio-button) {
            & + .pendo-radio:not(.pendo-radio-button) {
                margin-left: 16px;
            }
        }
    }

    @include is(vertical) {
        .pendo-radio:not(.pendo-radio-button) {
            & + .pendo-radio:not(.pendo-radio-button) {
                margin-top: 4px;
                margin-left: 0px;
            }
        }
    }

    @include is(button-group) {
        grid-auto-columns: 1fr;
    }
}
</style>
