<template>
    <div
        class="pendo-media-card"
        :class="{
            'pendo-media-card--tags': tags.length,
            'pendo-media-card--interactive': isInteractive,
            'is-loading': loading,
            'is-disabled': disabled,
            'is-showing-overlay': showOverlay,
            [`is-${shadowType}-shadow`]: shadowType
        }"
        :tabindex="isInteractive ? 0 : -1"
        @mouseenter="onMouseenter"
        @mouseleave="onMouseleave">
        <div
            v-pendo-loading:skeleton="{ loading, skeletonProps: { height: 148 } }"
            class="pendo-media-card__header">
            <div
                v-if="!loading && !disabled"
                class="pendo-media-card__overlay" />
            <div class="pendo-media-card__content">
                <!-- @slot use to pass content to card header @ignore image -->
                <slot
                    v-if="!loading"
                    name="media" />
            </div>
            <div
                v-if="!loading && !disabled"
                class="pendo-media-card__overlay-actions">
                <!-- @slot content to display when user hovers on card and overlay is visible -->
                <slot name="overlay" />
            </div>
        </div>
        <slot name="body">
            <div class="pendo-media-card__body">
                <div
                    ref="title"
                    v-pendo-loading:skeleton="{ loading, skeletonProps: { height: 23 } }"
                    v-pendo-tooltip="{ content: titleTooltipContent, delay: { show: 300, hide: 0 } }"
                    class="pendo-media-card__title">
                    <div v-if="!loading && (title || $slots.title)">
                        <!-- @slot title slot alternative to passing title as prop -->
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </div>
                </div>
                <div
                    ref="subtitle"
                    v-pendo-loading:skeleton="{ loading, skeletonProps: { height: 18 } }"
                    v-pendo-tooltip="{ content: subtitleTooltipContent, delay: { show: 300, hide: 0 } }"
                    class="pendo-media-card__subtitle">
                    <div v-if="!loading && (subtitle || $slots.subtitle)">
                        <!-- @slot subtitle alternative to passing subtitle as prop -->
                        <slot name="subtitle">
                            {{ subtitle }}
                        </slot>
                    </div>
                </div>
                <template v-if="tags && tags.length">
                    <div class="pendo-media-card__tags-wrapper">
                        <pendo-tag
                            v-for="tag in tags"
                            :key="tag.label"
                            :type="tag.type"
                            :label="tag.label"
                            :size="tag.size"
                            :color="tag.color" />
                    </div>
                </template>
            </div>
        </slot>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import PendoTag from '@/components/tag/pendo-tag.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import { PendoLoading } from '@/directives/loading/pendo-loading';
import { PendoAnalytics } from '@/directives/analytics/pendo-analytics';
import { lineClamp } from '@/utils/line-clamp';
import { hasSlot } from '@/utils/utils';

export default {
    name: 'PendoMediaCard',
    components: {
        PendoTag
    },
    directives: {
        PendoAnalytics,
        PendoTooltip,
        PendoLoading
    },
    props: {
        /**
         * Title text of card
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * Secondary text directly below title
         */
        subtitle: {
            type: String,
            default: ''
        },
        /**
         * Array of tags to place in footer
         */
        tags: {
            type: Array,
            default: () => []
        },
        /**
         * When to show card shadows
         * @values always, hover, never
         */
        shadow: {
            type: String,
            default: 'never',
            validator: (shadow) => ['always', 'hover', 'never'].includes(shadow)
        },
        /**
         * Disables card interactions
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Display skeleton loading animation
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * Custom options for logic related to showing/hiding of overlay actions slot
         */
        overlayProps: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            titleTooltipContent: null,
            subtitleTooltipContent: null,
            isHovering: false
        };
    },
    computed: {
        showOverlay () {
            if (this.disabled) {
                return false;
            }
            // overlays can be triggered by hover (default)
            // or by specifying 'manual' as the trigger. if using manual,
            // consumer is reponsible for showing and hiding through the
            // overlayProps.show boolean prop
            const { trigger, show } = { trigger: 'hover', show: false, ...this.overlayProps };

            if (trigger === 'hover' && this.isHovering) {
                return true;
            }

            if (trigger === 'hover' && !this.isHovering) {
                return !!show;
            }

            return trigger === 'manual' && !!show;
        },
        shadowType () {
            return this.disabled ? 'never' : this.shadow;
        },
        isInteractive () {
            return !this.disabled && hasSlot(this, 'overlay');
        }
    },
    watch: {
        title () {
            this.checkTitleOverflow();
        },
        async loading () {
            if (!this.loading) {
                await this.$nextTick();

                this.checkTitleOverflow();
                this.checkSubtitleOverflow();
            }
        }
    },
    async mounted () {
        await this.$nextTick();

        if (!this.loading) {
            this.checkTitleOverflow();
            this.checkSubtitleOverflow();
        }
    },
    methods: {
        onMouseenter (event) {
            this.isHovering = true;
            /**
             * Emitted on mouseenter of card
             *
             * @event mouseenter
             * @property {MouseEvent} event
             */
            this.$emit('mouseenter', event);
        },
        onMouseleave (event) {
            this.isHovering = false;
            /**
             * Emitted on mouseleave of card
             *
             * @event mouseleave
             * @property {MouseEvent} event
             */
            this.$emit('mouseleave', event);
        },
        async checkTitleOverflow () {
            const hasCustomTitleSlotContent = hasSlot(this, 'title');
            if (hasCustomTitleSlotContent) {
                return;
            }

            const { title } = this.$refs;
            if (title) {
                const isClamped = await lineClamp({
                    element: this.$refs.title,
                    lineCount: 2
                });
                // tooltip hides itself when null content is passed. this is more reliable than an inline ternary check
                this.titleTooltipContent = isClamped ? this.title : null;
            }
        },
        async checkSubtitleOverflow () {
            const hasCustomSubtitleSlotContent = hasSlot(this, 'subtitle');

            if (hasCustomSubtitleSlotContent) {
                return;
            }

            const { subtitle } = this.$refs;
            if (subtitle && subtitle.offsetWidth < subtitle.scrollWidth) {
                // tooltip hides itself when null content is passed. this is more reliable than an inline ternary check
                this.subtitleTooltipContent = this.subtitle;
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-media-card) {
    @include font-base;
    border-radius: $card-border-radius;
    border: $card-border;
    background-color: $card-background-color;
    color: $color-text-primary;
    width: $card-media-width;
    min-height: $card-media-height;

    @include element(header) {
        box-sizing: border-box;
        border-bottom: $card-border;
        height: $card-media-header-height;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px 2px 0 0;
    }

    @include element(body) {
        padding: 16px;
        display: grid;
        justify-content: start;
        grid-gap: 8px;
        grid-template-columns: 1fr;
        grid-template-rows: minmax(23px, auto) 18px;
    }

    @include element(title) {
        line-height: $card-media-title-line-height;
        font-size: $card-media-title-size;
        cursor: default;
        position: relative;

        &.pendo-loading-parent--relative > div {
            position: absolute;
        }
    }

    @include is(always-shadow) {
        border: 0;
        box-shadow: $card-always-shadow;
    }

    @include is(hover-shadow) {
        transition: $card-hover-transition;

        &:hover {
            box-shadow: $card-hover-shadow;
            transform: $card-hover-transform;
        }
    }

    @include is(disabled) {
        cursor: not-allowed;
        opacity: $disabled-opacity;
    }

    @include is(loading) {
        opacity: 1;
    }

    @include element(subtitle) {
        color: $card-media-subtitle-color;
        font-size: $card-media-subtitle-size;
        line-height: $card-media-subtitle-size;
        letter-spacing: 1.5px;
        text-decoration: none;
        text-transform: uppercase;
        cursor: default;
        position: relative;
        @include ellipsis;

        &.pendo-loading-parent--relative > div {
            position: absolute;
        }
    }

    @include element(content) {
        height: auto;
        width: 100%;
    }

    @include element(overlay) {
        background: rgba($color-gray-100, 0.8);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        will-change: opacity;
        transition: opacity 200ms ease-in-out 0s;
    }

    @include element(overlay-actions) {
        position: absolute;
        opacity: 0;
        will-change: opacity;
        transition: opacity 200ms ease-in-out 0s;
    }

    @include is(showing-overlay) {
        @include element((overlay, overlay-actions)) {
            opacity: 1;
            z-index: 1;
        }
    }

    @include modifier(interactive) {
        @include focus-ring($style: 'base');

        &:focus-visible {
            @include focus-ring($style: 'focused');
            @include element((overlay, overlay-actions)) {
                opacity: 1;
                z-index: 1;
            }
            box-shadow: $card-hover-shadow;
            transform: $card-hover-transform;
        }
    }

    @include element(tags-wrapper) {
        border-top: 1px solid $color-gray-30;
        padding-top: 16px;
    }

    @include modifier(tags) {
        @include element(body) {
            grid-template-rows: auto 18px auto;
        }
    }
}
</style>
