<template>
    <div class="pendo-filter-bar">
        <div class="pendo-filter-bar__container">
            <slot />
        </div>
        <div
            v-if="hasBottom"
            class="pendo-filter-bar__bottom">
            <slot name="bottom" />
        </div>
    </div>
</template>

<script>
import { hasSlot } from '@/utils/utils';

export default {
    name: 'PendoFilterBar',
    computed: {
        hasBottom () {
            return hasSlot(this, 'bottom');
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-filter-bar) {
    box-sizing: border-box;
    min-height: 68px;
    background: $color-white;
    border: 1px solid $color-gray-40;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.19);
    border-radius: 3px;
    z-index: 2;
    padding: 0 16px;

    @include element(container) {
        display: flex;
        flex-grow: 1;
        align-items: center;
        min-height: 66px;
    }

    @include element(bottom) {
        border-top: 1px solid $color-gray-40;
        min-height: 48px;
    }

    @include is(sticky) {
        box-shadow: $box-shadow-light;
        border-radius: 0;
        border: 0;
    }
}
</style>
