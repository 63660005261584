<template>
    <portal
        :disabled="!mount"
        :append-to="appendToTarget">
        <transition
            name="pendo-modal-transition"
            @before-enter="beforeOpen"
            @enter="open"
            @after-enter="afterOpen"
            @before-leave="beforeClose"
            @leave="close"
            @after-leave="afterClose">
            <div
                v-show="show"
                :id="id"
                class="pendo-modal"
                :class="[
                    inheritedClasses,
                    {
                        'is-confirmation': type === 'confirmation',
                        'is-closeable': showCloseButton,
                        'is-open': show
                    }
                ]"
                :style="{
                    'z-index': zIndex,
                    'cursor': clickToClose ? 'pointer' : 'default'
                }"
                role="dialog"
                :aria-label="title"
                :aria-modal="true"
                :aria-labelledby="`${id}-title`"
                :aria-describedby="`${id}-body`"
                v-bind="inheritedAttrs"
                tabindex="-1"
                @click="onClickOutside($event)"
                @keydown.esc="onEscapeKeydown">
                <div
                    v-if="showBackdrop"
                    class="pendo-modal__backdrop" />
                <div class="pendo-modal__container">
                    <focus-trap
                        v-model="isFocusTrapActive"
                        v-bind="focusTrapConfig">
                        <div
                            ref="modal"
                            tabindex="-1"
                            class="pendo-modal__content"
                            :style="{ cursor: 'default' }">
                            <div class="pendo-modal__header">
                                <!-- @slot alternative to passing `title` prop -->
                                <slot :name="$slots.header ? 'header' : 'title'">
                                    <h2
                                        :id="`${id}-title`"
                                        class="pendo-modal__title">
                                        {{ title }}
                                    </h2>
                                </slot>
                                <div
                                    v-if="showCloseButton"
                                    class="pendo-modal__close">
                                    <button
                                        class="pendo-modal__close-button"
                                        aria-label="Close"
                                        @click.prevent="close">
                                        <pendo-icon
                                            type="x"
                                            role="img"
                                            :size="20"
                                            aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <div
                                v-if="mount"
                                :id="`${id}-body`"
                                class="pendo-modal__body">
                                <!-- @slot alternative to passing `message` prop -->
                                <slot :name="$slots.body ? 'body' : 'default'">
                                    {{ message }}
                                </slot>
                            </div>
                            <div
                                v-if="hasFooter"
                                class="pendo-modal__footer">
                                <!-- @slot alternative to passing button configs using props -->
                                <slot name="footer">
                                    <div
                                        v-if="actionButtons"
                                        class="pendo-modal__button-wrapper">
                                        <template v-if="actionButtons.length">
                                            <pendo-button
                                                v-for="(button, index) in actionButtons"
                                                :key="`pendo-modal-button-${index}`"
                                                v-bind="button"
                                                @click.stop="handleActionButtonClick(index, $event)" />
                                        </template>
                                        <pendo-button
                                            v-if="!actionButtons.length"
                                            v-bind="cancelButtonAttrs"
                                            @click.stop="$emit('cancel')"
                                            @keydown.enter.stop="$emit('cancel')" />
                                        <pendo-button
                                            v-if="!actionButtons.length"
                                            ref="confirm"
                                            v-bind="confirmButtonAttrs"
                                            @click.stop="$emit('confirm')"
                                            @keydown.enter.stop="$emit('confirm')" />
                                    </div>
                                </slot>
                            </div>
                        </div>
                    </focus-trap>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import Portal from '@/components/portal/portal';
import FocusTrap from '@/utils/focus-trap';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoButton from '@/components/button/pendo-button';
import { getTopZindex } from '@/utils/dom';
import { hasSlot } from '@/utils/utils';
import { disableBodyScroll, enableBodyScroll } from '@/utils/body-scroll-lock';

import {
    parseSizeType,
    getModalTopLeftPosition,
    clampValueToWindowOrMaxWidth,
    clampValueToWindowOrMaxHeight,
    getWidth,
    getHeight,
    getBoundAttributes
} from '@/components/modal/utils';
import { setStyles } from '@/utils/dom';

let animatingZIndex = 0;
const baseZindex = 2001;

export default {
    name: 'PendoModal',
    components: {
        Portal,
        PendoIcon,
        PendoButton,
        FocusTrap
    },
    model: {
        prop: 'visible',
        event: 'close'
    },
    props: {
        /**
         * toggle visibility of the modal
         */
        visible: {
            type: Boolean,
            default: false
        },
        /**
         * convinience prop for passing an array of `pendo-button` configs that appear in the footer
         */
        actionButtons: {
            type: [Array, Boolean],
            default: () => []
        },
        /**
         * applies confirmation modal styles Emits `@confirm` and `@cancel` events as well as leverages `confirmButtonConfig` and `cancelButtonConfig` props
         * @values confirmation
         */
        type: {
            type: String,
            default: null,
            validator: (type) => ['confirmation'].includes(type)
        },
        /**
         * main modal text that appears in the header
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * secondary modal text. apperas in the body slot if no body slot is passed.
         */
        message: {
            type: String,
            default: null
        },
        /**
         * when `type` is `confirmation`, leverage this prop to override default **confirm** button properties. Props are merged with default config.
         */
        confirmButtonConfig: {
            type: Object,
            default: () => ({})
        },
        /**
         * when `type` is `confirmation`, leverage this prop to override default **cancel** button properties. Props are merged with default config.
         */
        cancelButtonConfig: {
            type: Object,
            default: () => ({})
        },
        /**
         * whether the modal has a darker backdrop
         */
        showBackdrop: {
            type: Boolean,
            default: true
        },
        /**
         * whether to append modal itself to body. A nested modal should have this attribute set to `true`
         */
        appendToBody: {
            type: Boolean,
            default: true
        },
        /**
         * whether to show the close button
         */
        showClose: {
            type: Boolean,
            default: true
        },
        /**
         * whether the Modal can be closed by clicking the backdrop
         */
        clickToClose: {
            type: Boolean,
            default: false
        },
        /**
         * whether the modal can be closed by pressing ESC
         */
        escToClose: {
            type: Boolean,
            default: false
        },
        /**
         * The minimum width to which modal can be resized
         */
        minWidth: {
            type: Number,
            default: 200
        },
        /**
         * The minimum height to which modal can be resized
         */
        minHeight: {
            type: Number,
            default: 0
        },
        /**
         * The maximum width of the modal (if the value is greater than window width, window width will be used instead
         */
        maxWidth: {
            type: [Number, String],
            default: Infinity
        },
        /**
         * The maximum height of the modal (if the value is greater than window height, window height will be used instead
         */
        maxHeight: {
            type: [Number, String],
            default: Infinity
        },
        /**
         * Width in pixels or percents (e.g. 50 or "50px", "50%")
         */
        width: {
            type: [Number, String],
            default: 400
        },
        /**
         * Height in pixels or percents (e.g. 50 or "50px", "50%") or `auto`
         */
        height: {
            type: [Number, String],
            default: 400
        },
        /**
         * Horizontal position in `%`, default is `0.5` (meaning that modal box will be in the middle (50% from left) of the window
         */
        pivotX: {
            type: Number,
            default: 0.5
        },
        /**
         * Vertical position in `%`, default is `0.5` (meaning that modal box will be in the middle (50% from top) of the window
         */
        pivotY: {
            type: Number,
            default: 0.5
        },
        /**
         * Focus trapping config options for [focus-trap](https://github.com/focus-trap/focus-trap#usage)
         */
        focusTrapConfig: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            zIndex: 0,
            show: false,
            mount: false,
            id: `pendo-modal-${this._uid}`,
            appendToTarget: 'body',
            isFocusTrapActive: false
        };
    },
    computed: {
        // when disabled, the portal component will maintain any classes and data attributes supplied by the consuming app
        // however when the portal is active, it drops those classes (and styles)
        // pendo-drawer makes use of a className prop however for backward compatibility with el-dialog behavior
        // we need to ensure those classes are still applied to the portal'd content
        inheritedClasses () {
            if (this.mount) {
                return [this.$vnode.data.staticClass, this.$vnode.data.class];
            }

            return '';
        },
        inheritedAttrs () {
            const scopedStyleAttr = this.$parent.$options._scopeId;
            const attrs = getBoundAttributes(this.$vnode);

            if (scopedStyleAttr) {
                attrs[scopedStyleAttr] = '';
            }

            return attrs;
        },
        showCloseButton () {
            // only show the close button for confirmation type modals if the user supplies :show-close="true"
            // this prevents the default `true` value for showClose from automatically being applied
            if (this.type === 'confirmation') {
                return this.$options.propsData.showClose || false;
            }

            return this.showClose;
        },
        confirmButtonAttrs () {
            const confirmButtonDefaults = {
                theme: 'app',
                type: 'primary',
                label: 'Confirm'
            };

            return { ...confirmButtonDefaults, ...this.confirmButtonConfig };
        },
        cancelButtonAttrs () {
            const cancelButtonDefaults = {
                theme: 'app',
                type: 'secondary',
                label: 'Cancel'
            };

            return { ...cancelButtonDefaults, ...this.cancelButtonConfig };
        },
        hasFooter () {
            const hasFooterSlot = hasSlot(this, 'footer');
            const hasActionButtons = Boolean(this.actionButtons);

            return hasFooterSlot || hasActionButtons;
        },
        isAutoHeight () {
            // for confirmation modals, use auto height unless the consumer explicitly provides a height value
            if (this.type === 'confirmation' && !this.$options.propsData.height) {
                return true;
            }

            return parseSizeType(this.height).type === 'auto';
        }
    },
    watch: {
        visible: {
            handler () {
                if (this.visible) {
                    this.setAppendToTarget();
                    this.mount = true;
                    this.$nextTick(() => {
                        this.show = true;
                    });
                } else if (this.show) {
                    this.show = false;
                }
            },
            immediate: true
        }
    },
    mounted () {
        this.resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                this.setModalStyle();
            });
        });
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.setModalStyle);
        enableBodyScroll(this.id);
        this.resizeObserver.disconnect();
    },
    methods: {
        setAppendToTarget () {
            const shouldAttachToParentNode = !this.appendToBody && this.$el && this.$el.parentNode;
            this.appendToTarget = shouldAttachToParentNode ? this.$el.parentNode : 'body';
        },
        setModalStyle () {
            if (this.visible && this.$refs.modal) {
                const maxHeight = getHeight(this.maxHeight);
                const maxWidth = getWidth(this.maxWidth);
                const trueModalWidth = clampValueToWindowOrMaxWidth(this.width, this.minWidth, this.maxWidth);
                const trueModalHeight = this.isAutoHeight
                    ? this.$refs.modal.clientHeight
                    : clampValueToWindowOrMaxHeight(this.height, this.minHeight, this.maxHeight);
                const modalPosition = getModalTopLeftPosition({
                    pivotY: this.pivotY,
                    pivotX: this.pivotX,
                    trueModalWidth,
                    trueModalHeight
                });

                const styles = {
                    top: `${modalPosition.top}px`,
                    left: `${modalPosition.left}px`,
                    width: `${trueModalWidth}px`,
                    height: this.isAutoHeight ? 'auto' : `${trueModalHeight}px`
                };

                if (maxHeight !== 0) {
                    styles.maxHeight = maxHeight;
                }

                if (maxWidth !== 0) {
                    styles.maxWidth = maxWidth;
                }

                if (this.$refs.modal) {
                    setStyles(this.$refs.modal, styles);
                }
            }
        },
        removeWindowResizeListeners () {
            window.removeEventListener('resize', this.setModalStyle);
        },
        initWindowResizeListeners () {
            window.addEventListener('resize', this.setModalStyle);
        },
        onClickOutside (event) {
            if (this.clickToClose && !this.$refs.modal.contains(event.target)) {
                this.close();
            }
        },
        onEscapeKeydown () {
            if (this.escToClose) {
                this.close();
            }
        },
        beforeOpen () {
            const lastZindex = getTopZindex();

            if (animatingZIndex) {
                this.zIndex = animatingZIndex + 2;
            } else {
                this.zIndex = lastZindex === 0 ? baseZindex : lastZindex + 2;
            }
            animatingZIndex = this.zIndex;
        },
        open () {
            disableBodyScroll(this.id);
            this.initWindowResizeListeners();

            /**
             * Emitted when modal is added to DOM and begins enter transition
             * @event open
             */
            this.$emit('open');
            /**
             * @ignore
             * @deprecated the .sync modifier is not suppored in vue 3
             * @since 10.0.0
             */
            this.$emit('update:visible', true);

            this.$nextTick(() => {
                this.resizeObserver.observe(this.$refs.modal);
            });
        },
        afterOpen () {
            this.isFocusTrapActive = true;

            /**
             * Emits after modal enter transition has completed
             * @event opened
             */
            this.$emit('opened');
        },
        beforeClose () {
            this.removeWindowResizeListeners();
            this.resizeObserver.unobserve(this.$refs.modal);
        },
        close () {
            /**
             * @ignore
             * @deprecated the .sync modifier is not suppored in vue 3
             * @since 10.0.0
             */
            this.$emit('update:visible', false);
            if (this.show) {
                /**
                 * Emitted when modal begins to close
                 * @event close
                 */
                this.$emit('close');
            }
        },
        async afterClose () {
            this.zIndex = 0;
            this.mount = false;
            await this.$nextTick();

            requestAnimationFrame(() => {
                enableBodyScroll(this.id);
                this.isFocusTrapActive = false;
                animatingZIndex = 0;
                /**
                 * Emitted once modal has closed and leave transition has completed
                 * @event closed
                 */
                this.$emit('closed');
            });
        },
        handleActionButtonClick (index, event, source = 'click') {
            const button = this.actionButtons[index];
            if (button && typeof button.handler === 'function') {
                button.handler(index, event, { source });
            } else {
                this.close();
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-modal) {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    @include element(backdrop) {
        background-color: rgba($color-gray-100, 0.75);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        will-change: opacity;
        transition: opacity $modal-transition-duration ease;
    }

    @include element(container) {
        position: fixed;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        overflow: auto;
        will-change: opacity, transform;
        transition: opacity $modal-transition-duration ease, transform $modal-transition-duration ease;
    }

    @include element(content) {
        padding: 0;
        text-align: left;
        box-shadow: $modal-box-shadow;
        border-radius: $modal-border-radius;
        background: $modal-background-color;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        display: grid;
        grid-template-rows: minmax($modal-header-min-height, max-content) 1fr minmax(0, max-content);
        @include font-base;
    }

    @include element(header) {
        display: grid;
        border-bottom: $modal-border;
        grid-template-columns: 1fr $modal-footer-min-height;
    }

    @include element(title) {
        line-height: 28px;
        font-size: $modal-header-font-size;
        font-weight: $modal-header-font-weight;
        color: $modal-header-font-color;
        padding: $modal-header-padding;
        margin: 0;
        display: grid;
        align-items: center;
    }

    @include element(close) {
        display: grid;
        align-items: center;
        justify-content: center;
        max-height: $modal-header-min-height;

        button {
            @include button-reset;
            @include focus-ring($style: 'base');
            border-radius: 3px;

            .pendo-icon__x {
                color: $modal-close-icon-color;
                transition: all 200ms;
                cursor: pointer;
            }
            &:hover,
            &:focus,
            &:focus-visible {
                .pendo-icon__x {
                    color: $modal-close-icon-hover-color;
                }
            }

            &:focus-visible {
                @include focus-ring($style: 'focused');
            }
        }
    }

    @include element(body) {
        color: $modal-body-font-color;
        font-size: $modal-body-font-size;
        text-align: left;
        padding: $modal-body-padding;
        line-height: $modal-line-height;
        overflow-y: auto;
    }

    @include element(footer) {
        min-height: $modal-footer-min-height;
        padding: $modal-footer-padding;
        text-align: right;
        box-sizing: border-box;
        border-top: $modal-border;
    }

    @include element(button-wrapper) {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
        justify-content: end;

        .pendo-button:not(.pendo-button--tertiary) + .pendo-button {
            margin: 0;
        }
    }

    @include is(closeable) {
        @include element(header) {
            grid-template-columns: 1fr $modal-footer-min-height;
        }
    }

    @include is(confirmation) {
        grid-template-rows: auto 1fr $modal-footer-min-height;

        @include element(header) {
            grid-template-rows: $modal-header-min-height auto;
            border-bottom: none;
        }

        @include element(title) {
            grid-row-start: 1;
            grid-row-end: -1;
            font-weight: $modal-confirmation-header-font-weight;
        }

        @include element(body) {
            padding: 0px 20px 32px;
        }
    }
}

.pendo-modal-transition-enter-active {
    transition: visibility 0s linear 0s, opacity $modal-transition-duration ease;
}
.pendo-modal-transition-leave-active {
    transition: visibility 0s linear $modal-transition-duration, opacity $modal-transition-duration ease;
}

.pendo-modal-transition-enter,
.pendo-modal-transition-leave-to {
    visibility: hidden;

    .pendo-modal__container,
    .pendo-modal__backdrop {
        opacity: 0;
    }

    .pendo-modal__container {
        transform: scale(0.97);
    }
}
</style>
