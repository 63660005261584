<template>
    <div class="pendo-guide-elements">
        <pendo-table
            class="pendo-guide-elements-table"
            :data="elementsActivity"
            :columns="elementsTableColumns"
            :filters="filters"
            :height="height"
            row-key="uiElementId"
            :csv-config="csvDownloadConfig"
            csv-download
            :row-class-name="getRowClassName"
            :cell-class-name="getCellClassName"
            :default-sort="{ prop: 'uiElementName', order: 'ascending' }"
            :group-config="groupConfig"
            :borderless="borderless"
            @link-click="onMetricClick">
            <template
                #headerLeft
                v-if="showHeader">
                Guide Elements
            </template>
            <template #groupRow="{ row }">
                <div
                    :class="
                        isAppRow({ row })
                            ? 'pendo-guide-elements-table__group-by-app-header'
                            : 'pendo-guide-elements-table__group-by-step-header'
                    ">
                    <div
                        v-pendo-tooltip="{
                            content: overflowTooltipContent,
                            delay: { show: 300, hide: 0 },
                            arrow: true,
                            multiline: true,
                            boundariesElement: 'body'
                        }"
                        :class="
                            isAppRow({ row })
                                ? 'pendo-guide-elements-table__group-by-app-header__group-header__app-name'
                                : 'pendo-guide-elements-table__group-by-step-header__group-header__step-name'
                        "
                        @mouseenter="checkForOverflowingContent($event, row)"
                        @focusin="checkForOverflowingContent($event, row)">
                        <pendo-app-display
                            v-if="isAppRow({ row })"
                            :apps="getAppById({ appId: row.appId })" />
                        <span v-if="isStepRow({ row })">{{ getGroupByStepAndAppHeaderText(row) }}</span>
                    </div>
                </div>
            </template>
            <template #status>
                <pendo-status-overlay
                    :state="elementsActivityAggStatus"
                    :options="loadingOptions" />
            </template>
            <template
                #headerActions
                v-if="showHeader">
                <div class="pendo-guide-elements-table__header-actions">
                    <pendo-checkbox
                        :value="showDeleted"
                        label="Show Deleted Elements"
                        @change="$emit('toggle-show-deleted', $event)" />
                    <pendo-checkbox
                        :value="grouped"
                        :label="groupedLabel"
                        @change="$emit('toggle-grouped', $event)" />
                </div>
            </template>
            <template #uiElementText="{ row: { uiElementText, isDeleted } }">
                <div class="pendo-guide-elements-table__element-text">
                    {{ uiElementText }}
                    <span
                        v-if="isDeleted"
                        class="pendo-guide-elements-table__element-text__deleted-label">
                        Deleted
                    </span>
                </div>
            </template>
            <template #guideStepName="{ row: { guideStepName } }">
                <div
                    class="pendo-guide-elements-table__step-name"
                    :class="{
                        'is-unnamed': guideStepName.includes('Unnamed'),
                        'is-deleted': guideStepName.includes('Deleted')
                    }">
                    {{ guideStepName }}
                </div>
            </template>
            <template #application="{ row }">
                <pendo-app-display :apps="getAppById({ appId: row.appId })" />
            </template>
            <template #uiElementType="{ row: { uiElementType, uiElementTypeIcon } }">
                <pendo-guide-element-type-action :ui-elements="[{ name: uiElementType, icon: uiElementTypeIcon }]" />
            </template>
            <template #uiElementActions="{ row: { uiElementActions } }">
                <pendo-guide-element-type-action :ui-elements="getElementActions(uiElementActions)" />
            </template>
        </pendo-table>
    </div>
</template>

<script>
import get from 'lodash/get';
import PendoAppDisplay from '@/composites/app-display/pendo-app-display';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoStatusOverlay from '@/composites/status-overlay/pendo-status-overlay.vue';
import PendoGuideElementTypeAction from '@/composites/guide-metrics/guide-elements-table/guide-element-type-action/pendo-guide-element-type-action.vue';

export default {
    name: 'PendoGuideElementsTable',
    components: {
        PendoGuideElementTypeAction,
        PendoCheckbox,
        PendoTable,
        PendoStatusOverlay,
        PendoAppDisplay
    },
    directives: {
        PendoTooltip
    },
    props: {
        /**
         * Guide step names
         */
        guideStepNames: {
            type: Array,
            required: true
        },
        /**
         * Guide step apps
         */
        apps: {
            type: Array,
            default: () => []
        },
        /**
         * Loading status for agg
         */
        elementsActivityAggStatus: {
            type: String,
            required: true
        },
        /**
         * Optional configuration inputs for loading indicator: [props](https://github.com/pendo-io/components/blob/master/src/composites/status-overlay/pendo-status-overlay.vue#L65)
         */
        loadingOptions: {
            type: Object,
            default: () => {}
        },
        /**
         * Agg data
         */
        elementsActivity: {
            type: Array,
            required: true
        },
        /**
         * Column names
         */
        elementsTableColumns: {
            type: Array,
            required: true
        },
        /**
         * Column names and filename for CSV download
         */
        csvDownloadConfig: {
            type: Object,
            required: true
        },
        /**
         * For cross-app guides, to group by app and steps
         */
        isCrossApp: {
            type: Boolean,
            default: false
        },
        /**
         * the outer table border visibility
         */
        borderless: {
            type: Boolean,
            default: false
        },
        /**
         * grouped state for the table
         */
        grouped: {
            type: Boolean,
            default: true
        },
        /**
         * whether the table shows deleted elements
         */
        showDeleted: {
            type: Boolean,
            default: false
        },
        /**
         * table header visibility
         */
        showHeader: {
            type: Boolean,
            default: true
        },
        /**
         * table height
         */
        height: {
            type: Number,
            default: 600
        }
    },
    emits: ['toggle-grouped', 'toggle-show-deleted', 'on-metric-click'],
    data () {
        return {
            overflowTooltipContent: null
        };
    },
    computed: {
        filters () {
            return [
                {
                    value: this.showDeleted,
                    filterFn: (row) => this.showDeleted || !row.isDeleted
                }
            ];
        },
        groupConfig () {
            const config = {
                enabled: this.grouped,
                groupKey: this.isCrossApp ? ['appId', 'guideStepId'] : 'guideStepId', // make sure appId key is unique so it's grouped such that there is a diff "group" for each consecutive grouping of steps belonging to that app
                sortChildren: true
            };

            return config;
        },
        groupedLabel () {
            return this.isCrossApp ? 'Group by Step and App' : 'Group by Step';
        }
    },
    methods: {
        checkForOverflowingContent (event, row) {
            this.overflowTooltipContent = null;

            const { scrollWidth, clientWidth } = event.target;

            if (scrollWidth > clientWidth) {
                const tooltipText = this.getGroupByStepAndAppHeaderText(row);
                this.overflowTooltipContent = tooltipText;
            }
        },
        getElementActions (uiElementActions) {
            if (!uiElementActions) {
                return [];
            }
            const elementActions = uiElementActions.map(({ action, icon }) => ({
                name: action,
                icon
            }));

            return elementActions;
        },
        getGroupByStepAndAppHeaderText ({ appId, guideStepId }) {
            if (this.isCrossApp && appId) {
                return this.getGroupByAppHeaderText({ appId });
            }

            return this.getGroupByStepHeaderText({ guideStepId });
        },
        getAppById ({ appId }) {
            const app = this.apps.find((app) => app.id.toString() === appId.replace(/-\d+/g, ''));

            return app || null;
        },
        getGroupByAppHeaderText ({ appId }) {
            if (appId === 'deleted') {
                return 'Deleted Step';
            }

            const app = this.getAppById({ appId });

            return get(app, 'name', null);
        },
        getGroupByStepHeaderText ({ guideStepId }) {
            const stepNumber = this.guideStepNames.findIndex((step) => step.id === guideStepId) + 1;

            if (stepNumber === 0) {
                return 'Deleted Step';
            }

            const { name: stepName } = this.guideStepNames.find((step) => step.id === guideStepId);

            if (stepName) {
                return `Step ${stepNumber} - ${stepName}`;
            }

            return `Step ${stepNumber}`;
        },
        getRowClassName ({ row }) {
            if (row.isDeleted) {
                return 'is-deleted';
            }

            if (this.isCrossApp && row.isGroup) {
                if (row.appId) {
                    return 'is-grouped-by-app';
                }

                return 'is-grouped-by-step';
            }

            return;
        },
        getCellClassName ({ columnIndex }) {
            if (this.isCrossApp && this.groupByStepAndApp) {
                if (columnIndex === 0) {
                    return 'is-guide-element-name';
                }
            }

            return;
        },
        onMetricClick ({ row }) {
            this.$emit('on-metric-click', row);
        },
        isAppRow ({ row }) {
            return this.isCrossApp && !!row.appId;
        },
        isStepRow ({ row }) {
            return !!row.guideStepId;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-guide-elements) {
    display: contents;
}

@include block(pendo-guide-elements-table) {
    @include element(group-by-step-header) {
        display: flex;
        align-items: center;
        &__group-header {
            &__step-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    @include element(group-by-app-header) {
        display: flex;
        align-items: center;
        &__group-header {
            &__app-name {
                display: flex;
                align-items: center;
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                &-icon {
                    padding-right: 0.5rem;
                }
            }
        }
    }

    @include element(header-actions) {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 16px;
        margin-right: 8px;

        .pendo-checkbox > label {
            margin-bottom: 0;
        }
    }

    @include element(element-text) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        @include element(deleted-label) {
            margin-left: 2px;
            color: $color-red-60;
            font-style: italic;
            font-size: 12px;
            font-weight: 400;
        }
    }

    @include element(step-name) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 700;
        color: $color-text-secondary;
        font-size: 12px;
        letter-spacing: 1.5px;
        @include is((deleted, unnamed)) {
            font-style: italic;
        }

        @include is(deleted) {
            color: $color-red-60;
        }
        @include is(unnamed) {
            color: $color-text-secondary;
        }
    }

    .pendo-table__row.is-deleted {
        background-color: $alert-error-background-color;
    }

    .is-guide-element-name {
        padding-left: 3.5rem;
    }

    .pendo-table__row--group.is-grouped-by-step > td {
        padding-left: 1.5rem;
    }

    .pendo-table__row--group.is-grouped-by-app > td {
        background-color: $color-gray-30;
        padding-left: 0;
    }
}
</style>
