<template>
    <div class="pendo-radio">
        <label
            class="pendo-radio__container"
            :class="{
                'is-disabled': isDisabled,
                'is-focus': focus,
                'is-checked': isChecked
            }"
            @keydown.space.stop.prevent="model = isDisabled ? model : value">
            <span
                class="pendo-radio__input"
                :class="{
                    'is-disabled': isDisabled,
                    'is-checked': isChecked
                }">
                <!-- zero-width space character, used to align radio properly -->
                &#8203;
                <input
                    v-model="model"
                    autocomplete="off"
                    class="pendo-radio__original"
                    type="radio"
                    :aria-checked="`${isChecked}`"
                    :disabled="isDisabled"
                    :name="inputName"
                    :value="value"
                    :checked="isChecked"
                    @focus="focus = true"
                    @blur="focus = false"
                    @change="handleChange">
                <span class="pendo-radio__inner" />
            </span>
            <span
                class="pendo-radio__label"
                @keydown.stop>
                <slot>
                    {{ label }}
                </slot>
            </span>
        </label>
        <template v-if="showSelectedDetails">
            <div
                v-if="selectedDetails.header || selectedDetails.content"
                :class="{ 'has-slot-content': !!$slots.selectedDetails }"
                class="pendo-radio__selected-details"
                @keydown.stop>
                <div
                    v-if="selectedDetails.header"
                    class="pendo-radio__selected-details-header">
                    {{ selectedDetails.header }}
                    <a
                        v-if="selectedDetails.link"
                        class="pendo-radio__selected-details-link"
                        :href="selectedDetails.link.url"
                        target="_blank">
                        {{ selectedDetails.link.label }}
                    </a>
                </div>
                <div
                    v-if="selectedDetails.content"
                    class="pendo-radio__selected-details-content">
                    {{ selectedDetails.content }}
                </div>
                <slot name="selectedDetails" />
            </div>
            <!-- @slot alternative to using `selectedDetails` prop -->
            <slot
                v-if="!selectedDetails.header && !selectedDetails.content"
                name="selectedDetails" />
        </template>
    </div>
</template>

<script>
import get from 'lodash/get';

export default {
    name: 'PendoRadio',
    inject: {
        $form: {
            default: ''
        },
        $radioGroup: {
            default: ''
        }
    },
    props: {
        /**
         * bound value
         */
        value: {
            type: null,
            default: null
        },
        /**
         * label text for radio
         */
        label: {
            type: String,
            default: null
        },
        /**
         * disables user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * same as native `name` attribute
         */
        name: {
            type: String,
            default: null
        },
        /**
         * convenience prop used to display additional information alongside selected radio.
         */
        selectedDetails: {
            type: [Boolean, Object],
            default: false
        }
    },
    data () {
        return {
            focus: false
        };
    },
    computed: {
        showSelectedDetails () {
            if (!this.$slots.selectedDetails && !this.selectedDetails) {
                return false;
            }

            return this.isChecked;
        },
        isRadioGroupChild () {
            return Boolean(this.$radioGroup);
        },
        model: {
            get () {
                return this.isRadioGroupChild ? this.$radioGroup.value : this.value;
            },
            set (val) {
                if (this.isRadioGroupChild) {
                    this.$radioGroup.$emit('input', val);
                } else {
                    this.$emit('input', val);
                }
            }
        },
        isDisabled () {
            const isFormDisabled = get(this, '$form.disabled');
            const isGroupDisabled = get(this, '$radioGroup.disabled');

            return isGroupDisabled || this.disabled || isFormDisabled;
        },
        isChecked () {
            return this.model === this.value;
        },
        inputName () {
            const radioGroupName = get(this, '$radioGroup.groupName', null);

            return this.name || radioGroupName;
        }
    },
    methods: {
        async handleChange () {
            await this.$nextTick();

            this.$emit('change', this.value);
            if (this.isRadioGroupChild) {
                this.$radioGroup.$emit('change', this.value);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-radio) {
    user-select: none;

    @include element(container) {
        color: $radio-color;
        font-weight: $radio-font-weight;
        line-height: 21px;
        position: relative;
        cursor: pointer;
        outline: none;
        font-size: $font-size-base;
        margin: 0;
        display: inline-flex;
        align-items: flex-start;

        @include is(checked) {
            @include element(inner) {
                border-color: $radio-checked-input-border-color;
                background: $radio-checked-input-fill;

                &::after {
                    transform: translate(-50%, -50%) scale(1);
                }

                &:hover {
                    border-color: $radio-input-border-color-hover;
                }
            }
        }

        @include is(disabled) {
            cursor: not-allowed;

            @include element(inner) {
                background-color: $disabled-fill;
                border-color: $disabled-border;
            }

            @include is(checked) {
                @include element(inner) {
                    background-color: $disabled-selected-fill;
                    border-color: $disabled-border;
                }
            }

            @include element(label) {
                color: $disabled-color;
            }
        }
    }

    @include element(input) {
        outline: none;
        display: flex;
        align-items: center;
        line-height: 21px;
        position: relative;
    }

    @include element(inner) {
        background-color: $radio-input-fill;
        border: $radio-input-border;
        border-radius: $radio-input-border-radius;
        box-sizing: border-box;
        display: inline-block;
        height: $radio-input-height;
        position: relative;
        width: $radio-input-width;

        &:hover {
            border-color: $radio-input-border-color-hover;
        }

        &::after {
            width: 6px;
            height: 6px;
            border-radius: $radio-input-border-radius;
            background-color: $color-white;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.15s ease-in;
        }

        @include focus-ring(
            $style: 'base',
            $transitions: (
                border-color 0.15s ease-in
            )
        );
    }

    @include element(original) {
        opacity: 0;
        outline: none;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;

        &:focus-visible {
            + .pendo-radio__inner {
                @include focus-ring($style: 'focused');
            }
        }
    }

    @include element(label) {
        font-size: $radio-font-size;
        display: inline-block;
        padding-left: 8px;
    }

    @include element(selected-details) {
        @include font-base;
        align-items: center;
        background: $color-gray-20;
        border: 1px solid $color-gray-40;
        border-radius: $border-radius-3;
        display: grid;
        grid-gap: 4px;
        line-height: 18px;
        margin: 8px auto 4px;
        padding: 8px 16px;

        .pendo-radio__selected-details-header + .pendo-radio__selected-details-content {
            color: $color-text-secondary;
            word-break: break-all;
        }

        &.has-slot-content {
            grid-gap: 8px;
            padding: 8px 16px 16px;
        }
    }

    @include element(selected-details-header) {
        font-weight: 600;
        color: $color-text-secondary;
    }

    @include element(selected-details-link) {
        float: right;
        color: $color-teal-70;
    }

    @include element(selected-details-content) {
        font-weight: normal;
        color: $color-text-primary;
    }
}
</style>
