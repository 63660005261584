<template>
    <div class="pendo-collapse-item__title">
        <slot name="prefix" />
        <slot name="title">
            <div class="pendo-collapse-item__title-inner">
                {{ title }}
            </div>
        </slot>
        <slot name="suffix">
            <pendo-icon
                :type="expanded ? 'minus-square' : 'plus-square'"
                size="14"
                class="pendo-collapse-item__suffix-icon" />
        </slot>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    name: 'PendoDetailsCollapseTrigger',
    components: {
        PendoIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        expanded: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-collapse-item) {
    @include modifier(details) {
        border-top: 1px solid $color-gray-40;

        .pendo-collapse-item__content > :not(.pendo-collapse-item) {
            border-top: 1px solid $color-gray-40;
        }

        @include element(header) {
            min-height: 40px;
            height: 100%;
            line-height: 22px;
            background-color: $color-gray-10;
            cursor: pointer;
            border-bottom: 0;
            font-size: 14px;
            font-weight: 700;
            justify-content: unset;
        }

        @include element(title) {
            display: grid;
            grid-gap: 16px;
            grid-auto-flow: column;
            grid-template-columns: 1fr auto;
            align-items: center;
        }

        @include element(suffix-icon) {
            transition: color 0.25s;
            color: $color-gray-70;
        }

        @include is(expanded) {
            @include element(suffix-icon) {
                color: $color-gray-100;
            }
        }
    }
}
</style>
