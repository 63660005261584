<template>
    <div class="pendo-collapse-item__title">
        <slot name="prefix" />
        <slot name="title">
            <div class="pendo-collapse-item__title-inner">
                {{ title }}
            </div>
        </slot>
        <slot name="suffix">
            <pendo-icon
                v-if="allowCollapse"
                type="chevron-down"
                size="18"
                class="pendo-collapse-item__suffix-icon" />
        </slot>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    name: 'PendoCollapseItemCard',
    components: {
        PendoIcon
    },
    inject: ['$collapse'],
    props: {
        title: {
            type: String,
            required: true
        },
        allowCollapse: {
            type: Boolean,
            default: true
        }
    },
    mounted () {
        // override the default 16px padding found in card-body
        if (this.$collapse.$el.parentNode.classList[0] === 'pendo-card__body') {
            this.$collapse.$el.parentNode.style.padding = 0;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-collapse-item) {
    @include modifier(card) {
        border-top: 1px solid $color-gray-40;

        .pendo-collapse-item__content:first-of-type {
            padding-bottom: 0;
        }

        @include element(header) {
            min-height: 76px;
            height: 100%;
            line-height: 28px;
            background-color: $collapse-header-fill;
            color: $collapse-header-color;
            border-bottom: 0;
            font-size: 18px;
            justify-content: unset;
        }

        @include element(title) {
            display: grid;
            grid-gap: 16px;
            grid-auto-flow: column;
            grid-template-columns: 1fr auto;
            align-items: center;
        }
    }
}
</style>
