<template>
    <div class="pendo-table__app">
        <pendo-icon
            class="pendo-table__app-icon"
            :aria-label="row.app.platform"
            :type="icon"
            size="14" />
        <div
            ref="name"
            class="pendo-table__app-name">
            {{ name }}
        </div>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    name: 'PendoAppCell',
    components: {
        PendoIcon
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        icon () {
            return {
                web: 'monitor',
                extension: 'puzzle-piece',
                ios: 'ios',
                android: 'android'
            }[this.row.app.platform];
        },
        name () {
            return this.row.app.displayName || this.row.app.name;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(app) {
        cursor: default;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 12px 1fr;
        align-items: center;
    }

    @include element(app-name) {
        @include ellipsis;
    }
}
</style>
