<template>
    <pendo-checkbox
        class="pendo-table__checkbox"
        aria-label="row selection"
        v-bind="{ checked, disabled, indeterminate, value: checked }"
        v-on="$listeners" />
</template>

<script>
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';

export default {
    name: 'PendoCheckboxCell',
    components: {
        PendoCheckbox
    },
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        indeterminate: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-table) {
    @include element(checkbox) {
        .pendo-checkbox__inner {
            transition-property: none;
        }
    }
}
</style>
