<template>
    <pendo-modal
        :visible="isVisible"
        :title="title"
        width="600px"
        height="auto"
        max-height="750"
        :click-to-close="true"
        :esc-to-close="true"
        :confirm-button-config="{
            type: 'primary',
            theme: 'app',
            label: 'Add',
            disabled: isInvalid
        }"
        @cancel="$emit('onCancel')"
        @close="$emit('onCancel')"
        @confirm="onConfirm"
        @closed="resetState">
        <template #body>
            <pendo-alert
                v-if="subHasExtensionApp"
                class="staging-modal-alert"
                type="info">
                To test staged guides for extension apps, be sure to also add your staging urls to the corresponding
                application's Websites under App Settings.
                <a
                    :href="stagingModalAlertLink"
                    target="_blank"> Learn more</a>.
            </pendo-alert>
            <div
                v-if="isHostType"
                class="modal-spacing">
                <pendo-input
                    v-if="isVisible"
                    v-model="searchInput"
                    class="search-input"
                    :placeholder="`Filter ${modalSearchPlaceholder()}`">
                    <template #prefix>
                        <pendo-icon
                            type="search"
                            stroke="#2a2c35"
                            size="16" />
                    </template>
                </pendo-input>
                <pendo-alert
                    v-if="hasMoreHosts && !isLoading"
                    type="warning"
                    style="margin-top: 0px">
                    <strong>Warning:</strong> Prefix search is required. Only first 1,000 rows are displayed.
                </pendo-alert>
                <pendo-table
                    ref="hostTable"
                    :columns="columns"
                    :default-sort="{
                        prop: 'name',
                        order: 'ascending'
                    }"
                    row-key="id"
                    :checkbox-config="{
                        checkMethod: () => true
                    }"
                    :data="searchedHosts"
                    max-height="365"
                    :loading="!hostsLoaded"
                    empty-text="No entries found."
                    @select-change="updateHostsList"
                    @select-all="updateHostsList" />
                <div>
                    <span>
                        <strong>Add additional {{ labelIncludesServer ? 'Hosts' : 'Websites' }} and * wildcard:
                        </strong>
                        <p class="wildcard-text">(comma separated for more than one)</p>
                    </span>
                    <pendo-input
                        v-model="bulkHostInput"
                        class="input-width"
                        placeholder="Example: dev.acme.com" />
                </div>
            </div>
            <div v-if="!isHostType">
                <div v-if="type === 'visitorId'">
                    <span>
                        <strong>Visitor ID </strong>
                        <p class="wildcard-text">(* wildcard supported)</p>
                    </span>
                </div>
                <div class="modal-item-spacing">
                    <pendo-input
                        v-model="entryInputValue"
                        class="input-width"
                        :class="{ 'ip-input': type === 'ip' }"
                        :invalid="isInvalid && entryInputValue !== ''"
                        :placeholder="placeholderText" />
                    <pendo-button
                        v-if="type === 'ip'"
                        class="ip-button"
                        theme="app"
                        type="secondary"
                        label="Detect My IP"
                        @click="detectIp" />
                </div>
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import PendoAlert from '@/components/alert/pendo-alert.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoInput from '@/components/input/pendo-input.vue';
import PendoIcon from '@/components/icon/pendo-icon.vue';

export default {
    name: 'AddExcludeListStagingItemModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoInput,
        PendoModal,
        PendoTable,
        PendoIcon
    },
    props: {
        hasMoreHosts: {
            type: Boolean,
            required: true,
            default: false
        },
        hosts: {
            type: Array,
            required: true
        },
        hostsLoaded: {
            type: Boolean,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        itemType: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        fetchMyIpAddress: {
            type: Function,
            required: true
        },
        serverHostDomainLabel: {
            type: String,
            default: 'Server Host/Domain'
        },
        stagingModalAlertLink: {
            type: String,
            default: ''
        },
        subHasExtensionApp: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            bulkHostInput: '',
            columns: [
                {
                    type: 'checkbox'
                },
                {
                    prop: 'name',
                    label: this.serverHostDomainLabel.includes('Server') ? 'Hosts/Domains' : 'Staging Websites',
                    sortable: true,
                    sortBy: (row) => row.name.toLowerCase(),
                    formatter: (row) => row.name
                }
            ],
            entryInputValue: '',
            hostsList: [],
            searchInput: '',
            wildcardsList: []
        };
    },
    computed: {
        currentTypeObj () {
            return this.typeMap[this.type] || {};
        },
        filteredHosts () {
            if (this.hosts) {
                return this.hosts.filter((entry) => {
                    return entry.name !== '';
                });
            }

            return [];
        },
        globType () {
            return this.itemType === 'staging' ? 'stagingGlob' : 'serverGlob';
        },
        isHostType () {
            return this.type === 'server' || this.type === 'staging';
        },
        isGlobType () {
            return this.entryInputValue.includes('*');
        },
        isInvalid () {
            if (this.isHostType) {
                return !this.hostsList.length > 0 && !this.bulkHostInput;
            }

            return !RegExp(this.validationPattern).test(this.entryInputValue);
        },
        itemToAdd () {
            return this.isHostType
                ? { hosts: this.hostsList, wildcards: this.wildcardsList }
                : { type: this.currentTypeObj.id, name: this.entryInputValue };
        },
        placeholderText () {
            return this.currentTypeObj.placeholder;
        },
        searchedHosts () {
            if (this.filteredHosts) {
                return this.filteredHosts.filter((host) => {
                    return host.name.includes(this.searchInput);
                });
            }

            return [];
        },
        title () {
            return `Add ${this.labelIncludesServer ? 'New ' : ''}${this.currentTypeObj.name}`;
        },
        typeMap () {
            return {
                accountId: {
                    id: this.isGlobType ? 'accountIdGlob' : 'accountId',
                    name: 'Account ID',
                    placeholder: 'Example: 65454',
                    validationPattern: /^.+$/
                },
                server: {
                    id: 'serverGlob',
                    name: 'Server Host/Domain',
                    placeholder: 'Filter Hosts / Domains',
                    validationPattern: /^.+$/
                },
                staging: {
                    id: 'stagingGlob',
                    name: this.serverHostDomainLabel,
                    placeholder: 'Filter Hosts / Domains',
                    validationPattern: /^.+$/
                },
                ip: {
                    id: this.isGlobType ? 'ipGlob' : 'ip',
                    name: 'IP Address',
                    placeholder: 'Example: 127.0.0.1',
                    validationPattern: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/
                },
                visitorId: {
                    id: this.isGlobType ? 'visitorIdGlob' : 'visitorId',
                    name: 'Visitor ID',
                    placeholder: 'Example: *@acme.org, 1337*, or test-*',
                    validationPattern: /^[^\[\]\?]+$/ //eslint-disable-line
                }
            };
        },
        validationPattern () {
            return this.currentTypeObj.validationPattern;
        },
        labelIncludesServer () {
            return this.serverHostDomainLabel.includes('Server');
        }
    },
    watch: {
        searchInput () {
            if (!this.hasMoreHosts) {
                return;
            }

            return this.$emit('onSearchChange', this.searchInput);
        }
    },
    methods: {
        modalSearchPlaceholder () {
            return this.labelIncludesServer ? 'Hosts/Domains' : 'Staging Websites';
        },
        buildWildcardList () {
            this.wildcardsList = this.bulkHostInput.split(',').map((str) => ({
                name: str
                    .trim()
                    // People like to add `http://` to these rules... :/
                    .replace(/^https?:\/\//, '')
                    // they also like to add stuff after the host (https://pendo-io.atlassian.net/browse/APP-1680)
                    .replace(/\/.*$/, ''),
                type: this.globType
            }));
        },
        detectIp () {
            return this.fetchMyIpAddress().then(({ data }) => {
                this.entryInputValue = data;
            });
        },
        onConfirm () {
            if (this.isHostType && this.bulkHostInput) {
                this.buildWildcardList();
            }

            return this.$emit('onConfirm', this.itemToAdd);
        },
        resetState () {
            this.bulkHostInput = '';
            this.entryInputValue = '';
            this.hostsList = [];
            this.searchInput = '';
            this.wildcardsList = [];
        },
        updateHostsList (userSelection) {
            this.hostsList = userSelection.selectedRows;
        }
    }
};
</script>

<style lang="scss" scoped>
.search-input {
    max-width: 208px;
}

.input-width {
    width: 560px;
    margin-top: 5px;
}

.ip-input {
    width: 445px;
    margin-top: 5px;
}

.ip-button {
    margin-top: 5px;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.modal-item-spacing {
    display: flex;
    justify-content: space-between;
}

.modal-spacing {
    display: grid;
    grid-gap: 20px;
}

.text-spacing {
    margin-bottom: 5px;
}

.wildcard-text {
    display: inherit;
    color: $color-gray-70;
}

.staging-modal-alert {
    margin-bottom: 10px;
}
</style>
