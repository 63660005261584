<script>
import kebabCase from 'lodash/kebabCase';
import icons from './icons.js';
import { warn } from '@/utils/console';
import { iconTypeToGeneric } from '@/utils/utils';

export default {
    name: 'PendoIcon',
    inheritAttrs: false,
    props: {
        /**
         * type of icon. must be a [feather](https://feathericons.com/) or match the kebab-case name of an icon in custom directory
         */
        type: {
            type: String,
            required: true
        },
        /**
         * applies a fill color to svg when supported
         */
        fill: {
            type: String,
            default: 'none'
        },
        /**
         * sets the height and width of the svg
         */
        size: {
            type: [Number, String],
            default: 24
        },
        /**
         * alternative to passing `size`. must also specify `width` if using this option
         */
        height: {
            type: [Number, String],
            default: null
        },
        /**
         * alternative to passing `size`. must also specify `height` if using this option
         */
        width: {
            type: [Number, String],
            default: null
        },
        /**
         * stroke color of icon
         */
        stroke: {
            type: String,
            default: 'currentColor'
        },
        /**
         * @ignore
         */
        strokeLinecap: {
            type: String,
            default: 'round'
        },
        /**
         * @ignore
         */
        strokeLinejoin: {
            type: String,
            default: 'round'
        },
        /**
         * stroke width
         */
        strokeWidth: {
            type: [Number, String],
            default: 2
        },
        /**
         * element tag to use for wrapper class. defaults to `<i>`
         */
        tag: {
            type: String,
            default: 'i'
        },
        /**
         * convinience helper for css display attribute
         */
        display: {
            type: String,
            default: 'block'
        }
    },
    computed: {
        icon () {
            const typeLookup = {
                account: 'users',
                feature: 'feature',
                guide: 'guide',
                page: 'file',
                parentAccount: 'home',
                poll: 'list',
                trackType: 'zap',
                visitor: 'user',
                web: 'monitor'
            };

            const type = kebabCase(typeLookup[this.type] || this.type);

            return icons[type];
        },
        defaultAriaLabel () {
            return iconTypeToGeneric(this.type);
        }
    },
    render (createElement) {
        const {
            $listeners,
            $attrs,
            defaultAriaLabel,
            display,
            fill,
            height,
            icon,
            size,
            stroke,
            strokeLinecap,
            strokeLinejoin,
            strokeWidth,
            tag,
            type,
            width
        } = this;

        if (!icon) {
            return warn(`[PendoIcon]: can't find matching icon for '${type}'.`);
        }

        return createElement(
            tag,
            {
                class: {
                    'pendo-icon': true,
                    [`pendo-icon__${type}`]: type
                },
                on: $listeners
            },
            [
                createElement('svg', {
                    attrs: {
                        ...icon.attrs,
                        'aria-label': defaultAriaLabel,
                        ...$attrs,
                        fill,
                        stroke,
                        display,
                        'height': height || size,
                        'width': width || size,
                        'stroke-linecap': strokeLinecap,
                        'stroke-linejoin': strokeLinejoin,
                        'stroke-width': strokeWidth
                    },
                    class: [icon.attrs.class, 'pendo-icon__content'],
                    domProps: {
                        innerHTML: icon.contents
                    }
                })
            ]
        );
    }
};
</script>

<style lang="scss">
.pendo-icon {
    &__content {
        height: inherit;
        width: inherit;
    }

    & + & {
        margin-left: 4px;
    }
}
</style>
