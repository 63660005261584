<script>
export default {
    name: 'Tooltip'
};
</script>

<style lang="scss">
.pendo-tooltip {
    &.tooltip {
        display: block !important;
        z-index: 10002;

        .tooltip-inner {
            font-size: 12px;
            @include font-family;
            font-weight: 400;
            text-align: left;
            background-color: $color-gray-110;
            color: $color-white;
            border-radius: $border-radius-3;
            line-height: 1.4;
            padding: 4px 6px;
            min-height: 26px;
            height: auto;
            max-width: unset;
            box-sizing: border-box;
            display: flex;
            justify-items: flex-start;
            align-items: center;
        }

        .tooltip-arrow {
            z-index: 1;
            display: none;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            margin: 4px;
            z-index: 1;
            border-color: $color-gray-110;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                position: absolute;
                z-index: 0;
                border-color: $color-gray-110;
            }
        }

        &.has-tooltip-arrow {
            .tooltip-arrow {
                display: unset;
            }
            .tooltip-inner {
                min-height: unset;
                padding: 8px 16px;
            }
        }

        &[x-placement^='top'] {
            .tooltip-arrow,
            .tooltip-arrow:before {
                border-width: 8px 8px 0 8px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                left: calc(50% - 8px);
                margin-top: 0;
                margin-bottom: 0;
            }

            .tooltip-arrow {
                bottom: -8px;
            }

            .tooltip-arrow:before {
                bottom: 1px;
            }
        }

        &[x-placement^='bottom'] {
            .tooltip-arrow,
            .tooltip-arrow:before {
                border-width: 0 8px 8px 8px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-top-color: transparent !important;
                left: calc(50% - 8px);
                margin-top: 0;
                margin-bottom: 0;
            }

            .tooltip-arrow {
                top: -8px;
            }

            .tooltip-arrow:before {
                top: 1px;
            }
        }

        &[x-placement^='right'] {
            .tooltip-arrow,
            .tooltip-arrow:before {
                border-width: 8px 8px 8px 0;
                border-left-color: transparent !important;
                border-top-color: transparent !important;
                border-bottom-color: transparent !important;
                top: calc(50% - 8px);
                margin-left: 0;
                margin-right: 0;
            }

            .tooltip-arrow {
                left: -8px;
            }

            .tooltip-arrow:before {
                left: 1px;
            }
        }

        &[x-placement^='left'] {
            .tooltip-arrow,
            .tooltip-arrow:before {
                border-width: 8px 0 8px 8px;
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                top: calc(50% - 8px);
                margin-left: 0;
                margin-right: 0;
            }

            .tooltip-arrow {
                right: -8px;
            }

            .tooltip-arrow:before {
                right: 1px;
            }
        }

        &.pendo-tooltip--light {
            .tooltip-inner {
                border: 1px solid $color-gray-40;
                background-color: $color-white;
                color: $color-gray-110;
            }

            .tooltip-arrow {
                border-color: $color-gray-40;

                &:before {
                    border-color: $color-white;
                }
            }
        }

        &.pendo-tooltip--dark {
            .tooltip-inner {
                color: $color-white;
                background-color: $color-gray-110;
            }

            .tooltip-arrow {
                border-color: $color-gray-110;

                &:before {
                    display: none;
                }
            }
        }

        &.is-multi-line {
            /* Edge does not support overflow-wrap */
            word-wrap: break-word;
            overflow-wrap: break-word;

            .tooltip-inner {
                display: block;
                min-width: 0;
                max-width: 240px;
            }
        }

        &.has-arrow {
            .tooltip-inner {
                padding: 8px;
            }

            .tooltip-arrow {
                display: unset;
            }
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
</style>
