<template>
    <div class="pendo-guide-elements-drawer__header-wrapper">
        <div class="pendo-guide-elements-drawer__header">
            <h3
                v-pendo-tooltip="{
                    content: overflowTooltipContent,
                    classes: 'pendo-guide-elements-drawer__title-tooltip',
                    delay: { show: 300, hide: 0 },
                    arrow: true,
                    multiline: true,
                    boundariesElement: 'body'
                }"
                class="pendo-guide-elements-drawer__title"
                @mouseenter="checkForOverflowingContent($event, uiElement.uiElementText)">
                {{ uiElement.uiElementText }}
            </h3>
            <div class="pendo-guide-elements-drawer__subtitle">
                <div class="subtitle__type">
                    <pendo-guide-element-type-action
                        class="guide-element-type"
                        :ui-elements="uiElementType"
                        :icon-size="18" />
                </div>
                <div class="subtitle__divider">
                    <pendo-divider
                        direction="vertical"
                        stroke="#DADCE5"
                        height="auto"
                        width="32px" />
                </div>
                <div class="subtitle__actions">
                    <pendo-guide-element-type-action
                        class="guide-element-actions"
                        :ui-elements="uiElementActions"
                        :icon-size="18" />
                </div>
            </div>
        </div>
        <div class="pendo-drawer__close">
            <pendo-button
                theme="app"
                type="tertiary"
                icon="x"
                icon-size="20"
                @click="$emit('close')" />
        </div>
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoDivider from '@/components/divider/pendo-divider';
import PendoGuideElementTypeAction from '@/composites/guide-metrics/guide-elements-table/guide-element-type-action/pendo-guide-element-type-action.vue';
import get from 'lodash/get';

export default {
    name: 'PendoGuideElementsDrawerHeader',
    components: {
        PendoGuideElementTypeAction,
        PendoButton,
        PendoDivider
    },
    directives: {
        PendoTooltip
    },
    props: {
        uiElement: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            overflowTooltipContent: this.uiElement.uiElementText
        };
    },
    computed: {
        uiElementType () {
            const typeArray = [];
            if (this.uiElement.uiElementType) {
                typeArray.push({
                    name: this.uiElement.uiElementType,
                    icon: this.uiElement.uiElementTypeIcon
                });
            }

            return typeArray;
        },
        uiElementActions () {
            return get(this.uiElement, 'uiElementActions', []).map((action) => {
                return {
                    name: action.action,
                    icon: action.icon
                };
            });
        }
    },
    methods: {
        checkForOverflowingContent (event, tooltipText) {
            const { scrollWidth, clientWidth } = event.target;

            this.overflowTooltipContent = scrollWidth > clientWidth ? tooltipText : null;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-guide-elements-drawer) {
    @include element(header-wrapper) {
        display: contents;
    }

    @include element(header) {
        display: grid;
        grid-gap: 8px;
        margin: 16px 0;
        cursor: default;
    }

    @include element(title) {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @include element(title-tooltip) {
        &.pendo-tooltip.tooltip {
            z-index: 20001;
        }
    }

    @include element(subtitle) {
        color: $color-text-secondary;
        display: flex;
        overflow: hidden;

        .subtitle__type,
        .subtitle__divider,
        .subtitle__actions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .guide-element-type {
            flex-basis: 0px;
            align-items: flex-start;
        }

        .pendo-divider {
            display: flex;
            align-items: flex-start;
            overflow: hidden;
            flex-grow: 1;
            flex-basis: 0px;
        }

        // stack multiple actions to prevent overflow
        .guide-element-actions {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 0px;

            .guide-element-action:not(:last-child) {
                margin-bottom: 8px;
            }

            .pendo-divider {
                display: none;
            }
        }
    }
}
</style>
