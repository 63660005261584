<template>
    <div class="rotating-keys">
        <active-keys
            class="rotating-keys--active"
            :table-data="activeKeys"
            :app-id="app.id"
            :new-key-name="newKeyName"
            :loading-state="isLoading"
            @create="handleKeyStateChange('create', $event)"
            @update="handleKeyStateChange('update', $event)"
            @revoke="handleKeyStateChange('revoke', $event)" />
        <revoked-keys
            class="rotating-keys--revoked"
            :table-data="revokedKeys"
            :loading-state="isLoading" />
    </div>
</template>

<script>
import ActiveKeys from './active-keys.vue';
import RevokedKeys from './revoked-keys.vue';

export default {
    name: 'PendoKeyRotation',
    components: {
        RevokedKeys,
        ActiveKeys
    },
    props: {
        keyIdPrefix: {
            type: String,
            default: ''
        },
        app: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        keys: {
            type: Array,
            required: true
        }
    },
    computed: {
        activeKeys () {
            if (!this.keys) {
                return null;
            }

            return this.keys.filter((key) => !key.revoked);
        },
        revokedKeys () {
            if (!this.keys) {
                return null;
            }

            return this.keys.filter((key) => key.revoked);
        },
        newKeyName () {
            return `${this.keyIdPrefix}-XXXXX`;
        }
    },
    methods: {
        handleKeyStateChange (method, options) {
            this.$emit(method, options);
        }
    }
};
</script>
<style scoped>
.active-keys {
    margin-bottom: 25px;
}
</style>
