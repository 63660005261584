<template>
    <form
        class="pendo-form"
        :class="[
            `pendo-form--label-${labelPosition}`,
            {
                'pendo-form--inline': inline
            }
        ]"
        @submit.prevent="onSubmit">
        <slot />
    </form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { noop } from '@/components/form/utils';
import { warn } from '@/utils/console';

export default {
    name: 'PendoForm',
    provide () {
        return {
            $form: this
        };
    },
    props: {
        /**
         * A boolean value representing the validity of the form.
         */
        value: {
            type: Boolean,
            default: false
        },
        /**
         * data of form component
         */
        model: {
            type: Object,
            default: () => ({})
        },
        /**
         * validation rules of form
         */
        rules: {
            type: Object,
            default: () => ({})
        },
        /**
         * position of label.
         * @values left, right, top
         */
        labelPosition: {
            type: String,
            default: 'top',
            validator: (labelPosition) => ['top', 'left', 'right'].includes(labelPosition)
        },
        /**
         * whether the form is inline
         */
        inline: {
            type: Boolean,
            default: false
        },
        /**
         * whether to disable all components in this form. If set to true, it cannot be overridden by its inner components' disabledprop
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * whether to trigger validation when the rulesprop is changed
         */
        validateOnRuleChange: {
            type: Boolean,
            default: true
        },
        /**
         * @ignore
         */
        callValidate: {
            type: Boolean,
            default: false
        },
        /**
         * @ignore
         */
        callResetFields: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            fields: [],
            watchers: [],
            errors: {}
        };
    },
    watch: {
        rules () {
            if (this.validateOnRuleChange) {
                this.validate(noop);
            }
        },
        errors: {
            handler (val) {
                const errors = Object.values(val).includes(true);

                this.$emit('input', !errors);
            },
            deep: true,
            immediate: true
        }
    },
    updated () {
        /**
         * @deprecated since 11.0.0
         * consumers should call validate and reset functions direclty and handle result
         */
        this.$nextTick(function () {
            if (this.callValidate) {
                warn("'callValidate' is deprecated. Use a ref and call the public 'validate' function instead", this);
                this.validateForm(true);
            }

            if (this.callResetFields) {
                warn("'callResetFields' is deprecated. Use a ref and call the public 'reset' function instead", this);
                this.resetFields(true);
            }
        });
    },
    async mounted () {
        await this.$nextTick();
        this.validate(noop, false);
    },
    methods: {
        /**
         * @private
         */
        register (field) {
            if (field) {
                this.fields.push(field);
                this.watchers.push(this.watchField(field));
            }
        },
        /**
         * @private
         */
        unregister (fieldToUnregister) {
            const found = this.fields.find((field) => field._uid === fieldToUnregister._uid);

            if (!found) {
                return;
            }

            const unwatch = this.watchers.find((watcher) => watcher._uid === found._uid);
            if (unwatch) {
                unwatch.valid();
            }

            this.fields = this.fields.filter((field) => field._uid !== found._uid);
            this.watchers = this.watchers.filter((watcher) => watcher._uid !== found._uid);
            this.$delete(this.errors, found._uid);
        },
        /**
         * @private
         */
        watchField (input) {
            const watcher = (input) => {
                return input.$watch(
                    'invalid',
                    (val) => {
                        this.$set(this.errors, input._uid, val);
                    },
                    { immediate: true }
                );
            };

            const watchers = {
                _uid: input._uid,
                valid: watcher(input)
            };

            return watchers;
        },
        /**
         * @public
         * reset all the fields and remove validation result
         */
        async reset () {
            if (!this.model) {
                return;
            }

            this.fields.forEach((field) => {
                field.reset();
            });

            // run validation in background to ensure v-model state remains in sync
            await this.$nextTick();
            this.validate(noop, false);
        },
        /**
         * @public
         * clear validation message for certain fields.
         * The parameter is prop name or an array of prop names of the form items whose validation messages will be removed.
         * When omitted, all fields' validation messages will be cleared
         *
         * @param Function(props: string | array)
         */
        resetValidation (props) {
            let fieldsToReset = this.fields;

            if (props) {
                const propsToReset = Array.isArray(props) ? props : [props];
                fieldsToReset = this.fields.filter((field) => propsToReset.includes(field.prop));
            }

            fieldsToReset.forEach((field) => {
                field.resetValidation();
            });
        },
        /**
         * @public
         * validate the whole form.
         * Takes a callback as a param.
         * After validation, the callback will be executed with two params:
         *    - a boolean indicating if the validation has passed
         *    - and an object containing all fields that fail the validation.
         *
         * Returns a promise if callback is omitted
         *
         * @param Function(callback: Function(boolean, object))
         */
        validate (callback, forceDirty = true) {
            if (!this.model) {
                return;
            }

            let promise;
            // if no callback, return promise
            if (typeof callback !== 'function' && window.Promise) {
                promise = new window.Promise((resolve, reject) => {
                    callback = function (valid, invalidFields) {
                        if (valid) {
                            resolve(valid);
                        } else {
                            reject(invalidFields);
                        }
                    };
                });
            }

            // If the fields to be verified are empty,
            // the callback will be returned immediately when the verification is called
            if (this.fields.length === 0 && callback) {
                callback(true);
            }

            let valid = true;
            let count = 0;
            let invalidFields = {};
            this.fields.forEach((field) => {
                if (forceDirty) {
                    field.touch();
                }

                field.validate('', (message, field) => {
                    if (message) {
                        valid = false;
                    }
                    invalidFields = Object.assign({}, invalidFields, field);
                    if (typeof callback === 'function' && ++count === this.fields.length) {
                        callback(valid, invalidFields);
                    }
                });
            });

            if (promise) {
                return promise;
            }
        },
        /**
         * @public
         * validate one or several form items
         * @param Function(props: string | array, callback: Function(errorMessage: string))
         */
        validateField (props, callback) {
            const propsToValidate = Array.isArray(props) ? props : [props];
            const fieldsToValidate = this.fields.filter((field) => propsToValidate.includes(field.prop));

            if (!fieldsToValidate.length) {
                return;
            }

            fieldsToValidate.forEach((field) => {
                field.validate('', callback);
            });
        },
        /**
         * @private
         * @deprecated since 11.0.0
         * use reset instead
         */
        resetFields (calledFromUpdatedHook) {
            if (!calledFromUpdatedHook) {
                warn("'resetFields' is deprecated. Use a ref and call the public 'reset' function instead", this);
            }

            this.reset();
            /**
             * @ignore
             * @deprecated call reset function
             * @since 11.0.0
             * Emitted when the form is reset
             * @event fieldsReset
             */
            this.$emit('fieldsReset');
        },
        /**
         * @private
         * @deprecated since 11.0.0
         * call validate function from consuming app using ref
         */
        validateForm (calledFromUpdatedHook) {
            if (!calledFromUpdatedHook) {
                warn("'validateForm' is deprecated. Use a ref and call the public 'validate' function instead", this);
            }

            this.validate((valid) => {
                if (valid) {
                    /**
                     * @ignore
                     * @deprecated call validate function directly from consuming app using refs
                     * @since 11.0.0
                     * Emitted when the form validated
                     * @event formValidated
                     * @property {Event} form model
                     */
                    this.$emit('formValidated', cloneDeep(this.model));
                } else {
                    /**
                     * @ignore
                     * @deprecated call validate function directly from consuming app using refs
                     * @since 11.0.0
                     * Emitted when form validation fails
                     * @event invalidForm
                     */
                    this.$emit('invalidForm');
                }
            });
        },
        /**
         * @private
         */
        onSubmit ($event) {
            this.$emit('submit', $event);
            this.validateForm();
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-form) {
    display: grid;

    @include modifier((label-right, label-left)) {
        grid-template-columns: [labels] auto [controls] 1fr;
        grid-template-rows: 36px;
        grid-auto-flow: row dense;
        align-items: flex-start;
        grid-row-gap: 22px;
        grid-column-gap: 16px;

        .pendo-form-item {
            display: contents;
        }

        .pendo-form-item__label {
            align-items: center;
            display: flex;
            grid-column: labels;
            grid-row: auto;
        }

        .pendo-form-item__content {
            margin-top: 0 !important;
        }
    }

    @include modifier(label-left) {
        .pendo-form-item__label {
            justify-self: start;
        }
    }

    @include modifier(label-right) {
        .pendo-form-item__label {
            justify-self: end;
        }
    }

    @include modifier(inline) {
        display: block;

        .pendo-form-item {
            display: inline-block;
            margin-right: 8px;
            vertical-align: top;
        }

        .pendo-form-item__label {
            float: none;
            display: inline-block;
        }

        .pendo-form-item__content {
            display: inline-block;
            vertical-align: top;
        }

        &.pendo-form--label-top .pendo-form-item__content {
            display: block;
        }
    }
}
</style>
