<template>
    <div
        class="pendo-button-group"
        role="group">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PendoButtonGroup'
};
</script>

<style lang="scss">
@import '../../styles/themes/app/buttons';
@import '../../styles/themes/p2-dark/buttons';

$button-namespace: '.pendo-button'; //prefix for all button classes

$button-themes: (
    app: $app--button-theme,
    p2-dark: $p2-dark--button-theme
);

@include block(pendo-button-group) {
    border-radius: $border-radius-3;
    display: inline-flex;
    max-width: 100%;

    & > .pendo-button {
        &:focus-visible {
            z-index: 1;
        }

        & + .pendo-button {
            margin-left: 0;
        }

        &.is-disabled {
            z-index: 1;
        }

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        &:not(:first-child) {
            margin-left: -1px;
        }
    }

    & > .pendo-multiselect__trigger {
        &.pendo-button {
            &:last-child {
                padding-left: 7px;
                padding-right: 9px;

                border-top: 0;
                border-right: 0;
                border-bottom: 0;
            }
        }
    }

    @each $theme in map-keys($button-themes) {
        .#{$theme} {
            @each $type, $values in map-fetch($button-themes, $theme types) {
                $button-type-namespace: #{$button-namespace}--#{$type};

                // background
                $button-background: map-fetch($button-themes, $theme types $type background);
                $button-background-rest: nth($button-background, 1);
                $button-background-hover: $button-background-rest;

                @if length($button-background) > 1 {
                    $button-background-hover: nth($button-background, 2);
                }

                // borders
                $border-props: map-fetch($button-themes, $theme types $type border);
                $button-border-rest: $button-background-rest;
                $button-border-hover: $button-background-hover;

                @if $border-props {
                    $button-border-rest: nth($border-props, 1);
                    $button-border-hover: nth($border-props, 2);
                }

                // .pendo-button--primary, .pendo-button--secondary, .pendo-button-tertiary etc.
                &#{$button-type-namespace} {
                    @if $border-props {
                        &:hover {
                            z-index: 1;
                        }

                        &:focus-visible {
                            z-index: 2;
                        }
                    } @else {
                        &:first-child {
                            border-right-color: $button-background-hover;
                            border-right-width: 1px;
                            border-right-style: solid;
                        }

                        &:last-child {
                            border-left-color: $button-background-hover;
                            border-left-width: 1px;
                            border-left-style: solid;
                        }

                        &:not(:first-child):not(:last-child) {
                            border-left-color: $button-background-hover;
                            border-left-width: 1px;
                            border-left-style: solid;
                            border-right-color: $button-background-hover;
                            border-right-width: 1px;
                            border-right-style: solid;
                        }
                    }

                    &.pendo-multiselect__trigger {
                        @if $border-props {
                            border-color: $button-border-rest;
                            border-top-width: 1px;
                            border-top-style: solid;
                            border-right-width: 1px;
                            border-right-style: solid;
                            border-bottom-width: 1px;
                            border-bottom-style: solid;

                            &.is-open {
                                border-color: $button-border-hover;
                            }
                        }

                        &.is-open {
                            background-color: $button-background-hover;
                        }
                    }
                }
            }
        }
    }
}
</style>
