<template>
    <pendo-card :title="title">
        <pendo-empty-state
            :title="emptyStateTitle"
            :description="description">
            <template #icon>
                <img :src="icon">
            </template>
            <template
                #actions
                v-if="!isReadOnly">
                <slot
                    v-if="$slots.actions"
                    name="actions" />
                <pendo-button
                    v-else
                    type="primary"
                    prefix-icon="plus"
                    :label="`Tag ${title}`"
                    @click="tagCountable" />
            </template>
        </pendo-empty-state>
    </pendo-card>
</template>
<script>
import PendoCard from '@/components/card/pendo-card.vue';
import PendoEmptyState from '@/components/empty-state/pendo-empty-state.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import capitalize from 'lodash/capitalize';

export default {
    name: 'PendoCountableEmptyState',
    components: {
        PendoCard,
        PendoEmptyState,
        PendoButton
    },
    props: {
        countableType: {
            type: String,
            required: true
        },
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        title () {
            return `${capitalize(this.countableType)}s`;
        },
        emptyStateTitle () {
            return `No ${this.title} Tagged`;
        },
        description () {
            return 'Tagging pages allows you to view page traffic and understand the behavior of your users.';
        },
        icon () {
            switch (this.countableType) {
                case 'page':
                    return 'https://cdn.pendo.io/img/no-pages-tagged.svg';
                default:
                    return '';
            }
        }
    },
    methods: {
        tagCountable () {
            this.$emit('tag-countable', true);
        }
    }
};
</script>
<style scoped>
.pendo-empty-state {
    position: relative;
}
</style>
