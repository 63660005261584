<template>
    <div
        class="pendo-input"
        :class="[
            `pendo-input--${size}`,
            {
                'pendo-input--file': type === 'file',
                'pendo-input--textarea': type === 'textarea',
                'pendo-input--prefix': $slots.prefix,
                'pendo-input--suffix': $slots.suffix,
                'pendo-input--prepend': $slots.prepend,
                'pendo-input--append': $slots.append,
                'pendo-input--resizable': resize,
                'is-left-label': hasLeftLabel,
                'is-right-label': hasRightLabel,
                'is-inline-label': !!inlineLabel,
                'is-invalid': invalid,
                'is-disabled': isDisabled
            }
        ]"
        :style="{
            width: actualWidth
        }">
        <div
            v-if="hasTopLabel"
            :id="`pendo-input__label--top-${_uid}`"
            class="pendo-input__label pendo-input__label--top">
            <slot name="topLabel">
                {{ topLabel }}
            </slot>
        </div>
        <div class="pendo-input__control">
            <div
                v-if="$slots.prepend"
                class="pendo-input__prepend">
                <slot name="prepend" />
            </div>
            <template v-if="type !== 'textarea'">
                <div
                    class="pendo-input__field"
                    :class="{
                        'is-focused': focused
                    }"
                    @click="focus">
                    <div
                        v-if="$slots.prefix"
                        class="pendo-input__prefix">
                        <slot name="prefix" />
                    </div>
                    <input
                        ref="input"
                        class="pendo-input__input"
                        tabindex="0"
                        v-bind="mergedAttributes"
                        :disabled="isDisabled"
                        :placeholder="placeholder"
                        :autofocus="autofocus"
                        :type="type"
                        :value="lazyValue"
                        @blur="onBlur"
                        @focus="onFocus"
                        @change="onChange"
                        @input="onInput"
                        @keydown="onKeydown">
                    <div
                        v-if="$slots.suffix"
                        class="pendo-input__suffix">
                        <slot name="suffix" />
                    </div>
                </div>
            </template>
            <template v-if="type === 'textarea'">
                <div
                    ref="textareaContainer"
                    class="pendo-input__field"
                    :class="{
                        'is-focused': focused
                    }"
                    :style="{
                        resize: resize ? resize : 'none'
                    }">
                    <div
                        v-if="$slots.prefix"
                        class="pendo-input__prefix">
                        <slot name="prefix" />
                    </div>
                    <textarea
                        ref="textarea"
                        tabindex="0"
                        class="pendo-input__textarea"
                        :value="lazyValue"
                        :disabled="isDisabled"
                        :placeholder="placeholder"
                        :autofocus="autofocus"
                        v-bind="mergedAttributes"
                        @input="onInput"
                        @focus="onFocus"
                        @blur="onBlur" />
                    <div
                        v-if="$slots.suffix"
                        class="pendo-input__suffix">
                        <slot name="suffix" />
                    </div>
                </div>
            </template>
            <div
                v-if="$slots.append"
                class="pendo-input__append">
                <slot name="append" />
            </div>
        </div>
        <div
            v-if="hasBottomLabel"
            :id="`pendo-input__label--bottom-${_uid}`"
            class="pendo-input__label pendo-input__label--bottom">
            <slot name="bottomLabel">
                {{ bottomLabel }}
            </slot>
        </div>
        <div
            v-if="$slots['helper-text']"
            class="pendo-input__helper-text">
            <slot name="helper-text" />
        </div>
    </div>
</template>

<script>
import DOMPurify from 'dompurify';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import LabelsMixin from '@/mixins/labels';
import { keyCodes } from '@/utils/utils';
import get from 'lodash/get';

const DEFAULT_DURATION = 300;

export default {
    name: 'PendoInput',
    mixins: [LabelsMixin],
    inject: {
        $form: {
            default: ''
        },
        $formItem: {
            default: ''
        }
    },
    inheritAttrs: false,
    props: {
        /**
         * bound value
         */
        value: {
            type: [String, Number],
            default: null
        },
        /**
         * same as `autofocus`in native input
         */
        autofocus: {
            type: Boolean,
            default: false
        },
        /**
         * disableds the input and prevents user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * placeholder for input or textarea
         */
        placeholder: {
            type: String,
            default: null
        },
        /**
         * sanitize input value before emitting. may cause performance impacts and should be used in combication with `debounce`
         */
        sanitize: {
            type: Boolean,
            default: false
        },
        /**
         * size of input
         * @values medium, small, mini
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['mini', 'small', 'medium'].includes(size)
        },
        /**
         * type of input
         */
        type: {
            type: String,
            default: 'text'
        },
        /**
         * the width of the pendo-input component, including any labels and slotted content
         * @values medium, small, mini, 100%
         */
        width: {
            type: String,
            default: '100%'
        },
        /**
         * whether or not to debounce input event emitting. if passing number as prop value, it represents the amount in milliseconds to debounce
         */
        debounce: {
            type: [Boolean, Number],
            default: false
        },
        /**
         * whether or not to throttle input event emitting. if passing number as prop value, it represents the amount in milliseconds to throttle
         */
        throttle: {
            type: [Boolean, Number],
            default: false
        },
        /**
         * control the resizability of textarea. `false` by default
         * @values vertical, horizontal, auto
         */
        resize: {
            type: [String, Boolean],
            default: false,
            validator: (resize) => ['vertical', 'horizontal', 'auto'].includes(resize) || typeof resize === 'boolean'
        },
        /**
         * whether textarea has an adaptive height, only works when type is 'textarea'
         */
        autosize: {
            type: Boolean,
            default: true
        },
        /**
         * applies invalid styles to input
         */
        invalid: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            focused: false,
            lazyValue: this.value
        };
    },
    computed: {
        internalValue: {
            get () {
                return this.lazyValue;
            },
            set (val) {
                this.lazyValue = val;
                /**
                 * emitted when the bound value is updated
                 * @event input
                 */
                this.$emit('input', this.lazyValue);
            }
        },
        actualWidth () {
            const { width } = this;

            switch (width) {
                case 'medium':
                    return '232px';
                case 'small':
                    return '160px';
                case 'mini':
                    return '80px';
            }

            return width;
        },
        delayDuration () {
            if (this.debounce) {
                return typeof this.debounce !== typeof true ? Number(this.debounce) : DEFAULT_DURATION;
            }

            return typeof this.throttle !== typeof true ? Number(this.throttle) : DEFAULT_DURATION;
        },
        isDisabled () {
            const isFormDisabled = get(this, '$form.disabled', false);

            return this.disabled || isFormDisabled;
        },
        ariaLabelledBy () {
            if (this.$formItem) {
                return this.$formItem.labelId;
            }
            if (this.hasTopLabel) {
                return `pendo-input__label--top-${this._uid}`;
            }
            if (this.hasBottomLabel) {
                return `pendo-input__label--bottom-${this._uid}`;
            }

            return undefined;
        },
        mergedAttributes () {
            return {
                'id': get(this, '$formItem.labelFor'),
                'aria-describedby': get(this, '$formItem.ariaDescribedBy'),
                'aria-invalid': Boolean(get(this, '$formItem.ariaDescribedBy')),
                'aria-labelledby': this.ariaLabelledBy,
                'required': get(this, '$formItem.isRequired', false),
                ...this.$attrs
            };
        }
    },
    watch: {
        value (val) {
            this.lazyValue = val;
            if (this.$formItem) {
                this.$formItem.onFieldChange(val);
            }
        }
    },
    created () {
        if (this.debounce || this.throttle) {
            const strategies = { throttle, debounce };
            const strategy = this.debounce ? 'debounce' : 'throttle';
            this.onInput = strategies[strategy](this.onInput, this.delayDuration);
        }
    },
    beforeDestroy () {
        if (this.type === 'textarea' && !this.resize && this.autosize) {
            this.$refs.textarea.removeEventListener('input', this.resizeTextarea);
        }
    },
    async mounted () {
        if (this.autofocus) {
            this.focus();
        }

        if (this.type === 'textarea' && !this.resize && this.autosize) {
            await this.$nextTick();
            this.$refs.textarea.addEventListener('input', this.resizeTextarea);
            this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight + 2}px`;
        }
    },
    methods: {
        focus () {
            this.onFocus();
        },
        blur () {
            requestAnimationFrame(() => {
                const ref = this.type === 'textarea' ? 'textarea' : 'input';
                if (this.$refs[ref]) {
                    this.$refs[ref].blur();
                }
            });
        },
        onChange (event) {
            // if input event is delayed, immediately flush to
            // ensure the input is updated with current typed value
            if (this.onInput.flush) {
                this.onInput.flush(event.target.value);
            }

            this.updateValue(event.target.value);
            /**
             * emitted when the input is blurred and bound model value has changed
             * @event change
             */
            this.$emit('change', this.internalValue);
            /**
             * @ignore
             * @deprecated Use `@change` event instead
             * @since 1.0.0
             */
            this.$emit('onChange', this.internalValue);
        },
        onKeydown (event) {
            if (event.keyCode === keyCodes.enter) {
                this.onChange(event);
            }

            if (event.keyCode === keyCodes.esc) {
                this.blur();
            }

            this.$emit('keydown', event);
        },
        onFocus (event) {
            const ref = this.type === 'textarea' ? 'textarea' : 'input';
            if (document.activeElement !== this.$refs[ref]) {
                return this.$refs[ref].focus();
            }

            if (!this.focused) {
                this.focused = true;
                if (event) {
                    this.$emit('focus', event);
                }
            }
        },
        async onBlur (event) {
            this.focused = false;
            if (event) {
                await this.$nextTick();
                this.$emit('blur', event);

                if (this.$formItem) {
                    this.$formItem.onFieldBlur(this.internalValue);
                }
            }
        },
        onInput (event) {
            this.updateValue(event.target.value);
        },
        updateValue (value) {
            if (this.sanitize) {
                value = DOMPurify.sanitize(value);
            }

            this.internalValue = value;
        },
        resizeTextarea (event = null) {
            this.$refs.textarea.style.height = 'auto';
            if (event) {
                this.$refs.textarea.style.height = `${event.target.scrollHeight + 2}px`;
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-input) {
    width: 100%;

    * {
        box-sizing: border-box;
    }

    @include element(helper-text) {
        color: $color-text-secondary;
        margin-top: 8px;
        @include font-base;

        * {
            margin: 0; // prevent bootstrap tags like <p> from impacting margin of helper text
        }
    }

    @include element(label) {
        @include font-base;
        display: grid;
        height: 24px;
        color: $color-gray-110;

        &--top {
            font-weight: 600;
            align-items: start;
        }
        &--bottom {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    @include element(control) {
        display: flex;
        position: relative;
    }

    @include element((prepend, append)) {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-gray-10;
        color: $color-text-secondary;
        border: 1px solid $color-gray-40;
        padding: 0 8px;
        white-space: nowrap;
        cursor: default;

        .pendo-multiselect__trigger--medium,
        .pendo-multiselect__trigger--small,
        .pendo-multiselect__trigger--mini {
            min-height: 0;
        }

        .pendo-multiselect {
            display: inline-block;
            margin: 0 -8px;
            color: $color-text-primary;
        }

        .pendo-multiselect .pendo-multiselect__trigger,
        .pendo-multiselect:hover .pendo-multiselect__trigger {
            border-color: transparent;
            background-color: transparent;
            color: inherit;
            border-top: 0;
            border-bottom: 0;
            outline: 1px solid transparent;
            transition: outline-color 150ms, background-color 150ms;
        }

        .pendo-multiselect .pendo-multiselect__trigger.is-active,
        .pendo-multiselect:hover .pendo-multiselect__trigger {
            outline: 1px solid $color-gray-100;
            background-color: $color-gray-20;
        }
    }

    @include element(prepend) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-right: 0;

        .pendo-multiselect .pendo-multiselect__trigger {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    @include element(append) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-left: 0;

        .pendo-multiselect .pendo-multiselect__trigger {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    @include element((prepend, append)) {
        transition: border-color 0.15s ease-in-out;
    }

    @include element((prefix, suffix)) {
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include element(field) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        overflow-wrap: break-word;
        vertical-align: top;
        pointer-events: auto;
        flex: 1 1 100%;
        overflow: hidden;
        color: $color-text-primary;
        border-color: $input-border-color;
        background-color: $color-white;
        @include focus-ring(
            $style: 'base',
            $transitions: (
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out
            )
        );

        &:hover {
            border-color: $input-border-color-active;
        }

        &.is-focused {
            border-color: $input-border-color-active;
            z-index: 1;
            // input elements that require text input show focus-visible in same instances as focus
            @include focus-ring($style: 'focused');
        }

        @include element((prepend, prefix, suffix, append)) {
            line-height: 20px;
            font-size: 14px;
        }
    }

    @include element((input, textarea)) {
        border: none;
        background-color: transparent;
        box-sizing: border-box;
        color: inherit;
        cursor: text;
        font-family: inherit;
        font-size: 14px;
        min-width: 0;
        width: 100%;
        border-width: 0;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        outline: none;
        padding: 8px;

        &[readonly] {
            cursor: default;
            &:hover {
                cursor: default;
            }
        }

        &:-webkit-autofill {
            transition: background-color 0.5s ease-in-out 0s;
        }

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        &[type='date'],
        &[type='time'] {
            background: transparent;
        }

        &[type='file'] {
            outline: none;

            &:focus {
                outline: none;
            }
        }
    }

    @include element(input) {
        line-height: 20px;
        min-height: 34px;
        max-height: 34px;
    }

    @include element(textarea) {
        max-height: unset;
        min-height: unset;
        line-height: 1.4;
        height: 100%;
        resize: none;
    }

    @include modifier(file) {
        overflow: hidden;

        input {
            opacity: 0.2;
            z-index: 1;
            position: absolute;
        }
    }

    @include modifier(textarea) {
        @include element(field) {
            min-height: 68px;
            min-width: 68px;
        }
    }

    @include modifier(small) {
        @include element(field) {
            @include element(input) {
                padding: 7px 8px;
                font-size: 14px;
                line-height: 16px;
                min-height: 30px;
                max-height: 30px;
            }

            @include element((prepend, prefix, suffix, append)) {
                line-height: 16px;
                font-size: 14px;
            }
        }
    }

    @include modifier(mini) {
        @include element(field) {
            @include element(input) {
                padding: 6px 8px;
                font-size: 12px;
                line-height: 14px;
                min-height: 24px;
                max-height: 24px;
                font-weight: 500;
            }

            @include element((prepend, prefix, suffix, append)) {
                line-height: 14px;
                font-size: 12px;
            }
        }
    }

    @include modifier(resizable) {
        @include element(field) {
            flex: unset;
            align-items: start;
            padding: 2px 8px;

            @include element(textarea) {
                padding: 2px 0;
            }
        }
    }

    @include modifier(prefix) {
        @include element(field) {
            @include element(input) {
                padding-left: 0;
            }
        }
    }
    @include modifier(suffix) {
        @include element(field) {
            @include element(input) {
                padding-right: 0;
            }
        }
    }
    @include modifier(prepend) {
        @include element(field) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    @include modifier(append) {
        @include element(field) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    @include is(inline-label) {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        grid-gap: 8px;

        .pendo-input__label {
            align-items: center;
            height: unset;
        }
        &.is-left-label {
            grid-template-columns: auto minmax(50%, 1fr);
        }
        &.is-right-label {
            grid-template-columns: minmax(50%, 1fr) auto;
        }
        // both right and left labels
        &.is-left-label.is-right-label {
            grid-template-columns: auto minmax(50%, 1fr) auto;
        }
    }

    @include is(disabled) {
        @include element(field) {
            color: $disabled-color;
            background-color: $disabled-fill;
            border-color: $disabled-border;

            @include element((input, textarea)) {
                cursor: not-allowed;
            }
        }
    }

    @include is(invalid) {
        @include element(field) {
            border-color: $border-color-invalid;
            &.is-focused {
                outline-color: $border-color-invalid-focus;
            }
        }
    }
}
</style>
