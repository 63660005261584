<template>
    <div
        class="pendo-table__guide-status"
        :class="{
            'pendo-table__guide-status--interactive': interactive
        }">
        <template v-if="!interactive">
            <pendo-icon
                type="circle"
                size="8"
                aria-hidden="true"
                :fill="statusColor"
                :stroke="statusColor" />
            <span class="pendo-table__guide-status-label">
                {{ status.label }}
            </span>
        </template>
        <template v-if="interactive">
            <div
                v-if="!isOpen"
                :class="triggerClass"
                role="button"
                @[clickListener]="toggleMultiselect">
                {{ status.label }}
                <div class="pendo-multiselect__suffix">
                    <pendo-icon
                        type="chevron-down"
                        size="14"
                        class="pendo-multiselect__caret" />
                </div>
            </div>
            <pendo-multiselect
                v-if="isOpen"
                ref="guideStatus"
                :value="status"
                :allow-empty="false"
                :min-menu-width="110"
                value-key="state"
                :options="options"
                @select="handleSelect"
                @closed="isOpen = false">
                <template #trigger>
                    <pendo-guide-status-trigger />
                </template>
                <template #option="{ option }">
                    <pendo-guide-status-option :option="option" />
                </template>
            </pendo-multiselect>
        </template>
    </div>
</template>

<script>
import startCase from 'lodash/startCase';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoGuideStatusOption from '@/components/multiselect/option-types/pendo-guide-status-option';
import PendoGuideStatusTrigger from '@/components/multiselect/trigger-types/pendo-guide-status-trigger';

export default {
    name: 'PendoGuideStatusCell',
    components: {
        PendoIcon,
        PendoMultiselect,
        PendoGuideStatusTrigger,
        PendoGuideStatusOption
    },
    inject: ['$table'],
    props: {
        row: {
            type: Object,
            required: true
        },
        options: {
            type: Array,
            default: () => [
                {
                    label: 'Public',
                    state: 'public'
                },
                {
                    label: 'Staged',
                    state: 'staged'
                },
                {
                    label: 'Draft',
                    state: 'draft'
                },
                {
                    label: 'Disabled',
                    state: 'disabled'
                }
            ]
        },
        interactive: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isOpen: false
        };
    },
    computed: {
        status () {
            return {
                state: this.row.state,
                label: startCase(this.row.state)
            };
        },
        statusColor () {
            return {
                'public': '#00aa62',
                'scheduled': '#f05b00',
                'staged': '#b08300',
                'draft': '#07699b',
                'pending-review': '#53afff',
                'disabled': '#6a6c75',
                'archived': '#6a6c75'
            }[this.row.state];
        },
        clickListener () {
            if (this.interactive) {
                return 'click';
            }

            return null;
        },
        triggerClass () {
            return [
                'pendo-multiselect__trigger',
                'pendo-multiselect__trigger--mini',
                'pendo-multiselect__trigger--outline',
                'pendo-multiselect__trigger--status',
                `pendo-multiselect__trigger--${this.status.state}`,
                {
                    'pendo-multiselect__trigger--fill': this.status.state === 'public'
                }
            ];
        }
    },
    watch: {
        '$table.scrollStore.scrollActive': function (value) {
            if (value) {
                this.isOpen = false;
            }
        }
    },
    methods: {
        handleSelect ({ state }) {
            this.$emit('status-change', { row: this.row, value: state });
        },
        async toggleMultiselect () {
            if (!this.interactive) {
                return;
            }

            this.isOpen = true;
            await this.$nextTick();

            if (this.$refs.guideStatus) {
                this.$refs.guideStatus.toggleMenu();
            }
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-table) {
    @include element(guide-status) {
        display: grid;
        grid-template-columns: 8px auto;
        grid-gap: 8px;
        align-items: center;
        min-width: 70px;
        text-transform: capitalize;

        .pendo-multiselect__trigger {
            cursor: default;
            font-size: 12px;
            justify-content: space-between;
            will-change: contents;
        }

        .pendo-multiselect__trigger--status {
            transition: none;
        }

        @include modifier(interactive) {
            display: inline-block;
            user-select: none;
            min-width: 92px;

            .pendo-multiselect__trigger {
                cursor: pointer;
            }
        }
    }
}
</style>
