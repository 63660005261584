<template>
    <div class="revoked-keys">
        <pendo-table
            ref="revokedtable"
            loading-text="Loading Revoked Keys"
            :loading="loadingState"
            class="revoked-keys--table"
            :data="tableData"
            :columns="columns"
            empty-text="No Keys have been created"
            max-height="500"
            row-key="id"
            title="Revoked Keys"
            collapsible
            :default-sort="defaultSort">
            <template #secret="{ row }">
                <pendo-button
                    theme="app"
                    type="link"
                    :label="row.showSecret ? 'Hide' : 'Show'"
                    @click="handleToggleSecret(row)" />
                <span v-show="row.showSecret">
                    {{ row.secret }}
                </span>
            </template>
        </pendo-table>
    </div>
</template>

<script>
import PendoTable from '@/components/table/pendo-table';
import PendoButton from '@/components/button/pendo-button';

import moment from 'moment';

export default {
    name: 'RevokedKeys',
    components: {
        PendoButton,
        PendoTable
    },
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        loadingState: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            defaultSort: {
                prop: 'revokedAt',
                order: 'descending'
            },
            columns: [
                {
                    prop: 'id',
                    label: 'Signing Key Name',
                    sortable: true
                },
                {
                    prop: 'description',
                    label: 'Description',
                    width: '250px',
                    sortable: true
                },
                {
                    prop: 'secret',
                    label: 'Key',
                    width: '500px',
                    sortable: false
                },
                {
                    label: 'Revoked Time',
                    prop: 'revokedAt',
                    sortable: true,
                    formatter: (row) => moment(row.revokedAt).format('MMM D, YYYY h:mm:ss A z')
                },
                {
                    label: 'Revoked By',
                    prop: 'revokedByUser.username',
                    sortable: true
                },
                {
                    type: 'actions',
                    width: '80px'
                }
            ]
        };
    },
    async mounted () {
        await this.$nextTick();
        this.$refs.revokedtable.collapseTable();
    },
    methods: {
        handleToggleSecret (row) {
            this.$refs.revokedtable.updateRow({
                ...row,
                showSecret: !row.showSecret
            });
        }
    }
};
</script>
