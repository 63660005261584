<template>
    <pendo-multiselect
        v-model="status"
        v-pendo-tooltip="toolTipConfig"
        :options="statusOptions"
        :disabled="!canChangeAlert"
        :allow-empty="false"
        value-key="value"
        full-width
        :min-menu-width="150"
        class="guide-alerts-status"
        :class="{
            'not-reviewed': status === 'Not Reviewed' || status === 'NotReviewed',
            'triage': status === 'Triage',
            'resolved': status === 'Resolved'
        }"
        @select="updateRowStatus">
        <template #trigger>
            <pendo-guide-status-trigger />
        </template>
        <template #option="{ option }">
            <pendo-icon-option :option="option" />
        </template>
    </pendo-multiselect>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoGuideStatusTrigger from '@/components/multiselect/trigger-types/pendo-guide-status-trigger';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import get from 'lodash/get';

export default {
    name: 'GuideAlertsStatus',
    components: {
        PendoMultiselect,
        PendoGuideStatusTrigger,
        PendoIconOption
    },
    directives: {
        PendoTooltip
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            statusOptions: [
                {
                    label: 'Not Reviewed',
                    value: 'NotReviewed',
                    icon: { type: 'circle', fill: '#db1111', stroke: '#db1111', size: 8 }
                },
                {
                    label: 'Triage',
                    value: 'Triage',
                    icon: { type: 'circle', fill: '#07699b', stroke: '#07699b', size: 8 }
                },
                {
                    label: 'Resolved',
                    value: 'Resolved',
                    icon: { type: 'circle', fill: '#00aa62', stroke: '#00aa62', size: 8 }
                }
            ],
            status: null
        };
    },
    computed: {
        toolTipConfig () {
            if (this.canChangeAlert) {
                return undefined;
            }

            return {
                content: `You do not have sufficient permissions for ${this.row.app.displayName} to change the alert status.`,
                arrow: true
            };
        },
        canChangeAlert () {
            return get(this.row, 'canChangeAlert', false);
        }
    },
    watch: {
        row: {
            deep: true,
            handler () {
                this.setStatus();
            }
        }
    },
    mounted () {
        this.setStatus();
    },
    methods: {
        setStatus () {
            this.status = this.statusOptions.find((option) => option.value === this.row.alertStatus);
        },
        updateRowStatus ({ value }) {
            this.$emit('update-status', this.row, value);
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep .pendo-multiselect__trigger {
    font-weight: bold;
    color: $color-gray-100;
    &--NotReviewed {
        background-color: $color-red-60;
        color: $color-white;
        border: 2px solid $color-red-60;
    }
    &--Triage {
        border: 2px solid $color-blue-90;
        &.is-open {
            background-color: $color-blue-90;
            color: $color-white;
        }
    }
    &--Resolved {
        border: 2px solid $color-green-70;
        &.is-open {
            background-color: $color-green-70;
            color: $color-white;
        }
    }
}
</style>
