<template>
    <main
        :style="pageStyle"
        :class="pageClasses"
        class="pendo-page">
        <div class="pendo-page-container">
            <slot />
        </div>
    </main>
</template>

<script>
export default {
    name: 'PendoPage',
    inject: ['$app'],
    props: {
        backgroundColor: {
            type: String,
            default () {
                return this.$app.pageBackgroundColor;
            }
        }
    },
    computed: {
        pageClasses () {
            return {
                'pendo-page--tabs': this.$app.tabs
            };
        },
        pageStyle () {
            const sidebarWidth = parseInt(this.$app.sidebarWidth);
            const topbarHeight = parseInt(this.$app.topbarHeight);

            return {
                background: this.backgroundColor,
                position: 'fixed',
                top: `${topbarHeight}px`,
                right: '0px',
                height: `calc(100% - ${topbarHeight}px)`,
                overflowY: 'auto',
                overflowX: 'auto',
                minWidth: '0px',
                left: `${sidebarWidth}px`
            };
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-page) {
    z-index: 1;
    height: 100%;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;

    &,
    * {
        box-sizing: border-box;
    }

    @include modifier(tabs) {
        @include block(pendo-page-header) {
            min-height: 113px;

            @include element(content) {
                margin-bottom: 0;
            }

            @include element(actions-wrapper) {
                margin-bottom: 0;
                margin-top: 16px;
            }
        }

        @include block(pendo-page-content) {
            border-top: 1px solid $color-white;
            padding: 0;
        }

        @include block(pendo-tabs) {
            .pendo-tabs-nav {
                border-top: 1px solid $color-white;
            }

            .pendo-tabs-nav,
            .pendo-tab-list,
            .pendo-tabs-content {
                padding-left: 32px;
                padding-right: 32px;
            }

            .pendo-tabs-content {
                padding-top: 32px;
            }
        }
    }
}

@include block(pendo-page-container) {
    overflow-y: auto;
    overscroll-behavior-y: contain;
    overflow-x: auto;

    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 100%;

    height: 100%;
    display: grid;
    grid-template-rows: 1fr 82px;

    > :first-child:not(.pendo-page-footer) {
        grid-row: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
    }
}
</style>
