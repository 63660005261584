<template>
    <div
        class="pendo-form-item"
        :class="{
            'is-error': showErrorMessage,
            'is-validating': isValidating,
            'is-success': isSuccess,
            'is-required': isRequired
        }">
        <label
            v-if="label || $slots.label"
            :id="labelId"
            :for="labelFor"
            class="pendo-form-item__label">
            <!-- @slot alternative to using the `label` prop -->
            <slot name="label">{{ label }}</slot>
        </label>
        <div class="pendo-form-item__content">
            <!-- @slot form control -->
            <slot />
            <pendo-collapse-transition>
                <!-- @slot custom error message and styling -->
                <slot
                    v-if="showErrorMessage"
                    :id="errorMessageId"
                    name="error"
                    :error="validateMessage">
                    <div
                        :id="errorMessageId"
                        class="pendo-form-item__error">
                        {{ validateMessage }}
                    </div>
                </slot>
            </pendo-collapse-transition>
        </div>
    </div>
</template>
<script>
import AsyncValidator from 'async-validator';
import isUndefined from 'lodash/isUndefined';
import PendoCollapseTransition from '@/utils/pendo-collapse-transition';
import { noop, getPropByPath, getRuleForTrigger } from '@/components/form/utils';

export default {
    name: 'PendoFormItem',
    components: {
        PendoCollapseTransition
    },
    provide () {
        return {
            $formItem: this
        };
    },
    inject: {
        $form: {
            default: ''
        }
    },
    props: {
        /**
         * label to place above the form control
         */
        label: {
            type: String,
            default: undefined
        },
        /**
         * a key of model. In the use of validate and resetFields method, the attribute is required
         */
        prop: {
            type: String,
            default: undefined
        },
        /**
         * whether the field is required or not, will be determined by validation rules if omitted
         */
        required: {
            type: Boolean,
            default: undefined
        },
        /**
         * validation rules of form, passed to the [async-validator](https://github.com/yiminghe/async-validator)
         * library. See their docs for detailed syntax and examples.
         */
        rules: {
            type: [Object, Array],
            default: undefined
        },
        /**
         * field error message, set its value and the field will validate error and show this message immediately
         */
        error: {
            type: String,
            default: undefined
        },
        /**
         * if the form control has a specified id, provide the id to associate the label with the control.
         * otherwise this is handled by default
         */
        for: {
            type: String,
            default: undefined
        }
    },
    data () {
        return {
            validateState: '',
            validateMessage: '',
            validateDisabled: false,
            validator: {},
            invalid: false,
            dirty: false
        };
    },
    computed: {
        allRules () {
            let parentFormRules = this.$form.rules;
            const selfRules = this.rules;
            const requiredRule = !isUndefined(this.required) ? { required: Boolean(this.required) } : [];

            const prop = getPropByPath(parentFormRules, this.prop);
            parentFormRules = parentFormRules ? prop.obj[this.prop || ''] || prop.value : [];

            return [].concat(selfRules || parentFormRules || []).concat(requiredRule);
        },
        shouldValidate () {
            return Boolean(this.allRules.length) || this.isRequired;
        },
        labelFor () {
            return this.for || `form-item--${this._uid}`;
        },
        labelId () {
            return `form-item--${this._uid}-label`;
        },
        errorMessageId () {
            return `form-item--${this._uid}-error`;
        },
        ariaDescribedBy () {
            return this.showErrorMessage ? this.errorMessageId : undefined;
        },
        fieldValue () {
            if (!this.$form.model || !this.prop) {
                return;
            }

            return getPropByPath(this.$form.model, this.prop, true).value;
        },
        isRequired () {
            if (this.required) {
                return true;
            }

            const rules = this.allRules;
            if (rules && rules.length) {
                return rules.some((rule) => rule.required);
            }

            return false;
        },
        isValidating () {
            return this.validateState === 'validating';
        },
        isSuccess () {
            return this.validateState === 'success';
        },
        isError () {
            return this.validateState === 'error';
        },
        showErrorMessage () {
            return this.dirty && this.invalid && this.isError;
        }
    },
    watch: {
        error: {
            immediate: true,
            handler (value) {
                this.validateMessage = value;
                this.validateState = value ? 'error' : '';
            }
        }
    },
    mounted () {
        if (this.$form) {
            this.$form.register(this);
        }

        if (this.prop) {
            this.setInitialFieldValue();
        }
    },
    beforeDestroy () {
        if (this.$form) {
            this.$form.unregister(this);
        }
    },
    methods: {
        /**
         * @private
         */
        touch () {
            this.dirty = true;
        },
        /**
         * @private
         */
        setInitialFieldValue () {
            let initialValue = this.fieldValue;
            if (Array.isArray(initialValue)) {
                initialValue = [].concat(initialValue);
            }
            Object.defineProperty(this, 'initialValue', {
                value: initialValue
            });
        },
        /**
         * @private
         */
        validate (trigger, callback = noop) {
            this.validateDisabled = false;

            const rules = getRuleForTrigger(this.allRules, trigger);
            if (!rules || rules.length === 0) {
                callback();

                return true;
            }

            this.validateState = 'validating';

            rules.forEach((rule) => {
                delete rule.trigger;
            });

            const descriptor = {
                [this.prop]: rules
            };

            const validator = new AsyncValidator(descriptor);
            const model = {
                [this.prop]: this.fieldValue
            };

            validator.validate(model, { firstFields: true, suppressWarning: true }, (errors, invalidFields) => {
                this.invalid = Boolean(errors);
                const validateMessage = errors ? errors[0].message : '';
                if (this.dirty) {
                    this.validateState = errors ? 'error' : 'success';
                    this.validateMessage = validateMessage;
                }

                callback(validateMessage, invalidFields);
                if (this.$form) {
                    this.$form.$emit('validate', this.prop, !errors, validateMessage || null);
                }
            });
        },
        /**
         * @public
         * reset validation status of the field
         */
        resetValidation () {
            this.validateState = '';
            this.validateMessage = '';
            this.validateDisabled = false;
            this.dirty = false;
        },
        /**
         * @public
         * reset current field value and reset validation status
         */
        async reset () {
            this.validateState = '';
            this.validateMessage = '';
            this.dirty = false;

            const value = this.fieldValue;
            const prop = getPropByPath(this.$form.model, this.prop, true);

            this.validateDisabled = true;
            if (Array.isArray(value)) {
                prop.obj[prop.key] = [].concat(this.initialValue);
            } else {
                prop.obj[prop.key] = this.initialValue;
            }

            // reset validateDisabled after onFieldChange triggered
            await this.$nextTick();

            this.validateDisabled = false;
        },
        /**
         * @private
         */
        onFieldBlur () {
            if (this.shouldValidate) {
                this.touch();
                this.validate('blur');
            }
        },
        /**
         * @private
         */
        onFieldChange () {
            if (!this.shouldValidate) {
                return;
            }

            if (this.validateDisabled) {
                this.validateDisabled = false;

                return;
            }

            this.touch();
            this.validate('change');
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-form-item) {
    margin-bottom: 16px;

    @include element(label) {
        display: block;
        font-size: 14px;
        line-height: 21px;
        color: $color-text-primary;
        font-weight: 600;
        box-sizing: border-box;
        padding: 0;
        margin-bottom: 0px !important; // bootstrap styles in appengine add a margin-bottom: 5px to labels

        + .pendo-form-item__content {
            margin-top: 4px;
        }
    }

    @include element(error) {
        color: $color-red-60;
        font-size: 12px;
        line-height: 17px;
        margin-top: 4px;

        &.pendo-transition-collapse-enter-active {
            transition-property: height, margin-top;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition: height 0.25s $collapse-transition-timing-function,
                margin-top 0.25s $collapse-transition-timing-function;
        }

        &.pendo-transition-collapse-leave-active {
            transition: height 0.2s $collapse-transition-timing-function,
                margin-top 0.2s $collapse-transition-timing-function;
        }

        &.pendo-transition-collapse-enter,
        &.pendo-transition-collapse-leave-active {
            margin-top: 0px;
        }
    }

    @include is(required) {
        .pendo-form-item__label:after {
            content: '*';
            color: $color-red-60;
            margin-left: 4px;
        }
    }

    @include is(error) {
        & .pendo-input__field,
        & .pendo-multiselect__trigger,
        & .pendo-input__inner,
        & .pendo-textarea__inner {
            border-color: $border-color-invalid;
            &.is-focused {
                outline-color: $border-color-invalid-focus;
            }
        }
        & .pendo-multiselect__trigger {
            &:focus-visible,
            &--searchable {
                &.is-active {
                    outline-color: $border-color-invalid-focus;
                }
            }
        }
    }
}
</style>
