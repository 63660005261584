<template>
    <div class="pendo-copy-code-block">
        <div class="pendo-copy-code-block__header">
            <div>
                <div
                    v-if="isTopDescription"
                    class="pendo-copy-code-block__helper-text">
                    {{ description }}
                </div>
                <h5
                    v-if="snippetConfig.title"
                    class="pendo-copy-code-block__header-title">
                    {{ snippetConfig.title }}
                </h5>
            </div>
            <div>
                <pendo-button
                    v-if="snippetConfig.link && snippetConfig.link.icon"
                    theme="app"
                    type="link"
                    :prefix-icon="snippetConfig.link.icon"
                    :label="snippetConfig.link.text"
                    @click="openJwtHelpArticle" />
                <pendo-button
                    v-if="isTopButton"
                    v-pendo-clipboard="codeToCopy"
                    theme="app"
                    type="link"
                    prefix-icon="clipboard"
                    :label="buttonLabel" />
            </div>
        </div>
        <div class="pendo-copy-code-block__body">
            <div
                v-if="isLeftDescription"
                class="pendo-copy-code-block__helper-text">
                {{ description }}
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <pre class="pendo-copy-code-block__pre">
                <code
                    :class="['hljs', language]"
                    v-html="highlightedCode" />
            </pre>

            <pendo-button
                v-if="isRightButton"
                v-pendo-clipboard="codeToCopy"
                theme="app"
                type="link"
                prefix-icon="clipboard"
                :label="buttonLabel" />
        </div>
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button.vue';
import PendoClipboard from '@/directives/clipboard/pendo-clipboard.js';
import get from 'lodash/get';

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import plaintext from 'highlight.js/lib/languages/plaintext';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('plaintext', plaintext);

export default {
    name: 'PendoCopyCodeBlock',
    components: {
        PendoButton
    },
    directives: {
        PendoClipboard
    },
    props: {
        /**
         * string of code being displayed for copy
         */
        codeToCopy: {
            type: String,
            required: true
        },
        /**
         * the language option for syntax highlighting (plaintext or javascript)
         */
        language: {
            type: String,
            default: 'plaintext'
        },
        /**
         * content/config for the placement and text of the description area
         */
        descriptionOptions: {
            type: Object,
            default: () => ({
                description: '',
                position: 'top'
            })
        },
        /**
         * config for the placement and label of the Copy button
         */
        buttonOptions: {
            type: Object,
            default: () => ({
                label: 'Copy',
                position: 'top'
            })
        },
        /**
         * config for snippet title and link
         */
        snippetConfig: {
            type: Object,
            default: () => ({
                title: null,
                link: {
                    icon: null,
                    text: null
                }
            })
        },
        /**
         * optional url override for help article
         */
        helpArticleUrl: {
            type: String,
            default: 'https://support.pendo.io/hc/en-us/articles/360039616892'
        }
    },
    computed: {
        buttonLabel () {
            return get(this.buttonOptions, 'label', '');
        },
        description () {
            return get(this.descriptionOptions, 'description', '');
        },
        isTopButton () {
            return get(this, 'buttonOptions.position') === 'top';
        },
        isRightButton () {
            return get(this, 'buttonOptions.position') === 'right';
        },
        isLeftDescription () {
            return get(this, 'descriptionOptions.position') === 'left';
        },
        isTopDescription () {
            return get(this, 'descriptionOptions.position') === 'top';
        },
        highlightedCode () {
            const result = hljs.highlight(this.codeToCopy, {
                language: this.language,
                ignoreIllegals: true
            });

            return result.value;
        }
    },
    methods: {
        openJwtHelpArticle () {
            window.open(this.helpArticleUrl, '_blank');
        }
    }
};
</script>

<style lang="scss">
.pendo-copy-code-block__header {
    display: flex;
    justify-content: space-between;
}

.pendo-copy-code-block__body {
    display: flex;
    justify-content: space-between;
}

.pendo-copy-code-block__pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.pendo-copy-code-block__helper-text {
    display: flex;
    align-items: center;
}

/* stylelint-disable scale-unlimited/declaration-strict-value */
/*
github.com style (c) Vasily Polovnyov <vast@whiteants.net>
Note: This theme is from Highlight.js 10.5.0, the GitHub theme from 11.4.0 is styled differently
*/
.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #333;
    background: #f8f8f8;
}
.hljs-comment,
.hljs-quote {
    color: #998;
    font-style: italic;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: #333;
    font-weight: bold;
}
.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
    color: #008080;
}
.hljs-string,
.hljs-doctag {
    color: #d14;
}
.hljs-title,
.hljs-section,
.hljs-selector-id {
    color: #900;
    font-weight: bold;
}
.hljs-subst {
    font-weight: normal;
}
.hljs-type,
.hljs-class .hljs-title {
    color: #458;
    font-weight: bold;
}
.hljs-tag,
.hljs-name,
.hljs-attribute {
    color: #000080;
    font-weight: normal;
}
.hljs-regexp,
.hljs-link {
    color: #009926;
}
.hljs-symbol,
.hljs-bullet {
    color: #990073;
}
.hljs-built_in,
.hljs-builtin-name {
    color: #0086b3;
}
.hljs-meta {
    color: #999;
    font-weight: bold;
}
.hljs-deletion {
    background: #fdd;
}
.hljs-addition {
    background: #dfd;
}
.hljs-emphasis {
    font-style: italic;
}
.hljs-strong {
    font-weight: bold;
}
</style>
