<template>
    <transition name="fade-in-linear">
        <div
            v-show="visible"
            v-pendo-analytics="{ type: 'components:alert', value: type }"
            class="pendo-alert"
            :class="[
                `pendo-alert--${type}`,
                {
                    'is-center': center,
                    'is-inline': inline,
                    'is-closable': closable
                }
            ]"
            role="alert">
            <pendo-icon
                v-if="showIcon"
                class="pendo-alert__icon"
                :type="icon"
                :aria-label="type" />
            <div class="pendo-alert__content">
                <span
                    v-if="hasTitle"
                    class="pendo-alert__title"
                    :class="{
                        'is-bold': hasDescription
                    }">
                    <slot name="title">{{ title }}</slot>
                </span>
                <p
                    v-if="hasDescription"
                    class="pendo-alert__description">
                    <slot>{{ description }}</slot>
                </p>
            </div>
            <button
                v-if="closable"
                class="pendo-alert__closebtn"
                aria-label="Close"
                @click="close">
                <pendo-icon
                    type="x"
                    role="img"
                    :size="16"
                    aria-hidden="true" />
            </button>
        </div>
    </transition>
</template>

<script>
import { PendoAnalytics } from '@/directives/analytics/pendo-analytics';
import PendoIcon from '@/components/icon/pendo-icon.vue';

export default {
    name: 'PendoAlert',
    components: {
        PendoIcon
    },
    directives: {
        PendoAnalytics
    },
    props: {
        /**
         * Type of alert
         * @values error, info, success, warning
         */
        type: {
            default: 'info',
            type: String,
            validator: (value) => ['error', 'info', 'success', 'warning'].includes(value)
        },
        /**
         * Main alert text. Bold when description is also present
         */
        title: {
            default: '',
            type: String
        },
        /**
         * Descriptive text. Can also be passed using the default slot
         */
        description: {
            default: '',
            type: String
        },
        /**
         * When content is less than the width of the alert, this option will center the text
         */
        center: {
            default: false,
            type: Boolean
        },
        /**
         * Show/hide the main icon
         */
        showIcon: {
            default: true,
            type: Boolean
        },
        /**
         * Adds an 'x' icon so alert can be dimissed
         */
        closable: {
            default: false,
            type: Boolean
        },
        /**
         * Title and description will appear inline as opposed to stacked
         */
        inline: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            visible: true
        };
    },
    computed: {
        icon () {
            return {
                success: 'check-circle-2-solid',
                info: 'info-solid',
                warning: 'alert-circle-solid',
                error: 'minus-circle-solid'
            }[this.type];
        },
        hasTitle () {
            return this.title || this.$slots.title;
        },
        hasDescription () {
            return this.description || this.$slots.default;
        }
    },
    methods: {
        close () {
            /**
             * Emitted on close of alert
             *
             * @event close
             */
            this.visible = false;
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-alert) {
    @include font-base;
    // override line height here since text wraps on smaller screens
    line-height: 1.5;
    width: 100%;
    padding: $alert-padding;
    margin: 0;
    box-sizing: border-box;
    border-radius: $alert-border-radius;
    position: relative;
    background-color: $color-white;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: $color-text-primary;
    border: 1px solid;

    @include element(description) {
        color: $color-text-primary;
    }

    & + & {
        // add some padding between repeat alerts
        margin-top: 16px;
    }

    @include element(content) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 16px 0 0;
    }

    @include element(icon) {
        & + .pendo-alert__content {
            padding: 0 16px;
        }
    }

    @include element(title) {
        font-size: $alert-title-font-size;

        @include is(bold) {
            font-weight: bold;
        }
    }

    & .pendo-alert__description {
        font-size: $alert-description-font-size;
        margin: 0;
    }

    @include element(closebtn) {
        @include button-reset;
        cursor: pointer;
        border-radius: 3px;
        @include focus-ring($style: 'base');

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }
    }

    @include is(center) {
        justify-content: center;

        @include element(content) {
            width: auto;
        }

        // when centered AND closable, content element gets margin-left: auto
        @include is(closable) {
            @include element(content) {
                margin-left: auto;
            }

            @include element((icon, closebtn)) {
                margin-left: auto;
            }

            // if the content element is adjacent sibling of icon (meaning, icon is present)
            // remove margin from icon
            @include element(icon) {
                & + .pendo-alert__content {
                    margin-left: 0;
                }
            }
        }
    }

    @include is(inline) {
        @include element(content) {
            display: block;
        }
        @include element(title) {
            display: inline;
        }

        @include element(description) {
            display: inline;
            margin-left: 4px;
        }
    }

    @include modifier(success) {
        background-color: $alert-success-background-color;
        border-color: $alert-success-border-color;

        @include element(closebtn) {
            color: $alert-success-close-btn-color;
        }

        @include element(icon) {
            .pendo-icon__content {
                fill: $alert-success-icon-color;
                stroke: $alert-icon-stroke;
            }
        }
    }

    @include modifier(info) {
        background-color: $alert-info-background-color;
        border-color: $alert-info-border-color;

        @include element(closebtn) {
            color: $alert-info-border-color;
        }

        @include element(icon) {
            .pendo-icon__content {
                fill: $alert-info-icon-color;
                stroke: $alert-icon-stroke;
            }
        }
    }

    @include modifier(warning) {
        background-color: $alert-warning-background-color;
        border-color: $alert-warning-border-color;

        @include element(closebtn) {
            color: $alert-warning-close-btn-color;
        }

        @include element(icon) {
            .pendo-icon__content {
                fill: $alert-warning-icon-color;
                stroke: $alert-icon-stroke;
            }
        }
    }

    @include modifier(error) {
        background-color: $alert-error-background-color;
        border-color: $alert-error-border-color;

        @include element(closebtn) {
            color: $alert-error-border-color;
        }

        @include element(icon) {
            .pendo-icon__content {
                fill: $alert-error-icon-color;
                stroke: $alert-icon-stroke;
            }
        }
    }
}
</style>
