<template>
    <div class="repeating-regexp-input">
        <div class="repeating-regexp-input__header">
            <slot name="header" />
            <pendo-tag
                v-if="!readOnly"
                :round="true"
                :subtle="true"
                label="Internal Use Only"
                color="#7639e0"
                size="small" />
        </div>
        <slot name="description" />
        <pendo-collapse
            v-model="localActivePanel"
            :panels="localPanels"
            theme="app"
            :accordion="true">
            <template
                v-for="(panel, index) in localPanels"
                #[panel.name]>
                <div
                    :key="index"
                    class="repeating-regexp-input__fields">
                    <div class="repeating-regexp-input__fields-rootDomain">
                        <p class="repeating-regexp-input__fields-title">
                            Root Domain
                        </p>
                        <pendo-input
                            :ref="`regexp${index}`"
                            :value="regexps[index].rootDomain"
                            :autofocus="index === localPanels.length - 1"
                            :invalid="!domainValidator(regexps[index].rootDomain)"
                            :disabled="readOnly"
                            class="repeating-regexp-input__fields-input"
                            @input="onInputChange({ index, value: $event }, 'rootDomain')" />
                    </div>
                    <div class="repeating-regexp-input__fields-subDomainRegExp">
                        <p class="repeating-regexp-input__fields-title">
                            Sub Domain Regular Expression
                        </p>
                        <pendo-input
                            :ref="`regexp${index}`"
                            :value="regexps[index].subDomainRegExp"
                            :autofocus="index === localPanels.length - 1"
                            :invalid="!regexValidator(regexps[index].subDomainRegExp, regexps[index].rootDomain, true)"
                            :disabled="readOnly"
                            class="repeating-regexp-input__fields-input"
                            @input="onInputChange({ index, value: $event }, 'subDomainRegExp')" />
                    </div>
                    <div class="repeating-regexp-input__fields-pathRegexp">
                        <p class="repeating-regexp-input__fields-title">
                            Path Domain Regular Expression
                        </p>
                        <pendo-input
                            :ref="`regexp${index}`"
                            :value="regexps[index].pathRegExp"
                            :autofocus="index === localPanels.length - 1"
                            :invalid="!regexValidator(regexps[index].pathRegExp, regexps[index].rootDomain, false)"
                            :disabled="readOnly"
                            class="repeating-regexp-input__fields-input"
                            @input="onInputChange({ index, value: $event }, 'pathRegExp')" />
                    </div>
                    <div
                        :key="panel.name"
                        class="regexp-remove-container">
                        <pendo-button
                            v-if="!readOnly"
                            theme="app"
                            :disabled="disableDelete"
                            type="secondary"
                            class="regexp-remove-button"
                            label="Delete Selective URL"
                            @click="removeField(index)" />
                    </div>
                </div>
            </template>
        </pendo-collapse>
        <pendo-button
            v-if="!readOnly"
            theme="app"
            type="link"
            prefix-icon="plus"
            class="field-input-add"
            label="Add Another"
            @click="addField" />
    </div>
</template>
<script>
import Vue from 'vue';
import PendoInput from '@/components/input/pendo-input';
import PendoButton from '@/components/button/pendo-button';
import PendoCollapse from '@/components/collapse/pendo-collapse.vue';
import PendoTag from '@/components/tag/pendo-tag';

export default {
    name: 'PendoRepeatingRegexpInput',
    components: {
        PendoInput,
        PendoCollapse,
        PendoButton,
        PendoTag
    },
    props: {
        /**
         * Default items if any
         */
        value: {
            type: Array,
            default: () => [
                {
                    subDomainRegExp: '',
                    pathRegExp: '',
                    rootDomain: ''
                }
            ]
        },
        /**
         * Function to be used to validate the rootDomain item
         */
        domainValidator: {
            type: Function,
            required: true
        },
        /**
         * Function to be used to validate the subDomainRegExp and pathRegExp items
         */
        regexValidator: {
            type: Function,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        panels: {
            type: Array,
            default: () => [
                {
                    name: 'regexp1',
                    title: 'Selective URL 1'
                }
            ]
        },
        activePanel: {
            type: Array,
            default: () => ['regexp1']
        }
    },
    data () {
        return {
            regexps: [
                {
                    subDomainRegExp: '',
                    pathRegExp: '',
                    rootDomain: ''
                }
            ],
            localPanels: [
                {
                    name: 'regexp1',
                    title: 'Selective URL 1'
                }
            ],
            localActivePanel: ['regexp1']
        };
    },
    computed: {
        disableDelete () {
            return (
                this.regexps.length <= 1 &&
                !Object.keys(this.regexps[0]).filter((key) => this.regexps[0][key] !== '').length
            );
        }
    },
    watch: {
        regexps (value) {
            /**
             * Updates value for parent consumption
             */
            this.$emit('input', value);
        },
        value (value) {
            /**
             * Makes sure local regexps stay in sync with passed in prop
             */
            this.regexps = value;
        },
        panels (value) {
            this.localPanels = value;
        },
        activePanel (value) {
            this.localActivePanel = value;
        }
    },
    created () {
        this.regexps = this.value;
        this.localPanels = this.panels;
        this.localActivePanel = this.activePanel;
    },
    methods: {
        removeField (i) {
            if (this.regexps.length === 1) {
                this.regexps = [
                    {
                        subDomainRegExp: '',
                        pathRegExp: '',
                        rootDomain: ''
                    }
                ];
            } else {
                this.localPanels = this.localPanels.slice(0, i).concat(this.localPanels.slice(i + 1));
                this.localPanels = this.updatePanelNames(this.localPanels);
                this.regexps = this.regexps.slice(0, i).concat(this.regexps.slice(i + 1));
                this.$emit('updatePanel', this.localPanels);
                this.localActivePanel = [this.localPanels[i < this.localPanels.length ? i : i - 1].name];
                this.$emit('updateActivePanel', this.localActivePanel);
            }
        },
        onInputChange (event, objKey) {
            const objToUpdate = this.regexps[event.index];
            objToUpdate[objKey] = event.value;
            Vue.set(this.regexps, event.index, objToUpdate);
        },
        addField () {
            this.regexps.push({
                subDomainRegExp: '',
                pathRegExp: '',
                rootDomain: ''
            });
            this.localPanels.push({
                name: `regexp${this.localPanels.length + 1}`,
                title: `Selective URL ${this.localPanels.length + 1}`
            });
            this.$nextTick(() => {
                this.$refs[`regexp${this.regexps.length - 1}`][0].focus();
            });
            this.localActivePanel = `regexp${this.localPanels.length}`;
        },
        updatePanelNames (panelsToUpdate) {
            const tempPaneArr = [];
            for (let index = 0; index < panelsToUpdate.length; index++) {
                tempPaneArr.push({
                    name: `regexp${index + 1}`,
                    title: `Selective URL ${index + 1}`
                });
            }

            return tempPaneArr;
        }
    }
};
</script>

<style lang="scss" scoped>
.repeating-regexp-input {
    &__header {
        font-weight: bold;
    }
    &__fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        gap: 5px;
        row-gap: 5px;
        &-subDomain {
            grid-column: 2 / 2;
            grid-row: 1;
        }
        &-rootDomain {
            grid-column: 1 / 2;
            grid-row: 1;
        }
        &-pathRegexp {
            grid-column: 1 / 2;
            grid-row: 2;
        }
        &-title {
            margin-top: 10px;
            font-size: 14px;
            font-weight: bold;
        }
        .regexp-remove-container {
            grid-column: 2 / 2;
            grid-row: 2;
            align-self: end;
        }
    }
}

.repeating-regexp-input ::v-deep .pendo-collapse-item {
    border: 1px solid #dadce5;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 10px;
    &__header {
        background: #f8f8f9;
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
    }
    &__wrap {
        background: #f8f8f9;
    }
    &__title {
        font-size: 14px;
        font-weight: bold;
    }
    &__content {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }
}
</style>
