<template>
    <div class="pendo-input-password">
        <pendo-input
            :value="value"
            :type="showPassword ? 'text' : 'password'"
            :placeholder="placeholder"
            aria-label="password"
            @input="onInput"
            @focus="onFocus">
            <template #suffix>
                <pendo-button
                    class="pendo-input-password__visibility-toggle"
                    type="tertiary"
                    :prefix-icon="showPassword ? 'eye-off' : 'eye'"
                    :label="showPassword ? 'Hide' : 'Show'"
                    :aria-label="showPassword ? 'Hide password' : 'Show password'"
                    icon-size="12"
                    @click="toggleVisibility" />
            </template>
        </pendo-input>
        <div
            v-if="showPasswordStrength"
            :data-strength="passwordStrength"
            class="pendo-input-password__password-strength">
            <div class="password-strength__meter">
                <div class="password-strength__background" />
                <div class="password-strength__fill" />
                <div class="password-strength__meter-divider password-strength__meter-divider--25" />
                <div class="password-strength__meter-divider password-strength__meter-divider--50" />
                <div class="password-strength__meter-divider password-strength__meter-divider--75" />
            </div>
            <div
                v-show="passwordStrengthLabel"
                class="password-strength__label"
                aria-live="polite">
                <transition name="password-strength-label">
                    <div>
                        {{ passwordStrengthLabel }}
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { checkPasswordStrength } from '@/components/input-password/password-strength';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoInput from '@/components/input/pendo-input.vue';

export default {
    name: 'PendoInputPassword',
    components: {
        PendoInput,
        PendoButton
    },
    props: {
        /**
         * bound value
         */
        value: {
            type: String,
            default: null
        },
        /**
         * placeholder for input or textarea
         */
        placeholder: {
            type: String,
            default: null
        },
        /**
         * shows password strength bar
         */
        showPasswordStrength: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            passwordStrength: null,
            showPassword: false
        };
    },
    computed: {
        passwordStrengthLabel () {
            if (!this.value) {
                return;
            }

            return {
                0: 'Very weak',
                1: 'Weak',
                2: 'Average',
                3: 'Strong',
                4: 'Very strong'
            }[this.passwordStrength];
        }
    },
    watch: {
        async value () {
            if (this.showPasswordStrength) {
                await this.checkPasswordStrength();
            }
        }
    },
    methods: {
        async checkPasswordStrength () {
            const { score } = await checkPasswordStrength(this.value);

            if (this.passwordStrength !== score) {
                this.passwordStrength = score;
                this.$emit('score', this.passwordStrength);
            }
        },
        toggleVisibility () {
            this.showPassword = !this.showPassword;
        },
        onFocus (event) {
            this.$emit('focus', event);
        },
        onInput (val) {
            this.$emit('input', val);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-input-password) {
    @include element(password-strength) {
        position: relative;
        width: 100%;
        height: 12px;
        align-items: flex-end;
        display: flex;

        .password-strength__meter {
            height: 4px;
            width: 100%;
        }

        &[data-strength='0'],
        &[data-strength='1'] {
            .password-strength__fill {
                width: 25%;
                background-color: $input-password-strength-1;
            }

            .password-strength__label {
                color: $input-password-strength-1;
            }
        }

        &[data-strength='2'] {
            .password-strength__fill {
                width: 50%;
                background-color: $input-password-strength-2-3;
            }

            .password-strength__label {
                color: $input-password-strength-2-3;
            }
        }

        &[data-strength='3'] {
            .password-strength__fill {
                width: 75%;
                background-color: $input-password-strength-2-3;
            }

            .password-strength__label {
                color: $input-password-strength-2-3;
            }
        }

        &[data-strength='4'] {
            .password-strength__fill {
                width: 100%;
                background-color: $input-password-strength-4;
            }

            .password-strength__label {
                color: $input-password-strength-4;
            }
        }

        .password-strength__background {
            height: 4px;
            position: absolute;
            left: 0;
            background-color: $input-password-strength-background;
            width: 100%;
        }

        .password-strength__fill {
            transition: background-color 150ms;
            height: 4px;
            position: absolute;
            left: 0;
        }

        .password-strength__meter-divider {
            height: 4px;
            width: 2px;
            position: absolute;
            background-color: $input-password-strength-divider;

            &--25 {
                left: 25%;
            }

            &--50 {
                left: 50%;
            }

            &--75 {
                left: 75%;
            }
        }

        .password-strength-label-enter-active,
        .password-strength-label-leave-active {
            transition: opacity 100ms;
        }

        .password-strength-label-enter,
        .password-strength-label-leave-to {
            opacity: 0;
        }

        .password-strength__label {
            position: absolute;
            line-height: 1;
            white-space: nowrap;
            font-size: 12px;
            right: 0;
            top: 12px;
            // matches padding top on pendo-form-item__error
            padding-top: 4px;
        }
    }

    @include element(visibility-toggle) {
        font-size: 12px;

        &:hover {
            text-decoration: none !important;
        }
    }
}
</style>
