<template>
    <div
        class="pendo-color-picker-presets"
        role="grid"
        :style="{
            gridTemplateColumns: `repeat(auto-fill, ${size}px)`
        }">
        <div
            class="pendo-color-picker-presets__row"
            role="row"
            @keydown="bindTravel">
            <color-picker-swatch
                v-for="(color, index) in presets"
                ref="color-preset"
                :key="color"
                role="gridcell"
                :tabindex="index === travel_currentIndex ? 0 : -1"
                :color="color"
                :value="value"
                :size="size"
                @click="onPresetSelect(color)"
                @keydown.enter="onPresetSelect(color)"
                @keydown.space="onPresetSelect(color)" />
        </div>
    </div>
</template>

<script>
import ColorPickerSwatch from '@/components/color-picker/common/color-picker-swatch';
import TravelMixin from '@/mixins/travel';

import { DEFAULT_PRESETS } from '@/components/color-picker/utils';
import { isValidHex } from '@/utils/color';

export default {
    name: 'PendoColorPickerPresets',
    components: {
        ColorPickerSwatch
    },
    mixins: [TravelMixin],
    $travel: {
        refName: 'color-preset'
    },
    props: {
        /**
         * Selected preset value
         */
        value: {
            type: String,
            default: null,
            validator: (value) => isValidHex(value)
        },
        /**
         * Array of preset colors to show
         */
        presets: {
            type: Array,
            default: () => DEFAULT_PRESETS
        },
        /**
         * Size of each preset
         */
        size: {
            type: Number,
            default: 16
        }
    },
    methods: {
        onPresetSelect (color) {
            /**
             * Emitted when a present selected. Triggered by click or enter keydown
             *
             * @event change
             * @property String hex value of selected preset
             */
            this.$emit('change', color);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-color-picker-presets) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, 16px);

    @include element(row) {
        display: contents;
    }

    .pendo-color-picker-swatch {
        cursor: pointer;
    }
}
</style>
