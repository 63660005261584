<template>
    <div class="alert-settings">
        <pendo-modal
            data-cy="alert-settings-modal"
            :visible="visible"
            title="Alert Settings"
            height="auto"
            @close="closeModal">
            <template #body>
                <pendo-loading-indicator
                    v-if="isLoading"
                    size="medium"
                    text="Loading..." />
                <div v-else>
                    <b class="display-error-threshold-label">Display Error Threshold</b>
                    <p class="display-error-threshold-description">
                        The amount of time allowed for a guide step to load or search for an element before triggering a
                        display error. (Maximum 60 seconds)
                    </p>
                    <pendo-input-number
                        v-model="timeoutThreshold"
                        data-cy="timeout-threshold-input"
                        width="small"
                        :labels="{ suffix: 'seconds' }"
                        :min="1"
                        :max="60" />
                </div>
            </template>
            <template #footer>
                <pendo-button
                    data-cy="alert-settings-cancel"
                    theme="app"
                    type="secondary"
                    label="Cancel"
                    @click="closeModal" />
                <pendo-button
                    data-cy="alert-settings-save"
                    theme="app"
                    type="primary"
                    label="Save Changes"
                    :loading="isSaving"
                    :disabled="isLoading"
                    @click="onSave" />
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoInputNumber from '@/components/input-number/pendo-input-number';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';
import PendoModal from '@/components/modal/pendo-modal';

export default {
    name: 'GuideAlertSettingsModal',
    components: {
        PendoButton,
        PendoInputNumber,
        PendoLoadingIndicator,
        PendoModal
    },
    props: {
        guideSeenTimeout: {
            type: Number,
            default: 10000
        },
        guideAlertsAggStatus: {
            type: String,
            required: true
        },
        isSaving: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            timeoutThreshold: 10
        };
    },
    computed: {
        isLoading () {
            return this.guideAlertsAggStatus === 'loading';
        }
    },
    watch: {
        visible (val) {
            if (val) {
                this.timeoutThreshold = this.guideSeenTimeout / 1000;
            }
        }
    },
    created () {
        this.timeoutThreshold = this.guideSeenTimeout / 1000;
    },
    methods: {
        onSave () {
            this.$emit('update-guide-seen-timeout', this.timeoutThreshold * 1000);
        },
        closeModal () {
            this.$emit('close');
        }
    }
};
</script>

<style scoped lang="scss">
.display-error-threshold-label {
    font-weight: 600;
}
.display-error-threshold-description {
    margin: 10px 0 10px;
}
// "seconds" is too long of a suffix for normal pendo-input-number
::v-deep .pendo-input-number__suffix-label {
    padding-right: 8px;
}
</style>
