<template>
    <div class="pendo-table__pagination-controls">
        <span>Items per page:</span>
        <pendo-multiselect
            class="pendo-table__page-size-select"
            :value="$table.pageSize"
            :options="pageSizeOptions"
            :allow-empty="false"
            :min-trigger-width="50"
            @input="onPageSizeChange" />
        <span> {{ currentRange.start }}-{{ currentRange.end }} of {{ $table.allRowsCount }} </span>
        <div>
            <pendo-icon-button
                icon="chevrons-left"
                @click="goToFirstPage" />
            <pendo-icon-button
                icon="chevron-left"
                @click="goToPreviousPage" />
            <pendo-icon-button
                icon="chevron-right"
                @click="goToNextPage" />
            <pendo-icon-button
                icon="chevrons-right"
                @click="goToLastPage" />
        </div>
    </div>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoIconButton from '@/components/icon-button/pendo-icon-button';
import { pageSizeOptions } from '@/components/table/utils/constants';

export default {
    name: 'TablePaginationControls',
    components: {
        PendoMultiselect,
        PendoIconButton
    },
    inject: ['$table'],
    data () {
        return {
            pageSizeOptions
        };
    },
    computed: {
        currentRange () {
            return {
                start: (this.$table.pageNumber - 1) * this.$table.pageSize + 1,
                end: Math.min(this.$table.pageNumber * this.$table.pageSize, this.$table.allRowsCount)
            };
        },
        lastPage () {
            return Math.ceil(this.$table.allRowsCount / this.$table.pageSize);
        }
    },
    methods: {
        onPageSizeChange (value) {
            this.$emit('update-page-size', value);
            this.goToFirstPage();
        },
        goToFirstPage () {
            this.$emit('update-page-number', 1);
        },
        goToPreviousPage () {
            if (this.$table.pageNumber > 1) {
                this.$emit('update-page-number', this.$table.pageNumber - 1);
            }
        },
        goToNextPage () {
            if (this.$table.pageNumber < this.lastPage) {
                this.$emit('update-page-number', this.$table.pageNumber + 1);
            }
        },
        goToLastPage () {
            this.$emit('update-page-number', this.lastPage);
        }
    }
};
</script>
