<template>
    <div
        :id="`${name}`"
        class="pendo-collapse-item"
        :class="[
            {
                [`pendo-collapse-item--${type}`]: type,
                'pendo-collapse-item--collapsible': collapsible,
                'is-expanded': isExpanded,
                'is-always-open': !allowCollapse,
                'is-disabled': disabled
            }
        ]">
        <button
            :id="`pendo-collapse-head-${id}`"
            class="pendo-collapse-item__header"
            type="button"
            :tabindex="allowCollapse ? 0 : -1"
            :disabled="disabled"
            :class="{
                'is-focused': isFocused,
                'is-expanded': isExpanded
            }"
            :aria-controls="`pendo-collapse-content-${id}`"
            :aria-expanded="`${isExpanded}`"
            @click="handleHeaderClick"
            @keydown.space.enter.stop.prevent="handleEnterKeydown"
            @focus="handleFocus"
            @blur="isFocused = false">
            <card-trigger
                v-if="type === 'card'"
                :title="titleContent"
                :allow-collapse="allowCollapse">
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope">
                    <slot
                        :name="slot"
                        v-bind="scope" />
                </template>
            </card-trigger>
            <details-trigger
                v-if="type === 'details'"
                :expanded="isExpanded"
                :title="titleContent">
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope">
                    <slot
                        :name="slot"
                        v-bind="scope" />
                </template>
            </details-trigger>
            <template v-if="!type">
                <!-- @slot alternative to using the `prefixIcon` prop -->
                <slot name="prefix">
                    <pendo-icon
                        v-if="showPrefixIcon"
                        v-bind="panelPrefixIcon"
                        class="pendo-collapse-item__prefix-icon" />
                </slot>
                <!-- @slot alternative to using the `title` prop -->
                <slot name="title">
                    <div
                        v-if="!type"
                        class="pendo-collapse-item__title">
                        {{ titleContent }}
                    </div>
                </slot>
                <!-- @slot alternative to using the `suffixIcon` prop -->
                <slot name="suffix">
                    <pendo-icon
                        v-if="showSuffixIcon"
                        v-bind="panelSuffixIcon"
                        class="pendo-collapse-item__suffix-icon" />
                </slot>
            </template>
        </button>
        <pendo-collapse-transition>
            <div
                v-show="isExpanded"
                :id="`pendo-collapse-content-${id}`"
                class="pendo-collapse-item__wrap"
                role="region"
                :aria-hidden="!isExpanded"
                :aria-labelledby="`pendo-collapse-head-${id}`">
                <div class="pendo-collapse-item__content">
                    <!-- @slot used to pass content when expanded to collapse item -->
                    <slot />
                </div>
            </div>
        </pendo-collapse-transition>
    </div>
</template>
<script>
import uniqueId from 'lodash/uniqueId';
import PendoIcon from '@/components/icon/pendo-icon';
import CardTrigger from '@/components/collapse/trigger-types/card-trigger';
import DetailsTrigger from '@/components/collapse/trigger-types/details-trigger';
import PendoCollapseTransition from '@/utils/pendo-collapse-transition';

export default {
    name: 'CollapseItem',
    components: {
        CardTrigger,
        PendoCollapseTransition,
        DetailsTrigger,
        PendoIcon
    },
    inject: ['$collapse'],
    props: {
        /**
         * When false, will not allow item to collapse or open
         */
        collapsible: {
            type: Boolean,
            default: true
        },
        /**
         * Set id of collapse item
         */
        name: {
            type: [String, Number],
            default () {
                return this._uid;
            }
        },
        /**
         * Text to display inside of the collapse item
         */
        title: {
            type: String,
            default: null
        },
        /**
         * When item is expanded, text will display in place of title
         */
        activeTitle: {
            type: String,
            default: null
        },
        /**
         * Places icon to the left of title prop
         */
        prefixIcon: {
            type: Object,
            default: null
        },
        /**
         * Places icon to the right of title prop
         */
        suffixIcon: {
            type: [Object, Boolean],
            default: null
        },
        /**
         * Applies disabled styles and prevents user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * When true, expands collapse item
         */
        expanded: {
            type: Boolean,
            default: false
        },
        /**
         * Type of collapse item
         * @values card, details
         */
        type: {
            type: String,
            default: null,
            validator: (type) => ['card', 'details'].includes(type)
        }
    },
    data () {
        return {
            isFocused: false,
            isClick: false,
            id: uniqueId()
        };
    },
    computed: {
        panelPrefixIcon () {
            return {
                ...this.$collapse.panelHeaderProps.prefixIcon,
                ...this.prefixIcon
            };
        },
        panelSuffixIcon () {
            return {
                ...(this.suffixIcon !== false &&
                    this.allowCollapse && {
                        'type': 'chevron-down',
                        'size': '14px',
                        'stroke': '#6a6c75',
                        'aria-hidden': true
                    }),
                ...this.$collapse.panelHeaderProps.suffixIcon,
                ...this.suffixIcon
            };
        },
        titleContent () {
            if (this.isExpanded && this.activeTitle) {
                return this.activeTitle;
            }

            return this.title;
        },
        isExpanded () {
            return this.$collapse.model.indexOf(this.name) > -1;
        },
        allowCollapse () {
            return this.$collapse.forceAlwaysOpen && this.$collapse.forceAlwaysOpen.indexOf(this.name) === -1;
        },
        showPrefixIcon () {
            if (this.$slots.title) {
                return false;
            }

            return this.panelPrefixIcon && this.panelPrefixIcon.type;
        },
        showSuffixIcon () {
            if (!this.allowCollapse) {
                return false;
            }

            if (this.type) {
                return false;
            }

            return this.panelSuffixIcon && this.panelSuffixIcon.type;
        }
    },
    created () {
        if (this.expanded) {
            this.$collapse.handlePanelClick(this.name);
        }
    },
    methods: {
        handleFocus () {
            setTimeout(() => {
                if (!this.isClick) {
                    this.isFocused = true;
                } else {
                    this.isClick = false;
                }
            }, 50);
        },
        handleHeaderClick () {
            if (this.allowCollapse && !this.disabled && this.collapsible) {
                this.$collapse.handlePanelClick(this.name);
                this.isFocused = false;
                this.isClick = true;
            }

            this.$emit('click', this.name);
        },
        handleEnterKeydown () {
            if (this.allowCollapse && !this.disabled && this.collapsible) {
                this.$collapse.handlePanelClick(this.name);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-collapse-item) {
    @include element(header) {
        @include button-reset;
        height: $collapse-header-height;
        line-height: $collapse-header-line-height;
        background-color: $collapse-header-fill;
        color: $collapse-header-color;
        cursor: default;
        text-align: left;
        user-select: none;
        width: 100%;
        border-bottom: 1px solid $collapse-border-color;
        border-radius: 2px;
        font-size: $collapse-header-size;
        font-weight: 500;
        padding: 0 16px;
        display: grid;
        grid-gap: 16px;
        grid-auto-flow: column;
        align-items: center;
        justify-content: space-between;
        @include focus-ring(
            $style: 'base',
            $offset: -2px,
            $transitions: (
                border-bottom-color 300ms
            )
        );

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }

        @include element(suffix-icon) {
            transition: transform 300ms;
        }

        @include is(expanded) {
            border-bottom-color: transparent;

            @include element(suffix-icon) {
                transform: rotate(180deg);
            }
        }
    }

    @include modifier(collapsible) {
        @include element(header) {
            cursor: pointer;
        }
    }

    @include is(always-open) {
        @include element(header) {
            cursor: default;
        }
    }

    @include is(disabled) {
        color: $disabled-color;
        opacity: 0.6;
        @include element(header) {
            cursor: not-allowed;
        }
    }

    @include element(wrap) {
        will-change: height;
        background-color: $collapse-content-fill;
        overflow: hidden;
        box-sizing: border-box;
    }

    @include element(content) {
        padding-bottom: 24px;
        font-size: $collapse-content-size;
        color: $collapse-content-color;
        line-height: 1.4;
    }
}
</style>
