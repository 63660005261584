<template>
    <div
        class="pendo-responsive"
        :style="measurableStyles"
        v-on="$listeners">
        <div
            v-if="aspectStyle"
            :style="aspectStyle"
            class="pendo-responsive__sizer" />
        <div class="pendo-responsive__content">
            <slot />
        </div>
    </div>
</template>

<script>
import { convertToUnit } from '@/utils/utils';

export default {
    name: 'PendoResponsive',
    props: {
        aspectRatio: {
            type: [String, Number],
            default: null
        },
        height: {
            type: [Number, String],
            default: null
        },
        maxHeight: {
            type: [Number, String],
            default: null
        },
        maxWidth: {
            type: [Number, String],
            default: null
        },
        minHeight: {
            type: [Number, String],
            default: null
        },
        minWidth: {
            type: [Number, String],
            default: null
        },
        width: {
            type: [Number, String],
            default: null
        }
    },
    computed: {
        measurableStyles () {
            const styles = {};

            const height = convertToUnit(this.height);
            const minHeight = convertToUnit(this.minHeight);
            const minWidth = convertToUnit(this.minWidth);
            const maxHeight = convertToUnit(this.maxHeight);
            const maxWidth = convertToUnit(this.maxWidth);
            const width = convertToUnit(this.width);

            if (height) {
                styles.height = height;
            }
            if (minHeight) {
                styles.minHeight = minHeight;
            }
            if (minWidth) {
                styles.minWidth = minWidth;
            }
            if (maxHeight) {
                styles.maxHeight = maxHeight;
            }
            if (maxWidth) {
                styles.maxWidth = maxWidth;
            }
            if (width) {
                styles.width = width;
            }

            return styles;
        },
        computedAspectRatio () {
            return Number(this.aspectRatio);
        },
        aspectStyle () {
            return this.computedAspectRatio ? { paddingBottom: `${(1 / this.computedAspectRatio) * 100}%` } : undefined;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-responsive) {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    display: flex;

    @include element(content) {
        flex: 1 0 0px;
    }

    @include element(sizer) {
        transition: padding-bottom 150ms cubic-bezier(0.25, 0.8, 0.5, 1);
        flex: 0 0 0px;
    }
}
</style>
