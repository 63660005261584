<template>
    <div
        class="pendo-empty-state"
        :style="{ 'background-color': backgroundColor }">
        <div class="pendo-empty-state__inner">
            <div
                v-if="icon || !!$slots.icon"
                class="pendo-empty-state__icon">
                <!-- @slot alternative to `icon` prop -->
                <slot name="icon">
                    <pendo-icon
                        v-if="icon"
                        v-bind="icon" />
                </slot>
            </div>
            <div
                v-if="title || !!$slots.title"
                class="pendo-empty-state__title">
                <!-- @slot alternative to `title` prop -->
                <slot name="title">
                    {{ title }}
                </slot>
            </div>
            <div
                v-if="description || !!$slots.description"
                class="pendo-empty-state__description">
                <!-- @slot alternative to `description` prop -->
                <slot name="description">
                    {{ description }}
                </slot>
            </div>
            <!-- @slot actions slot, used to passing any actions (if needed) -->
            <slot
                v-if="!!$slots.actions"
                name="actions" />
        </div>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon.vue';

export default {
    name: 'PendoEmptyState',
    components: {
        PendoIcon
    },
    props: {
        /**
         * pendo-icon props for main icon
         */
        icon: {
            type: Object,
            default: null
        },
        /**
         * main text
         */
        title: {
            type: String,
            default: null
        },
        /**
         * secondary text
         */
        description: {
            type: String,
            default: null
        },
        /**
         * background color of empty state
         */
        backgroundColor: {
            type: String,
            default: '#FFF'
        }
    }
};
</script>

<style lang="scss">
.pendo-empty-state {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    @include font-family;

    &__inner {
        max-width: 66%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    &__icon {
        display: flex;
        margin-bottom: 8px;
        justify-content: center;
        color: $color-gray-100;
    }

    &__title {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 8px;
        color: $color-text-primary;
    }

    &__description {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 8px;
        color: $color-text-primary;
    }
}
</style>
