<template>
    <div
        class="pendo-status-overlay"
        :class="{
            'is-loading': state === LOADING_STATES.LOADING,
            'is-empty': state === LOADING_STATES.EMPTY,
            'is-rejected': state === LOADING_STATES.REJECTED
        }">
        <!-- loading -->
        <pendo-loading-indicator
            v-if="state === LOADING_STATES.LOADING"
            class="pendo-status-overlay__loading-indicator"
            :skeleton-props="{
                height: 24
            }"
            :spinner-props="{
                size: config.loadingSpinnerSize,
                background: config.loadingSpinnerBackground,
                color: config.loadingSpinnerColor
            }"
            :prevent-relative-parent="true"
            :loading="true"
            :type="config.loadingType"
            :text="config.loadingText" />
        <span v-if="config.loadingSubtext">
            {{ config.loadingSubtext }}
        </span>
        <!-- empty -->
        <pendo-empty-state
            v-if="state === LOADING_STATES.EMPTY"
            :icon="{
                type: config.emptyIconType,
                size: config.emptyIconSize,
                stroke: config.emptyIconColor
            }"
            :title="config.emptyText"
            :description="config.emptySubtext">
            <template #icon>
                <slot name="emptyIcon" />
            </template>
            <template #actions>
                <slot name="emptyActions" />
            </template>
        </pendo-empty-state>
        <!-- error -->
        <pendo-empty-state
            v-if="state === LOADING_STATES.REJECTED"
            :icon="{
                type: config.errorIconType,
                size: config.errorIconSize,
                stroke: config.errorIconColor
            }"
            :title="config.errorText"
            :description="config.errorSubtext">
            <template #actions>
                <slot name="errorActions" />
            </template>
        </pendo-empty-state>
        <!-- resolved -->
        <slot />
    </div>
</template>

<script>
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator.vue';
import PendoEmptyState from '@/components/empty-state/pendo-empty-state.vue';

const defaults = {
    // empty
    emptyIconColor: '#2A2C35',
    emptyIconSize: 36,
    emptyIconType: 'alert-circle',
    emptySubtext: 'Data will appear as usage occurs. Consider adjusting any applied filters.',
    emptyText: 'No Data Available',
    // error
    errorIconColor: '#DB1111',
    errorIconSize: 36,
    errorIconType: 'minus-circle',
    errorSubtext:
        'Looks like something went wrong. Try reloading the page or contacting support if this issue continues.',
    errorText: 'Loading Error',
    // loading
    loadingSubtext: null,
    loadingText: null,
    loadingType: 'material',
    loadingSpinnerSize: 36,
    loadingSpinnerBackground: '#FFFFFF',
    loadingSpinnerColor: undefined
};

const LOADING_STATES = {
    LOADING: 'loading',
    REJECTED: 'rejected',
    EMPTY: 'empty'
};

export default {
    name: 'PendoStatusOverlay',
    components: {
        PendoLoadingIndicator,
        PendoEmptyState
    },
    props: {
        /**
         * One of `loading`, `rejected`, `empty`. Otherwise, overlay renders nothing.
         */
        state: {
            type: String,
            default: null
        },
        /**
         * Optional configuration inputs: [props](https://github.com/pendo-io/components/blob/master/src/composites/status-overlay/pendo-status-overlay.vue#L65)
         */
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            LOADING_STATES
        };
    },
    computed: {
        config () {
            return {
                ...defaults,
                ...this.options
            };
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-status-overlay) {
    position: relative;
    min-height: inherit;
    height: inherit;

    @include is((empty, rejected)) {
        .pendo-empty-state {
            z-index: 1;
        }
    }

    @include is(rejected) {
        .pendo-empty-state {
            height: inherit;
            min-height: inherit;
            display: flex;
        }
    }

    @include element(loading-indicator) {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .pendo-loading.pendo-loading-overlay {
        position: absolute !important;
        height: 100%;
        width: 100%;
    }
}
</style>
