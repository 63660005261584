<template>
    <div
        :style="bgStyle"
        class="color-picker-checkboard" />
</template>

<script>
export default {
    name: 'ColorPickerCheckboard',
    props: {
        size: {
            type: Number,
            default: 10
        }
    },
    computed: {
        bgStyle () {
            return {
                backgroundColor: '#c5c5c5',
                backgroundImage:
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23fff'%3E%3Crect x='50' width='50' height='50' /%3E%3Crect y='50' width='50' height='50' /%3E%3C/svg%3E\")",
                backgroundSize: `${this.size}px ${this.size}px`
            };
        }
    }
};
</script>
