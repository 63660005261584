<template>
    <div
        class="pendo-time-picker"
        aria-label="Time picker">
        <pendo-multiselect
            v-model="model"
            value-key="value"
            :allow-empty="false"
            :invalid="invalid"
            :placeholder="placeholder"
            :labels="labels"
            :disabled="disabled"
            :options="options">
            <template #placeholder>
                <div class="pendo-multiselect-option--icon">
                    <pendo-icon
                        type="clock"
                        size="14" />
                    <span class="pendo-multiselect-option__label">
                        {{ placeholder }}
                    </span>
                </div>
            </template>
            <template #selectedLabel="{ option }">
                <pendo-icon-option :option="option" />
            </template>
            <template #suffix="{ open, hover }">
                <div class="pendo-multiselect__suffix">
                    <pendo-button
                        v-if="model && (open || hover)"
                        type="tertiary"
                        icon="x-circle"
                        icon-size="14"
                        size="mini"
                        class="pendo-time-picker__clear"
                        @click.stop="clearModel" />
                </div>
            </template>
        </pendo-multiselect>
    </div>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import timeranges from '@/components/time-picker/time-range';

export default {
    name: 'PendoTimePicker',
    components: {
        PendoMultiselect,
        PendoIconOption,
        PendoIcon,
        PendoButton
    },
    props: {
        /**
         * bound value
         */
        value: {
            type: String,
            default: null
        },
        /**
         * placeholder text
         */
        placeholder: {
            type: String,
            default: 'Select Time'
        },
        /**
         * applies invalid styles to time-picker
         */
        invalid: {
            type: Boolean,
            default: false
        },
        /**
         * disables user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * pass multiple labels in the form of
         * `:labels="{ top: 'label text', bottom: 'label text', suffix: 'label text'}"`
         */
        labels: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        options () {
            return timeranges.map((time) => ({
                label: time,
                value: time,
                icon: {
                    type: 'clock'
                }
            }));
        },
        model: {
            get () {
                return this.options.find((option) => option.value === this.value);
            },
            set ({ value }) {
                /**
                 * Emitted when the binding value changes
                 *
                 * @event change
                 * @property Any Current model value
                 */
                this.$emit('change', value);
                /**
                 * Emitted when the binding value changes
                 *
                 * @event input
                 * @property Any Current model value
                 */
                this.$emit('input', value);
            }
        }
    },
    methods: {
        clearModel () {
            this.model = { value: null };
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-time-picker) {
    @include element(clear) {
        .pendo-icon {
            color: $color-gray-70;
        }

        &:hover {
            .pendo-icon {
                color: $color-gray-100;
            }
        }
    }
}
</style>
