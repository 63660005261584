<template>
    <span
        class="pendo-tag"
        :class="[
            `pendo-tag--${type || 'custom'}`,
            `pendo-tag--${size}`,
            {
                'is-round': round,
                'is-subtle': subtle,
                'has-prefix-icon': !!prefixIcon,
                'has-suffix-icon': !!suffixIcon
            }
        ]"
        :style="styleObject">
        <!-- @slot alternative to prop `prefixIcon` -->
        <slot name="prefixIcon">
            <pendo-icon
                v-if="prefixIcon"
                v-bind="formattedPrefix"
                class="pendo-tag__prefix-icon" />
        </slot>
        <span>
            <!-- @slot alternative to prop `label` -->
            <slot>
                {{ tagLabel }}
            </slot>
        </span>
        <!-- @slot alternative to prop `suffixIcon` -->
        <slot name="suffixIcon">
            <pendo-icon
                v-if="suffixIcon"
                v-bind="formattedSuffix"
                class="pendo-tag__suffix-icon" />
        </slot>
        <button
            v-if="closable"
            class="pendo-tag__close-button"
            aria-label="Remove tag"
            @click="handleClose">
            <pendo-icon
                size="14"
                type="x"
                aria-hidden="true" />
        </button>
    </span>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon.vue';
import { getContrastingColor, isValidHex, getColor } from '@/utils/color';

export default {
    name: 'PendoTag',
    components: {
        PendoIcon
    },
    props: {
        /**
         * type of tag
         * @values success, info, error, warning, filter, beta, sdk
         */
        type: {
            type: String,
            default: null,
            validator: (type) => ['success', 'info', 'error', 'warning', 'filter', 'beta', 'sdk'].includes(type)
        },
        /**
         * Hex value to use for tag color
         */
        color: {
            type: String,
            default: '#BABCC5',
            validator: (color) => isValidHex(color)
        },
        /**
         * Tag text
         */
        label: {
            type: String,
            default: null
        },
        /**
         * size of label
         * @values medium, small, mini
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['mini', 'small', 'medium'].includes(size)
        },
        /**
         * whether Tag can be removed
         */
        closable: {
            type: Boolean,
            default: false
        },
        /**
         * places pendo-icon on left of label
         */
        prefixIcon: {
            type: [String, Object],
            default: null
        },
        /**
         * places pendo-icon on right of label
         */
        suffixIcon: {
            type: [String, Object],
            default: null
        },
        /**
         * round corners of tag
         */
        round: {
            type: Boolean,
            default: false
        },
        /**
         * applies subtle colors
         */
        subtle: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formattedPrefix () {
            if (typeof this.prefixIcon === 'string') {
                return {
                    type: this.prefixIcon,
                    size: 14
                };
            }

            return this.prefixIcon;
        },
        formattedSuffix () {
            if (typeof this.suffixIcon === 'string') {
                return {
                    type: this.suffixIcon,
                    size: 14
                };
            }

            return this.suffixIcon;
        },
        styleObject () {
            if (this.type) {
                return {};
            }

            if (this.subtle) {
                const { hex: backgroundColor } = getColor({ hex: this.color, alpha: 0.15 });
                const subtleTextColor = this.color === '#BABCC5' ? '#6A6C75' : this.color;

                return {
                    backgroundColor,
                    borderColor: 'transparent',
                    color: subtleTextColor
                };
            }

            const textColor = getContrastingColor(this.color);

            return {
                backgroundColor: this.color,
                borderColor: this.color,
                color: textColor
            };
        },
        tagLabel () {
            if (this.type === 'beta') {
                return 'Beta';
            }

            return this.text || this.label;
        }
    },
    methods: {
        handleClose (event) {
            event.stopPropagation();
            /**
             * Emitted when user closes a tag
             *
             * @event close
             * @property {Event} $event - Dom Event
             */
            this.$emit('close', event);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-tag) {
    @include font-base;
    text-align: center;
    padding: $tag-padding-base;
    height: 26px;
    line-height: 24px;
    font-size: $tag-font-size;
    font-weight: $tag-font-weight;
    letter-spacing: $tag-font-letter-spacing;
    border-radius: $tag-border-radius;
    box-sizing: border-box;
    border: 1px solid;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 0.2s;

    & + & {
        margin-left: 8px;
    }

    @include element(prefix-icon) {
        margin-right: 8px;
    }

    @include element(suffix-icon) {
        margin-left: 8px;
    }

    @include element(close-button) {
        @include button-reset;
        cursor: pointer;
        border-radius: 3px;
        margin-left: 8px;

        @include focus-ring($style: 'base');

        &:focus-visible {
            @include focus-ring($style: 'focused');
        }
    }

    @include is(round) {
        border-radius: 50px;
        padding: 0 16px;
    }

    &.has-prefix-icon:not(.has-suffix-icon) {
        padding: 0 10px 0 8px;
    }

    &.has-suffix-icon:not(.has-prefix-icon) {
        padding: 0 8px 0 10px;
    }

    @include modifier(info) {
        background-color: $tag-info-background;
        border-color: $tag-info-border-color;
        color: $tag-info-text-color;

        @include element(close-button) {
            color: $tag-info-text-color;
        }

        @include is(subtle) {
            background-color: rgba($tag-info-background, 0.15);
            border-color: $tag-subtle-border;
            color: $tag-info-subtle-text;

            @include element(close-button) {
                color: $tag-info-subtle-text;
            }
        }
    }

    @include modifier(success) {
        background-color: $tag-success-background;
        border-color: $tag-success-border-color;
        color: $tag-success-text-color;

        @include element(close-button) {
            color: $tag-success-text-color;
        }

        @include is(subtle) {
            background-color: rgba($tag-success-subtle-text, 0.15);
            border-color: $tag-subtle-border;
            color: $tag-success-subtle-text;

            @include element(close-button) {
                color: $tag-success-subtle-text;
            }
        }
    }

    @include modifier(warning) {
        background-color: $tag-warning-background;
        border-color: $tag-warning-border-color;
        color: $tag-warning-text-color;

        @include element(close-button) {
            color: $tag-warning-text-color;
        }

        @include is(subtle) {
            background-color: rgba($tag-warning-subtle-text, 0.15);
            border-color: $tag-subtle-border;
            color: $tag-warning-subtle-text;

            @include element(close-button) {
                color: $tag-warning-subtle-text;
            }
        }
    }

    @include modifier(error) {
        background-color: $tag-error-background;
        border-color: $tag-error-border-color;
        color: $tag-error-text-color;

        @include element(close-button) {
            color: $tag-error-text-color;
        }

        @include is(subtle) {
            background-color: rgba($tag-error-subtle-text, 0.15);
            border-color: $tag-subtle-border;
            color: $tag-error-subtle-text;

            @include element(close-button) {
                color: $tag-error-subtle-text;
            }
        }
    }

    @include modifier(filter) {
        letter-spacing: unset;
        background-color: $tag-filter-background;
        border-color: $tag-filter-border-color;
        color: $tag-filter-color;
        font-weight: $tag-filter-font-weight;

        @include element(close-button) {
            color: $tag-filter-close-btn;
            transition: color 150ms;

            &:hover {
                color: $tag-filter-close-btn-hover;
            }
        }
    }

    @include modifier(beta) {
        background-color: $tag-beta-background;
        border-color: $tag-beta-border-color;
        color: $tag-beta-color;
        font-weight: 700;

        @include element(close-button) {
            color: $tag-beta-color;
        }

        @include is(subtle) {
            background-color: $tag-beta-subtle-background;
            border-color: rgba($tag-beta-border-color, 0.15);
            color: $tag-beta-subtle-text;

            @include element(close-button) {
                color: $tag-beta-background;
            }
        }
    }

    @include modifier(sdk) {
        color: $tag-sdk-color;
        background-color: $tag-sdk-background;
        border: 1px solid rgba($tag-sdk-border-color, 0.15);
        border-radius: 11px;
        width: 18px;
        height: 14px;
        text-align: center;
        font-size: 8px;
        letter-spacing: 0.06em;
        font-style: italic;
        font-weight: bold;
        line-height: 14px;

        @include is(subtle) {
            background-color: $tag-sdk-subtle-background;
        }
    }

    @include modifier(medium) {
        height: 26px;
        line-height: 24px;
    }

    @include modifier(small) {
        height: 20px;
        padding: 0 8px;
        line-height: 18px;

        @include element((prefix-icon, suffix-icon, close-button)) {
            svg {
                height: 12px;
                width: 12px;
            }
        }

        @include element(close-button) {
            margin-left: 4px;
        }

        @include is(round) {
            padding: 0 8px;
        }
    }

    @include modifier(mini) {
        height: 14px;
        padding: 0 6px;
        line-height: 12px;
        font-size: 8px;

        @include element((prefix-icon, suffix-icon, close-button)) {
            svg {
                height: 8px;
                width: 8px;
            }
        }

        @include element(close-button) {
            margin-left: 4px;
        }

        @include is(round) {
            padding: 0 6px;
        }
    }
}
</style>
