<template>
    <button
        ref="trigger"
        type="button"
        class="pendo-multiselect__trigger"
        aria-haspopup
        :aria-expanded="`${isOpen}`"
        data-toggle
        :disabled="disabled"
        :class="[
            `pendo-multiselect__trigger--${triggerSize}`,
            {
                'pendo-multiselect__trigger--searchable': isSearchable,
                'pendo-multiselect__trigger--full-width': isFullWidth,
                'is-active': isFocused || isOpen,
                'is-open': isOpen,
                'is-disabled': disabled,
                'is-error': invalid
            }
        ]"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @click="$emit('click', $event)"
        @keyup.esc="$emit('esc')"
        @keyup.space.prevent.self.stop="$emit('space', $event)"
        @keydown.down.prevent.self="$emit('down', $event)"
        @keydown.up.prevent.self="$emit('up', $event)"
        @keydown.enter="$emit('enter', $event)"
        @keydown.tab="$emit('tab', $event)"
        @mouseenter="onMouseenter"
        @mouseleave="onMouseleave">
        <slot />
        <slot
            name="suffix"
            :hover="hover"
            :open="isOpen">
            <div class="pendo-multiselect__suffix">
                <pendo-icon
                    v-if="!loading"
                    type="chevron-down"
                    size="14"
                    aria-hidden="true"
                    class="pendo-multiselect__caret"
                    :class="{
                        'is-active': isOpen
                    }" />
                <pendo-loading-indicator
                    v-if="loading"
                    :spinner-props="{
                        color: '#6a6c75'
                    }"
                    type="material"
                    size="small" />
            </div>
        </slot>
    </button>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import PendoIcon from '@/components/icon/pendo-icon';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';
import { setStyles } from '@/utils/dom';

export default {
    components: {
        PendoIcon,
        PendoLoadingIndicator
    },
    inheritAttrs: false,
    props: {
        disabled: {
            type: Boolean
        },
        invalid: {
            type: Boolean
        },
        isFocused: {
            type: Boolean
        },
        isFullWidth: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        },
        isSearchable: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        maxTriggerWidth: {
            type: [String, Number]
        },
        minTriggerWidth: {
            type: [String, Number]
        },
        triggerSize: {
            type: String
        }
    },
    data () {
        return {
            hover: false
        };
    },
    watch: {
        isOpen (isOpen) {
            if (isOpen) {
                this.$el.focus();
            }
        },
        minTriggerWidth () {
            setStyles(this.$refs.trigger, {
                minWidth: this.isFullWidth ? '100%' : this.minTriggerWidth
            });
        },
        maxTriggerWidth () {
            setStyles(this.$refs.trigger, { maxWidth: this.maxTriggerWidth });
        }
    },
    mounted () {
        setStyles(this.$refs.trigger, {
            minWidth: this.isFullWidth ? '100%' : this.minTriggerWidth,
            maxWidth: this.maxTriggerWidth
        });
    },
    methods: {
        onMouseenter (event) {
            this.hover = true;
            this.$emit('mouseenter', event);
        },
        onMouseleave (event) {
            this.hover = false;
            this.$emit('mouseleave', event);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(trigger) {
        box-sizing: border-box;
        padding: 0;
        position: relative;
        width: 100%;
        text-align: left;
        -webkit-appearance: none;
        border-radius: 3px;
        border: 1px solid $color-gray-40;
        background: $color-white;
        padding-left: 8px;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;

        @include font-base;
        @include focus-ring(
            $style: 'base',
            $transitions: (
                border-color 200ms
            )
        );

        &:hover,
        &.is-active,
        &.is-open {
            border: 1px solid $color-gray-100;
        }

        &:focus-visible {
            // by including the &:not selector
            // we can exclude the custom triggers where we don't want the focus ring
            &:not(.is-open) {
                @include focus-ring($style: 'focused');
            }
        }

        @include is(disabled) {
            background-color: $disabled-fill;
            color: $disabled-color;
            border-color: $disabled-border;
            cursor: not-allowed;
        }

        @include is(error) {
            border: 1px solid $border-color-invalid;
        }

        @include modifier(searchable) {
            @include is(open) {
                .pendo-multiselect__suffix::before {
                    opacity: 0.6;
                }
            }

            &.is-active {
                // input elements that require text input show focus-visible in same instances as focus
                @include focus-ring($style: 'focused');
            }
        }

        @include modifier(medium) {
            min-height: 36px;

            .pendo-multiselect__suffix {
                height: 34px;
                width: 34px;

                &::before {
                    height: 34px;
                    width: 8px;
                }
            }
        }

        @include modifier(small) {
            min-height: 32px;

            .pendo-multiselect__suffix {
                height: 30px;
                width: 30px;

                &::before {
                    height: 30px;
                    width: 7px;
                }
            }
        }

        @include modifier(mini) {
            min-height: 26px;

            .pendo-multiselect__suffix {
                height: 24px;
                width: 24px;

                &::before {
                    height: 24px;
                    width: 6px;
                }
            }

            .pendo-multiselect__placeholder,
            .pendo-multiselect__selected {
                font-size: 12px;
            }
        }
    }

    @include element(suffix) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        text-decoration: none;
        text-align: center;

        &::before {
            opacity: 0;
            content: '';
            position: absolute;
            width: 8px;
            height: 34px;
            right: 100%;
            top: 0;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(270deg, $color-white 0%, rgba(248, 248, 249, 0) 100%);
        }
    }

    @include element(caret) {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s ease;

        @include is(active) {
            transform: rotateZ(180deg);
        }
    }
}
</style>
