<template>
    <pendo-drawer
        :title="title"
        :visible="visible"
        push=".pendo-page"
        size="400px"
        placement="right"
        @close="$emit('close')">
        <template #body>
            <note-drawer-content
                v-if="isTypeNote"
                :active-user="activeUser"
                :display-alert="displayAlert"
                :has-admin-access="hasAdminAccess"
                :note-action="noteAction"
                :note-error="noteError"
                v-on="$listeners" />
            <user-impact-drawer-content
                v-else
                :impacted-users="impactedUsers"
                :guide-visitor-agg-status="guideVisitorAggStatus"
                :csv-agg-state="csvAggState"
                :product-type="productType"
                v-on="$listeners" />
        </template>
    </pendo-drawer>
</template>

<script>
import PendoDrawer from '@/components/drawer/pendo-drawer';
import NoteDrawerContent from './notes/note-drawer-content';
import UserImpactDrawerContent from './user-impact/user-impact-drawer-content.vue';

export default {
    name: 'GuideAlertsDrawer',
    components: {
        PendoDrawer,
        NoteDrawerContent,
        UserImpactDrawerContent
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        isTypeNote: {
            type: Boolean,
            required: true
        },
        activeUser: {
            type: Object,
            required: true
        },
        displayAlert: {
            type: Object,
            default: () => {}
        },
        noteAction: {
            type: Object,
            default: () => {}
        },
        noteError: {
            type: Object,
            default: () => {}
        },
        hasAdminAccess: {
            type: Boolean,
            default: false
        },
        impactedUsers: {
            type: Array,
            default: () => []
        },
        guideVisitorAggStatus: {
            type: String,
            required: true
        },
        csvAggState: {
            type: Object,
            default: () => ({})
        },
        productType: {
            type: String,
            required: true
        }
    },
    computed: {
        title () {
            return this.isTypeNote ? 'Guide Activity' : 'Visitors Impacted';
        }
    }
};
</script>
