<template>
    <div
        class="pendo-progress-bar"
        :class="{
            'is-complete': isComplete,
            'is-in-progress': isInProgress,
            'is-not-started': isNotStarted
        }"
        role="progressbar"
        :aria-valuenow="internalValue"
        :style="styleObj">
        <svg :viewBox="`0 0 100 ${(relativeHeight * 2 + relativeStrokeWidth * 2) / 2}`">
            <rect
                ref="background"
                :width="100 - relativeStrokeWidth * 2"
                :x="relativeStrokeWidth"
                :y="relativeStrokeWidth"
                :height="relativeHeight - relativeStrokeWidth"
                :stroke-width="relativeStrokeWidth"
                :stroke="colors.default.stroke"
                :fill="colors.default.fill"
                :rx="svgRadius"
                :ry="svgRadius" />
            <rect
                ref="progress"
                :style="{
                    transition: `all ${speed}ms ${easing}`
                }"
                class="pendo-progress-bar__progress"
                :x="relativeStrokeWidth"
                :y="relativeStrokeWidth"
                :width="progressWidth"
                :height="relativeHeight - relativeStrokeWidth"
                :stroke-width="relativeStrokeWidth"
                :stroke="colors[isComplete ? 'complete' : 'progress'].stroke"
                :fill="colors[isComplete ? 'complete' : 'progress'].fill"
                :rx="svgRadius"
                :ry="svgRadius" />
        </svg>
        <resize-observer
            v-if="isPercentWidth"
            @notify="setComputedWidth" />
    </div>
</template>

<script>
import isNumber from 'lodash/isNumber';
import clamp from 'lodash/clamp';
import { ResizeObserver } from 'vue-resize';
import {
    isNotStarted,
    isInProgress,
    isComplete,
    getTrickleProgress,
    getRelativeThickness,
    getRelativeStrokeWidth,
    queue
} from '@/components/progress/utils';
import { isPercent } from '@/utils/dom';

export default {
    name: 'PendoProgressBar',
    components: {
        ResizeObserver
    },
    props: {
        /**
         * percent value for current progress
         */
        value: {
            type: Number,
            default: 0,
            required: true,
            validator: (val) => val >= 0 && val <= 100
        },
        /**
         * height of progress bar
         */
        height: {
            type: Number,
            default: 8
        },
        /**
         * width of progress bar. accepts `number` or `px` for fixed width
         * or `%` based to set flexible width
         */
        width: {
            type: [Number, String],
            default: 200
        },
        /**
         * width of progress bar border
         */
        strokeWidth: {
            type: Number,
            default: 1
        },
        /**
         * color config for progress states
         */
        colors: {
            type: Object,
            default: () => ({
                default: {
                    fill: '#DADCE5',
                    stroke: '#DADCE5'
                },
                progress: {
                    fill: '#128297',
                    stroke: '#128297'
                },
                complete: {
                    fill: '#00C583',
                    stroke: '#00C583'
                }
            })
        },
        /**
         * Automatically increment progress behavior by setting this to `true`. default: `false`
         */
        trickle: {
            type: Boolean,
            default: false
        },
        /**
         * Adjust how often to trickle/increment, in ms. default: `200`
         */
        trickleSpeed: {
            type: Number,
            default: 200
        },
        /**
         * Animation easing (a CSS easing string). default: `ease`
         */
        easing: {
            type: String,
            default: 'ease-in-out'
        },
        /**
         * Animation speed (in ms). default: `200`
         */
        speed: {
            type: Number,
            default: 200
        },
        /**
         * x and y radius for the progress bar
         */
        radius: {
            type: Number,
            default: -1
        }
    },
    data () {
        return {
            computedWidth: parseInt(this.width, 10),
            internalValue: this.value,
            minInProgressValue: 2,
            maxInProgressValue: 99.4
        };
    },
    computed: {
        isPercentWidth () {
            return isPercent(this.width);
        },
        styleObj () {
            if (isNumber(this.width)) {
                return {
                    width: `${this.width}px`
                };
            }

            return {
                width: this.width
            };
        },
        relativeStrokeWidth () {
            const { strokeWidth, computedWidth } = this;

            return getRelativeStrokeWidth({ strokeWidth, width: computedWidth });
        },
        relativeHeight () {
            const { height, computedWidth } = this;

            return getRelativeThickness({ thickness: height, width: computedWidth });
        },
        isInProgress () {
            return isInProgress(this.internalValue);
        },
        isComplete () {
            return isComplete(this.internalValue);
        },
        isNotStarted () {
            return isNotStarted(this.internalValue);
        },
        progressWidth () {
            const strokeWidth = this.relativeStrokeWidth * 2;
            const progress = this.internalValue - strokeWidth;

            return clamp(progress, 0, 100);
        },
        svgRadius () {
            return this.radius >= 0 ? this.radius : this.relativeHeight / 2;
        }
    },
    watch: {
        value () {
            if (!this.trickle) {
                this.internalValue = this.value;

                return;
            }

            const value = clamp(this.value, this.internalValue, 100);

            if (value === 100) {
                this.done();
            } else {
                this.increment(value);
            }
        }
    },
    async mounted () {
        await this.$nextTick();
        if (this.isPercentWidth) {
            this.setComputedWidth({ width: this.$el.offsetWidth });
        }
    },
    beforeDestroy () {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        setComputedWidth ({ width }) {
            requestAnimationFrame(() => {
                this.computedWidth = width;
            });
        },
        increment (amount) {
            if (this.isNotStarted) {
                this.start();
            }

            if (this.isComplete) {
                return;
            }

            if (typeof amount !== 'number') {
                amount = this.internalValue + getTrickleProgress(this.internalValue);
            }

            amount = clamp(amount, 0, this.maxInProgressValue);
            this.set(amount);
        },
        done (force) {
            if (!force && !this.internalValue) {
                return;
            }

            this.increment(30 + 50 * Math.random());
            this.set(100);
        },
        start () {
            if (this.isNotStarted) {
                this.set(0);
            }

            if (this.trickle) {
                this.timer = setInterval(() => {
                    this.increment();
                    if (this.isComplete) {
                        clearInterval(this.timer);
                    }
                }, this.trickleSpeed);
            }
        },
        set (value) {
            value = clamp(value, this.minInProgressValue, 100);

            queue((next) => {
                if (value === 1) {
                    setTimeout(() => {
                        setTimeout(() => {
                            this.internalValue = 100;
                            next();
                        }, this.speed);
                    }, this.speed);
                } else {
                    this.internalValue = value;
                    setTimeout(next, this.speed);
                }
            });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-progress-bar) {
    position: relative;
}
</style>
