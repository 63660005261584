<template>
    <v-popover
        v-bind="popoverAttrs"
        v-on="$listeners">
        <slot />
        <template #popover>
            <slot name="popover" />
        </template>
    </v-popover>
</template>

<script>
import { VPopover } from 'v-tooltip';

// NOTE: because we leverage passing unknown props via this.$attrs
// our defaults should follow same kebab-case format as this.$attrs
const DEFAULT_POPOVER_SETTINGS = {
    'popover-class': [],
    'popover-base-class': ['pendo-popover'],
    'popover-wrapper-class': ['pendo-popover__wrapper'],
    'popover-inner-class': ['pendo-popover__inner'],
    'popover-arrow-class': ['pendo-popover__arrow'],
    'popper-options': {},
    'auto-height': true,
    'handle-resize': true,
    'placement': 'right',
    'trigger': 'hover',
    'delay': 0,
    'offset': 8
};

export default {
    name: 'PendoPopover',
    components: {
        VPopover
    },
    inheritAttrs: false,
    props: {
        /**
         * popover theme
         * @values light, dark
         */
        theme: {
            type: String,
            default: 'light',
            validator: (theme) => ['light', 'dark'].includes(theme)
        },
        /**
         * enable/disable the caret arrow
         */
        arrow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        popoverAttrs () {
            const popoverProps = {
                ...this.$attrs
            };

            Object.keys(DEFAULT_POPOVER_SETTINGS).forEach((prop) => {
                if (!popoverProps[prop]) {
                    popoverProps[prop] = DEFAULT_POPOVER_SETTINGS[prop];
                } else {
                    popoverProps[prop] = Array.isArray(DEFAULT_POPOVER_SETTINGS[prop])
                        ? [...DEFAULT_POPOVER_SETTINGS[prop], popoverProps[prop]]
                        : popoverProps[prop];
                }
            });

            return popoverProps;
        }
    },
    created () {
        DEFAULT_POPOVER_SETTINGS['popover-class'] = [].concat(
            `pendo-popover--${this.theme}`,
            ...(this.arrow ? ['has-popover-arrow'] : [])
        );
    }
};
</script>

<style lang="scss">
@include block(pendo-popover) {
    z-index: 10001;
    border-radius: $border-radius-3;
    filter: drop-shadow(rgba(0, 0, 0, 0.12) 0 1px 3px);

    @include element(inner) {
        padding: 8px;
        @include font-family;
    }

    &[x-placement^='top'] {
        .pendo-popover__arrow,
        .pendo-popover__arrow:before {
            border-width: 8px 8px 0 8px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            left: calc(50% - 8px);
            margin-top: 0;
            margin-bottom: 0;
        }

        .pendo-popover__arrow {
            bottom: -8px;
        }

        .pendo-popover__arrow:before {
            bottom: 1px;
        }
    }

    &[x-placement^='bottom'] {
        .pendo-popover__arrow,
        .pendo-popover__arrow:before {
            border-width: 0 8px 8px 8px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            left: calc(50% - 8px);
            margin-top: 0;
            margin-bottom: 0;
        }

        .pendo-popover__arrow {
            top: -8px;
        }

        .pendo-popover__arrow:before {
            top: 1px;
        }
    }

    &[x-placement^='right'] {
        .pendo-popover__arrow,
        .pendo-popover__arrow:before {
            border-width: 8px 8px 8px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            top: calc(50% - 8px);
            margin-left: 0;
            margin-right: 0;
        }

        .pendo-popover__arrow {
            left: -8px;
        }

        .pendo-popover__arrow:before {
            left: 1px;
        }
    }

    &[x-placement^='left'] {
        .pendo-popover__arrow,
        .pendo-popover__arrow:before {
            border-width: 8px 0 8px 8px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            top: calc(50% - 8px);
            margin-left: 0;
            margin-right: 0;
        }

        .pendo-popover__arrow {
            right: -8px;
        }

        .pendo-popover__arrow:before {
            right: 1px;
        }
    }

    @include element(arrow) {
        display: none;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 4px;
        z-index: 1;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            z-index: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }

    @include modifier(light) {
        border: 1px solid $color-gray-40;
        background-color: $color-white;

        .pendo-popover__arrow {
            border-color: $color-gray-40;

            &:before {
                border-color: $color-white;
            }
        }
    }

    @include modifier(dark) {
        color: $color-white;
        background-color: $color-gray-110;

        .pendo-popover__arrow {
            border-color: $color-gray-110;

            &:before {
                display: none;
            }
        }
    }

    &.has-popover-arrow {
        .pendo-popover__arrow {
            display: unset;
        }
    }
}
</style>
