<template>
    <div class="edit-extension-domain-patterns">
        <div class="extension-domain-patterns-list">
            {{ domainPatternsListString }}
        </div>
        <pendo-button
            class="edit-extension-domain-patterns-button"
            theme="app"
            type="link"
            label="Edit"
            @click="editDomainPatterns" />
        <pendo-add-update-extension-app-modal
            v-if="showEditModal"
            :visible="showEditModal"
            :create-mode="false"
            :app="app"
            :saving="savingDomainPatterns"
            :read-only-mode="canUpdateDomainPatterns"
            :submission-error="submissionError"
            :websites-modal-alert-link="websitesModalAlertLink"
            @update="updateDomainPatterns"
            @close="closeEditModal" />
    </div>
</template>
<script>
import PendoButton from '@/components/button/pendo-button.vue';
import PendoAddUpdateExtensionAppModal from '@/composites/add-update-extension-app-modal/pendo-add-update-extension-app-modal.vue';

export default {
    name: 'EditExtensionDomainPatterns',
    components: {
        PendoButton,
        PendoAddUpdateExtensionAppModal
    },
    props: {
        app: {
            required: true,
            type: Object
        },
        submissionError: {
            type: Object,
            default: () => {}
        },
        savingDomainPatterns: {
            type: Boolean,
            default: false
        },
        websitesModalAlertLink: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            showEditModal: false,
            previousSaveState: this.savingDomainPatterns
        };
    },
    computed: {
        domainPatterns () {
            return this.app.extensionDomainPatterns ? [...this.app.extensionDomainPatterns] : [];
        },
        domainPatternsListString () {
            return this.domainPatterns.length ? this.domainPatterns.join(', ') : 'No websites configured.';
        },
        canUpdateDomainPatterns () {
            return this.app.extensionStructRegExps ? this.app.extensionStructRegExps.length > 0 : false;
        }
    },
    watch: {
        savingDomainPatterns () {
            if (this.previousSaveState && !this.savingDomainPatterns) {
                if (this.submissionError === null || this.submissionError === {}) {
                    this.closeEditModal();
                }
            }

            this.previousSaveState = this.savingDomainPatterns;
        }
    },
    methods: {
        editDomainPatterns () {
            this.showEditModal = true;
        },
        closeEditModal () {
            this.showEditModal = false;
        },
        updateDomainPatterns (domainPatterns) {
            this.$emit('update-websites', domainPatterns);
        }
    }
};
</script>
<style lang="scss" scoped>
.extension-domain-patterns-list {
    line-height: 1.5;
}
</style>
