<template>
    <div
        v-if="img || icon"
        class="pendo-app-icon">
        <img
            v-if="img"
            :height="size"
            :width="size"
            :alt="img.alt"
            :src="img.src">
        <pendo-icon
            v-if="!img && icon"
            v-bind="icon" />
    </div>
</template>

<script>
import get from 'lodash/get';
import PendoIcon from '@/components/icon/pendo-icon.vue';

const APP_ICONS = {
    android: 'android',
    extension: 'puzzle-piece',
    ios: 'ios',
    web: 'monitor'
};

export default {
    name: 'PendoAppIcon',
    components: {
        PendoIcon
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        size: {
            type: Number,
            default: 14
        }
    },
    computed: {
        isTrainingApp () {
            return get(this.app, 'applicationFlags.trainingApplication', false);
        },
        trainingAppWhiteLabelSettings () {
            return get(this.app, 'trainingAttributes.whiteLabelSettings', {});
        },
        displayName () {
            const name = this.app.displayName || this.app.name;

            if (this.isTrainingApp) {
                return get(this.app, 'trainingAttributes.displayName') || name;
            }

            return name;
        },
        img () {
            let src = this.app.faviconB64;
            if (this.isTrainingApp && this.trainingAppWhiteLabelSettings.logoUrl) {
                src = this.trainingAppWhiteLabelSettings.logoUrl;
            }

            if (src) {
                return {
                    src,
                    alt: `${this.displayName} logo`
                };
            }

            return null;
        },
        icon () {
            const type = APP_ICONS[this.app.platform];
            if (!type) {
                return null;
            }

            let fill = this.app.color;
            if (this.isTrainingApp && this.trainingAppWhiteLabelSettings.primaryColor) {
                fill = this.trainingAppWhiteLabelSettings.primaryColor;
            }

            return {
                fill,
                type,
                size: this.size,
                stroke: '#2A2C35'
            };
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-app-icon) {
    display: flex;
    align-items: center;
}
</style>
