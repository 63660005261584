<template>
    <div
        :class="spinnerProps.class"
        :style="{ color: spinnerProps.color }">
        <svg
            :width="`${spinnerProps.size}px`"
            :height="`${spinnerProps.size}px`"
            stroke="currentColor"
            viewBox="0 0 24 24"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            xmlns="http://www.w3.org/2000/svg">
            <line
                id="line-1"
                x1="12"
                y1="2"
                x2="12"
                y2="6">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-2"
                x1="16.24"
                y1="7.76"
                x2="19.07"
                y2="4.93">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.1s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-3"
                x1="18"
                y1="12"
                x2="22"
                y2="12">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.2s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-4"
                x1="16.24"
                y1="16.24"
                x2="19.07"
                y2="19.07">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.3s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-5"
                x1="12"
                y1="18"
                x2="12"
                y2="22">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.4s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-6"
                x1="4.93"
                y1="19.07"
                x2="7.76"
                y2="16.24">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.5s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-7"
                x1="2"
                y1="12"
                x2="6"
                y2="12">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.6s"
                    repeatCount="indefinite" />
            </line>
            <line
                id="line-8"
                x1="4.93"
                y1="4.93"
                x2="7.76"
                y2="7.76">
                <animate
                    attributeName="opacity"
                    from="1"
                    to="0.2"
                    dur="0.8s"
                    begin="0.7s"
                    repeatCount="indefinite" />
            </line>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Feather',
    inheritAttrs: false,
    props: {
        spinnerProps: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>

<style lang="scss">
#line-1 {
    opacity: 1;
}
#line-2 {
    opacity: 0.3;
}
#line-3 {
    opacity: 0.4;
}
#line-4 {
    opacity: 0.5;
}
#line-5 {
    opacity: 0.6;
}
#line-6 {
    opacity: 0.7;
}
#line-7 {
    opacity: 0.8;
}
#line-8 {
    opacity: 0.9;
}
</style>
