<template>
    <pendo-drawer
        :visible="visible"
        :class-name="[className, 'pendo-side-panel-drawer', noComponentClass]"
        :push="push"
        size="372px"
        placement="right"
        :z-index="zIndex"
        @close="$emit('close')">
        <template #header="{ close }">
            <component
                :is="header.component"
                v-if="header.component"
                v-bind="header.props"
                @close="close" />
            <template v-if="!header.component">
                <h3
                    v-pendo-tooltip="{
                        content: overflowTooltipContent,
                        classes: 'pendo-side-panel-drawer__title-tooltip',
                        delay: { show: 300, hide: 0 },
                        arrow: true,
                        multiline: true,
                        boundariesElement: 'body'
                    }"
                    class="pendo-side-panel-drawer__title"
                    @mouseenter="checkForOverflowingContent($event, headerTitleProp)"
                    @focusin="checkForOverflowingContent($event, headerTitleProp)">
                    {{ headerTitleProp }}
                </h3>
                <div class="pendo-drawer__close">
                    <pendo-button
                        theme="app"
                        type="tertiary"
                        icon="x"
                        icon-size="20"
                        @click="close" />
                </div>
            </template>
        </template>
        <template #body>
            <component
                :is="body.component"
                v-bind="body.props"
                v-on="$listeners"
                @close="$emit('close')" />
        </template>
    </pendo-drawer>
</template>

<script>
import PendoButton from '@/components/button/pendo-button.vue';
import PendoDrawer from '@/components/drawer/pendo-drawer.vue';
import PendoTooltip from '@/directives/tooltip/tooltip.vue';
import get from 'lodash/get';

export default {
    name: 'PendoSidePanelDrawer',
    components: {
        PendoButton,
        PendoDrawer
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: null
        },
        header: {
            type: Object,
            default: () => ({})
        },
        body: {
            type: Object,
            default: () => ({})
        },
        zIndex: {
            type: Number,
            default: 20000
        },
        push: {
            type: String,
            default: 'body'
        }
    },
    data () {
        return {
            // eslint-disable-next-line vue/no-computed-properties-in-data
            overflowTooltipContent: this.headerTitleProp
        };
    },
    computed: {
        noComponentClass () {
            return this.header.component ? '' : 'pendo-side-panel-drawer--no-component';
        },
        headerTitleProp () {
            return get(this.header, 'props.title', '');
        }
    },
    methods: {
        checkForOverflowingContent (event, tooltipText) {
            const { scrollWidth, clientWidth } = event.target;

            this.overflowTooltipContent = scrollWidth > clientWidth ? tooltipText : null;
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-side-panel-drawer) {
    @include element(title) {
        font-size: 24px;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 10px 0px;
    }

    @include element(title-tooltip) {
        &.pendo-tooltip.tooltip {
            z-index: 20001;
        }
    }

    .pendo-drawer__header {
        align-items: baseline;
    }

    .pendo-drawer__content {
        grid-template-rows: auto 1fr !important;
    }

    @include is(no-component) {
        .pendo-drawer__header {
            display: grid;
            grid-gap: 8px;
            margin: 16px 0;
            cursor: default;
        }
    }
}
</style>
