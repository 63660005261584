<template>
    <button
        ref="trigger"
        type="button"
        class="pendo-multiselect__trigger"
        aria-haspopup
        :disabled="multiselect.isDisabled"
        :aria-expanded="`${multiselect.isOpen}`"
        :class="[
            `pendo-multiselect__trigger--mini`,
            `pendo-multiselect__trigger--data-measure`,
            {
                'is-active': multiselect.isFocused || multiselect.isOpen,
                'is-open': multiselect.isOpen,
                'is-disabled': multiselect.isDisabled,
                'pendo-multiselect__trigger--searchable': multiselect.isSearchable
            }
        ]"
        @focus="multiselect.handleFocus"
        @blur="multiselect.handleBlur"
        @click="multiselect.handleTriggerClick"
        @keyup.esc="multiselect.deactivate"
        @keydown.tab="multiselect.handleKeydown('tab')"
        @keydown.up.prevent.self="multiselect.handleKeydown('up')"
        @keydown.enter="multiselect.handleKeydown('enter', $event)"
        @keydown.down.prevent.self="multiselect.handleKeydown('down')"
        @keyup.space.prevent.self.stop="multiselect.handleKeydown('space', $event)">
        <pendo-multiselect-value
            v-bind="{
                getOptionLabel: multiselect.getOptionLabel,
                isPlaceholderVisible: multiselect.isPlaceholderVisible,
                isSelectedLabelVisible: multiselect.isSelectedLabelVisible,
                placeholder: multiselect.placeholder,
                selectedLabel: multiselect.selectedLabel,
                selectedValue: multiselect.selectedValue,
                isOpen: multiselect.isOpen
            }"
            :class="{
                'is-disabled': multiselect.isDisabled
            }">
            <template #control>
                <pendo-multiselect-input
                    v-if="multiselect.isSearchable"
                    v-bind="{
                        computedPlaceholder: multiselect.computedPlaceholder,
                        disabled: multiselect.isDisabled,
                        placeholder: multiselect.placeholder,
                        id: multiselect.id,
                        isFullWidth: multiselect.isFullWidth,
                        isOpen: multiselect.isOpen,
                        isSearchIconVisible: multiselect.isSearchIconVisible,
                        updateInputValue: multiselect.updateInputValue,
                        shouldAnimateSearchEnterExit:
                            !!$scopedSlots.selectedLabel || !!$scopedSlots.placeholder
                                ? false
                                : multiselect.shouldAnimateSearchEnterExit
                    }"
                    @up="multiselect.handleKeydown('up')"
                    @down="multiselect.handleKeydown('down')"
                    @delete="multiselect.handleKeydown('delete')"
                    @right="multiselect.handleKeydown('right')"
                    @left="multiselect.handleKeydown('left')"
                    @enter="multiselect.handleKeydown('enter', $event)"
                    @space="multiselect.handleKeydown('space', $event)"
                    @tab="multiselect.handleKeydown('tab', $event)"
                    @esc="multiselect.deactivate" />
            </template>
            <template #placeholder="{ placeholder }">
                <pendo-icon
                    v-if="prefixIcon"
                    size="12"
                    :stroke-width="2.5"
                    :type="prefixIcon" />
                {{ placeholder }}
            </template>
            <template #selectedLabel="{ selectedLabel, option }">
                <pendo-icon
                    v-if="option.icon || prefixIcon"
                    size="12"
                    :stroke-width="2.5"
                    v-bind="option.icon || { type: prefixIcon }" />
                <div class="pendo-multiselect__label-container">
                    <span class="pendo-multiselect-selected__label">
                        {{ selectedLabel }}
                    </span>
                    <span
                        v-if="option.aside"
                        class="pendo-multiselect-selected__aside">
                        {{ option.aside }}
                    </span>
                </div>
            </template>
        </pendo-multiselect-value>
        <div class="pendo-multiselect__suffix">
            <pendo-icon
                v-if="!multiselect.loading"
                type="chevron-down"
                size="14"
                aria-hidden="true"
                class="pendo-multiselect__caret"
                :stroke-width="2.5"
                :class="{
                    'is-active': multiselect.isOpen
                }" />
            <pendo-loading-indicator
                v-if="multiselect.loading"
                type="material"
                :spinner-props="{
                    size: 12,
                    color: '#6A6C75'
                }" />
        </div>
    </button>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoMultiselectValue from '@/components/multiselect/pendo-multiselect-value';
import PendoMultiselectInput from '@/components/multiselect/pendo-multiselect-input';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';
import { setStyles } from '@/utils/dom';

export default {
    name: 'PendoDataMeasureTrigger',
    components: {
        PendoIcon,
        PendoLoadingIndicator,
        PendoMultiselectValue,
        PendoMultiselectInput
    },
    inject: ['multiselect'],
    inheritAttrs: false,
    props: {
        prefixIcon: {
            type: String,
            default: null
        }
    },
    watch: {
        'multiselect.isOpen': function (isOpen) {
            if (isOpen) {
                this.$el.focus();
            }
        },
        'multiselect.maxTriggerWidth': function () {
            setStyles(this.$refs.trigger, { maxWidth: this.multiselect.maxTriggerWidth });
        }
    },
    mounted () {
        setStyles(this.$refs.trigger, {
            minWidth: this.multiselect.isFullWidth ? '100%' : 0,
            maxWidth: this.multiselect.maxTriggerWidth
        });
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(trigger) {
        @include modifier(data-measure) {
            outline-offset: 4px;
            border: none;
            background: $color-white;
            padding: 0;
            color: $color-link;
            font-weight: 600;
            margin-left: 1px;

            &:after {
                content: '';
                border-bottom: 1px solid transparent;
                transition: border 100ms;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            &:hover,
            &.is-active,
            &.is-open {
                border: none;
                color: $color-link-hover;

                &:after {
                    border-color: $color-link-hover;
                }
            }

            @include is(disabled) {
                cursor: not-allowed;
                opacity: 0.7;
                color: $disabled-color;
                background: none;

                &:after {
                    border-bottom: none;
                }
            }

            @include element((placeholder, selected)) {
                user-select: none;
                height: 100%;
                display: grid;
                grid-gap: 4px;
                grid-auto-columns: auto;
                grid-auto-flow: column;
                align-items: center;
                overflow: hidden;
                font-size: 14px;

                @include element(label-container) {
                    @include ellipsis;
                }
            }

            @include element(caret) {
                transform: translateX(2px);

                @include is(active) {
                    transform: translateX(2px) rotateZ(180deg);
                }
            }

            @include element(suffix) {
                width: 16px;
                justify-content: flex-end;
            }
        }
    }
}
</style>
