<template>
    <label
        :id="id"
        class="pendo-checkbox"
        :class="{
            'is-checked': isChecked,
            'is-disabled': isDisabled,
            'is-indeterminate': indeterminate,
            'is-focus': focus
        }">
        <span class="pendo-checkbox__input">
            <!-- zero-width space character, used to align checkbox properly -->
            &#8203;
            <input
                v-if="trueValue || falseValue"
                ref="input"
                v-model="model"
                class="pendo-checkbox__original"
                type="checkbox"
                :name="name"
                :disabled="isDisabled"
                :true-value="trueValue"
                :false-value="falseValue"
                :aria-checked="indeterminate ? 'mixed' : isChecked"
                @change="handleChange"
                @focus="focus = true"
                @blur="focus = false">
            <input
                v-else
                ref="input"
                v-model="model"
                class="pendo-checkbox__original"
                :aria-checked="indeterminate ? 'mixed' : isChecked"
                type="checkbox"
                :disabled="isDisabled"
                :value="label"
                :name="name"
                @change="handleChange"
                @focus="focus = true"
                @blur="focus = false">
            <span class="pendo-checkbox__inner">
                <pendo-icon
                    v-if="isChecked || indeterminate"
                    :type="indeterminate ? 'minus' : 'check'"
                    :size="10"
                    :stroke-width="4"
                    aria-hidden="true"
                    stroke="#fff" />
            </span>
        </span>
        <span
            v-if="$slots.default || label"
            class="pendo-checkbox__label">
            <slot>{{ label }}</slot>
        </span>
    </label>
</template>

<script>
import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    name: 'PendoCheckbox',
    components: {
        PendoIcon
    },
    inject: {
        $checkboxGroup: {
            default: ''
        },
        $form: {
            default: ''
        },
        $formItem: {
            default: ''
        }
    },
    props: {
        /**
         * Bound value
         */
        value: {
            type: [String, Boolean, Number],
            default: false
        },
        /**
         * Label text to display next to checkbox
         */
        label: {
            type: String,
            default: null
        },
        /**
         * id for checkbox
         */
        id: {
            type: Number,
            default () {
                return this._uid;
            }
        },
        /**
         * whether the Checkbox is disabled
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * native 'name' attribute
         */
        name: {
            type: String,
            default: null
        },
        /**
         * same as `indeterminate` in native checkbox
         */
        indeterminate: {
            type: Boolean,
            default: false
        },
        /**
         * A Boolean attribute indicating whether or not this checkbox is checked by default (when the page loads).
         * It does not indicate whether this checkbox is currently checked
         * if the checkbox's value is changed, this prop does not reflect the change.
         */
        checked: {
            type: Boolean,
            default: false
        },
        /**
         * value of the Checkbox if it's checked
         */
        trueValue: {
            type: [String, Number],
            default: undefined
        },
        /**
         * value of the Checkbox if it's not checked
         */
        falseValue: {
            type: [String, Number],
            default: undefined
        },
        /**
         * The id of the element that this checkbox adjusts or modifies.
         * This id is used in the `aria-controls` attribute.
         * This attribute only appears if the `indeterminate` prop is set to true
         */
        controls: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            focus: false,
            isCheckboxGroupLimitExceeded: false
        };
    },
    computed: {
        model: {
            get () {
                if (this.isCheckboxGroupChild) {
                    return this.$checkboxGroup.value;
                }

                if (this.value !== undefined) {
                    return this.value;
                }

                return false;
            },
            set (val) {
                if (this.isCheckboxGroupChild) {
                    this.isCheckboxGroupLimitExceeded = false;
                    const { min, max } = this.$checkboxGroup;

                    if (min !== undefined && val.length < min) {
                        this.isCheckboxGroupLimitExceeded = true;
                    }

                    if (max !== undefined && val.length > max) {
                        this.isCheckboxGroupLimitExceeded = true;
                    }

                    if (this.isCheckboxGroupLimitExceeded === false) {
                        this.$checkboxGroup.$emit('input', val);
                    }
                } else {
                    this.$emit('input', val);
                }
            }
        },
        isChecked () {
            if (isBoolean(this.model)) {
                return this.model;
            }

            if (Array.isArray(this.model)) {
                return this.model.includes(this.label);
            }

            if (!isNil(this.model)) {
                return this.model === this.trueValue;
            }

            return false;
        },
        isCheckboxGroupChild () {
            return Boolean(this.$checkboxGroup);
        },
        isDisabled () {
            const isCheckboxGroupDisabled = get(this, '$checkboxGroup.disabled', false);
            const isFormDisabled = get(this, '$form.disabled', false);

            return this.disabled || isCheckboxGroupDisabled || isFormDisabled;
        }
    },
    watch: {
        value (value) {
            if (this.$formItem) {
                this.$formItem.onFieldChange(value);
            }
        }
    },
    created () {
        if (this.checked) {
            this.addToStore();
        }
    },
    mounted () {
        if (this.indeterminate) {
            this.$refs.input.setAttribute('aria-controls', this.controls);
        }
    },
    methods: {
        addToStore () {
            if (Array.isArray(this.model) && this.model.includes(this.label)) {
                this.model.push(this.label);
            } else {
                this.model = this.trueValue || true;
            }
        },
        handleChange (ev) {
            if (this.isCheckboxGroupLimitExceeded) {
                return;
            }
            let value;
            if (ev.target.checked) {
                value = this.trueValue === undefined ? true : this.trueValue;
            } else {
                value = this.falseValue === undefined ? false : this.falseValue;
            }
            this.$emit('change', value, ev);
            this.$nextTick(() => {
                if (this.isCheckboxGroupChild) {
                    this.$checkboxGroup.$emit('change', this.$checkboxGroup.value);
                }
            });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-checkbox) {
    @include font-base;
    color: $checkbox-color;
    font-weight: $checkbox-font-weight;
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
    line-height: 21px;
    margin: 0;

    & + .pendo-checkbox {
        margin-left: 16px;
    }

    @include element(input) {
        position: relative;
        display: flex;
        align-items: center;
    }

    @include element(inner) {
        position: relative;
        border: $checkbox-input-border;
        border-radius: $checkbox-input-border-radius;
        box-sizing: border-box;
        width: $checkbox-input-width;
        height: $checkbox-input-height;
        padding: 1px;
        background-color: $checkbox-input-fill;
        user-select: none;
        @include focus-ring(
            $style: 'base',
            $transitions: (
                border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
                background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46)
            )
        );

        &:hover {
            border-color: $checkbox-input-border-color-hover;
        }
    }

    @include element(original) {
        opacity: 0;
        outline: none;
        position: absolute;
        margin: 0;
        width: 0;
        height: 0;
        z-index: -1;

        &:focus-visible {
            + .pendo-checkbox__inner {
                @include focus-ring($style: 'focused');
            }
        }
    }

    @include element(label) {
        padding-left: 8px;
    }

    @include is((checked, indeterminate)) {
        @include element(inner) {
            background-color: $checkbox-checked-input-fill;
            border-color: $checkbox-checked-input-border-color;
        }
    }

    @include is(disabled) {
        cursor: not-allowed;

        @include element(label) {
            color: $disabled-color;
        }

        @include element(inner) {
            background-color: $disabled-fill;
            border-color: $disabled-border;
        }

        @include is(checked) {
            @include element(inner) {
                background-color: $disabled-selected-fill;
            }
        }
    }
}
</style>
